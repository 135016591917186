import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ACompanyService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 회사 기본 정보 가져오기
  getCompanyInfo(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'getCompanyInfo'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회사 기본 정보 저장하기
  setCompanyInfo( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'setCompanyInfo'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회사 계좌번호 리스트 가져오기
  getAccountList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'getAccountList'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회사 계좌번호 저장하기
  setAccount( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'setAccount'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회사 계좌번호 삭제하기
  deleteAccount( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'deleteAccount'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
