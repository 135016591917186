/*******************************************************************************
  설  명 : 창고관리 - 철수지시서
  작성일 : 2020-10-03
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AWarehouseService } from '@admin/service/warehouse.service';

import * as moment from 'moment';

const MAXROW: number = 54;

@Component({
  selector: 'app-withdraw-print',
  templateUrl: './withdraw-print.component.html',
  styleUrls: ['./withdraw-print.component.scss']
})
export class AWithdrawPrintComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private projectSeq: any = '';
  private whSeq: any = '';

  public warehouseList: any = [];
  public projectInfo: any = {};

  public totalQty: number = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aWarehouseService: AWarehouseService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.projectSeq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
      this.whSeq = ( typeof params.whSeq == 'undefined' || params.whSeq == '' ) ? '' : params.whSeq;

      if( this.projectSeq == '' ) {
        this.toastrService.error("정상적인 경로로 접근하시기 바랍니다.", "철수지시서");
        window.close();
      }

      this.getWarehouseStockMove();
    });
  }

  /*******************************************************************************
    설  명 : 철수지시서 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseStockMove() {
    this.aWarehouseService.getWarehouseStockMove( {seq: this.whSeq, projectSeq: this.projectSeq} ).then( response => {
      //console.log( response );
      if( response.ResultCode ) {
        this.warehouseList = response.data;
        this.projectInfo = response.info;

        let total: number = 0;
        for( let item of this.warehouseList ) {
          total += parseInt(item.total_stock);
        }

        this.totalQty = total;

      } else {
        this.warehouseList = [];
        this.projectInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray1( num: number ) {
    let tmp: any = [];
    for( let i = 0; i < num; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray( startNum: number ) {
    let tmp: any = [];

    startNum = startNum * MAXROW;

    for( let i = startNum; i < startNum + MAXROW; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 배열에 index 키 값을 가져온다.
    입력값 : index, key
    리턴값 : value
  *******************************************************************************/
  getListIndex( index, key ) {
    if( index >= this.warehouseList.length ) return '';
    else return this.warehouseList[index][key];
  }

  /*******************************************************************************
    설  명 : 카테고리가 이전 카테고리와 같을 경우 rowspan
    입력값 : index
    리턴값 : true / false
  *******************************************************************************/
  checkPrev( index: number ) {
    if( ( index % MAXROW ) == 0 ) return true;
    if( index >= this.warehouseList.length ) return true;

    if( this.warehouseList[index-1].category_name == this.warehouseList[index].category_name ) {
      return false;
    } else return true;
  }

  /*******************************************************************************
    설  명 : rowspan count 가져오기
    입력값 : index
    리턴값 : rowspan count
  *******************************************************************************/
  rowSpanCount( index: number ) {
    let count = 1;

    for( let i = index; i < this.warehouseList.length - 1; i++ ) {
      if( this.warehouseList[i]['category_name'] == this.warehouseList[i+1]['category_name'] ) count++;
      else break;
    }

    return count;
  }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }
  
}
