/*******************************************************************************
  설  명 : 회원 견적요청 컴포넌트
  작성일 : 2020-08-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ABoardService } from '@admin/service/board.service';
import { UtilService } from '@app/service/util.service';
import { AApplicationService } from '@admin/service/application.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-order-quotation',
  templateUrl: './member-order-quotation.component.html',
  styleUrls: ['./member-order-quotation.component.scss']
})
export class ACMemberOrderQuotationComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() mem_no: any;

  selectedRows: number = 0;
  quotationList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aApplicationService: AApplicationService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    private aBoardService: ABoardService
  ) {
    this.columnDefs = [
      {headerName: '선택', field: 'board_seq', cellClass: 'cp', width: 80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '상태', field: 'status', cellClass: 'cp center', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1' ) return '<span class="badge badge-primary f12 fn">처리완료</span>';
          else return '<span class="badge badge-success f12 fn">진행중</span>';
        }
      },
      {headerName: '제목', field: 'subject', cellClass: 'cp', width: 200 },
      {headerName: '구분', field: 'project_group_name', cellClass: 'cp center', width: 80 },
      {headerName: '전시/행사명', field: 'project_name', cellClass: 'cp', width: 150 },
      {headerName: '장소', field: 'project_place', width: 200, cellClass: 'cp'},
      {headerName: '기간', field: 'notice_date', cellClass: 'cp center', width: 150 },
      {headerName: '요청내용', field: 'contents', cellClass: 'cp', width: 300 },
      {headerName: '신청일시', field: 'write_date', cellClass: 'cp center', width: 130 }
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getEstimateList();
  }

   /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEstimateList() {
    this.aBoardService.getEstimateList( {mem_no: this.mem_no} ).then( response => {
      if( response.ResultCode ) {
        this.quotationList = response.data;
      } else {
        this.quotationList = [];
      }
    });
  }

}
