import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AApplicationService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // application component 용 거래처 주문정보 리스트 가져오기
  getACApplicationList( seq: any, mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'getACApplicationList'
      }, {
        seq: seq,
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 관리자 주문
  setApplication( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setApplication'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문정보 연도 리스트 가져오기
  getApplicationYearList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'getApplicationYearList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문정보 리스트 가져오기
  getApplicationList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'getApplicationList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문 상세정보 가져오기
  getApplicationDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'getApplicationDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문 상세정보 리스트 가져오기
  getMultipleApplicationDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'getMultipleApplicationDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // 주문관리 상세 주문정보 저장
  setOrderInfoSave( formValue: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setOrderInfoSave'
      }, {
        data: formValue
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문관리 상세 주문 공통 정보 수정
  setOrderDetailCommonInfoSave( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setOrderDetailCommonInfoSave'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문관리 상세 주문 상품 정보 변경
  setOrderDetailProductInfoSave( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setOrderDetailProductInfoSave'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문관리 상세 주문 상품 정보 삭제
  setOrderDetailProductInfoDelete( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setOrderDetailProductInfoDelete'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 주문취소
  setOrderCancel( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setOrderCancel'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 프로젝트에 주문완료된 내역을 가져온다.
  getOrderCompleteList( projectSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'getOrderCompleteList'
      }, {
        projectSeq: projectSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세금계산서 발행
  setInvoicePublish( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setInvoicePublish'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 현장주문 및 판매
  setOrderInfoOfflineSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'application',
        version: '1.0',
        action: 'setOrderInfoOfflineSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
