/*******************************************************************************
  설  명 : 결제내역 관리
  작성일 : 2020-08-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import { Subject, BehaviorSubject } from 'rxjs';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { AApplicationService } from '@admin/service/application.service';
import { APaymentService } from '@admin/service/payment.service';
import { AProjectService } from '@admin/service/project.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-payment-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class APaymentHistoryComponent implements OnInit {

 /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  paymentHistoryList: any = [];
  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  statistic: any = {
    card_total: 0,
    bank_total: 0,
    nbank_total: 0,
  };

  sendList: any = [];
  projectList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  search: any = {
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    searchText: '',
    payMethodType: '',
    dateType: '',
    term: '',
    project_seq: ''
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  private dateModel: NgbDateStruct;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aApplicationService: AApplicationService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    private calendar: NgbCalendar,
    public authService: AuthService,
    private aPaymentService: APaymentService,
    private aProjectService: AProjectService
  ) {
    this.columnDefs = [
      { headerName: '결제번호', field: 'op_seq', cellClass: 'cp center', width: 90, checkboxSelection: true },
      { headerName: '주문번호', field: 'om_seq', cellClass: 'cp center', width: 100 },
      {headerName: '상태', field: 'status', cellClass: 'cp center', width: 70, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1000' )
            return '<span class="badge badge-success f12 fn">정상</span>';
          else
            return '<span class="badge badge-secondary f12 fn">취소</span>';
        }
      },
      {headerName: '구분', field: 'pay_method', cellClass: 'cp center', width: 110, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.pay_method == 'CARD' )
            return '<span class="badge badge-success f12 fn">신용카드</span>';
          else if( params.data.pay_method == 'BANK' )
            return '<span class="badge badge-primary f12 fn">실시간계좌이체</span>';
          else if( params.data.pay_method == 'NBANK' )
            return '<span class="badge badge-warning f12 fn">무통장입급</span>';
          else
            return '<span class="badge badge-secondary f12 fn">현금지급</span>';
        }
      },
      {headerName: '결제금액', field: 'pay_amt', cellClass: 'cp right', width: 90 },
      {headerName: '결제일시', field: 'app_datetime', cellClass: 'cp center', width: 130 },
      {headerName: '취소일시', field: 'cancel_datetime', cellClass: 'cp center', width: 130 },
      {headerName: '카드(계좌)번호', field: 'card_num', cellClass: 'cp center', width: 150 },
      {headerName: '카드(계좌)명', field: 'card_name', cellClass: 'cp center', width: 120 },
      {headerName: '승인번호', field: 'app_no', cellClass: 'cp center', width: 130 },
      {headerName: 'TID', field: 'tid', cellClass: 'cp center', width: 150 },
      {headerName: 'OID', field: 'oid', cellClass: 'cp center', width: 150 },
      {headerName: '상품명', field: 'goods_name', cellClass: 'cp', width: 150 },
      {headerName: '구매자명', field: 'buyer_name', cellClass: 'cp center', width: 90 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    // 프로젝트 가져오기
    this.getProjectList();

    // 결제내역 리스트 가져오기
    this.searchInit();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

   /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      searchText: '',
      payMethodType: '',
      dateType: '',
      term: ''
    };

    this.setSearchDate('1', 'months');

    this.getPaymentHistoryList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getPaymentHistoryList();
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let search = {
      type: '',
      searchGroup: '',
      searchText: '',
      sdate: '',
      edate: ''
    };

    this.aProjectService.getProjectList( search ).then( response => {
      this.projectList = [];

      if( response.ResultCode ) {
        this.projectList.push({id: '', text: '프로젝트 선택'});

        response.data.forEach( row => {
          this.projectList.push({
            id: row.seq,
            text: row.name
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changed( item: any ) {
    this.search.project_seq = item.id;

    this.getPaymentHistoryList();
  }

  /*******************************************************************************
    설  명 : 위젯 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentHistoryStatistic() {
    this.aPaymentService.getPaymentHistoryStatistic( this.search ).then( response => {
      if( response.ResultCode ) {
        this.statistic = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 결제내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentHistoryList() {
    // 위젯 통계 가져오기
    this.getPaymentHistoryStatistic();

    this.aPaymentService.getPaymentHistoryList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.paymentHistoryList = response.data;
      } else {
        this.paymentHistoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getPaymentHistoryList();
    }
  }

  /*******************************************************************************
    설  명 : 검색날짜 지정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.sdate = this.dateModel;
    this.search.edate = this.calendar.getToday();

    this.getPaymentHistoryList();
  }

  /*******************************************************************************
    설  명 : 결제취소하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderCancel() {
    const rowSelection = this.gridApi.getSelectedRows()[0];

    if(  typeof rowSelection === 'undefined' ) {
      this.toastrService.error( '취소할 내역을 선택하시기 바랍니다.', '');
      return false;
    } else if( rowSelection.status != '1000' ) {
      this.toastrService.error( '결제상태가 정상 승인이 아닙니다.', '');
      return false;
    } else {

      if( confirm('주문 및 결제를 취소 하시겠습니까?') ) {
        const params: any = {
          seq: rowSelection.om_seq,
          status_code: '3000'
        };

        this.aApplicationService.setOrderCancel( params ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '' );
            this.getPaymentHistoryList();
          } else {
            this.toastrService.error( response.ResultMessage, '' );
          }
        }, error => {
          this.toastrService.error( error, '' );
        });       
      }

    }
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'om_seq' ) {
      this.router.navigate(
        ['/order/application/info'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.om_seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
  }

}
