import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { StatisticService } from '@admin/service/statistic.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { ACTooltipComponent } from '@admin/component/tooltip/tooltip.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-receivable-customer',
  templateUrl: './receivable-customer.component.html',
  styleUrls: ['./receivable-customer.component.scss']
})
export class ReceivableCustomerComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public kindList: any = [];

  // 검색 조건
  public search: any = {
    customer_seq: '',
    customer_name: '',
    customer_kind: '',
    sdate: '',
    edate: ''
  };

  // 미수금 합계
  public projectUncollectedTotalAmt: number = 0;

  // 매출 내역
  public detailList: any = [];
  public sumList: any = [];

  // 그리드 관련 선언
  gridApiCustomer: any;
  gridColumnApiCustomer: any;
  columnDefsCustomer: any;

  gridApiSum: any;
  gridColumnApiSum: any;
  columnDefsSum: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;
  pinnedBottomRowData: any = [];

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private aCommonService: ACommonService,
    private statisticService: StatisticService,
    public authService: AuthService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefsCustomer = [
      { headerName: '주문번호', field: 'order_seq', width: 100, cellClass: 'cp center' },
      { headerName: '주문일자', field: 'order_date', width: 120, cellClass: 'cp center' },
      { headerName: '분류', field: 'kind_name', width: 120, cellClass: 'cp center' },
      { headerName: '거래처', field: 'customer_name', width: 170, cellClass: 'cp left' },
      { headerName: '전시/행사', field: 'project_name', width: 270, cellClass: 'cp left' },
      { headerName: '공급가액', field: 'sum_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '부가세', field: 'sum_vat', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },      
      { headerName: '금액', field: 'sum_total_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '결제금액', field: 'pay_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '미수금', field: 'uncollectedAmt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '세금계산서', field: 'taxbill_yn', width: 100, cellClass: 'cp center',
        cellRenderer: 'agGridHtmlComponent',
          valueGetter: function(params) {
            if( params.data.taxbill_yn == '1' ) {
              return '<span class="badge badge-success f11">발행</span>';
            } else {
              return '<span class="badge badge-secondary f11">미발행</span>';
            }
          }
      },
      { headerName: '관리자메모', field: 'admin_memo', width: 200, cellClass: 'cp left',
        cellRendererFramework: ACTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
    ];

     // ag grid 컬럼 선언
     this.columnDefsSum = [
      { headerName: '', field: '', width: 780, cellClass: 'cp center' },
      { headerName: '', field: 'sum_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '', field: 'sum_vat', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '', field: 'sum_total_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '', field: 'sum_pay_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '', field: 'sum_uncollected_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '', field: '', width: 100, cellClass: 'cp center' },
      { headerName: '', field: '', width: 200, cellClass: 'cp center' }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      return parseInt(params.data[ params.column.colId ], 10);
    }
  }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();
    this.getCustomerUncollectedDetailList();
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 거래처 분류
    await this.aCommonService.getCommonList(3).then( response => {
      this.kindList = response.data;
    });
  }

  /*******************************************************************************
    성  명 : 미수금 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCustomerUncollectedDetailList() {
    await this.statisticService.getCustomerUncollectedDetailList( this.search ).then( response => {
      //console.log(response);
      if (response.ResultCode) {
        this.detailList = response.data;
        this.getGridSum(response.data);

        this.getTotalSum();
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 총 합계 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTotalSum() {
    let sumAmt = 0;
    let sumVat = 0;
    let sumTotalAmt = 0;
    let sumPayAmt = 0;
    let sumUncollectedAmt = 0;

    this.detailList.forEach(item => {
      sumAmt += parseInt(item.sum_amt);
      sumVat += parseInt(item.sum_vat);
      sumTotalAmt += parseInt(item.sum_total_amt);
      sumPayAmt += parseInt(item.pay_amt);
      sumUncollectedAmt += parseInt(item.uncollectedAmt);
    });

    let tmp: any = [];
    tmp.push({
      sum_amt: sumAmt,
      sum_vat: sumVat,
      sum_total_amt: sumTotalAmt,
      sum_pay_amt: sumPayAmt,
      sum_uncollected_amt: sumUncollectedAmt,
    });

    this.sumList = tmp;
  }
    
  /*******************************************************************************
     설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCustomer(params) {
    this.gridApiCustomer = params.api;
    this.gridColumnApiCustomer = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadySum(params) {
    this.gridApiSum = params.api;
    this.gridColumnApiSum = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApiCustomer.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 거래처 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.search.customer_seq = result.seq;
      this.search.customer_name = result.name;

      this.getCustomerUncollectedDetailList();
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getCustomerUncollectedDetailList();
  }
  
  /*******************************************************************************
     설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      customer_seq: '',
      customer_name: '',
      customer_kind: '',
      sdate: '',
      edate: ''
    };

    this.getCustomerUncollectedDetailList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate('');
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate('');
      obj.close();
    }
  }
  
  /*******************************************************************************
     설  명 : 엔터 입력 시 검색
    입력값 : $evnet
    리턴값 : 없음
  *******************************************************************************/
  setSearchInput( $event ) {
    if ( $event.key == 'Enter' ) {
      this.getCustomerUncollectedDetailList();
    }
  }

  /*******************************************************************************
    설  명 : 그리드 하단 합계 계산
  *******************************************************************************/
  getGridSum = (data: any) => this.pinnedBottomRowData = [{
    order_seq: '총합계',
    sum_amt: data.reduce((a: any, c: any) => a + parseInt(c.sum_amt), 0),
    sum_vat: data.reduce((a: any, c: any) => a + parseInt(c.sum_vat), 0),
    sum_total_amt: data.reduce((a: any, c: any) => a + parseInt(c.sum_total_amt), 0),
    pay_amt: data.reduce((a: any, c: any) => a + parseInt(c.pay_amt), 0),
    uncollectedAmt: data.reduce((a: any, c: any) => a + parseInt(c.uncollectedAmt), 0)
  }]

}
