/*******************************************************************************
  설  명 : 환불관리
  작성일 : 2020-02-20
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { ACommonService } from '@admin/service/common.service';
import { ARefundService } from '@admin/service/refund.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

import { ARefundDetailComponent } from './detail/detail.component';

import { ISms } from '@admin/component/send-sms/send-sms.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class ARefundComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 거래처분류, 거래처종류 리스트
  public refundList:any = [];
  public refundStatus:any = [];
  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  public sendList: ISms[] = [];

  dateModel: NgbDateStruct;

  // 검색 필드
  public search: any = {
    searchText: '',
    request_gbn: '',
    refund_status: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    dateType: '',
    term: ''
  }

  public statistic: any = {
    cancel_count: 0,
    refund_count: 0,
    complete_count: 0,
    uncompleted_count: 0
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aRefundService: ARefundService,
    private aCommonService: ACommonService,
    private calendar: NgbCalendar,
    public authService: AuthService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '선택', field: 'seq', width: 80, cellClass: 'cp', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '요청일', field: 'request_date', width: 130, cellClass: 'cp center' },
      {headerName: '상태', field: 'refund_status', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.refund_status == '0001' ) return '<span class="badge badge-secondary f12 fn">' + params.data.refund_status_name + '</span>';
          else if( params.data.refund_status == '0002' ) return '<span class="badge badge-success f12 fn">' + params.data.refund_status_name + '</span>';
          else if( params.data.refund_status == '0004' ) return '<span class="badge badge-primary f12 fn">' + params.data.refund_status_name + '</span>';
          else return '<span class="badge badge-danger f12 fn">' + params.data.refund_status_name + '</span>';
        }
      },
      {headerName: '구분', field: 'request_gbn', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.request_gbn == 'C') return '<span class="badge badge-warning f12 fn">취소</span>';
          else return '<span class="badge badge-danger f12 fn">환불</span>';
        }
      },
      {headerName: '회원정보', field: 'name', width: 170, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.name + '(' + params.data.id + ')';
        }
      },
      {headerName: '연락처', field: 'hp', width: 100, cellClass: 'cp center' },
      {headerName: '환불금액', field: 'refund_app_amt', width: 100, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.refund_app_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '환불은행', field: 'refund_bank', width: 100, cellClass: 'cp' },
      {headerName: '환불계좌', field: 'refund_account', width: 120, cellClass: 'cp' },
      {headerName: '환불계좌주', field: 'refund_depositary', width: 120, cellClass: 'cp' },
      {headerName: '요청메모', field: 'refund_memo', width: 200, cellClass: 'cp' },
      {headerName: '취소수량', field: 'refund_qty', width: 100, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.refund_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '환불신청금액', field: 'amt', width: 120, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '취소부가세', field: 'vat', width: 120, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.vat);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.getCommonList();

    this.searchInit();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 - 환불요청 상태 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 환불요청 상태
    this.aCommonService.getCommonList(45).then( response => {
      this.refundStatus = response.data;
    });
  }


  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      request_gbn: '',
      refund_status: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      dateType: '',
      term: ''
    }

    this.setSearchDate('1', 'months');

    this.getRefundList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getRefundList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  onSelectionChanged($event) {
    this.sendList = [];
    this.gridApi.getSelectedRows().forEach( row => {
      this.sendList.push({
        mem_no: row.mem_no,
        name: row.name,
        mobile: row.hp,
        id: row.id,
        smsYn: row.sms_yn,
        leavedate: row.leavedate,
      });
    });
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.addRefund( $event.data.seq );
    } else {

    }
  }

  /*******************************************************************************
    설  명 : 환불 신청 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addRefund( seq: any ) {
    const modalRef = this.modalService.open(ARefundDetailComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      this.getRefundList();
    }, (reason) => {
      console.log( reason );
    });
  }

  /*******************************************************************************
    설  명 : 거래처 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRefundList() {
    this.aRefundService.getRefundList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.refundList = response.data;
        this.statistic = response.statistic;
      } else {
        this.refundList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getRefundList();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.request_sdate = this.utilService.getDateStr( this.calendar.getToday() );
      obj.close();
    } else {
      this.search.request_edate = this.utilService.getDateStr( this.calendar.getToday() );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.request_sdate = this.dateModel;
    this.search.request_edate = this.calendar.getToday();

    this.getRefundList();
  }

}
