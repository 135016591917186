import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { ABoardService } from '@admin/service/board.service';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';
import { compilePipeFromMetadata } from '@angular/compiler';

@Component({
  selector: 'app-order-estimate-detail',
  templateUrl: './order-estimate-detail.component.html',
  styleUrls: ['./order-estimate-detail.component.scss']
})
export class AOrderEstimateDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardSeq: string;

  public projectTypeList: any = [];
  public excelList: any = [];

  public boardInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  dateModel: NgbDateStruct;

  // 그리드 관련 선언
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
     설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      board_seq: ['', []],
      board_id: ['estimate', []],
      mem_name: ['', []],
      write_date: ['', []],
      project_group: ['', [Validators.required]],
      project_name: ['', [Validators.required]],
      project_place: ['', [Validators.required]],
      notice_sdate: [null, [Validators.required]],
      notice_edate: [null, [Validators.required]],
      contents: ['', [Validators.required]],
      excel: [[], [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    private aBoardService: ABoardService,
    public authService: AuthService
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '부스', field: 'booth', cellClass: 'cp center', width: 140 },
      {headerName: '부스명', field: 'booth_name', cellClass: 'cp left', width: 200 },
      {headerName: '대분류', field: 'category_name', cellClass: 'cp right', width: 150 },
      {headerName: '상품', field: 'product_name', cellClass: 'cp left', width: 350 },
      {headerName: '수량', field: 'qty', cellClass: 'cp center', width: 100 },
      {headerName: '설치요청일시', field: 'install_date', cellClass: 'cp center', width: 150 },
      {headerName: '담당자명', field: 'manager_name', cellClass: 'cp center', width: 100 },
      {headerName: '담당자연락처', field: 'manager_hp', cellClass: 'cp center', width: 200 },
      {headerName: '담당자이메일', field: 'manager_email', cellClass: 'cp center', width: 200 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '등록된 데이터가 없습니다.';
  }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonListCode();

    this.activatedRoute.queryParams.subscribe(params => {
      this.boardSeq = params.boardSeq;

      this.form.patchValue({
        board_seq: this.boardSeq
      });

      this.getEstimateView();
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonListCode() {
    // 주문상태
    await this.aCommonService.getCommonListCode('PJT').then( response => {
      if ( response.ResultCode ) {
        this.projectTypeList = response.data;
      } else {
        this.projectTypeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 견적문의 상세내역 가져오기
  *******************************************************************************/
  getEstimateView() {
    this.aBoardService.getEstimateView( this.form.controls.board_id.value, this.form.controls.board_seq.value, false ).then( response => {
      if( response.ResultCode ) {

        this.boardInfo = response.data;
        this.excelList = response.excel;

        // 시작날짜
        if( this.boardInfo.notice_sdate != '' && this.boardInfo.notice_sdate != null ) {
          if( typeof this.boardInfo.notice_sdate != 'object' ) {
            this.dateModel = {
              year: parseInt( this.boardInfo.notice_sdate.substr( 0, 4 ) ),
              month: parseInt( this.boardInfo.notice_sdate.substr( 5, 2 ) ),
              day: parseInt( this.boardInfo.notice_sdate.substr( 8, 2 ) )
            };

            this.boardInfo.notice_sdate = this.dateModel;
          }
        } else {
          this.boardInfo.notice_sdate = null;
        }

        // 종료날짜
        if( this.boardInfo.notice_edate != '' && this.boardInfo.notice_edate != null ) {
          if( typeof this.boardInfo.notice_edate != 'object' ) {
            this.dateModel = {
              year: parseInt( this.boardInfo.notice_edate.substr( 0, 4 ) ),
              month: parseInt( this.boardInfo.notice_edate.substr( 5, 2 ) ),
              day: parseInt( this.boardInfo.notice_edate.substr( 8, 2 ) )
            };

            this.boardInfo.notice_edate = this.dateModel;
          }
        } else {
          this.boardInfo.notice_edate = null;
        }

        this.form.patchValue( this.boardInfo );

      } else {
        this.boardInfo = {};
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {

  }

  /*******************************************************************************
    설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(['/order/estimate']);
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aBoardService.setEstimateSave( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );
          this.router.navigate(['/order/estimate']);
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
     설  명 : 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeleteBoard() {
    if( confirm('삭제하시겠습니까?') ) {
      this.aBoardService.deleteBoard( this.form.controls.board_id.value, this.form.controls.board_seq.value ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );
          this.router.navigate(['/order/estimate']);
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      });
    }
  }

  /*******************************************************************************
     설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderApplication() {
    this.router.navigate(
      ['/order/application/info'], {
        relativeTo: this.activatedRoute,
        queryParams: { seq: '', boardSeq: this.boardSeq },
        queryParamsHandling: '', // remove to replace all query params by provided
    });    
  }

  /*******************************************************************************
    설  명 : 문의 상태 완료 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEstimateStatus() {
    this.aBoardService.setEstimateStatus( this.boardInfo.board_seq ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '' );
        this.router.navigate(['/order/estimate']);
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }
}
