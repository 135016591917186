/*******************************************************************************
  설  명 : 거래처/회원의 주문정보 컴포넌트
  작성일 : 2019-08-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { AApplicationService } from '@admin/service/application.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-customer-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ACApplicationComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() customerSeq: any;
  @Input() mem_no: any;

  public type: boolean = true; // 거래처/회원 구분, true = 거래처, false = 회원

  selectedRows: number = 0;
  applicationList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aApplicationService: AApplicationService,
    private agGridHtmlComponent: AgGridHtmlComponent
  ) {
    this.columnDefs = [
      {headerName: '주문번호', field: 'seq', cellClass: 'cp center' },
      {headerName: '주문상태', field: 'status', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let color: string;

          if( params.data.status == '주문접수' || params.data.status == '주문확정' || params.data.status == '부분결제' ) color = 'success';
          else if( params.data.status == '결제완료' || params.data.status == '진행완료' ) color = 'primary';
          else if( params.data.status == '취소신청' || params.data.status == '주문취소' || params.data.status == '부분취소' ) color = 'warning';
          else color = 'danger';

          return '<span class="badge badge-' + color + ' f12 fn">' + params.data.status + '</span>';
        }
      },
      {headerName: '주문일시', field: 'order_datetime', cellClass: 'cp center' },
      {headerName: '장소/부스', field: 'place', cellClass: 'cp' },
      {headerName: '상품', field: 'product_name', cellClass: 'cp' },
      {headerName: '수량', field: 'qty', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)"},
      {headerName: '세트', field: 'set_cnt', cellClass: 'cp right' },
      {headerName: '금액', field: 'unit_amt', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '부가세', field: 'vat', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '배송비', field: 'delivery_amt', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '할인', field: 'discount', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '결제금액', field: 'total_amt', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";


  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 주문정보 가져오기
    this.getApplicationList( this.customerSeq, this.mem_no );
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApplicationList( seq, mem_no ) {
    this.aApplicationService.getACApplicationList( seq, mem_no ).then( response => {
      this.applicationList = response.data;

      if( this.applicationList.length > 0 ) {
        this.applicationList.forEach( ( row, index ) => {
          this.totalAmt += parseInt( ( row.total_amt != null ) ? row.total_amt : 0 );
        });
      } else {
        this.totalAmt = 0;
      }
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

}
