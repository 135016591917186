/*******************************************************************************
  설  명 : 상품관리
  작성일 : 2019-10-22
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { config } from '@app/service/config.service';
import { ACommonService } from '@admin/service/common.service';
import { ACategoryService } from '@admin/service/category.service';
import { AProductService } from '@admin/service/product.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class AProductComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public categoryList: any = [];
  public gbnList: any = [];
  public typeList: any = [];

  selectedRows = 0;
  productList: any = [];
  statistic: any = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  search: any = {
    searchText: '',
    category_seq: '',
    use_yn: '',
    gbn: '',
    type: '',
    seq: '',
    barcode_yn: ''
  };

  option: any = {
    placeholder: '카테고리 선택'
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCategoryService: ACategoryService,
    private aCommonService: ACommonService,
    private aProductService: AProductService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      { headerName: '', field: 'checkbox', width: 40, pinned: true, filter: false,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
      },
      { headerName: '이동', field: 'sort_order', width: 50, rowDrag: true, pinned: true, filter: false },
      { headerName: '번호', field: 'seq', cellClass: 'cp center ag-cell80h', width: 50, filter: false, valueGetter: numberGetter },
      { headerName: '이미지', field: 'thumbnail_filename', cellClass: 'cp center ag-cell80h', width: 200, cellRenderer: 'agGridImageComponent' },
      { headerName: '카테고리', field: 'category_name', cellClass: 'cp left ag-cell80h', width: 200, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.category_name == params.data.parent_category_name ) {
            return params.data.category_name;
          } else {
            return params.data.parent_category_name + ' > ' + params.data.category_name;
          }
        }
      },
      { headerName: '상품종류', field: 'assembled_yn', cellClass: 'cp center ag-cell80h', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.gbn_name == '부속상품' ) {
            return '<span class="badge badge-secondary f12">부속상품</span>';
          } else if ( params.data.assembled_yn == false ) {
            return '<span class="badge badge-success f12">자체상품</span>';
          } else if ( params.data.assembled_yn == true ) {
            return '<span class="badge badge-primary f12">조립상품</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '추천', field: 'recommend', cellClass: 'cp ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.recommend == '1' ) {
            return '<span class="badge badge-success f12">추천상품</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '상품명', field: 'name', cellClass: 'cp ag-cell80h', width: 220 },
      { headerName: '상품구분', field: 'gbn_name', cellClass: 'cp center ag-cell80h', width: 80 },
      { headerName: '세트상품', field: 'type_name', cellClass: 'cp center ag-cell80h', width: 80 },
      { headerName: '규격', field: 'spec', cellClass: 'cp ag-cell80h', width: 150 },
      { headerName: '단가', field: 'sale_amt', cellClass: 'cp right ag-cell80h', width: 80,
        valueFormatter: currencyFormatter,
        valueGetter: numberGetter
      },
      { headerName: '구매단가', field: 'purchase_amt', cellClass: 'cp right ag-cell80h', width: 80,
        valueFormatter: currencyFormatter,
        valueGetter: numberGetter
      },
      { headerName: '발주단가', field: 'order_amt', cellClass: 'cp right ag-cell80h', width: 80,
        valueFormatter: currencyFormatter,
        valueGetter: numberGetter
      },
      { headerName: '주문수량', field: 'order_count', cellClass: 'cp right ag-cell80h', width: 80,
        valueFormatter: currencyFormatter,
        valueGetter: numberGetter
      },
      { headerName: '재고', field: 'remainder_stock', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        cellStyle(params: any) {
          if (params.value < 0) {
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return null;
          }
        },
        valueGetter: numberGetter
      },
      { headerName: '태그', field: 'associated_tag', cellClass: 'cp left ag-cell80h', width: 650 },
      { headerName: '등록일', field: 'write_date', cellClass: 'cp center ag-cell80h', width: 150 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      const value = params.data[ params.column.colId ];
      return ( value ) ? parseInt( value ) : value;
    }
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search = {
          searchText: params.searchText,
          category_seq: params.category_seq,
          use_yn: params.use_yn,
          gbn: params.gbn,
          type: params.type,
          seq: '',
          barcode_yn : ''
        };
      }
    });

    await this.getCategoryList();

    this.getStatistic();

    this.getCommonList();

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.aCategoryService.getCategoryList2().then( response => {
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.categoryList.push({
            id: String( row.category_code ),
            text: row.category_nm
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 상품 종류
    this.aCommonService.getCommonList(23).then( response => {
      if ( response.ResultCode ) {
        this.gbnList = response.data;
      } else {
        this.gbnList = [];
      }
    });

    // 상품 구분
    this.aCommonService.getCommonList(26).then( response => {
      if ( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      searchText: '',
      category_seq: '',
      use_yn: '',
      gbn: '',
      type: '',
      seq: '',
      barcode_yn: ''
    };

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductList() {
    this.aProductService.getProductList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.productList = response.data;
      } else {
        this.productList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 상품정보 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productAdd( seq ) {
    this.search.seq = seq;

    this.router.navigate(
    ['/product/list/add'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 그리드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if ( $event.colDef.field !== 'sort_order' && $event.colDef.field !== 'checkbox' ) {
      this.search.seq = $event.data.seq;

      this.router.navigate(
        ['/product/list/add'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.search,
          queryParamsHandling: '', // remove to replace all query params by provided
        });
    }
  }

  /*******************************************************************************
    설  명 : select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changed( item: any ) {
    this.search.category_seq = item.value;
  }

  /*******************************************************************************
    설  명 : 상품 전체 통계를 가져온다.
    입력값 : 없음
    리턴값 : 구성상품 전체 금액
  *******************************************************************************/
  getStatistic() {
    this.aProductService.getStatistic().then( response => {
      if ( response.ResultCode ) {
        this.statistic = response.data;
      } else {
        this.statistic = {
          total_count: 0,
          use_count: 0,
          notuse_count: 0,
          noset_count: 0,
          set_count: 0
        };
      }
    }, error => {
      this.toastrService.error( '상품 정보를 가져오는데 실패하였습니다.', '상품정보');
    });
  }

  /*******************************************************************************
    설  명 : 상품 순서저장
    입력값 : $evnet
    리턴값 : 없음
  *******************************************************************************/
  setSortOrder() {
    const gridData = this.gridApi.rowModel.rowsToDisplay;

    if ( gridData.length < 1 ) {
      this.toastrService.error( '저장할 데이터가 없습니다.', '');
      return false;
    }

    const seqArray = [];
    gridData.forEach(item => {
      seqArray.push( item.data.seq );
    });

    this.aProductService.setSortOrder( seqArray ).then( response => {
      // console.log(response);
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    let str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 엔터 입력 시 검색
    입력값 : $evnet
    리턴값 : 없음
  *******************************************************************************/
  getProductListSearch( $event ) {
    if ( $event.key == 'Enter' ) {
      this.getProductList();
    }
  }

  /*******************************************************************************
    설  명 : 추천 / 추천해제
    입력값 : check = true : 추천, false : 추천해제
    리턴값 : 없음
  *******************************************************************************/
  setRecommend( check: any ) {
    let nodes = this.gridApi.getSelectedRows();
    let title = ( check ) ? '추천상품' : '추천상품 해제';

    if( nodes.length < 1 ) {
      this.toastrService.error( '상품을 선택하시기 바랍니다.' , title);
      return false;
    }

    let seq: any = [];

    for(let node of nodes ) {
      seq.push( node.seq );
    }

    this.aProductService.setRecommend( check, seq ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage , title);
        this.getProductList();

      } else {
        this.toastrService.error( response.ResultMessage , title);
      }
    });
  }
}
