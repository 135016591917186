import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { AProductService } from '@admin/service/product.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-barcode-find',
  templateUrl: './barcode-find.component.html',
  styleUrls: ['./barcode-find.component.scss']
})
export class ACBarcodeFindComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  product_seq: any = '';  // 상품 코드
  warehouse_seq_out: any = '';  // 출고창고 번호

  barcodeList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aProductService: AProductService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    public activeModal: NgbActiveModal,
  ) {
    this.columnDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', width: 80,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '바코드 발행번호', field: 'barcode', cellClass: 'cp left', width: 120 },
      { headerName: '입고창고(현재위치)', field: 'warehouse_in_name', cellClass: 'cp left', width: 150 },
      { headerName: '입고일자', field: 'inout_date', cellClass: 'cp left', width: 100 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: false
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "해당 창고에 입고된 바코드상품이 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 바코드 리스트 가져오기
    this.getProductBarcodeList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductBarcodeList() {
    let params = {
      product_seq: this.product_seq,
      warehouse_seq_out: this.warehouse_seq_out,
      use_yn: true,
    };

    this.aProductService.getProductBarcodeList( params ).then( response => {
      if( response.ResultCode ) {
        this.barcodeList = response.data;
      } else {
        this.barcodeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 바코드 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  select() {
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( "바코드를 선택하세요.", '바코드 검색');
      return false;
    } else {

      let tmp = [];
      nodes.forEach(item => {
        tmp.push(item.data.barcode);
      });

      this.activeModal.close( tmp );
    }
  }
}
