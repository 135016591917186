/*******************************************************************************
  설  명 : 회원 결제정보 컴포넌트
  작성일 : 2020-08-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { APaymentService } from '@admin/service/payment.service';
import { AApplicationService } from '@admin/service/application.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-payment',
  templateUrl: './member-payment.component.html',
  styleUrls: ['./member-payment.component.scss']
})
export class ACMemberPaymentComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() mem_no: any;

  selectedRows: number = 0;
  paymentList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aApplicationService: AApplicationService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    private aPaymentService: APaymentService
  ) {
    this.columnDefs = [
      {headerName: '결제번호', field: 'op_seq', cellClass: 'cp center', width: 80 },
      {headerName: '상태', field: 'status', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1000' )
            return '<span class="badge badge-success f12 fn">정상</span>';
          else
            return '<span class="badge badge-secondary f12 fn">취소</span>';
        }
      },
      {headerName: '구분', field: 'pay_method', cellClass: 'cp center', width: 120, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.pay_method == 'CARD' )
            return '<span class="badge badge-success f12 fn">신용카드</span>';
          else if( params.data.pay_method == 'BANK' )
            return '<span class="badge badge-primary f12 fn">실시간계좌이체</span>';
          else if( params.data.pay_method == 'NBANK' )
            return '<span class="badge badge-warning f12 fn">무통장입급</span>';
          else
            return '<span class="badge badge-secondary f12 fn">현금지급</span>';
        }
      },
      {headerName: '결제금액', field: 'pay_amt', cellClass: 'cp right', width: 100 },
      {headerName: '결제일시', field: 'app_datetime', cellClass: 'cp center', width: 120 },
      {headerName: '취소일시', field: 'cancel_datetime', cellClass: 'cp center', width: 120},
      {headerName: '카드(계좌)번호', field: 'card_num', cellClass: 'cp', width: 150 },
      {headerName: '카드(계좌)명', field: 'card_name', cellClass: 'cp', width: 120 },
      {headerName: '승인번호', field: 'app_no', cellClass: 'cp center', width: 100},
      {headerName: 'TID', field: 'tid', cellClass: 'cp center', width: 200 },
      {headerName: 'OID', field: 'oid', cellClass: 'cp center', width: 150 },
      {headerName: '상품명', field: 'goods_name', cellClass: 'cp', width: 150},
      {headerName: '구매자명', field: 'buyer_name', cellClass: 'cp center', width: 100},
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getPaymentMemberList();
  }

   /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 회원의 결제내역을 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentMemberList() {
    this.aPaymentService.getPaymentMemberList( this.mem_no ).then( response => {
      if( response.ResultCode ) {
        this.paymentList = response.data;

        this.totalAmt = 0;
        for( let item of this.paymentList ) {
          this.totalAmt += parseInt(item.pay_amt.replace(',', ''));
        }
      } else {
        this.paymentList = [];
      }
    });
  }

}
