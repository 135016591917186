import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { StatisticService } from '@admin/service/statistic.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import * as moment from 'moment';

@Component({
  selector: 'app-month-sales',
  templateUrl: './month-sales.component.html',
  styleUrls: ['./month-sales.component.scss']
})
export class MonthSalesComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectTypeList: any = [];

  // 차트 관련 변수
  public barChartData: any = null;
  public chartType = 'bar';
  public chartLabel: string[] = [];

  // 차트 옵션
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') + ' 원';
        }
      }
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true,
          callback : value => value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }
      }]
    }
  };

  // 검색 조건
  public search: any = {
    nowYear: moment().format('YYYY'),
    projectType: ''
  };

  // 매출 내역
  public salesList: any = [];

  // 공통코드 그룹 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private calendar: NgbCalendar,
    private aCommonService: ACommonService,
    private statisticService: StatisticService,
    public authService: AuthService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: 'year', width: 160, cellClass: 'cp center'},
      { headerName: '1월', field: 'january', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '2월', field: 'february', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '3월', field: 'march', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '4월', field: 'april', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '5월', field: 'may', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '6월', field: 'june', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '7월', field: 'july', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '8월', field: 'august', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '9월', field: 'september', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '10월', field: 'october', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '11월', field: 'november', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '12월', field: 'december', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '합계', field: 'total', width: 110, cellClass: 'cp right',
      valueFormatter: currencyFormatter, valueGetter: numberGetter },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      return parseInt(params.data[ params.column.colId ], 10);
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonListCode();

    this.getMonthlySalesList();
  }

  /*******************************************************************************
    설  명 : 주문상태 리스트 가져오기
  *******************************************************************************/
  getCommonListCode() {
    this.aCommonService.getCommonListCode( 'PJT' ).then( response => {
      if( response.ResultCode ) {
        this.projectTypeList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 월별 매출현황 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMonthlySalesList() {
    this.statisticService.getMonthlySalesList( this.search ).then( response => {
      if( response.ResultCode ) {
        if( response.data.length > 0 ) {
          response.data.forEach(item => {
            item.total = parseInt(item.january || 0) +
                          parseInt(item.february || 0) +
                          parseInt(item.march || 0) +
                          parseInt(item.april || 0) +
                          parseInt(item.may || 0) +
                          parseInt(item.june || 0) +
                          parseInt(item.july || 0) +
                          parseInt(item.august || 0) +
                          parseInt(item.september || 0) +
                          parseInt(item.october || 0) +
                          parseInt(item.november || 0) +
                          parseInt(item.december || 0);
          });
        }

        this.salesList = response.data;

        this.salesList.push({
          year: '전년대비(%)',
          january: ( response.data[1].january > 0 ) ? ( response.data[0].january - response.data[1].january ) / response.data[1].january * 100 : 0,
          february: ( response.data[1].february > 0 ) ? ( response.data[0].february - response.data[1].february ) / response.data[1].february * 100 : 0,
          march: ( response.data[1].march > 0 ) ? ( response.data[0].march - response.data[1].march ) / response.data[1].march * 100 : 0,
          april: ( response.data[1].april > 0 ) ? ( response.data[0].april - response.data[1].april ) / response.data[1].april * 100 : 0,
          may: ( response.data[1].may > 0 ) ? ( response.data[0].may - response.data[1].may ) / response.data[1].may * 100 : 0,
          june: ( response.data[1].june > 0 ) ? ( response.data[0].june - response.data[1].june ) / response.data[1].june * 100 : 0,
          july: ( response.data[1].july > 0 ) ? ( response.data[0].july - response.data[1].july ) / response.data[1].july * 100 : 0,
          august: ( response.data[1].august > 0 ) ? ( response.data[0].august - response.data[1].august ) / response.data[1].august * 100 : 0,
          september: ( response.data[1].september > 0 ) ? ( response.data[0].september - response.data[1].september ) / response.data[1].september * 100 : 0,
          october: ( response.data[1].october > 0 ) ? ( response.data[0].october - response.data[1].october ) / response.data[1].october * 100 : 0,
          november: ( response.data[1].november > 0 ) ? ( response.data[0].november - response.data[1].november ) / response.data[1].november * 100 : 0,
          december: ( response.data[1].december > 0 ) ? ( response.data[0].december - response.data[1].december ) / response.data[1].december * 100 : 0,
          total: ( response.data[1].total > 0 ) ? ( response.data[0].total - response.data[1].total ) / response.data[1].total * 100 : 0
        });

        this.chartFilled();
      }
    });
  }

  /*******************************************************************************
    설  명 : 차트 데이터 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  chartFilled() {
    let chartData: any = {
      data: [],
      label : this.salesList[0].year + '년'
    };

    let prevChartData: any = {
      data: [],
      label : this.salesList[1].year + '년'
    };

    this.chartLabel = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
    //for ( const item of this.salesList ) {
    //this.chartLabel.push( this.salesList[0].year + '년' );

    chartData.data.push( this.salesList[0].january );
    chartData.data.push( this.salesList[0].february );
    chartData.data.push( this.salesList[0].march );
    chartData.data.push( this.salesList[0].april );
    chartData.data.push( this.salesList[0].may );
    chartData.data.push( this.salesList[0].june );
    chartData.data.push( this.salesList[0].july );
    chartData.data.push( this.salesList[0].august );
    chartData.data.push( this.salesList[0].september );
    chartData.data.push( this.salesList[0].october );
    chartData.data.push( this.salesList[0].november );
    chartData.data.push( this.salesList[0].december );

    prevChartData.data.push( this.salesList[1].january );
    prevChartData.data.push( this.salesList[1].february );
    prevChartData.data.push( this.salesList[1].march );
    prevChartData.data.push( this.salesList[1].april );
    prevChartData.data.push( this.salesList[1].may );
    prevChartData.data.push( this.salesList[1].june );
    prevChartData.data.push( this.salesList[1].july );
    prevChartData.data.push( this.salesList[1].august );
    prevChartData.data.push( this.salesList[1].september );
    prevChartData.data.push( this.salesList[1].october );
    prevChartData.data.push( this.salesList[1].november );
    prevChartData.data.push( this.salesList[1].december );


    //}
    this.barChartData = [prevChartData, chartData];
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if ( $event.colDef.field !== 'store_name' ) {
    }
  }

  /*******************************************************************************
    설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      nowYear: moment().format('YYYY'),
      projectType: ''
    };

    this.getMonthlySalesList();
  }

  /*******************************************************************************
    설  명 : 이전 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  prevYear() {
    this.search.nowYear = moment( this.search.nowYear + '-01-01' ).add(-1, 'year').format('YYYY');

    this.getMonthlySalesList();
  }

  /*******************************************************************************
    설  명 : 다음 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nextYear() {
    if( moment().format('YYYY') !== this.search.nowYear ) {
      this.search.nowYear = moment( this.search.nowYear + '-01-01' ).add(1, 'year').format('YYYY');

      this.getMonthlySalesList();
    }
  }

  /*******************************************************************************
    설  명 : 프로젝트구분 검색 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchProjectType( val: any ) {
    this.search.projectType = val;

    this.getMonthlySalesList();
  }

}
