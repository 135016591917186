import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ABoardService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfoList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 견적요청 리스트 가져오기
  getEstimateList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getEstimateList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 1:1 문의 가져오기
  getBoardQNAList( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardQNAList'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfo(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfoId(board_id): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoId'
      }, {
        board_id: board_id
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardView(board_id, board_seq, view_check ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getEstimateView(board_id, board_seq, view_check): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getEstimateView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 테이블 이름 리스트 가져오기
  getTableNameList( type: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getTableNameList'
      }, {
        type: type
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 캐리어 정보 저장하기
  setBoardInfo( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setBoardInfo'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 삭제하기
  deleteBoardInfo( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteBoardInfo'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 테이블 생성
  setBoardTableName( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setBoardTableName'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 저장
  setWrite( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setWrite'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 견적문의 저장
  setEstimateSave( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setEstimateSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 견적 문의 완료 처리
  setEstimateStatus( board_seq ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setEstimateStatus'
      }, {
        board_seq: board_seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }
  

  // 게시판 리스트 가져오기
  getBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 삭제
  deleteBoard( board_id, board_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteBoard'
      }, {
        board_id: board_id,
        board_seq: board_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 첨부파일 삭제
  deleteUploadFile( board_id, board_seq, seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteUploadFile'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  getBoardQnaInfo( board_id ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardQnaInfo'
      }, { board_id: board_id }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 마지막 업데이트 내역 가져오기
  getUpdateList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getUpdateList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  updateOrderApplication( boardSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'updateOrderApplication'
      }, {
        boardSeq: boardSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
