import { Injectable } from '@angular/core';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(
    public restful: RestfulService
  ) { }

  // 등록된 프로젝트 연도 리스트
  getProjectYearList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectYearList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 프로젝트별 매출현황 리스트
  getProjectSalesTotalList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectSalesTotalList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 프로젝트별 수입 리스트
  getProjectSalesIncomeList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectSalesIncomeList'
      }, {
        projectSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 프로젝트별 지출 리스트
  getProjectSalesSpendingList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectSalesSpendingList'
      }, {
        projectSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 월별매출현황 리스트
  getMonthlySalesList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getMonthlySalesList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 미수금 총계
  getProjectUncollectedTotal( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectUncollectedTotal'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 프로젝트별 미수금 리스트
  getProjectUncollectedTotalList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectUncollectedTotalList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 프로젝트별 미수금 디테일 리스트
  getProjectUncollectedDetailList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectUncollectedDetailList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 거래처별 미수금 디테일 리스트
  getCustomerUncollectedDetailList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getCustomerUncollectedDetailList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 매입현황 리스트
  getProjectPurchaseList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getProjectPurchaseList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
