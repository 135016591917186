import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ACustomerService {

  public selectedItem: any;

  // 관리자 거래처 담당자 변경여부
  private refreshCustomer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get getRefreshCustomer() {
    return this.refreshCustomer.asObservable();
  }

  /*******************************************************************************
    설  명 : 로그인
  *******************************************************************************/
  setRefreshCustomer() {
    this.refreshCustomer.next(true);
  }

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 거래처 정보 가져오기
  getCustomerList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'getCustomerList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 가져오기
  getCustomerInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'getCustomerInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 거래처 정보 저장하기
  setCustomer( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'setCustomer'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 삭제하기
  deleteCustomer( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'deleteCustomer'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 저장
  setCustomerManager( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'setCustomerManager'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 정보 가져오기
  getCustomerManager( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'getCustomerManager'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 정보 삭제하기
  deleteCustomerManager( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'deleteCustomerManager'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처별 할인 금액 가져오기
  getProductDiscountList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'getProductDiscountList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  setProductDiscountAmt( seq: any, data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'customer',
        version: '1.0',
        action: 'setProductDiscountAmt'
      }, {
        seq: seq,
        amtData: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
