/*******************************************************************************
  설  명 : 공통코드 추가
  작성일 : 2019-09-21
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { ApiResponse } from '@app/service/api-response';

@Component({
  selector: 'app-common-code-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ACommonCodeAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedItem: any;

  public title: string = '추가';

  public form: FormGroup;
  formErrors = {};
  errorResponse: ApiResponse;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ["", []],
      group_seq:["", [Validators.required]],
      code:["", [Validators.required]],
      name:["", [Validators.required]],
      common_code:["", [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')]],
      common_name:["", [Validators.required, Validators.maxLength(45)]],
      common_ename:["", [Validators.maxLength(45)]],
      use_yn:["", [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private commonService: ACommonService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    public authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if( this.selectedItem.seq == 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';
    }

    console.log( this.selectedItem );

    this.form.patchValue( this.selectedItem );
  }

  /*******************************************************************************
    설  명 : 공통코드 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid){
      this.commonService.setAddCommonCode( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.toastrService.error('등록에 실패하였습니다.', '');
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    this.commonService.setCommonDelete( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success('정상적으로 삭제되었습니다.', '');
        this.activeModal.close();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
    .catch(response => {
      this.toastrService.error('삭제에 실패하였습니다.', '');
      this.errorResponse = response;
      this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
    });
  }

}
