/*******************************************************************************
  설  명 : 재고관리
  작성일 : 2019-12-07
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import {forkJoin} from 'rxjs';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';

import { ADiscountService } from '@admin/service/discount.service';
import { ACommonService } from '@admin/service/common.service';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { ACategoryService } from '@admin/service/category.service';
import { AStockService } from '@admin/service/stock.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AStockImportComponent } from './import/import.component';
import { AStockLossComponent } from './loss/loss.component';
import { AStockMoveComponent } from './move/move.component';
import { AStockPurchaseComponent } from './purchase/purchase.component';

import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACProductFindGridComponent } from '@admin/component/product-find-grid/product-find-grid.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class AStockComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  stockList: any = [];
  stockDetailList: any = [];
  whStockList: any = [];
  barcodeStockList: any = [];

  warehouseList: any = [];
  categoryList: any = [];
  
  gridApi: any;
  gridColumnApi: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;

  gridApiWarehouse: any;
  gridColumnApiWarehouse: any;

  gridApiBarcode: any;
  gridColumnApiBarcode: any;

  columnDefs: any;
  columnDetailDefs: any;
  columnWarehouseDefs: any;
  columnBarcodeDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    warehouse_seq: '',
    category_code: '',
    product_name: '',
    product_seq: '',
    date: this.calendar.getToday()
  }

  inout: string = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aDiscountService: ADiscountService,
    private aCommonService: ACommonService,
    private aWarehouseService: AWarehouseService,
    private aCategoryService: ACategoryService,
    private aStockService: AStockService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {headerName: '상품코드', field: 'seq', cellClass: 'cp center' },
      {headerName: '구분', field: 'assembled_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.gbn_code == '0003' )
            return '<span class="badge badge-secondary f12">부속상품</span>';
          else if( params.data.assembled_yn == '1' )
            return '<span class="badge badge-primary f12">조립상품</span>';
          else
            return '<span class="badge badge-success f12">자체상품</span>';
        }
      },
      {headerName: '바코드', field: 'barcode_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if ( params.data.barcode_yn == '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
      {headerName: '대분류', field: 'parent_category_name', cellClass: 'cp left' },
      {headerName: '중분류', field: 'category_name', cellClass: 'cp left' },
      {headerName: '상품명', field: 'name', cellClass: 'cp left' },
      {headerName: '총재고수량', field: 'total_stock', cellClass: 'cp right',
        cellStyle: function(params) {
          if (parseInt(params.data.total_stock) < 1) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return null;
          }
        }
      },
      {headerName: '주문수량', field: 'order_count', cellClass: 'cp right' },
      {headerName: '본사과부족', field: 'cnt', cellClass: 'cp right',
        cellStyle: function(params) {
          if (parseInt(params.data.cnt) < 0) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return null;
          }
        }
      },
    ];

    this.columnDetailDefs = [
      {headerName: '코드', field: 'seq', cellClass: 'cp center' },
      {headerName: '일자', field: 'inout_date', cellClass: 'cp center' },
      {headerName: '입출고구분', field: 'inout_gbn_name', cellClass: 'cp left' },
      {headerName: '창고', field: 'warehouse_name', cellClass: 'cp left' },
      {headerName: '수량', field: 'inout_qty', cellClass: 'cp right' },
      {headerName: '메모', field: 'memo', cellClass: 'cp left' },
      {headerName: '연계코드', field: 'group_seq', hide: true },
    ];

    this.columnWarehouseDefs = [
      {headerName: '선택', field: 'seq', hide: true },
      {headerName: '창고', field: 'name', cellClass: 'cp left' },
      {headerName: '창고주소', field: 'address', cellClass: 'cp left' },
      {headerName: '수량', field: 'qty', cellClass: 'cp right' },
      {headerName: '개요', field: 'outline', cellClass: 'cp left' },
    ];

    this.columnBarcodeDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', hide: true },
      { headerName: '바코드 발행번호', field: 'barcode', cellClass: 'cp left', width: 200 },
      { headerName: '입출고구분', field: 'inout_gbn_name', cellClass: 'cp left', width: 150 },
      { headerName: '창고', field: 'warehouse_name', cellClass: 'cp left', width: 150 },
      { headerName: '입고일자', field: 'inout_date', cellClass: 'cp left', width: 100 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getWareHouse();

    this.getCategoryList();

    this.getStockMaster();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid detail ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridDetailReady(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyWarehouse(params) {
    this.gridApiWarehouse = params.api;
    this.gridColumnApiWarehouse = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyBarcode(params) {
    this.gridApiBarcode = params.api;
    this.gridColumnApiBarcode = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 입고 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  importAdd( seq ) {

    let nodes = this.gridApi.getSelectedRows();

    if( seq == 0 && nodes.length < 1 ) {  
      this.toastrService.error("기초/기타 입고할 상품을 재고현황에서 선택해 주시기 바랍니다.");
      return false;
    }

    const modalRef = this.modalService.open(AStockImportComponent, options);

    modalRef.componentInstance.seq = seq;
    
    if( nodes.length > 0 ) {
      modalRef.componentInstance.product_seq = nodes[0].seq;
      modalRef.componentInstance.product_name = nodes[0].name;
      modalRef.componentInstance.barcode_yn = nodes[0].barcode_yn;
    } else {
      modalRef.componentInstance.product_seq = null;
      modalRef.componentInstance.product_name = '';
    }

    modalRef.result.then( (result) => {
      this.getStockMaster();

      this.stockDetailList = [];
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 폐기 / 분실 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  lossAdd( seq ) {
    let nodes = this.gridApi.getSelectedRows();

    if( seq == 0 && nodes.length < 1 ) {  
      this.toastrService.error("폐기/분실 처리할 상품을 재고현황에서 선택해 주시기 바랍니다.");
      return false;
    }

    const modalRef = this.modalService.open(AStockLossComponent, options);

    modalRef.componentInstance.seq = seq;

    if( nodes.length > 0 ) {
      modalRef.componentInstance.product_seq = nodes[0].seq;
      modalRef.componentInstance.product_name = nodes[0].name;
      modalRef.componentInstance.barcode_yn = nodes[0].barcode_yn;
    } else {
      modalRef.componentInstance.product_seq = null;
      modalRef.componentInstance.product_name = '';
    }

    modalRef.result.then((result) => {
      this.getStockMaster();

      this.stockDetailList = [];
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 창고 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  moveAdd( seq ) {
    let nodes = this.gridApi.getSelectedRows();

    if( seq == 0 && nodes.length < 1 ) {  
      this.toastrService.error("창고이동할 상품을 재고현황에서 선택해 주시기 바랍니다.");
      return false;
    }

    const modalRef = this.modalService.open(AStockMoveComponent, options);

    modalRef.componentInstance.seq = seq;
    
    if( nodes.length > 0 ) {
      modalRef.componentInstance.product_seq = nodes[0].seq;
      modalRef.componentInstance.product_name = nodes[0].name;
      modalRef.componentInstance.barcode_yn = nodes[0].barcode_yn;
    } else {
      modalRef.componentInstance.product_seq = null;
      modalRef.componentInstance.product_name = '';
    }

    modalRef.result.then((result) => {
      this.getStockMaster();

      this.stockDetailList = [];
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 구매입고
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  purchaseAdd( seq ) {
    const modalRef = this.modalService.open(AStockPurchaseComponent, optionsXXL);

    modalRef.componentInstance.seq = seq;
    modalRef.componentInstance.check = 'S';
    modalRef.componentInstance.warehouseList = this.warehouseList;

    modalRef.result.then((result) => {
      this.getStockMaster();

      this.stockDetailList = [];
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.getStockSlave( $event.data.seq, this.inout );
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    // 기초재고, 기타입고 일 경우
    if( $event.data.inout_gbn_name == '기초재고' || $event.data.inout_gbn_name == '기타입고' ) {
      this.importAdd( $event.data.seq );

    // 이동출고 / 이동입고 일 경우
    } else if( $event.data.inout_gbn_name == '이동출고' || $event.data.inout_gbn_name == '이동입고' ) {
      if( $event.data.inout_gbn_name == '이동출고' )
        this.moveAdd( $event.data.seq );
      else {
        if( $event.data.group_seq )
          this.moveAdd( $event.data.group_seq );
        else
          this.moveAdd( $event.data.seq );
      }

    // 폐기/분실 일 경우
    } else if( $event.data.inout_gbn_name == '폐기출고' || $event.data.inout_gbn_name == '분실출고' ) {
      this.lossAdd( $event.data.seq );

    // 구매입고 일 경우
    } else if( $event.data.inout_gbn_name == '구매입고' ) {
      this.purchaseAdd( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClickedWarehouse($event) {
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClickedBarcode($event) {
  }
  
  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWareHouse() {
    this.aWarehouseService.getWarehouseList({
      typeSelect: '',
      searchText: ''
    }).then( response => {
      if( response.ResultCode ) {
        this.warehouseList = response.data;
      } else {
        this.warehouseList = [];
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.aCategoryService.getCategoryList2().then( response => {
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.categoryList.push({
            id: String( row.category_code ),
            text: row.category_nm
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productSearch() {
    const modalRef = this.modalService.open(ACProductFindGridComponent, optionsXXL);

    // modalRef.componentInstance.part_view_yn = true;
    modalRef.componentInstance.customer_seq = '';
    modalRef.componentInstance.project_seq = '';
    modalRef.componentInstance.mem_no = '';
    modalRef.componentInstance.part_include = true;

    modalRef.result.then((result) => {
      this.search.product_name = result[0].name + ( result.length > 1 ? ' 외 ' + ( result.length - 1 ) + '건' : '' );
      this.search.product_seq = result.map(item => item.product_seq);

      this.getStockMaster();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 재고 현황 마스터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStockMaster() {
    let search: any = {
      warehouse_seq: this.search.warehouse_seq,
      category_code: this.search.category_code,
      product_name: this.search.product_name,
      product_seq: this.search.product_seq,
      date: this.ngbDateParserFormatter.format( this.search.date )
    };

    let nodes: any = null;

    if( typeof this.gridApi !== 'undefined' )
      nodes = this.gridApi.getSelectedRows();

    this.aStockService.getStockList( search ).then( response => {
      if( response.ResultCode ) {
        this.stockList = response.data;

        // 기존에 선택되어 있던 노드를 재선택 시킨다.
        if( nodes !== null && nodes.length > 0 ) {
          setTimeout( () => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( nodes[0].seq == node.data.seq ) {
                node.setSelected(true);
              }
            });

            this.getStockSlave( nodes[0].seq, this.inout );
          }, 500);
        }

      } else {
        this.stockList = [];
      }
    }, error => {
      this.toastrService.error( error, '재고현황');
    });
  }

  /*******************************************************************************
    설  명 : 재고 현황 디테일 가져오기
    입력값 : seq = 상품코드, inout = 입고/출고 구분
    리턴값 : 없음
  *******************************************************************************/
  getStockSlave( seq, inout ) {
    forkJoin([
      this.aStockService.getStockDetailList( seq, inout, this.ngbDateParserFormatter.format( this.search.date ) ),
      this.aStockService.getWarehouseStockList( seq ),
      this.aStockService.getBarcodeStockList( {product_seq: seq} )
    ])
    .subscribe( promise => {
      if( promise[0].ResultCode ) {
        this.stockDetailList = promise[0].data;
      } else {
        this.stockDetailList = [];
      }

      if( promise[1].ResultCode ) {
        this.whStockList = promise[1].data;
      } else {
        this.whStockList = [];
      }

      if( promise[2].ResultCode ) {
        this.barcodeStockList = promise[2].data;
      } else {
        this.barcodeStockList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 재고 현황 디테일 입고/출고 구분 선택 시 처리
    입력값 : inout 입고/출고 구분
    리턴값 : 없음
  *******************************************************************************/
  getStockSlaveSearch( inout ) {
    this.inout = inout;
    let nodes = this.gridApi.getSelectedRows();

    this.getStockSlave( nodes[0].seq, inout );
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : inout 입고/출고 구분
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      warehouse_seq: '',
      category_code: '',
      product_name: '',
      product_seq: '',
      date: ''
    }

    this.getStockMaster();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker ) {
    this.search.date = this.calendar.getToday();
    obj.close();
  }
}
