import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AOrderService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // Order component 용 거래처 주문정보 리스트 가져오기
  getOrderList( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 리스트 가져오기
  *******************************************************************************/
  getOrderPayList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderPayList'
      }, {
        order_seq: seq
      }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 출력 리스트 가져오기
  *******************************************************************************/
  getOrderPrintList( params: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderPrintList'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 견적서 메일발송
  *******************************************************************************/
  sendEmailEstimate( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'sendEmailEstimate'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
