/*******************************************************************************
  설  명 : 회원 추가/수정
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { AMemberListService } from '@admin/service/member.service';
import { Globals } from '@app/service/globals.service';
import { ACustomerService } from '@admin/service/customer.service';
import { ACommonService } from '@admin/service/common.service';
import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-member-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class AMemberDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: string;
  public title: string = '추가';

  public form: FormGroup;
  public formErrors = {};

  public gradeList: any = [];

  daumAddressOptions =  {
    class: ['btn', '']
  };

  public addFunc: any;           // 거래처검색
  public addIdFunc: any;         // 아이디 중복검색
  private checkIdDup: boolean = false;

  public memberInfo: any;

  public params: any = {
    mem_no: '',
    searchField: 'name',
    searchText: '',
    customerField: 'name',
    customerText: '',
    project: '',
    grade: '',
    status: '',
    join_sdate: '',
    join_edate: '',
    joindate_group: 'A',
    order_sdate: '',
    order_edate: '',
    orderdate_group: 'A',
    range_group: 'amt',
    range_start: '',
    range_end: '',
    grade_group: ''
  };

  /*******************************************************************************
    설  명 : 회원추가 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      name:["", [Validators.required, Validators.maxLength(50)] ],
      mem_no:["", [] ],
      id:["", [] ],
      level:["", [] ],
      customer_seq:["", [] ],
      customer_name:["", [] ],
      password:["", [Validators.required] ],
      password_confirm:["", [Validators.required] ],
      hp:["", [Validators.maxLength(20), CustomValidator.phone] ],
      tel:["", [Validators.maxLength(20), CustomValidator.phone] ],
      email:["", [Validators.email, Validators.maxLength(100)] ],
      sms_yn:[ '1', [] ],
      email_yn:[ false, [] ],
      grade:["", []],
      refund_depositary:["", [Validators.maxLength(50)] ],
      refund_account:["", [Validators.maxLength(50)] ],
      refund_bank:["", [Validators.maxLength(50)] ],
      zipcode:["", [Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric] ],
      address:["", [Validators.maxLength(100)] ],
      address_detail:["", [Validators.maxLength(100)] ],
      memo:["", []]
    }, this.pwdMatchValidator);

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  pwdMatchValidator(frm: FormGroup) {
    return frm.get('password').value === frm.get('password_confirm').value
       ? null : {'mismatch': true};
  }

  /*******************************************************************************
    설  명 : 회원추가 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm_edit(): void {
    this.form = this.formBuilder.group({
      name:["", [Validators.required, Validators.maxLength(50)] ],
      mem_no:["", [] ],
      id:["", [] ],
      level:["", [] ],
      customer_seq:["", [] ],
      customer_name:["", [] ],
      password:["", [] ],
      password_confirm:["", [] ],
      status:["", [] ],
      hp:["", [Validators.maxLength(20), CustomValidator.phone] ],
      tel:["", [] ],
      email:["", [Validators.email, Validators.maxLength(100)] ],
      sms_yn:[ '1', [] ],
      email_yn:[ false, [] ],
      grade:["", []],
      refund_depositary:["", [Validators.maxLength(50)] ],
      refund_account:["", [Validators.maxLength(50)] ],
      refund_bank:["", [Validators.maxLength(50)] ],
      zipcode:["", [Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric] ],
      address:["", [Validators.maxLength(100)] ],
      address_detail:["", [Validators.maxLength(100)] ],
      memo:["", []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public location: Location,
    private modalService: NgbModal,
    private aCustomerService: ACustomerService,
    private aCommonService: ACommonService,
    private aMemberListService: AMemberListService,
    public authService: AuthService
  ) {
    this.addFunc = this.findCustomerAdd.bind(this);
    this.addIdFunc = this.checkIdDuplicate.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      //this.seq = params.seq;
      this.params = params;

      if( this.seq == '0' || this.seq == '' ) {
        this.title = '추가';

        this.buildForm();
      } else {
        this.title = '수정';

        this.buildForm_edit();

        // 회원정보 가져오기
        this.getMemberDetail();
      }
    });

    this.getGradeList();
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findCustomerAdd() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, options);

    modalRef.result.then((result) => {
      this.form.patchValue({
        customer_seq: this.aCustomerService.selectedItem.seq,
        customer_name: this.aCustomerService.selectedItem.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 중복 아이디 검사 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkIdDuplicate() {
    if ( this.form.controls.id.value.length < 1 ) {
      this.toastrService.error( '중복 검사할 아이디를 입력하세요.', '아이디 중복검사');

      return false;
    }

    this.aMemberListService.checkIdDuplicate( this.form.controls.id.value ).then( response => {
      if( response.ResultCode ) {
        this.checkIdDup = true;
        this.toastrService.success( response.ResultMessage, '아이디 중복검사');

      } else {
        this.checkIdDup = false;
        this.toastrService.error( response.ResultMessage, '아이디 중복검사');
      }
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    // 거래처 담당자로 리다이렉션
    if( typeof this.params.returnURL !== 'undefined' && this.params.returnURL != '' ) {
      let search: any = {
        seq: this.params.seq,
        tabIndex: this.params.tabIndex
      }

      this.router.navigate([ this.params.returnURL ], {
        //relativeTo: this.activatedRoute,
        queryParams: search,
        queryParamsHandling: '', // remove to replace all query params by provided
      });

    // 회원 리스트 페이지
    } else {
      this.router.navigate(['/member/list'], {
        //relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    }

  }

  /*******************************************************************************
    설  명 : 회원등급 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGradeList() {
    this.aCommonService.getCommonList(19).then( response => {
      if( response.ResultCode ) {
        this.gradeList = response.data;
        
        if( this.seq == '0' || this.seq == '' ) {
          this.form.patchValue({grade: this.gradeList[0].seq});
        }
      } else {
        this.gradeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원등급 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberDetail() {
    this.aMemberListService.getMemberDetail( this.params.mem_no ).then( response => {
      if( response.ResultCode ) {
        this.memberInfo = response.data;

        this.form.patchValue( this.memberInfo );
      } else {
        this.memberInfo = [];
      }
    });
  }


  /*******************************************************************************
    설  명 : 회원 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    // 회원 추가 일 경우만 검사
    if( this.seq == '0' || this.seq == '' ) {
      if (this.form.controls.id.dirty && this.checkIdDup === false) {
        this.toastrService.error( '아이디 중복체크를 해주세요.', '아이디 중복검사');
        return false;
      }
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.aMemberListService.setMemberSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.router.navigate(['/member/list']);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
