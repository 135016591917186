/*******************************************************************************
  설  명 : 거래처 찾기 컴포넌트
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ACustomerService } from '@admin/service/customer.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACustomerDetailComponent } from '@admin/page/basic/customer/detail/detail.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-find',
  templateUrl: './customer-find.component.html',
  styleUrls: ['./customer-find.component.scss']
})
export class ACCustomerFindComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public search: any = {
    typeSelect: '',
    kindSelect: '',
    searchGroup: 'name',
    searchText: ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCustomerService: ACustomerService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    this.columnDefs = [
      {headerName: '거래처코드', field: 'seq', hide:true },
      {headerName: '회사명', field: 'name', cellClass: 'cp', width: 200 },
      {headerName: '사업자번호', field: 'business_no', cellClass: 'cp center', width: 135 },
      {headerName: '대표자', field: 'ceo', cellClass: 'cp center', width: 150 },
      {headerName: '거래처구분', field: 'type', cellClass: 'cp center', width: 100 },
      {headerName: '거래처분류', field: 'kind', cellClass: 'cp center', width: 150 }
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: false
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 거래처 리스트 가져오기
    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.aCustomerService.selectedItem = $event.data;

    this.activeModal.close( $event.data );
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    this.aCustomerService.getCustomerList( this.search ).then( response => {
      this.customerList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCustomerList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    // 검색 필드
    this.search = {
      typeSelect: '',
      kindSelect: '',
      searchGroup: 'name',
      searchText: ''
    }

    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 거래처 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  customerAdd() {
    const modalRef = this.modalService.open(ACustomerDetailComponent, options);

    modalRef.componentInstance.isModal = true;

    modalRef.result.then((result) => {

    }, (reason) => {
      // console.log( reason );
    });
  }
}
