/*******************************************************************************
  설  명 : 프로젝트 찾기 컴포넌트
  작성일 : 2019-10-19
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions,
  NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { APurchaseOrderService } from '@admin/service/purchase.order.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-purchase-find',
  templateUrl: './purchase-find.component.html',
  styleUrls: ['./purchase-find.component.scss']
})
export class ACPurchaseFindComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  purchaseList: any = [];
  purchaseDetailList: any = [];

  gbnList: any = [];

  gridApi: any;
  gridColumnApi: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;

  columnDefs: any;
  columnDetailDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    gbn_seq: '',
    in_complete: 'N',
    customer_seq: '',
    sdate: '',
    edate: '',
    status: true
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aPurchaseOrderService: APurchaseOrderService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    public activeModal: NgbActiveModal,
    private aCommonService: ACommonService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    private calendar: NgbCalendar
  ) {
    this.columnDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '확정', field: 'status', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.status == 1 ? '<span class="badge badge-success">확정</span>' : '<span class="badge badge-danger">미확정</span>';
        }
      },
      {headerName: '발주구분', field: 'gbn_name', cellClass: 'cp center' },
      {headerName: '발주일자', field: 'date', cellClass: 'cp center' },
      {headerName: '거래처코드', field: 'customer_seq', hide: true },
      {headerName: '거래처', field: 'customer_name', cellClass: 'cp left' },
      {headerName: '발주금액', field: 'amt', cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
      },
      {headerName: '비고', field: 'memo', cellClass: 'cp left' },
      {headerName: '입고여부', field: 'complete_qty', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.complete_qty == 0 ? '입고완료' : '미입고(' + ( params.data.max_qty - params.data.in_qty ) + ')';
        },
        cellStyle: function(params) {
          if (params.value !== 0) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return null;
          }
        }
      }
    ];

    this.columnDetailDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, 
        //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true 
      },
      { headerName: '대분류', field: 'parent_category_name', cellClass: 'cp left' },
      { headerName: '중분류', field: 'category_name', cellClass: 'cp left' },
      { headerName: '상품명', field: 'product_name', cellClass: 'cp left'},
      { headerName: '수량', field: 'qty', cellClass: 'cp right' },
      { headerName: '입고', field: 'in_qty', cellClass: 'cp right' },
      { headerName: '미입고', field: 'not_qty', cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.qty - params.data.in_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellStyle: function(params) {
          if (params.value !== 0) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return null;
          }
        }
      },
      { headerName: '입고일자', field: 'in_date', cellClass: 'cp center' }
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();

    this.getPurchaseOrderMaster();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.getPurchaseOrderSlave( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    //this.activeModal.close( $event.data );
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 검색 조건 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      gbn_seq: '',
      in_complete: 'N',
      customer_seq: '',
      sdate: '',
      edate: '',
      status: true
    };

    this.getPurchaseOrderMaster();
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.gbnList = [];

    // 발주 구분
    this.aCommonService.getCommonListCode('POG').then( response => {
      if( response.ResultCode ) {
        this.gbnList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 발주서 마스터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseOrderMaster() {
    this.purchaseList = [];

    let tmp: any = Object.assign({}, this.search );

    tmp.sdate = this.ngbDateParserFormatter.format( this.search.sdate );
    tmp.edate = this.ngbDateParserFormatter.format( this.search.edate );

    this.aPurchaseOrderService.getPurchaseOrderMaster(tmp).then( response => {
      if( response.ResultCode ) {
        this.purchaseList = response.data;
      }
    }, error => {
      this.toastrService.error( error, '발주서 가져오기');
    });
  }

  /*******************************************************************************
    설  명 : 발주서 디테일 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseOrderSlave( seq: any ) {
    this.purchaseDetailList = [];

    this.aPurchaseOrderService.getPurchaseOrderSlave( seq, true ).then( response => {
      if( response.ResultCode ) {
        this.purchaseDetailList = response.data;
      }
    }, error => {
      this.toastrService.error( error, '발주서 가져오기');
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.search.customer_seq = result.seq;
      this.search.customer_name = result.name;
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 발주서 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  select() {
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( "발주서를 선택하세요.", '발주서 선택');
      return false;
    } else {
      this.activeModal.close( nodes[0].data );
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }

}
