/*******************************************************************************
  설  명 : 수입지출항목 관리
  작성일 : 2019-10-17
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { APaymentService } from '@admin/service/payment.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

import { APaymentItemAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-payment-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class APaymentItemComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  paymentItemList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  search: any = {
    searchText: '',
    type: ''
  };

  public typeList:any = [];

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aPaymentService: APaymentService,
    public authService: AuthService,
    private aCommonService: ACommonService
  ) {
    this.columnDefs = [
      {headerName: '항목코드', field: 'seq', width: 80, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '수입지출구분', field: 'item_name', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.item_name == '수입') return '<span class="badge badge-success f12 fn">수입</span>';
          else if( params.data.item_name == '지출') return '<span class="badge badge-danger f12 fn">지출</span>';
          else return '<span class="badge badge-secondary f12 fn">기타</span>';
        }
      },
      {headerName: '항목명', field: 'name', width: 200, cellClass: 'cp left' },
      {headerName: '메모', field: 'outline', width: 300, cellClass: 'cp left' },
      {headerName: '작성자', field: 'writer_name', width: 120, cellClass: 'cp' },
      {headerName: '작성일', field: 'write_date', width: 130, cellClass: 'cp' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getTypeList();

    this.getAccountItemList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.addItem( $event.data );
    }
  }

  /*******************************************************************************
    설  명 : 수입지출항목 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addItem( item: any ) {
    const modalRef = this.modalService.open(APaymentItemAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      this.getAccountItemList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAccountItemList() {
    this.aPaymentService.getAccountItemList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.paymentItemList = response.data;
      } else {
        this.paymentItemList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getAccountItemList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      type: ''
    }

    this.getAccountItemList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getAccountItemList();
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTypeList() {
    this.aCommonService.getCommonList(40).then( response => {
      if( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });
  }
}
