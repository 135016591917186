/*******************************************************************************
  설  명 : SMS 발송 버튼 표시 및 처리
  작성일 : 2019-09-18
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ACSmsModalComponent } from './sms-modal/sms-modal.component';

import { ToastrService } from 'ngx-toastr';

export interface ISms {
  mem_no: string;
  name: string;
  mobile: string;
  id: string;
  smsYn: string;
  leavedate: string;
}

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'my-class'
};
@Component({
  selector: 'btn-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})
export class ACSendSmsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() sendList: ISms[];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : SMS 발송 모달 실행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendSMS() {

    if ( this.sendList.length < 1 ) {
      this.toastrService.error( '발송할 회원을 선택하시기 바랍니다.' );
      return false;

    } else {
      const tmp: any = [];
      for ( const item of this.sendList ) {
        if ( item.smsYn === '1' && item.leavedate == null ) {
          tmp.push(item);
        }
      }

      if ( tmp.length < 1 ) {
        this.toastrService.error( '탈퇴한 회원이거나 SMS 수신동의가 되지 않은 회원을 선택하여 발송 가능한 회원이 없습니다.' );
        return false;

      } else {
        const modalRef = this.modalService.open(ACSmsModalComponent, options);

        modalRef.componentInstance.sendList = this.sendList;

        modalRef.result.then((result) => {
        }, (reason) => {
        });
      }
    }
  }

}
