/*******************************************************************************
  설  명 : 창고 추가/수정
  작성일 : 2019-09-16
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { Globals } from '@app/service/globals.service';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-warehouse-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AWarehouseAddComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 창고 종류
  public typeList = [];

  public seq: number; // true = 추가, false = 수정
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  daumAddressOptions =  {
    class: ['btn', 'btn-default','btn-small','f12']
  };

  public uploader: FileUploader;
  public uploadProgress: any = 0;
  public baseURL = config.baseUrl;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      name:["", [Validators.required, Validators.maxLength(100)] ],
      type:["", [Validators.required] ],
      person:["", [Validators.maxLength(50)] ],
      person_hp:["", [Validators.maxLength(50)] ],
      zipcode:["", [Validators.maxLength(5)] ],
      address:["", [Validators.maxLength(255)] ],
      address_detail:["", [Validators.maxLength(255)] ],
      outline:["", [Validators.maxLength(2000)] ],
      stock_yn:["", [Validators.required] ],
      main_banner_yn:["", [] ],
      main_banner_link:["", [] ],
      file: ["", [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aCommonService: ACommonService,
    public authService: AuthService,
    private restful: RestfulService,
    private globals: Globals,
    private aWarehouseService: AWarehouseService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    await this.getCommonList();

    this.getWarehouseInfo();

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량 50M
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 파일 업로드 전 사용자 세션 토큰 추가
    this.uploader.onBuildItemForm = this.onBuildItemForm.bind(this);

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.formAdd.controls.files.value;

        files.push({
          url: config.baseUrl + response.url,
          origin: response.origin_filename,
          size: response.size,
          thumbnail: response.thumbnail,
          thumbnail_result: response.thumbnail_result,
        });

        this.formAdd.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 뷰 생성후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.seq == 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if ( this.formAdd.controls.main_banner_yn.value ) {
      if ( this.formAdd.controls.main_banner_link.value == '' ) {
        this.toastrService.error('메인배너 표시함상태에서는 링크 주소 기입 필수', '창고 관리');
        return;
      } else if ( this.formAdd.controls.files.value.length == 0 && this.formAdd.controls.upload.value.length == 0 ) {
        this.toastrService.error('메인배너 표시함상태에서는 메인배너 이미지 등록 필수', '창고 관리');
        return;
      }
    }

    if(this.formAdd.valid) {
      this.aWarehouseService.setWarehouse(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 다음 주소 API 호출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.formAdd.patchValue({
      zipcode: data.zip,
      address: data.addr
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 - 창고 종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 창고 구분
    await this.aCommonService.getCommonList(16).then( response => {
      this.typeList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 창고 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getWarehouseInfo() {
    if( this.seq != 0 ) {
      await this.aWarehouseService.getWarehouseInfo( this.seq ).then( response => {
        this.formAdd.patchValue( response.data );
      });
    }
  }

  /*******************************************************************************
    설  명 : 본사 주소 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addressCopy() {
    this.aWarehouseService.getCompanyAddress().then( response => {
      this.formAdd.patchValue( response.data );
    });
  }

  /*******************************************************************************
    설  명 : 창고 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteWarehouse() {
    if( confirm("선택하신 창고 정보를 삭제하시겠습니까?") ) {
      this.aWarehouseService.deleteWarehouse(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 파일 업로드 전에 토큰 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  private onBuildItemForm(fileItem: FileItem, form: FormData): any {
    form.append('token', this.globals.token);
    return { fileItem, form };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    if( this.formAdd.controls.files.value.length >= 1 ) {
      this.toastrService.error( '배너이미지는 한개만 첨부가능합니다.', '배너이미지');
      return false; 
    }

    // 전체 허용 용량 검증

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.aWarehouseService.deleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.formAdd.controls.upload.value;
          uploadData.splice( index, 1 );
          this.formAdd.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '첨부파일 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '첨부파일 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.formAdd.controls.files.value;

      tmp.splice( index, 1 );

      this.formAdd.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }
}
