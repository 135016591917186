/*******************************************************************************
  설  명 : 상품관리
  작성일 : 2019-10-22
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ADiscountService } from '@admin/service/discount.service';
import { ACommonService } from '@admin/service/common.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ADiscountAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class ADiscountComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  discountList: any = [];

  discount_gbn: any = [];
  discount_type: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    discount_gbn: '',
    searchGroup: 'discount',
    searchText: '',
    discount_type: '',
    use_yn: ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aDiscountService: ADiscountService,
    private aCommonService: ACommonService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {headerName: '선택', field: 'seq', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '할인명', field: 'discount_name', cellClass: 'cp' },
      {headerName: '할인구분', field: 'discount_gbn_name', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.discount_gbn_code == '0001' ) {
            return '<span class="badge badge-success f12 fn">프로젝트할인</span>';
          } else if( params.data.discount_gbn_code == '0002' ) {
            return '<span class="badge badge-danger f12 fn">업체할인</span>';
          } else if( params.data.discount_gbn_code == '0003' ) {
            return '<span class="badge badge-primary f12 fn">상품할인</span>';
          } else {
            return '';
          }
        },
      },
      {headerName: '적용시작일', field: 'sdate', cellClass: 'cp center' },
      {headerName: '적용종료일', field: 'edate', cellClass: 'cp center' },
      {headerName: '거래처', field: 'sc_name', cellClass: 'cp' },
      {headerName: '프로젝트', field: 'project_name', cellClass: 'cp' },
      {headerName: '상품', field: 'product_name', cellClass: 'cp' },
      {headerName: '할인방법', field: 'discount_type_name', cellClass: 'cp center' },
      {headerName: '할인율(%)', field: 'discount_rate', cellClass: 'cp right' },
      {headerName: '할인금액(원)', field: 'discount_amt', cellClass: 'cp right' },
      {headerName: '절사구분', field: 'truncation_type_name', cellClass: 'cp center' },
      {headerName: '절사단위', field: 'truncation_unit', cellClass: 'cp right' },
      {headerName: '메모', field: 'memo', cellClass: 'cp' },
      {headerName: '등록일', field: 'write_date', cellClass: 'cp center' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();

    this.getDiscountList();
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 할인 구분
    this.aCommonService.getCommonListCode('DG').then( response => {
      if( response.ResultCode ) {
        this.discount_gbn = response.data;
      } else {
        this.discount_gbn = [];
      }
    });

    // 할인 방법
    this.aCommonService.getCommonListCode('DT').then( response => {
      if( response.ResultCode ) {
        this.discount_type = response.data;
      } else {
        this.discount_type = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 할인 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  discountAdd( seq ) {
    const modalRef = this.modalService.open(ADiscountAddComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      this.getDiscountList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 그리드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field != 'seq' ) {
      this.discountAdd( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 할인 내역을 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDiscountList() {
    this.aDiscountService.getDiscountList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.discountList = response.data;
      } else {
        this.discountList = [];
      }
    }, error => {
      this.toastrService.error( error, '할인정보');
    });
  }

  /*******************************************************************************
    설  명 : 검색을 초기화 한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      discount_gbn: '',
      searchGroup: 'discount',
      searchText: '',
      discount_type: '',
      use_yn: ''
    }

    this.getDiscountList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getDiscountList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getDiscountList();
    }
  }
}
