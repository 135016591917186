/*******************************************************************************
  설  명 : 수입지출현황 > 월별 합계표
  작성일 : 2023-02-07
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/service/auth.service';
import { APaymentService } from '@admin/service/payment.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import * as moment from 'moment';

@Component({
  selector: 'app-statistic-bymonthlist',
  templateUrl: './statistic-bymonthlist.component.html',
  styleUrls: ['./statistic-bymonthlist.component.scss']
})
export class StatisticBymonthlistComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 검색 조건
  public search: any = {
    nowYear: moment().format('YYYY'),
  };

  // 지출 내역
  public paymentList: any = [];
  public pinnedBottomRowData = [];

  // 공통코드 그룹 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    public paymentService: APaymentService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '항목', field: 'name', width: 130, cellClass: 'cp left'},
      { headerName: '1월', field: 'january', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '2월', field: 'february', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '3월', field: 'march', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '4월', field: 'april', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '5월', field: 'may', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '6월', field: 'june', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '7월', field: 'july', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '8월', field: 'august', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '9월', field: 'september', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '10월', field: 'october', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '11월', field: 'november', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '12월', field: 'december', width: 110, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter },
      { headerName: '합계', field: 'total', width: 110, cellClass: 'cp right',
      valueFormatter: currencyFormatter, valueGetter: numberGetter },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      return parseInt(params.data[ params.column.colId ], 10);
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getPaymentMonthAccountList();
  }

  /*******************************************************************************
    설  명 : 월별 매출현황 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentMonthAccountList() {
    this.paymentService.getPaymentMonthAccountList( this.search.nowYear ).then( response => {
      if( response.ResultCode ) {
        if( response.data.length > 0 ) {
          response.data.forEach(item => {
            item.total = parseInt(item.january || 0) +
                          parseInt(item.february || 0) +
                          parseInt(item.march || 0) +
                          parseInt(item.april || 0) +
                          parseInt(item.may || 0) +
                          parseInt(item.june || 0) +
                          parseInt(item.july || 0) +
                          parseInt(item.august || 0) +
                          parseInt(item.september || 0) +
                          parseInt(item.october || 0) +
                          parseInt(item.november || 0) +
                          parseInt(item.december || 0);
          });
        }

        this.paymentList = response.data;
        this.calcSum(this.paymentList);
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      nowYear: moment().format('YYYY'),
    };

    this.getPaymentMonthAccountList();
  }

  /*******************************************************************************
    설  명 : 이전 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  prevYear() {
    this.search.nowYear = moment( this.search.nowYear + '-01-01' ).add(-1, 'year').format('YYYY');

    this.getPaymentMonthAccountList();
  }

  /*******************************************************************************
    설  명 : 다음 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nextYear() {
    if( moment().format('YYYY') !== this.search.nowYear ) {
      this.search.nowYear = moment( this.search.nowYear + '-01-01' ).add(1, 'year').format('YYYY');

      this.getPaymentMonthAccountList();
    }
  }
  
  /*******************************************************************************
    설  명 : 총합계 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcSum(data) {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    let e = 0;
    let f = 0;
    let g = 0;
    let h = 0;
    let i = 0;
    let j = 0;
    let k = 0;
    let l = 0;
    let m = 0;
    let n = 0;
    let o = 0;
    let p = 0;
    let q = 0;
    let r = 0;
    let s = 0;
    let t = 0;
    let u = 0;
    let v = 0;
    let w = 0;
    let x = 0;
    let y = 0;
    let z = 0;
    let aa = 0;
    let ab = 0;
    let ac = 0;
    let ad = 0;
    let ae = 0;
    let af = 0;
    let ag = 0;
    let ah = 0;
    let ai = 0;
    let aj = 0;
    let ak = 0;
    let al = 0;
    let am = 0;

    data.map(row => {
      if ( row.type == '200' ) {
        a += parseInt(row.january);
        b += parseInt(row.february);
        c += parseInt(row.march);
        d += parseInt(row.april);
        e += parseInt(row.may);
        f += parseInt(row.june);
        g += parseInt(row.july);
        h += parseInt(row.august);
        i += parseInt(row.september);
        j += parseInt(row.october);
        k += parseInt(row.november);
        l += parseInt(row.december);
        m += parseInt(row.total);
        aa += parseInt(row.january);
        ab += parseInt(row.february);
        ac += parseInt(row.march);
        ad += parseInt(row.april);
        ae += parseInt(row.may);
        af += parseInt(row.june);
        ag += parseInt(row.july);
        ah += parseInt(row.august);
        ai += parseInt(row.september);
        aj += parseInt(row.october);
        ak += parseInt(row.november);
        al += parseInt(row.december);
        am += parseInt(row.total);
      } else {
        n += parseInt(row.january);
        o += parseInt(row.february);
        p += parseInt(row.march);
        q += parseInt(row.april);
        r += parseInt(row.may);
        s += parseInt(row.june);
        t += parseInt(row.july);
        u += parseInt(row.august);
        v += parseInt(row.september);
        w += parseInt(row.october);
        x += parseInt(row.november);
        y += parseInt(row.december);
        z += parseInt(row.total);
        aa -= parseInt(row.january);
        ab -= parseInt(row.february);
        ac -= parseInt(row.march);
        ad -= parseInt(row.april);
        ae -= parseInt(row.may);
        af -= parseInt(row.june);
        ag -= parseInt(row.july);
        ah -= parseInt(row.august);
        ai -= parseInt(row.september);
        aj -= parseInt(row.october);
        ak -= parseInt(row.november);
        al -= parseInt(row.december);
        am -= parseInt(row.total);
      }
    });
    this.pinnedBottomRowData = [
      {
        name: '총 수입', 
        january: a,
        february: b,
        march: c,
        april: d,
        may: e,
        june: f,
        july: g,
        august: h,
        september: i,
        october: j,
        november: k,
        december: l,
        total: m,
      },
      {
        name: '총 지출', 
        january: n,
        february: o,
        march: p,
        april: q,
        may: r,
        june: s,
        july: t,
        august: u,
        september: v,
        october: w,
        november: x,
        december: y,
        total: z,
      },
      {
        name: '총 합계', 
        january: aa,
        february: ab,
        march: ac,
        april: ad,
        may: ae,
        june: af,
        july: ag,
        august: ah,
        september: ai,
        october: aj,
        november: ak,
        december: al,
        total: am,
      },
    ]
  }
}
