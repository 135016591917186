import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class APurchaseOrderService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 발주서 저장
  setPurchaseOrder( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'purchase',
        version: '1.0',
        action: 'setPurchaseOrder'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발주서 마스터 가져오기
  getPurchaseOrderMaster( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'purchase',
        version: '1.0',
        action: 'getPurchaseOrderMaster'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발주서 슬레이브 가져오기
  getPurchaseOrderSlave( seq: any, in_complete: boolean ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'purchase',
        version: '1.0',
        action: 'getPurchaseOrderSlave'
      }, {
        seq: seq,
        in_complete: ( in_complete ) ? '1' : '0'
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발주서 바코드 정보 리스트 가져오기
  getPurchaseBarcodeList( po_seq: number, product_seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'purchase',
        version: '1.0',
        action: 'getPurchaseBarcodeList'
      }, {
        po_seq: po_seq,
        product_seq: product_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발주서 상태 변경
  setPurchaseStatus( list: any, check: boolean ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'purchase',
        version: '1.0',
        action: 'setPurchaseStatus'
      }, {
        list: list,
        check: check
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발주서 상세 정보 가져오기
  getPurchaseInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'purchase',
        version: '1.0',
        action: 'getPurchaseInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
