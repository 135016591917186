import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class ACWidgetComponent implements OnChanges {
  constructor(
    private restfulService: RestfulService,
  ) { }

  @Input() version ? = '1.0';
  @Input() program ? = 'admin';
  @Input() service: string;
  @Input() action: string;
  @Input() params ? = {};

  public widgetList = [];

  ngOnChanges() {
    this.requestAPI(this.params);
  }

  requestAPI(params) {
    this.restfulService.get({
        program: this.program,
        service: this.service,
        version: this.version,
        action: this.action
      }, { params }
    ).then( (response) => {
      this.widgetList = [];
      if (typeof response !== 'undefined' && response.ResultCode) {
        const widgetData = response.data;

        for (const key in widgetData) {
          if (widgetData.hasOwnProperty(key)) {
            this.widgetList.push({
              title: key,
              content: widgetData[key].replace(/[^0-9]/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              unit: widgetData[key].replace(/^[0-9]*/, ''),
            });
          }
        }
      }
    }).catch();
  }
}





