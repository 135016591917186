/*******************************************************************************
  설  명 : 입금대사관리
  작성일 : 2020-08-31
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { ADepositService } from '@admin/service/deposit.service';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

@Component({
  selector: 'app-auto-deposit',
  templateUrl: './auto-deposit.component.html',
  styleUrls: ['./auto-deposit.component.scss']
})
export class AutoDepositComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  dateModel: NgbDateStruct;

  depositStatistic: any = {};
  depositInfo: any = {};

  depositList: any = [];
  originalOrderList: any = [];
  orderList: any = [];
  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  isRowSelectable: any;

  isViewAll: boolean = false;

  gridApi: any;
  gridColumnApi: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;

  columnDefs: any;
  columnDetailDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionDetail: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    gbn_seq: '',
    in_complete: '',
    customer_seq: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    auto_deposit_seq: '',
    dateType: '',
    term: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aDepositService: ADepositService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public calendar: NgbCalendar,
    public authService: AuthService
  ) {
    this.columnDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', width: 80, 
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true 
      },
      { headerName: '매칭상태', field: 'isComplete', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.isComplete == '1' ? '<span class="badge badge-success f12">처리완료</span>' : '<span class="badge badge-danger f12">미확인</span>';
        }
      },
      { headerName: '입금일자', field: 'TransDT', width: 124, cellClass: 'cp' },
      { headerName: '입출금구분', field: 'TransType', width: 82, cellClass: 'cp center' },
      { headerName: '계좌번호', field: 'BankAccountNum', width: 102, cellClass: 'cp' },
      { headerName: '입출금취급점', field: 'TransOffice', width: 93, cellClass: 'cp' },
      { headerName: '입출금비고', field: 'TransRemark', width: 132, cellClass: 'cp' },
      { headerName: '입금액', field: 'Deposit', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
    ];

    this.columnDetailDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상태', field: 'status', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.status;
        },
        cellStyle: function(params) {
          if (params.data.status_code == '1000' ) {
            return {color: 'green', backgroundColor: '#eeffee'};
          } else if (params.data.status_code == '2000' ) {
            return {color: 'green', backgroundColor: '#d8f7d2'};
          } else if (params.data.status_code == '3000' || params.data.status_code == '3010' || params.data.status_code == '4000' ) {
            return {color: 'blue', backgroundColor: '#dee7ff'};
          } else if (params.data.status_code == '8000' || params.data.status_code == '8010' || params.data.status_code == '8020' ) {
            return {color: 'red', backgroundColor: '#ffdede'};
          } else if (params.data.status_code == '9000' || params.data.status_code == '9010' || params.data.status_code == '9020' ) {
            return {color: 'orange', backgroundColor: '#fffce5'};
          } else {
            return null;
          }
        }
      },
      { headerName: '주문일자', field: 'order_datetime', width: 130, cellClass: 'cp center' },
      { headerName: '거래처', field: 'customer_name', width: 110, cellClass: 'cp' },
      { headerName: '회원구분', field: '', cellClass: 'cp center', width: 70, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.id ) {
            return '<span class="badge badge-success f11">회원</span>';
          } else {
            return '<span class="badge badge-secondary f11">비회원</span>';
          }
        }
      },
      { headerName: '회원명', field: 'name', cellClass: 'cp', width:200 },
      { headerName: '상품명', field: 'product_name', cellClass: 'cp', width:150 },
      { headerName: '전시/행사', field: 'project_name', cellClass: 'cp', width:200 },
      { headerName: '주문금액', field: 'unit_amt', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.unit_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '할인금액', field: 'discount', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.discount);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '부가세', field: 'vat', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.vat);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '금액', field: 'total_amt', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "single";
    this.rowSelectionDetail = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.isRowSelectable = function (rowNode) {
      return rowNode.data ? rowNode.data.status_code < 3000 : false;
    };

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.setSearchDate('1', 'months');

    this.getDepositStatistic();
    this.getDepositList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 입금대사 관리 위젯 통계 가져오기
  *******************************************************************************/
  async getDepositStatistic() {
    await this.aDepositService.getDepositStatistic(this.search).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.depositStatistic = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 계좌입금 현황 가져오기
  *******************************************************************************/
  async getDepositList() {
    this.orderList = [];

    await this.aDepositService.getDepositList( this.search ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.depositList = response.data;

        let nodes = '';
        setTimeout(() => {
          nodes = this.gridApi.getSelectedNodes();

          if( nodes.length < 1 ) {
            let index = 0;
            this.gridApi.forEachNode(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }
        });

        if( this.depositList.length > 0 ) {
          this.getIncompleteOrderList( this.depositList[0].seq, this.depositList[0].auto_deposit_seq );
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 매칭 미완료된 주문내역 가져오기
  *******************************************************************************/
  async getIncompleteOrderList( seq: number, auto_deposit_seq: any ) {
    await this.aDepositService.getIncompleteOrderList( seq, auto_deposit_seq ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.originalOrderList = response.data;
        this.depositInfo = response.deposit;

        if( auto_deposit_seq == null) {
          this.changeOrderList();
        } else {
          this.matchOrderInfo( auto_deposit_seq );
        }        
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 입금/주문 매칭
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDepositComplete() {
    let leftRowSelection = this.gridApi.getSelectedRows();
    let rightRowSelection = this.gridApiDetail.getSelectedRows();

    if( leftRowSelection.length < 1 ) {
      this.toastrService.error( '입금 내역을 선택하시기 바랍니다.', '입금/주문 매칭' );
      return false;
    }

    if( leftRowSelection[0].auto_deposit_seq != null ) {
      this.toastrService.error( '처리완료된 입금내역은 매칭하실 수 없습니다.', '입금/주문 매칭' );
      return false;
    }

    if( rightRowSelection.length < 1 ) {
      this.toastrService.error( '주문 내역을 선택하시기 바랍니다.', '입금/주문 매칭' );
      return false;
    }

    if( confirm("선택하신 입금내역을 주문내역과 매칭하시겠습니까?") ) {
      this.aDepositService.setDepositComplete( leftRowSelection[0], rightRowSelection[0] ).then( async response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          await this.getDepositStatistic();
          await this.getDepositList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 입금/주문 매칭취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDepositCancel() {
    let leftRowSelection = this.gridApi.getSelectedRows();

    if( leftRowSelection.length < 1 ) {
      this.toastrService.error( '입금 내역을 선택하시기 바랍니다.', '매칭취소' );
      return false;
    }

    if( leftRowSelection[0].auto_deposit_seq == null ) {
      this.toastrService.error( '미확인 입금내역은 매칭취소할 수 없습니다.', '매칭취소' );
      return false;
    }

    if( confirm("선택하신 입금내역을 매칭취소하시겠습니까?") ) {
      this.aDepositService.setDepositCancel( leftRowSelection[0] ).then( async response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          await this.getDepositStatistic();
          await this.getDepositList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 강제 처리완료
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDepositForceComplete() {
    let leftRowSelection = this.gridApi.getSelectedRows();

    if( leftRowSelection.length < 1 ) {
      this.toastrService.error( '입금 내역을 선택하시기 바랍니다.', '입금/주문 매칭' );
      return false;
    }

    if( leftRowSelection[0].isComplete == '1' ) {
      this.toastrService.error( '처리완료된 입금내역은 처리할 수 없습니다.', '강제 처리완료' );
      return false;
    }

    if( confirm("선택하신 입금내역을 강제 처리완료하시겠습니까?") ) {
      this.aDepositService.setDepositForceComplete( leftRowSelection[0] ).then( async response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          await this.getDepositStatistic();
          await this.getDepositList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid detail ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked(event: any) {
    this.getIncompleteOrderList( event.data.seq, event.data.auto_deposit_seq );
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {

  }

  /*******************************************************************************
    설  명 : 전체 주문내역 보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setIsViewAll(event : any) {
    this.isViewAll = event.target.checked;

    let leftRowSelection = this.gridApi.getSelectedRows();

    if( leftRowSelection[0].auto_deposit_seq == null ) {
      this.changeOrderList();
    } else {
      this.matchOrderInfo( leftRowSelection[0].auto_deposit_seq );
    }
  }

  /*******************************************************************************
    설  명 : 주문내역 조건에 맞게 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeOrderList() {
    let tmp: any = [];
    this.originalOrderList.forEach(item=> {
      if( this.isViewAll === false ) {
        if(
            item.total_amt == this.depositInfo.Deposit || 
            item.name.indexOf( this.depositInfo.TransRemark ) > -1 ||
            this.depositInfo.TransRemark.indexOf( item.name ) > -1
          ) {
          tmp.push(item);
        }
      } else {
        tmp.push(item);
      }
    });

    this.orderList = tmp;
  }

  /*******************************************************************************
    설  명 : 주문내역 조건에 맞게 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  matchOrderInfo( auto_deposit_seq: number ) {
    let tmp: any = [];

    this.originalOrderList.forEach(item => {
      if( auto_deposit_seq === item.auto_deposit_seq ) {
        tmp.push(item);
      }
    });

    this.orderList = tmp;
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : inout 입고/출고 구분
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      gbn_seq: '',
      in_complete: '',
      customer_seq: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      auto_deposit_seq: '',
      dateType: '',
      term: ''
    };

    this.setSearchDate('1', 'months');

    this.getDepositList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getDepositList();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, type: boolean ) {
    if( type )
      this.search.sdate = this.calendar.getToday();
    else
      this.search.edate = this.calendar.getToday();

    obj.close();
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.sdate = this.dateModel;
    this.search.edate = this.calendar.getToday();
  }
}

