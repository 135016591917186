import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AWarehouseService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 창고 정보 가져오기
  getWarehouseList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getWarehouseList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 정보 가져오기
  getWarehouseInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getWarehouseInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 정보 저장하기
  setWarehouse( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'setWarehouse'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 정보 삭제하기
  deleteWarehouse( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'deleteWarehouse'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 본사 주소 가져오기
  getCompanyAddress(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getCompanyAddress'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고에 있는 상품의 재고를 가져온다.
  getWarehouseProductStock( warehouse_seq, product_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getWarehouseProductStock'
      }, {
        warehouse_seq: warehouse_seq,
        product_seq: product_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 별 재고 상품 리스트를 가져온다.
  getWarehouseStock( params: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getWarehouseStock'
      }, {
        seq: params.seq,
        projectSeq: params.projectSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고이동에서 사용할 창고별 재고
  getWarehouseStockMove( params: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getWarehouseStockMove'
      }, {
        seq: params.seq,
        projectSeq: params.projectSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기본 입고/출고 창고 정보를 가져온다.
  getDefaultWarehouse(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'getDefaultWarehouse'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 첨부파일 삭제
  deleteUploadFile( upload_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'warehouse',
        version: '1.0',
        action: 'deleteUploadFile'
      }, {
        upload_seq: upload_seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }
}
