/*******************************************************************************
  설  명 : 회원관리
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbInputDatepicker, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { APaymentService } from '@admin/service/payment.service';
import { AProjectService } from '@admin/service/project.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

@Component({
  selector: 'app-payment-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class APaymentStatisticComponent implements OnInit {
  
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  paymentList: any = [];
  paymentDetailList: any = [];

  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  private dateModel: NgbDateStruct;

  typeList: any = [];
  paymentItemList: any = [];
  private paymentItemListOrg: any = [];
  projectList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  columnDetailDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  search: any = {
    type: '',
    dateType: '',
    term: '',
    account_seq: '',
    project_seq: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
  };

  total: any = {
    importAmt: 0,
    exportAmt: 0,
    incomeAmt: 0
  }

  totalDetail: any = {
    importAmt: 0,
    exportAmt: 0,
    incomeAmt: 0
  }

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aPaymentService: APaymentService,
    private aCommonService: ACommonService,
    private aProjectService: AProjectService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public authService: AuthService,
    private calendar: NgbCalendar
  ) {
    this.columnDefs = [
      {headerName: '일자', field: 'reg_date', width:170, cellClass: 'cp center' },
      {headerName: '수입금액', field: 'importAmt', cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.importAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '지출금액', field: 'exportAmt', cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.exportAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '잔액', field: 'incomeAmt', cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.incomeAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    this.columnDetailDefs = [
      {headerName: '프로젝트', field: 'project_name', cellClass: 'cp left', width: 150 },
      {headerName: '항목', field: 'name', cellClass: 'cp left', width: 150 },
      {headerName: '공급가', field: 'amt', cellClass: 'cp right', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.importAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '부가세', field: 'vat', cellClass: 'cp right', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.importAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '수입금액', field: 'importAmt', cellClass: 'cp right', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.importAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '지출금액', field: 'exportAmt', cellClass: 'cp right', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.exportAmt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.getTypeList();

    this.getProjectList();

    this.getAccountItemList();

    this.getPaymentDayList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTypeList() {
    this.aCommonService.getCommonList(40).then( response => {
      if( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 목록 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let search = {
      type: '',
      searchGroup: '',
      searchText: '',
      sdate: '',
      edate: ''
    };

    this.aProjectService.getProjectList( search ).then( response => {
      this.projectList = [];

      if( response.ResultCode ) {
        this.projectList.push({id: '', text: '프로젝트 선택'});

        response.data.forEach( row => {
          this.projectList.push({
            id: row.seq,
            text: row.name
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAccountItemList() {
    this.aPaymentService.getAccountItemList( this.search ).then( response => {
      this.paymentItemList = [];
      this.paymentItemListOrg = [];

      if( response.ResultCode ) {
        this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

        response.data.forEach( row => {
          this.paymentItemList.push({
            id: row.seq,
            text: row.name,
            type: row.type
          });
        });

        this.paymentItemListOrg = this.paymentItemList.map(x => Object.assign({}, x));
      } else {
        this.paymentItemList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentDayList() {
    this.paymentDetailList = [];

    this.aPaymentService.getPaymentDayList( this.search ).then( response => {
      this.total = {
        importAmt: 0,
        exportAmt: 0,
        incomeAmt: 0
      };

      if( response.ResultCode ) {
        this.paymentList = response.data;

        this.paymentList.forEach( row => {
          this.total.importAmt += parseInt(row.importAmt);
          this.total.exportAmt += parseInt(row.exportAmt);
        });

        this.total.incomeAmt = this.total.importAmt - this.total.exportAmt;

      } else {
        this.paymentList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentDayDetailList( date: any ) {
    this.search.reg_date = date;

    this.aPaymentService.getPaymentDayDetailList( this.search ).then( response => {
      this.totalDetail = {
        importAmt: 0,
        exportAmt: 0,
        incomeAmt: 0
      };

      if( response.ResultCode ) {
        this.paymentDetailList = response.data;

        this.paymentDetailList.forEach( row => {
          this.totalDetail.importAmt += parseInt(row.importAmt);
          this.totalDetail.exportAmt += parseInt(row.exportAmt);
        });

        this.totalDetail.incomeAmt = this.totalDetail.importAmt - this.totalDetail.exportAmt;

      } else {
        this.paymentDetailList = [];
      }
    });
  }


  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.getPaymentDayDetailList( $event.data.reg_date );
  }

  /*******************************************************************************
    설  명 : 셀 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDetailClicked($event) {
    this.getPaymentDayDetailList( $event.data.reg_date );
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changed( item: any ) {
    this.search.account_seq = item.id;

    this.getPaymentDayList();
  }

  /*******************************************************************************
    설  명 : 프로젝트 select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changedProject( item: any ) {
    this.search.project_seq = item.id;

    this.getPaymentDayList();
  }

  /*******************************************************************************
    설  명 : btn type select
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  btnSelect( value: any ) {
    /*
    this.paymentItemList = [];

    this.paymentItemList.push({id: '', text: '항목 선택', type: ''});
    */

    this.paymentItemListOrg.forEach( row => {
      if( value == '' ) this.paymentItemList.push( row );
      else if( row.type == value ) this.paymentItemList.push( row );
    });

    this.search.type = value;

    this.getPaymentDayList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.projectList = [];
    this.paymentItemList = [];
    
    this.search = {
      type: '',
      dateType: '',
      term: '',
      account_seq: '',
      project_seq: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
    }

    this.getProjectList();
    this.getAccountItemList();
    this.getPaymentDayList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.start_date = this.calendar.getToday();
      obj.close();
    } else {
      this.search.end_date = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.start_date = this.dateModel;
    this.search.end_date = this.calendar.getToday();

    this.getPaymentDayList();
  }
}
