import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { StatisticService } from '@admin/service/statistic.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-receivable-project',
  templateUrl: './receivable-project.component.html',
  styleUrls: ['./receivable-project.component.scss']
})
export class ReceivableProjectComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectYearList: any = [];
  public projectMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  public kindList: any = [];

  // 검색 조건
  public search: any = {
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    projectName: '',
    projectSeq: '',
    customer_seq: '',
    customer_name: '',
    customer_kind: '',
    mem_no: '',
    member_name: '',
    sdate: '',
    edate: ''
  };

  // 매출 내역
  public totalList: any = [];
  public totalSumList: any = [];
  public detailList: any = [];

  // 공통코드 그룹 그리드 관련 선언
  gridApiTotal: any;
  gridColumnApiTotal: any;
  columnDefsTotal: any;

  gridApiTotalSum: any;
  gridColumnApiTotalSum: any;
  columnDefsTotalSum: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  pinnedBottomRowData: any = [];
  detailPinnedBottomRowData: any = [];

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private statisticService: StatisticService,
    public authService: AuthService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefsTotal = [
      { headerName: '프로젝트명', field: 'project_name', width: 360, cellClass: 'cp left' },
      { headerName: '미수금(공급가)', field: 'receivable_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '미수금(부가세)', field: 'receivable_vat', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];

    this.columnDefsTotalSum = [
      { headerName: '', field: 'project_name', width: 360, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent' },
      { headerName: '', field: 'receivable_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '', field: 'receivable_vat', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];

    this.columnDefsDetail = [
      { headerName: '주문번호', field: 'order_seq', width: 70, cellClass: 'cp center' },
      { headerName: '세금계산서', field: 'tex_yn', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
        if( params.data.tex_yn == '발행') return '<span class="badge badge-primary f12 fn">발행</span>';
        else return '<span class="badge badge-secondary f12 fn">미발행</span>';
      }
      },
      { headerName: '거래처', field: 'customer_name', width: 200, cellClass: 'cp left' },
      { headerName: '주문자', field: 'member_name', width: 150, cellClass: 'cp left' },
      { headerName: '연락처', field: 'member_hp', width: 100, cellClass: 'cp center' },
      { headerName: '주문일자', field: 'order_date', width: 85, cellClass: 'cp center' },
      { headerName: '주문금액', field: 'total_amt', width: 80, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '결제금액', field: 'pay_amt', width: 80, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '미수금(공급가)', field: 'uncollectedAmt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '미수금(부가세)', field: 'uncollectedVat', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      return parseInt(params.data[ params.column.colId ], 10);
    }
  }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();
    this.getCommonList();
    // this.getProjectYearList();
    this.getProjectUncollectedTotalList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.projectYearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 거래처 분류
    await this.aCommonService.getCommonList(3).then( response => {
      this.kindList = response.data;
    });
  }
  
  /*******************************************************************************
     설  명 : 프로젝트 연도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // async getProjectYearList() {
  //   // 매출현황 리스트
  //   await this.statisticService.getProjectYearList().then( response => {
  //     //console.log(response);
  //     if (response.ResultCode) {
  //       this.projectYearList = response.data;
  //     }
  //   });
  // }

  /*******************************************************************************
     설  명 : 미수금 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getProjectUncollectedTotalList() {
    this.detailList = [];

    await this.statisticService.getProjectUncollectedTotalList( this.search ).then( response => {
      //console.log(response);
      if (response.ResultCode) {
        this.totalList = response.data;
        this.getGridSum(response.data);
        
        let nodes = '';
        setTimeout(() => {
          nodes = this.gridApiTotal.getSelectedNodes();

          if( nodes.length < 1 ) {
            let index = 0;
            this.gridApiTotal.forEachNode(function(node) {

              if (index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }
        }, 0);

        let total: any = {
          project_name: '<b>합계</b>',
          receivable_amt: 0,
          receivable_vat: 0
        };

        if( this.totalList.length > 0 ) {
          this.totalList.forEach(item => {
            total.receivable_amt += parseInt(item.receivable_amt);
            total.receivable_vat += parseInt(item.receivable_vat);
          });

          this.search.projectSeq = this.totalList[0].seq;

          this.getProjectUncollectedDetailList();
        }

        this.totalSumList = [total];
      }
    });
  }

  /*******************************************************************************
     설  명 : 미수금 상세 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getProjectUncollectedDetailList() {
    await this.statisticService.getProjectUncollectedDetailList( this.search ).then( response => {
      //console.log(response);
      if (response.ResultCode) {
        this.detailList = response.data;
        this.getDetailGridSum(response.data);
      }
    });
  }

  /*******************************************************************************
     설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyTotal(params) {
    this.gridApiTotal = params.api;
    this.gridColumnApiTotal = params.columnApi;
  }

  /*******************************************************************************
     설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyTotalSum(params) {
    this.gridApiTotalSum = params.api;
    this.gridColumnApiTotalSum = params.columnApi;
  }
  
  /*******************************************************************************
     설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApiDetail.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
     설  명 : ag grid 행 키보드로 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async onSelectionChanged($event) {
    let nodes: any;
    nodes = this.gridApiTotal.getSelectedNodes();

    this.search.projectSeq = nodes[0].data.seq;

    await this.getProjectUncollectedDetailList();
  }

  /*******************************************************************************
     설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async onRowClicked($event) {
    this.search.projectSeq = $event.data.seq;

    await this.getProjectUncollectedDetailList();
  }

  /*******************************************************************************
    설  명 : 거래처 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.search.customer_seq = result.seq;
      this.search.customer_name = result.name;

      this.getProjectUncollectedTotalList();
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(ACMemberFindComponent, optionsLG);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.search.mem_no = result.data.mem_no;
      this.search.member_name = result.data.name;

      this.getProjectUncollectedTotalList();
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectUncollectedTotalList();
  }
  
  /*******************************************************************************
     설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      projectName: '',
      projectSeq: '',
      customer_seq: '',
      customer_name: '',
      customer_kind: '',
      mem_no: '',
      member_name: '',
      sdate: '',
      edate: ''
    };

    this.getProjectUncollectedTotalList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate('');
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate('');
      obj.close();
    }
  }
  
  /*******************************************************************************
     설  명 : 엔터 입력 시 검색
    입력값 : $evnet
    리턴값 : 없음
  *******************************************************************************/
  setSearchInput( $event ) {
    if ( $event.key == 'Enter' ) {
      this.getProjectUncollectedTotalList();
    }
  }

  /*******************************************************************************
    설  명 : 그리드 하단 합계 계산
  *******************************************************************************/
  getGridSum = (data: any) => this.pinnedBottomRowData = [{
    project_name: '총합계',
    receivable_amt: data.reduce((a: any, c: any) => a + parseInt(c.receivable_amt), 0),
    receivable_vat: data.reduce((a: any, c: any) => a + parseInt(c.receivable_vat), 0),
  }]
  getDetailGridSum = (data: any) => this.detailPinnedBottomRowData = [{
    order_seq: '총합계',
    total_amt: data.reduce((a: any, c: any) => a + parseInt(c.total_amt), 0),
    pay_amt: data.reduce((a: any, c: any) => a + parseInt(c.pay_amt), 0),
    uncollectedAmt: data.reduce((a: any, c: any) => a + parseInt(c.uncollectedAmt), 0),
    uncollectedVat: data.reduce((a: any, c: any) => a + parseInt(c.uncollectedVat), 0),
  }]

}
