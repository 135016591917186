import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';

@Component({
  selector: 'app-tax-view',
  templateUrl: './tax-view.component.html',
  styleUrls: ['./tax-view.component.scss']
})
export class ATaxViewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public formData: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    public utilService: UtilService
  ) { }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : 모달창 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

  /*******************************************************************************
    설  명 : 날짜 포맷 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDateType(value: any, format: any) {
    if( !value ) return null;

    return this.utilService.getDateType(value, format);
  }
  
}
