/*******************************************************************************
  설  명 : 출고관리 - 출고지시서
  작성일 : 2020-10-03
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';

import * as moment from 'moment';

const MAXROW: number = 54;

@Component({
  selector: 'app-stock-out-order-print',
  templateUrl: './stock-out-order-print.component.html',
  styleUrls: ['./stock-out-order-print.component.scss']
})
export class AStockOutOrderPrintComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private projectSeq: any = '';

  public stockOriginOrderList: any = [];
  public stockOrderList: any = [];

  public projectInfo: any = {};

  public totalQty: number = 0;

  public maxRow: number = 54;   // 한 페이지에 한 줄마다 표시될 항목 수
  public maxPageRow: number = 162;  // 한 페이지에 표시될 항목 수
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aStockService: AStockService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.projectSeq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;

      if( this.projectSeq == '' ) {
        this.toastrService.error("정상적인 경로로 접근하시기 바랍니다.", "출고지시서");
        window.close();
      }

      this.getStockOrderPrint();
    });
  }

  /*******************************************************************************
    설  명 : 출고지시서 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStockOrderPrint() {
    this.aStockService.getStockOrderPrint( this.projectSeq ).then( response => {
      //console.log( response );
      if( response.ResultCode ) {
        this.stockOriginOrderList = response.data;
        this.projectInfo = response.info;

        let i: number = 0;
        let prev: string = '';
        let index: number = 1;
        let total: number = 0;

        for( let item of this.stockOriginOrderList ) {
          total += parseInt(item.qty);
        }

        this.totalQty = total;
        let tmpOrder: any = [];
        let tmpArray: any = [];
        if( this.stockOriginOrderList != null ) {
          if( this.stockOriginOrderList.length > this.maxPageRow ) {

            for( let i = 0; i < this.stockOriginOrderList.length; i++ ) {
              if( i > 0 && i % this.maxPageRow === 0 ) {
                tmpOrder.push(tmpArray);

                tmpArray = [];
              }

              tmpArray.push(this.stockOriginOrderList[i]);

              if( i === ( this.stockOriginOrderList.length - 1 ) ) {
                tmpOrder.push(tmpArray);
              }
            }

          } else {
            tmpOrder.push(this.stockOriginOrderList);
          }
        }

        this.stockOrderList = tmpOrder;

      } else {
        this.stockOrderList = [];
        this.projectInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray1( num: number ) {
    let tmp: any = [];
    for( let i = 0; i < num; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray( startNum: number ) {
    let tmp: any = [];

    startNum = startNum * MAXROW;

    for( let i = startNum; i < startNum + MAXROW; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 배열에 index 키 값을 가져온다.
    입력값 : index, key
    리턴값 : value
  *******************************************************************************/
    getListIndex( pIndex, index, key ) {
      if( index >= this.stockOrderList[pIndex].length ) return '';
      else return this.stockOrderList[pIndex][index][key];
    }

  /*******************************************************************************
    설  명 : 카테고리가 이전 카테고리와 같을 경우 rowspan
    입력값 : index
    리턴값 : true / false
  *******************************************************************************/
    checkPrev( pIndex: number, index: number ) {
      if( ( index % this.maxRow ) == 0 ) return true;
      if( index >= this.stockOrderList[pIndex].length ) return true;
  
      if( this.stockOrderList[pIndex][index-1].category_name == this.stockOrderList[pIndex][index].category_name ) {
        return false;
      } else return true;
    }
  

  /*******************************************************************************
    설  명 : rowspan count 가져오기
    입력값 : index
    리턴값 : rowspan count
  *******************************************************************************/
    rowSpanCount( pIndex: number, index: number ) {
      let count = 1;
  
      for( let i = index; i < this.stockOrderList[pIndex].length - 1; i++ ) {
        if( this.stockOrderList[pIndex][i]['category_name'] == this.stockOrderList[pIndex][i+1]['category_name'] ) count++;
        else break;
      }
  
      return count;
    }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }

}
