/*******************************************************************************
  설  명 : 거래처 담당자 정보 컴포넌트
  작성일 : 2019-09-18
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { AMemberListService } from '@admin/service/member.service';
import { ACustomerService } from '@admin/service/customer.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';
import { ACCustomerAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ACManagerComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() customer_seq: any = '';

  selectedRows: number = 0;
  managerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aMemberListService: AMemberListService,
    private modalService: NgbModal,
    private aCustomerService: ACustomerService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {headerName: '회원번호', field: 'mem_no', width: 70, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '상태', field: 'status', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == 'Y' ) return '<span class="badge badge-primary f12 fn">정상</span>';
          else if( params.data.status == 'N' ) return '<span class="badge badge-secondary f12 fn">탈퇴</span>';
          else return '<span class="badge badge-success f12 fn">휴면</span>';
        }
      },
      {headerName: '아이디', field: 'id', cellClass: 'cp center' },
      {headerName: '성명', field: 'name', cellClass: 'cp center' },
      {headerName: '성별', field: 'gender', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.gender == 'M' ) return '<span class="badge badge-primary f12 fn">남</span>';
          else if( params.data.gender == 'F' ) return '<span class="badge badge-success f12 fn">여</span>';
          else return '';
        }
      },
      {headerName: '휴대폰', field: 'hp', cellClass: 'cp center' },
      {headerName: '전화', field: 'tel', cellClass: 'cp center' },
      {headerName: '내선번호', field: 'extension_number', cellClass: 'cp center' },
      {headerName: '이메일', field: 'email', cellClass: 'cp' },
      {headerName: '메모', field: 'memo', cellClass: 'cp' },
      {headerName: '등록일', field: 'joindate', cellClass: 'cp center' },
      {headerName: '퇴사일', field: 'leavedate', cellClass: 'cp center' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 담당자 가져오기
    this.getManagerList( this.customer_seq );
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리 - 담당자 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'mem_no' ) {
      this.managerAdd( $event.data.mem_no );
    }
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getManagerList( customer_seq: any ) {
    this.aMemberListService.getManagerList( customer_seq ).then( response => {
      console.log( response );
      this.managerList = [];

      if( response.ResultCode ) {
        this.managerList = response.data;
      }
    }, error => {
      this.toastrService.error( error, '담당자' );
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 담당자 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  managerAdd( mem_no: any ) {
    const modalRef = this.modalService.open(ACCustomerAddComponent, options);

    modalRef.componentInstance.sc_seq = this.customer_seq;
    modalRef.componentInstance.mem_no = mem_no;

    console.log( this.customer_seq, modalRef.componentInstance.sc_seq );

    modalRef.result.then((result) => {
      this.getManagerList( this.customer_seq );
    }, (reason) => {
      console.log( reason );
    });
  }

  /*******************************************************************************
    설  명 : 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  managerDel() {
    let obj = this.gridApi.rowModel.nodeManager.allNodesMap;
    let dataList: any = [];

    for( const index in obj ) {
      let addCheck = false;

      if( obj[index].selected ) {
        dataList.push( obj[index].data );
      }
    }

    if( dataList.length < 1 ) {
      this.toastrService.error( '삭제할 담당자를 선택하시기 바랍니다.', '담당자 삭제' );
      return false;

    } else {
      if( confirm( "선택하신 담당자를 삭제하시겠습니까?" ) ) {
        console.log( dataList );
        this.aMemberListService.delManager( dataList ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '담당자 삭제');

            this.getManagerList( this.customer_seq );
          } else {
            this.toastrService.error( response.ResultMessage, '담당자 삭제');
          }
        }, error => {
          this.toastrService.error( error, '담당자 삭제');
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 주 담당자 지정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  managerSelect() {
    let obj = this.gridApi.rowModel.nodeManager.allNodesMap;
    let data: any;

    let count: number = 0;

    for( const index in obj ) {
      let addCheck = false;

      if( obj[index].selected ) {
        data = obj[index].data;
        count++;
        break;
      }
    }

    if( count < 1 ) {
      this.toastrService.error( '거래처의 주 담당자를 선택하시기 바랍니다.', '주 담당자 선택' );
      return false;

    } else {
      this.aMemberListService.getACMemberSelect( data.customer_seq, data.mem_no ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '주 담당자 선택');

          this.aCustomerService.setRefreshCustomer();
          this.getManagerList( this.customer_seq );

        } else {
          this.toastrService.error( response.ResultMessage, '주 담당자 선택');
        }
      }, error => {
        this.toastrService.error( error, '주 담당자 선택');
      });
    }
  }
}
