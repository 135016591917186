/*******************************************************************************
  설  명 : 창고별 재고관리
  작성일 : 2019-12-16
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModalOptions, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '@app/service/auth.service';

import { AWarehouseService } from '@admin/service/warehouse.service';
import { AProjectService } from '@admin/service/project.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import { ToastrService } from 'ngx-toastr';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class AStockWarehouseComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectSelectList$: BehaviorSubject<[]> = new BehaviorSubject([]);

  selectedRows: number = 0;

  gridApiWarehouse: any;
  gridColumnApiWarehouse: any;
  gridApiStock: any;
  gridColumnApiStock: any;

  columnDefsStock: any;
  columnDefsWarehouse: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    warehouse_seq: '',
    category_code: '',
    product_name: '',
    product_seq: '',
    date: '',
    sdate: '',
    edate: '',
    projectSeq: '',
    status: ''
  }

  projectList: any = [];

  stockList: any = [];
  warehouseList: any = [];

  warehouseName: String = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private aWarehouseService: AWarehouseService,
    public authService: AuthService,
    public calendar: NgbCalendar,
    private aProjectService: AProjectService
  ) {
    this.columnDefsWarehouse = [
      {headerName: '선택', field: 'seq', hide: true },
      {headerName: '창고', field: 'name', cellClass: 'cp', width: 100 },
      {headerName: '타입', field: 'type', cellClass: 'cp center', width: 75, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return ( params.data.type == '임시창고' ) ? '<span class="badge badge-success">임시창고</span>' : '<span class="badge badge-primary">상시창고</span>';
        }
      },
      {headerName: '창고주소', field: 'address', width: 200, cellClass: 'cp' },
      {headerName: '개요', field: 'outline',width: 200, cellClass: 'cp' },
    ];

    this.columnDefsStock = [
      {headerName: '상품코드', field: 'seq', cellClass: 'cp center' },
      {headerName: '구분', field: 'assembled_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.gbn_code == '0003' )
            return '<span class="badge badge-secondary f12">부속상품</span>';
          else if( params.data.assembled_yn == '1' )
            return '<span class="badge badge-primary f12">조립상품</span>';
          else
            return '<span class="badge badge-success f12">자체상품</span>';
        }
      },
      {headerName: '대분류', field: 'parent_category_name', cellClass: 'cp left' },
      {headerName: '중분류', field: 'category_name', cellClass: 'cp left' },
      {headerName: '상품명', field: 'name', cellClass: 'cp left' },
      {headerName: '총재고수량', field: 'total_stock', cellClass: 'cp right' },
      {headerName: '주문수량', field: 'order_count', cellClass: 'cp right' },
      {headerName: '창고과부족', field: 'cnt', cellClass: 'cp right',
        cellStyle: function(params) {
          if (params.value < 0) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return null;
          }
        }
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 프로젝트 리스트 가져오기
    this.getProjectList();

    // 창고 리스트 가져오기
    this.getWareHouse();
  }

  /*******************************************************************************
    설  명 : 검색에 사용할 프로젝트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let params: any = {
      type: '32',
      status: this.search.status,
      sdate: this.search.sdate,
      edate: this.search.edate,
      searchGroup: '',
      searchText: ''
    };

    this.aProjectService.getProjectList(params).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        tmp.push({
          id: '',
          text: '프로젝트 검색'
        });

        for( let item of response.data ) {
          tmp.push({
            id: item.seq,
            text: item.name
          });
        }

        this.projectList = response.data;
        this.projectSelectList$.next(tmp);
      }
    });
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWareHouse() {
    this.aWarehouseService.getWarehouseList({
      typeSelect: '',
      searchText: ''
    }).then( response => {
      if( response.ResultCode ) {
        this.warehouseList = response.data;
      } else {
        this.warehouseList = [];
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyWarehouse(params) {
    this.gridApiWarehouse = params.api;
    this.gridColumnApiWarehouse = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedWarehouse($event) {
    let filteredRowCount = 0;
    this.gridApiWarehouse.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedWarehouse($event) {
    this.search.warehouse_seq = $event.data.seq;

    this.getWarehouseStock( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApiStock = params.api;
    this.gridColumnApiStock = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApiStock.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
  }

  /*******************************************************************************
    설  명 : 창고별 재고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseStock( seq ) {
    this.aWarehouseService.getWarehouseStock({ seq:seq, projectSeq: this.search.projectSeq.id }).then( response => {
      if( response.ResultCode ) {
        this.stockList = response.data;
      } else {
        this.stockList = [];
      }
    }, error => {
      this.toastrService.error( error, '창고별 재고정보');
    });
  }

  /*******************************************************************************
    설  명 : 오늘 날짜 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj, data ) {
    if( data == 'sdate' )
      this.search.sdate = this.calendar.getToday();
    else
      this.search.edate = this.calendar.getToday();

    obj.close();
  }

  /*******************************************************************************
    설  명 : 프로젝트 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedProject( $event ) {
    let index = this.projectList.findIndex( item => item.seq == $event.id );

    if( this.projectList[index].warehouse_name == null )
      this.warehouseName = '미등록';
    else
      this.warehouseName = this.projectList[index].warehouse_name;

    if( this.search.warehouse_seq !== '' ) {
      this.getWarehouseStock( this.search.warehouse_seq );
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectList();
  }
}
