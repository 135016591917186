/*******************************************************************************
  설  명 : 캐리어 추가
  작성일 : 2019-09-20
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FileUploader, FileSelectDirective } from 'ng2-file-upload';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';

import { ACarrierService } from '@admin/service/carrier.service';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-carrier-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ACarrierAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedItem: any = {
    seq: 0, // 0 = 추가, 0 != 수정
    category_name: '',
    category_seq: '',
    file_path: '',
    name: '',
    spec: '',
    stock_qty: '',
    logo_origin: '',
    logo_url: ''
  };
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });

  // 캐리어 분류
  public categoryList: any = [];

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      name:["", [Validators.required, Validators.maxLength(100)] ],
      category_seq:["", [Validators.required] ],
      spec:["", [Validators.required, Validators.maxLength(1000)] ],
      image:["", [Validators.maxLength(255)] ],
      logo_url:["", [Validators.maxLength(255)] ],
      logo_origin:["", [Validators.maxLength(255)] ]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aCarrierService: ACarrierService,
    public authService: AuthService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        this.formAdd.patchValue( {
          logo_url: config.baseUrl + response.url,
          logo_origin: response.origin_filename
        });

      } else {
        this.toastrService.error( response.message, '');
      }
    };

    this.getCarrierCategory();

    // 데이터가 넘어온 경우 데이터 설정
    if( this.selectedItem.seq !== 0 ) {
      this.selectedItem.logo_origin = this.selectedItem.org_filename;
      this.selectedItem.logo_url = this.selectedItem.file_path;

      this.formAdd.patchValue( this.selectedItem );
    }

  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;
    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 표시 처리 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.selectedItem.seq == 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aCarrierService.setCarrier(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 캐리어 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCarrierCategory() {
    this.aCarrierService.getCarrierCategory().then( response => {
      this.categoryList = [];

      for( let index in response.data ) {
        this.categoryList.push({
          title: response.data[index].category_name,
          value: response.data[index].seq
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 캐리어를 삭제하시겠습니까?") ) {
      this.aCarrierService.deleteCarrier( this.formAdd ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
