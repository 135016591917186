/*******************************************************************************
  설  명 : 수입지출 관리
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { APaymentService } from '@admin/service/payment.service';
import { AProjectService } from '@admin/service/project.service';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { APaymentAddComponent } from '@app/admin/page/payment/add/add.component';
import { ACustomerService } from '@app/admin/service/customer.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-statistic-incomepayment-status',
  templateUrl: './statistic-incomepayment-status.component.html',
  styleUrls: ['./statistic-incomepayment-status.component.scss']
})
export class StatisticIncomepaymentStatusComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  paymentList: any = [];
  typeList: any = [];
  paymentItemList: any = [];
  private paymentItemListOrg: any = [];
  projectList: any = [];
  public projectYearList: any = [];
  public projectMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  private dateModel: NgbDateStruct;

  search: any = {
    type: '',
    dateType: '',
    term: '',
    account_seq: '',
    project_seq: '',
    searchDate: '1',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
  };

  total: any = {
    importAmt: 0,
    exportAmt: 0,
    imcomeAmt: 0
  }

  arrayBuffer: any;
  filelist: any[];

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private aPaymentService: APaymentService,
    private aCommonService: ACommonService,
    private aProjectService: AProjectService,
    public authService: AuthService,
    private calendar: NgbCalendar,
    private aCustomerService: ACustomerService
  ) {
    this.columnDefs = [
      {headerName: '', field: 'seq', cellClass: 'cp center', width: 80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '구분', field: 'type_name', width: 60, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type_name == '수입') return '<span class="badge badge-success f12 fn">수입</span>';
          else if( params.data.type_name == '지출') return '<span class="badge badge-danger f12 fn">지출</span>';
          else return '<span class="badge badge-secondary f12 fn">기타</span>';
        }
      },
      {headerName: '등록일자', field: 'reg_date', width: 80, cellClass: 'cp center' },
      // {headerName: '증빙일자', field: 'proof_date', width: 100, cellClass: 'cp center' },
      {headerName: '발행일자', field: 'issue_date', width: 100, cellClass: 'cp center' },
      {headerName: '입금일자', field: 'payment_date', width: 100, cellClass: 'cp center' },
      {headerName: '프로젝트', field: 'project_name', width: 315, cellClass: 'cp' },
      {headerName: '거래처명', field: 'customer_name', width: 150, cellClass: 'cp center' },
      {headerName: '일용직명', field: 'member_name', width: 150, cellClass: 'cp center' },
      {headerName: '항목', field: 'name', cellClass: 'cp center', width: 80 },
      {headerName: '공급가액', field: 'amt', cellClass: 'cp right', width: 90, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.amt);
          // if( params.data.type_name == '수입' )
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          // else
          //   return '';
        }
      },
        {headerName: '부가세', field: 'vat', cellClass: 'cp right', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.vat);
          // if( params.data.type_name == '수입' )
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          // else
          //   return '';
        }
      },
      {headerName: '수입금액', field: 'total_amt', cellClass: 'cp right', width: 90, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_amt);
          if( params.data.type_name == '수입' )
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          else
            return '';
        }
      },
      {headerName: '지출금액', field: 'total_amt', cellClass: 'cp right', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_amt);

          if( params.data.type_name == '지출' )
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          else
            return '';
        }
      },
      {headerName: '카드사용자', field: 'card_no', cellClass: 'cp center', width: 120 },
      {headerName: '사용처', field: 'use_detail', cellClass: 'cp center', width: 120 },
      {headerName: '참석자', field: 'attendance', cellClass: 'cp center', width: 100 },
      {headerName: '인원', field: 'people_count', cellClass: 'cp center', width: 70 },
      {headerName: '비고', field: 'memo', cellClass: 'cp', width: 245 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.getTypeList();

    this.getProjectList();

    this.getAccountItemList();

    this.getCustomerList();

    this.getPaymentList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.projectYearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.paymentAdd( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 수입지출항목 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  paymentAdd( seq ) {
    const modalRef = this.modalService.open(APaymentAddComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      this.getPaymentList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentList() {

    let search: any = {
      type: this.search.type,
      account_seq: this.search.account_seq,
      project_seq: this.search.project_seq,
      customer_seq: this.search.customer_seq,
      searchDate: this.search.searchDate,
      searchYear: this.search.searchYear,
      searchMonth: this.search.searchMonth,
    }

    this.aPaymentService.getPaymentList( search ).then( response => {
      this.total = {
        importAmt: 0,

        exportAmt: 0,
        imcomeAmt: 0
      };

      if( response.ResultCode ) {
        this.paymentList = response.data;

        this.paymentList.forEach( row => {
          if( row.type_name == '수입' )
            this.total.importAmt += parseInt(row.total_amt);
          else
            this.total.exportAmt += parseInt(row.total_amt);
        });

        this.total.imcomeAmt = this.total.importAmt - this.total.exportAmt;

      } else {
        this.paymentList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTypeList() {
    this.aCommonService.getCommonList(40).then( response => {
      if( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let search = {
      type: '',
      searchGroup: '',
      searchText: '',
      sdate: '',
      edate: ''
    };

    this.aProjectService.getProjectList( search ).then( response => {
      this.projectList = [];

      if( response.ResultCode ) {
        this.projectList.push({id: '', text: '프로젝트 선택'});

        response.data.forEach( row => {
          this.projectList.push({
            id: row.seq,
            text: row.name
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAccountItemList() {
    this.aPaymentService.getAccountItemList( this.search ).then( response => {
      this.paymentItemList = [];
      this.paymentItemListOrg = [];

      if( response.ResultCode ) {
        this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

        response.data.forEach( row => {
          this.paymentItemList.push({
            id: row.seq,
            text: row.name,
            type: row.type
          });
        });

        this.paymentItemListOrg = this.paymentItemList.map(x => Object.assign({}, x));
      } else {
        this.paymentItemList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCustomerList() {
    let search = {
      typeSelect: '',
      kindSelect: '',
      searchGroup: 'name',
      searchText: ''
    };

    this.aCustomerService.getCustomerList( search ).then( response => {
      this.customerList = [];

      if( response.ResultCode ) {
        this.customerList.push({id: '', text: '거래처 선택'});

        response.data.forEach( row => {
          this.customerList.push({
            id: row.seq,
            text: row.name
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changed( item: any ) {
    this.search.account_seq = item.id;

    this.getPaymentList();
  }

  /*******************************************************************************
    설  명 : 프로젝트 select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changedProject( item: any ) {
    this.search.project_seq = item.id;

    this.getPaymentList();
  }

  /*******************************************************************************
    설  명 : 일용직 select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  changedMember( item: any ) {
    this.search.customer_seq = item.id;

    this.getPaymentList();
  }


  /*******************************************************************************
    설  명 : btn type select
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  btnSelect( value: any ) {
    this.paymentItemList = [];

    this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

    this.paymentItemListOrg.forEach( row => {
      if( value == '' ) this.paymentItemList.push( row );
      else if( row.type == value ) this.paymentItemList.push( row );
    });

    this.search.type = value;

    this.getPaymentList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      type: '',
      dateType: '',
      term: '',
      account_seq: '',
      project_seq: '',
      customer_seq: '',
      searchDate: '1',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
    }

    this.getPaymentList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.start_date = this.calendar.getToday();
      obj.close();
    } else {
      this.search.end_date = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.start_date = this.dateModel;
    this.search.end_date = this.calendar.getToday();

    this.getPaymentList();
  }

  /*******************************************************************************
    설  명 : 파일 업로드 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event: any) {

    if(!confirm('엑셀 업로드를 하시겠습니까?')) return

    const files: any = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(files);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);

      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary", cellDates: true, dateNF: 'YYYY-MM-DD'});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});

      let params: any = [];
      this.filelist.map((obj, i) => {
        if( obj['등록일자'] !== undefined ) {
          params.push({
            name: obj['사용자'] ? obj['사용자'] : '',
            reg_date: obj['등록일자'] ? moment(obj['등록일자']).add(1, 'hour').format('YYYY-MM-DD') : '',
            issue_date: obj['발행일자'] ? moment(obj['발행일자']).add(1, 'hour').format('YYYY-MM-DD') : '',
            proof_date: '',
            payment_date: obj['입금일자'] ? moment(obj['입금일자']).add(1, 'hour').format('YYYY-MM-DD') : '',
            total_amt: obj["합계"] ? obj["합계"] : '',
            amt: obj["공급가액"] ? obj["공급가액"] : '',
            vat: obj["부가세"] ? obj["부가세"] : '',
            card_no: obj["카드사용자"] ? obj["카드사용자"] : '',
            use_detail: obj["사용처"] ? obj["사용처"] : '',
            account_name: obj["비용항목"] ? obj["비용항목"] : '',
            project_name: obj["프로젝트"] ? obj["프로젝트"] : '',
            customer_name: obj["거래처"] ? obj["거래처"] : '',
            attendance: obj["카드사용자"] ? obj["카드사용자"] : '',
            people_count: obj["인원"] ? obj["인원"] : '',
            memo: obj["비고"] ? obj["비고"] : ''
          });
        }
      });

      if( params.length < 1 ) {
        this.toastrService.error( '등록일자가 없습니다. 업로드 데이터를 다시 확인하시기 바랍니다.', '');

      } else {
        this.aPaymentService.setAccountExcel( JSON.stringify(params) ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '엑셀업로드');
            this.getPaymentList();
          } else {
            this.toastrService.error( '수입지출 엑셀업로드를 저장하는데 실패하였습니다.', '엑셀업로드');
          }
        });
      }
    }
  }
}
