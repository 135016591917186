/*******************************************************************************
  설  명 : 캐리어 관리 관리
  작성일 : 2019-09-20
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { ACommonService } from '@admin/service/common.service';
import { ACarrierService } from '@admin/service/carrier.service';
import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACarrierAddComponent } from './add/add.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.scss']
})
export class ACarrierComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 캐리어 분류
  public categoryList: any = [];

  // 검색 필드
  public search = {
    searchText: '',
    category_code: ''
  }

  selectedRows: number = 0;
  carrierList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  getRowHeight: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aCarrierService: ACarrierService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {headerName: '코드', field: 'seq', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '캐리어분류', field: 'category_name', cellClass:'cp ag-cell80h'},
      {headerName: '이미지', field: 'file_path', cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return '<img src="' + params.data.file_path + '" style="max-height:80px;" />';
        }
      },
      {headerName: '캐리어명', field: 'name', cellClass: 'cp ag-cell80h' },
      {headerName: '규격', field: 'spec', cellClass: 'cp ag-cell80h' },
      {headerName: '재고', field: 'stock_qty', cellClass: 'cp right ag-cell80h' },
      {headerName: '캐리어코드', field: 'category_seq', hide:true },
      {headerName: '원본이미지파일명', field: 'org_filename', hide:true },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.getRowHeight = function(params) {
      return 80;
    };
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngAfterViewInit() {
    // 캐리어 분류 가져오기
    await this.getCarrierCategory();

    this.getCarrierList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.carrierAdd( $event.data );
    }
  }

  /*******************************************************************************
    설  명 : 캐리어 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  carrierAdd( item ) {
    const modalRef = this.modalService.open(ACarrierAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      this.getCarrierList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 캐리어 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCarrierList() {
    this.aCarrierService.getCarrierList( this.search ).then( response => {
      this.carrierList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 캐리어 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCarrierCategory() {
    this.aCarrierService.getCarrierCategory().then( response => {
      this.categoryList = [];

      for( let index in response.data ) {
        this.categoryList.push({
          title: response.data[index].category_name,
          value: response.data[index].category_code
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    // 검색 필드
    this.search = {
      searchText: '',
      category_code: ''
    }

    this.getCarrierList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getCarrierList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCarrierList();
    }
  }
}
