import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';

import { AProductService } from '@admin/service/product.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-product-find-grid',
  templateUrl: './product-find-grid.component.html',
  styleUrls: ['./product-find-grid.component.scss']
})
export class ACProductFindGridComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public customer_seq: any = '';  // 거래처 코드 : 할인율 계산을 위해
  public project_seq: any = '';   // 프로젝트 코드 : 할인율 계산을 위해
  public mem_no: any = '';        // 회원번호 : 할인율 계산을 위해

  public set_yn: boolean = true; // 세트 상품 표시 여부 true , false = 표시안함
  public part_yn: boolean = false; // 부속 상품만 가져올지 여부 true, false = 전체 상품 가져오기
  public part_include: boolean = false; // 부속 상품도 포함해서 가져올지 여부
  public product_use_yn: boolean = true; // 사용중인 상품만 가져올지 여부

  public productList: any = [];
  public selectedList: any = [];

  public baseURL: any = config.baseUrl;

  public searchText: any = '';

  leftGridApi: any;
  leftGridColumnApi: any;
  leftColumnDefs: any;

  rightGridApi: any;
  rightGridColumnApi: any;
  rightColumnDefs: any;

  defaultColDef: any;

  leftGridNoRowsTemplate: string;
  rightGridNoRowsTemplate: string;

  // 그리드 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private aProductService: AProductService
  ) {
    this.leftColumnDefs = [
      { headerName: '상품번호', field: 'product_seq', cellClass: 'cp center', width: 88 },
      { headerName: '대분류', field: 'parent_category_name', cellClass: 'cp center', width: 120,
        valueGetter: function(params) {
          if( params.data.parent_category_name ) {
            return params.data.parent_category_name;
          } else {
            return params.data.category_name;
          }
        }
      },
      { headerName: '중분류', field: 'category_name', cellClass: 'cp center', width: 145 },
      // { headerName: '상품이미지', field: 'thumbnail_filename', cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'name', cellClass: 'cp left', width: 250 },
      { headerName: '선택', field: '', cellClass: 'cp center', width: 65, rowDrag: true, suppressMenu: true,
        rowDragText: function(params, dragItemCount) {
          if (dragItemCount > 1) {
              return dragItemCount + ' 상품';
          }
          return params.rowNode.data.product_name;
        }
      },
    ];

    this.rightColumnDefs = [
      { headerName: '상품번호', field: 'product_seq', cellClass: 'cp center', width: 88 },
      { headerName: '대분류', field: 'parent_category_name', cellClass: 'cp center', width: 120 },
      { headerName: '중분류', field: 'category_name', cellClass: 'cp center', width: 145 },
      // { headerName: '상품이미지', field: 'thumbnail_filename', cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'name', cellClass: 'cp left', width: 250 },
      {
        field: '삭제',
        width: 65,
        cellClass: 'mt7',
        cellRenderer: function(params) {
          const button = document.createElement('i');

          button.addEventListener('click', function() {
              params.api.updateRowData({ remove: [params.node.data] });
          });

          button.classList.add('material-icons');
          button.style.cursor = 'pointer';
          button.textContent = 'delete_forever';

          return button;
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    // 메시지 표시 선언
    this.leftGridNoRowsTemplate = '검색된 데이터가 없습니다.';
    this.rightGridNoRowsTemplate = '추가할 상품을 왼쪽 그리드에서 더블클릭 하거나 드래그해주세요.';
  }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 상품 리스트 가져오기
    this.getCategoryProductList();
  }

  /*******************************************************************************
     설  명 : 카테고리별 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryProductList() {
    let params: any = {
      set_yn: this.set_yn,
      part_yn: this.part_yn,
      part_include: this.part_include, // 송영석 추가
      customer_seq: this.customer_seq,
      project_seq: this.project_seq,
      mem_no: this.mem_no,
      product_use_yn: this.product_use_yn
    };

    this.aProductService.getCategoryProductList( params ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.product;

      } else {
        this.productList = [];

        this.toastrService.error( response.ResultMessage, '상품 가져오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '상품 가져오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onLeftGridReady(params) {
    this.leftGridApi = params.api;
    this.leftGridColumnApi = params.columnApi;
  }

  onRightGridReady(params) {
    this.rightGridApi = params.api;
    
    this.addGridDropZone();
  }

  // 그리드 노드 아이디 가져오기
  getRowNodeId = data => data.product_seq;

  // 그리드 드랍 시
  addGridDropZone() {
    const dropZoneParams = this.rightGridApi.getRowDropZoneParams({
      onDragStop: params => {
        var nodes = params.nodes;

        nodes.forEach(function(node) {
          node.setSelected(false);
        });
        // this.leftGridApi.applyTransaction({
        //   remove: nodes.map(function(node) { return node.data; })
        // });
      }
    });

    this.leftGridApi.addRowDropZone(dropZoneParams);
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( event: any ) {
    let newData: any = [];
    let duplicate: boolean = false;

    this.rightGridApi.forEachNodeAfterFilterAndSort(node => {
      if( event.data.product_seq == node.data.product_seq ) duplicate = true;

      newData.push(node.data);
    });

    if( duplicate === false ) {
      newData.push( event.data );
    }

    this.rightGridApi.setRowData( newData );
  }
    
  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.leftGridApi.setQuickFilter(searchText.value);
  }

  /*******************************************************************************
     설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
     설  명 : 상품 선택 시 결과 돌려주기
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  selectProduct() {
    const rowData = [];
    this.rightGridApi.forEachNode(node => rowData.push(node.data));
    // console.log(rowData);
    this.activeModal.close( rowData );
  }

}
