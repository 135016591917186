/*******************************************************************************
  설  명 : 수입지출항목 관리
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { APaymentService } from '@admin/service/payment.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-item-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class APaymentItemAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedItem: any = {
    seq: '0'
  };
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public typeList:any = [];

  /*******************************************************************************
    설  명 : 폼 빌드 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      type:["", [Validators.required] ],
      name:["", [Validators.required, Validators.maxLength(50)] ],
      outline:["", [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aPaymentService: APaymentService,
    public authService: AuthService,
    private aCommonService: ACommonService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 수입지출 타입 가져오기
    this.getTypeList();
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.selectedItem.seq == '0' ) {
      this.title = '추가';
    } else {
      this.title = '수정';

      // 데이터 로딩 처리
      this.formAdd.patchValue( this.selectedItem );
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aPaymentService.setPaymentItem(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTypeList() {
    this.aCommonService.getCommonList(40).then( response => {
      if( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 수입지출 항목을 삭제하시겠습니까?") ) {
      this.aPaymentService.delAccountItem( this.formAdd.controls.seq.value ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
