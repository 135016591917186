/*******************************************************************************
  설  명 : 직원관리
  작성일 : 2019-09-22
  작성자 : 송영석
*******************************************************************************/
import { Component, ViewChild, ElementRef, OnInit, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { PioneerTreeConfiguration, IPioneerTreeConfiguration, IPioneerTreeComponent } from '@pioneer-code/pioneer-tree';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';
import { Subject, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';

import { ASystemGroupService } from '@admin/service/system.group.service';
import { ASystemPositionService } from '@admin/service/position.service';
import { ASystemAuthGroupService } from '@admin/service/auth.group.service';
import { AEmployeeService } from '@admin/service/employee.service';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class AEmployeeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild ( 'pt', {static: false} ) pt: IPioneerTreeComponent ;

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });

  public employeePositionList = [];
  public groupList: any = [];
  public positionList: any = [];
  public authGroupList: any = [];

  public form: FormGroup;
  public formErrors = {};

  public selectedNode:any = {};
  public selectedTree:any = {};

  private dateModel: NgbDateStruct;

  public uploadProgress: any = 0;

  public configuration = {
    "childPropertyName" : "children",
    "sortPropertyName" : "id",
    "collapseAllOnLoad" : false
  } as IPioneerTreeConfiguration;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no:["", [] ],
      id:["", [Validators.required] ],
      level:["", [] ],
      password:["", [Validators.required] ],
      name:["", [Validators.required, Validators.maxLength(50)] ],
      email:["", [Validators.maxLength(100)] ],
      birthdate:[null, [] ],
      birth_sec:["S", [] ],
      joindate:[null, [] ],
      leavedate:[null, [] ],
      dept_seq:["", [Validators.required] ],
      position_seq:["", [] ],
      tel:["", [Validators.maxLength(20)] ],
      hp:["", [Validators.maxLength(20)] ],
      extension_number:["", [Validators.maxLength(20)] ],
      emergency_tel:["", [Validators.maxLength(100)] ],
      auth_group_seq:["", [Validators.required] ],
      zipcode:["", [] ],
      address:["", [Validators.maxLength(255)] ],
      address_detail:["", [Validators.maxLength(255)] ],
      picture:["", [] ],
      picture_url:['/assets/admin/images/no_image.png', [] ],
      picture_origin:["", [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 선택 노드 값을 초기화한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initNode() {
    this.selectedNode = {
      mem_no: '',
      id: '',
      level: '',
      password: '',
      name: '',
      email: '',
      birthdate: null,
      birth_sec: "S",
      joindate: null,
      leavedate: null,
      dept_seq: '',
      position_seq: '',
      tel: '',
      hp: '',
      extension_number: '',
      emergency_tel: '',
      auth_group_seq: '',
      picture: '',
      picture_url: '/assets/admin/images/no_image.png',
      picture_origin: '',
    };
  }

  /*******************************************************************************
    설  명 : 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteImage() {
    this.form.patchValue({
      picture_url: '/assets/admin/images/no_image.png',
      picture_origin: ''
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private aEmployeeService: AEmployeeService,
    private aSystemGroupService: ASystemGroupService,
    private aSystemPositionService: ASystemPositionService,
    private aSystemAuthGroupService: ASystemAuthGroupService,
    private restful: RestfulService,
    public authService: AuthService,
    private aCommonService: ACommonService
  ) {
    this.buildForm();

    this.initNode();
  }

  /*******************************************************************************
    설  명 : 데이터 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 5 * (1024 * 1024), // 최대 업로드 허용 5MB 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        this.form.patchValue( {
          picture_url: config.baseUrl + response.url,
          picture_origin: response.origin_filename
        });

      } else {
        this.toastrService.error( response.message, '');
      }
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;
    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngAfterViewInit() {
    // 조직도 가져오기
    this.getGroupList();

    // 직위 가져오기
    this.getPositionList();

    // 권한그룹 가져오기
    this.getAuthGroupList();

    // 사원 조직도 리스트 가져오기
    this.getGroupEmployeeList();
  }

  /*******************************************************************************
    설  명 : 카테고리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aEmployeeService.setEmployee(this.form).then( async response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          if( this.form.controls.mem_no.value == '' ) {
            this.form.patchValue({mem_no: response.mem_no});
          }

          this.getGroupEmployeeList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.aEmployeeService.getGroupEmployeeList().then( response => {      
      this.employeePositionList = response.data;
    });

    this.configuration = { "childPropertyName" : "children", "sortPropertyName" : "id", "collapseAllOnLoad" : false };
  }

  /*******************************************************************************
    설  명 : 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupList() {
    this.aEmployeeService.getGroupList().then( response => {

      response.data.forEach( row => {
        this.groupList.push({
          title: row.select_name,
          value: row.seq
        });
      });
    });
  }

  /*******************************************************************************
    설  명 : 직위 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPositionList() {
    this.aSystemPositionService.getPositionList().then( response => {
      response.data.forEach( row => {
        this.positionList.push({
          title: row.name,
          value: row.seq
        });
      });
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAuthGroupList() {
    this.aSystemAuthGroupService.getAuthGroupList().then( response => {
      response.data.forEach( row => {
        this.authGroupList.push({
          title: row.name,
          value: row.seq
        });
      });
    });
  }

  /*******************************************************************************
    설  명 : 직원 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeInfo( seq ) {
    this.aEmployeeService.getEmployeeInfo( seq ).then( response => {
      this.selectedNode = response.data;

      if( this.selectedNode.birthdate !== null ) {
        this.dateModel = {
          year: parseInt( this.selectedNode.birthdate.substr( 0, 4 ) ),
          month: parseInt( this.selectedNode.birthdate.substr( 5, 2 ) ),
          day: parseInt( this.selectedNode.birthdate.substr( 8, 2 ) )
        };

        this.selectedNode.birthdate = this.dateModel;
      }

      if( this.selectedNode.joindate !== null ) {
        this.dateModel = {
          year: parseInt( this.selectedNode.joindate.substr( 0, 4 ) ),
          month: parseInt( this.selectedNode.joindate.substr( 5, 2 ) ),
          day: parseInt( this.selectedNode.joindate.substr( 8, 2 ) )
        };

        this.selectedNode.joindate = this.dateModel;
      }

      if( this.selectedNode.leavedate !== null ) {
        this.dateModel = {
          year: parseInt( this.selectedNode.leavedate.substr( 0, 4 ) ),
          month: parseInt( this.selectedNode.leavedate.substr( 5, 2 ) ),
          day: parseInt( this.selectedNode.leavedate.substr( 8, 2 ) )
        };

        this.selectedNode.leavedate = this.dateModel;
      }

      this.form.patchValue( this.selectedNode );
    });
  }

  /*******************************************************************************
    설  명 : 조직도 노드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async selectedEmployee( node ) {

    // 트리노드 선택
      this.selectedTree = node;

    // 선택 노드가 직원일 경우 직원 정보 가져오기
    if( node.mem_no != null ) {
      await this.getEmployeeInfo( node.mem_no );

    // 선택 노드가 부서일 경우
    } else {
      // 사원정보 초기화
      this.initNode();

      // 부서 정보 선택
      this.selectedNode.dept_seq = node.seq;

      this.form.patchValue( this.selectedNode );
    }
  }

  /*******************************************************************************
    설  명 : 직원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addEmployee() {
    this.initNode();

    this.form.patchValue( this.selectedNode );
  }

  /*******************************************************************************
    설  명 : 직원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteEmployee() {
    if( this.form.controls.mem_no.value == '' ) {
      this.toastrService.error( '삭제할 직원을 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 직원를 삭제하시겠습니까?") ) {
      this.aEmployeeService.deleteEmployee( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.initNode();

          this.form.patchValue( this.selectedNode );

          this.getGroupEmployeeList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 다음 주소 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }


}
