import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';

import { AMemberListService } from '@admin/service/member.service';
import { UtilService } from '@app/service/util.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { NonMemberAddComponent } from '@admin/page/order/application/non-member-add/non-member-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-find',
  templateUrl: './member-find.component.html',
  styleUrls: ['./member-find.component.scss']
})
export class ACMemberFindComponent implements OnInit {

  // 그리드 변수 선언
  selectedRows: number = 0;
  domLayout: any;
  memberList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;

  noRowsTemplate: string;

  // 폼 변수 선언
  public form: FormGroup;
  public formErrors = {
    searchText: ''
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  buildForm(): void {
    this.form = this.formBuilder.group({
      searchText: ['', [Validators.required, Validators.maxLength(50)]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  constructor(
    private formBuilder: FormBuilder,
    private aMemberListService: AMemberListService,
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    private modalService: NgbModal,
  ) {
    this.buildForm();

    // 그리드 컬럼 선언
    this.columnDefs = [
      {headerName: '회원번호', field: 'mem_no', hide: true },
      {headerName: '구분', field: 'level', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.level == '0' ) return '<span class="badge badge-secondary">비회원</span>';
          else return '<span class="badge badge-success">일반회원</span>';
        }
      },
      {headerName: '이름', field: 'name', cellClass: 'cp left', width:260 },
      {headerName: '아이디', field: 'id', cellClass: 'cp center', width:130 },
      {headerName: '휴대전화', field: 'hp', cellClass: 'cp center', width:130 },
      {headerName: '이메일', field: 'email', cellClass: 'cp center', width:200 },
      {headerName: '회사명', field: 'company', cellClass: 'cp center', width:150 },
      {headerName: '성별', field: 'gender', hide: true },
      {headerName: '내선번호', field: 'extension_number', hide: true },
    ];

    // 컬럼 디폴트 값 선언
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $("#searchText").focus();
  }

  // 그리드 준비 시 처리
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // 필터 변경시 처리
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  // 그리드 row 클릭 시 처리
  onRowClicked($event) {
    this.activeModal.close( $event );
  }

  // 처음 데이터 렌더링 시 처리
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // 회원검색
  getMemberList() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aMemberListService.getACMemberList( this.form.value.searchText ).then( response => {
        this.memberList = response.data;
      });
    }
  }

  /*******************************************************************************
    설  명 : 비회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nonMemberAdd() {
    const modalRef = this.modalService.open(NonMemberAddComponent, options);

    modalRef.result.then((result) => {
      this.form.patchValue({
        mem_no: result.mem_no,
        mem_name: result.name,
        mem_hp: result.hp,
        mem_email: result.email
      });

    }, (reason) => {
      // console.log( reason );
    });
  }
}
