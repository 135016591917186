/*******************************************************************************
  설  명 : 창고이동
  작성일 : 2020-08-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbModal, NgbModalOptions, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, BehaviorSubject } from 'rxjs';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { AStockService } from '@admin/service/stock.service';
import { AProjectService } from '@admin/service/project.service';

import * as moment from 'moment';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridButtonComponent } from '@app/component/ag-grid-button/ag-grid-button.component';

import { ACBarcodeInputComponent } from '@admin/component/barcode-input/barcode-input.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-warehousing',
  templateUrl: './warehousing.component.html',
  styleUrls: ['./warehousing.component.scss']
})
export class AWarehousingComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectSelectList$: BehaviorSubject<[]> = new BehaviorSubject([]);

  public warehouseList: any = [];
  public stockList: any = [];

  gridApi: any;
  gridColumnApi: any;

  gridApiWarehouse: any;
  gridColumnApiWarehouse: any;

  columnDefs: any;
  columnDefsWarehouse: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionWarehouse: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  search: any = {
    seq: '',
    warehouse_seq: '',
    date: '',
    projectSeq: '',
    status: ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aWarehouseService: AWarehouseService,
    public calendar: NgbCalendar,
    private toastrService: ToastrService,
    private aProjectService: AProjectService,
    private agGridExComponent: AgGridExComponent,
    private aStockService: AStockService
  ) {

    this.columnDefsWarehouse = [
      {headerName: '선택', field: 'seq', width: 50, hide: true },
      {headerName: '창고', field: 'name', width: 140, cellClass: 'cp' },
      {headerName: '타입', field: 'type', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return ( params.data.type == '임시창고' ) ? '<span class="badge badge-success">임시창고</span>' : '<span class="badge badge-primary">상시창고</span>';
        }
      },
      {headerName: '창고주소', field: 'address', width: 200, cellClass: 'cp' },
      {headerName: '개요', field: 'outline', width: 150, cellClass: 'cp' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "single";
    this.rowSelectionWarehouse = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 프로젝트 리스트 가져오기
    this.getProjectList();

    // 창고리스트 가져오기
    await this.getWareHouse();

    this.columnDefs = [
      { headerName: '', field: '', cellClass: 'cp center', width: 40,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '대분류', field: 'parent_category_name', width: 100, cellClass: 'cp center' },
      { headerName: '중분류', field: 'category_name', width: 100, cellClass: 'cp center' },
      { headerName: '바코드', field: 'barcode_yn', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if ( params.data.barcode_yn == '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
      { headerName: '상품코드', field: 'product_seq', hide: true },
      { headerName: '상품명', field: 'name', width: 160, cellClass: 'cp left'},
      { headerName: '출고수량', field: 'out_qty', width: 90, cellClass: 'cp right' },
      { headerName: '재고수량', field: 'total_stock', width: 90, cellClass: 'cp right' },
      { headerName: '이동수량', field: 'input_qty', width: 90, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if( params.data.barcode_yn == '1' ) {
            return 'cp right';
          } else {
            return 'cp right ag-cell-edit';
          }
        },
        editable: function(params) {
          if( params.data.barcode_yn == '1' ) {
            return false;
          } else {
            return true;
          }
        },
        valueGetter: function(params) {
          var str = String(params.data.input_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          if ( params.value > parseInt( params.data.total_stock ) ) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return {color: 'black', backgroundColor: '#fff'};
          }
        }
      },
      { headerName: '바코드 입력', field: 'barcode', width: 120, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'barcode',  // 바코드용 그리드버튼
          applyField: 'input_qty',  // 적용할 필드
          btnName: '바코드 입력'  // 버튼명
        }
      },
      { headerName: '이동일자', field: 'in_date', width: 120, cellClass: 'cp center ag-cell-edit', editable: true, cellEditor: "datePicker" },
      { headerName: '이동창고', field: 'warehouse_in_seq', width: 120, cellClass: 'cp center ag-cell-edit', editable: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.warehouseList, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {
          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.warehouse_in_seq ) selected = item;
          }

          return (selected == null ) ? '' : selected.name;
        },
      }
    ];
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기 - aggrid에 사용하기 위해서 async 걸어둠
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getWareHouse() {
    await this.aWarehouseService.getWarehouseList({typeSelect: '', searchText: ''}).then( response => {
      if( response.ResultCode ) {
        this.warehouseList = response.data;
      } else {
        this.warehouseList = [];
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
  }

  /*******************************************************************************
    설  명 : 출고 리스트 셀 변경 시 호출 - 출고창고가 변경될 경우 재고 수량 변경을 위함
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  async onCellValueChanged( $event ) {
    const field: any = $event.colDef.field;

    // 변경셀이 수량 또는 단가일 때
    if( field == 'barcode' ) {
      const barcode = $event.data.barcode;

      //let barcodeLines: any = barcode.replace(/\s/gi, "").split('\n');
      const barcodeLines: any = barcode.split('\n');

      let lineCount: number = 0;
      barcodeLines.forEach(line => {
        if( line != '' ) lineCount++;
      });

      this.stockList[ $event.node.rowIndex ].input_qty = lineCount;

      $event.node.setData( this.stockList[ $event.node.rowIndex ] );
    }
  }

  /*******************************************************************************
    설  명 : 셀 숫자 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }

    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }

    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }

    function NumericCellEditor() {}

    NumericCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function(event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };

    NumericCellEditor.prototype.getGui = function() {
      return this.eInput;
    };

    NumericCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };

    NumericCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };

    NumericCellEditor.prototype.isCancelAfterEnd = function() {};

    NumericCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };

    NumericCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };

    NumericCellEditor.prototype.focusOut = function() {
    };

    return NumericCellEditor;
  }

  /*******************************************************************************
    설  명 : 셀 날짜 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDatePicker() {
    function Datepicker() {}

    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement("input");
      this.eInput.value = params.value;
      this.eInput.style.width = '100%';

      (<any>$(this.eInput)).datepicker({
        dateFormat: 'yy-mm-dd',
        prevText: '이전 달',
        nextText: '다음 달',
        closeText: "닫기",
        currentText: "오늘",
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        dayNames: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        yearSuffix: '년',
        showOtherMonths: true, //빈 공간에 현재월의 앞뒤월의 날짜를 표시
        showMonthAfterYear:true, //년도 먼저 나오고, 뒤에 월 표시
        changeYear: true, //콤보박스에서 년 선택 가능
        changeMonth: true, //콤보박스에서 월 선택 가능
        showButtonPanel: true
      });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }

  /*******************************************************************************
    설  명 : 셀 선택 에디터, 기존의 agRichSelectCellEditor는 enterprise 버전에서만 지원
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSelectCellEditor() {
    function SelectCellEditor() {}

    SelectCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("select");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.options = params.values;

      let option = document.createElement("option");
      option.value = '';
      option.text = '선택';
      this.eInput.appendChild(option);

      for( let item of params.values ) {
        let option = document.createElement("option");
        option.value = item.seq;
        option.text = item.name;
        this.eInput.appendChild(option);
      }

      var that = this;

      this.eInput.addEventListener("keypress", function(event) {
        that.eInput.focus();
        if (event.preventDefault) event.preventDefault();
      });
    }

    SelectCellEditor.prototype.getGui = function() {
      return this.eInput;
    };

    SelectCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
      }
    };

    SelectCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };

    SelectCellEditor.prototype.isCancelAfterEnd = function() {};

    SelectCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };

    SelectCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
    };

    SelectCellEditor.prototype.focusOut = function() {

    };

    return SelectCellEditor;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyWarehouse(params) {
    this.gridApiWarehouse = params.api;
    this.gridColumnApiWarehouse = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedWarehouse($event) {
    this.search.seq = $event.data.seq;
    this.search.status = '';
    this.search.projectSeq = '';

    this.getProjectList();
    this.getWarehouseStockMove( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 창고별 재고 현황 가져오기
    입력값 : seq = 창고 코드
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseStockMove( seq: any ) {
    this.aWarehouseService.getWarehouseStockMove({seq: seq, projectSeq: this.search.projectSeq.id}).then( response => {
      if( response.ResultCode ) {
        this.stockList = response.data;
      } else {
        this.stockList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 입고창고 일괄선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouse() {
    if( this.search.seq == '' ) {
      this.toastrService.error("일괄 적용할 창고를 먼저 선택하세요.");
      return false;

    } else if( this.search.warehouse_seq == this.search.seq ) {
      this.toastrService.error("현재 선택된 창고와 동일한 창고를 선택하셨습니다.");
      return false;
    }

    this.gridApi.forEachNode(function(node, index) {
      if( node.selected === true ) {
        this.stockList[index].warehouse_in_seq = this.search.warehouse_seq;

        node.setData( this.stockList[index++] );
      }
    }.bind(this));

  }

  /*******************************************************************************
    설  명 : 철수지시서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOrderWithdrawPrint() {
    if( this.search.seq == '' ) {
      this.toastrService.error("창고를 선택하시기 바랍니다.");
    } else if( this.search.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
    } else {
      const url = '/process/warehousing/print?whSeq=' + this.search.seq + '&seq=' + this.search.projectSeq.id;

      window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1000, height=800, left=0, top=0' );
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker ) {
    this.search.date = this.calendar.getToday();
    obj.close();
  }

  /*******************************************************************************
    설  명 : 이동일자 일괄변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDate() {
    if( this.search.seq == '' ) {
      this.toastrService.error("일괄 적용할 창고를 먼저 선택하세요.");
      return false;

    } else if( this.search.date == '' ) {
      this.toastrService.error("일괄 적용할 이동일자를 선택하세요.");
      return false;
    }

    this.gridApi.forEachNode(function(node, index) {
      this.stockList[index].in_date = this.utilService.getDateStr(this.search.date);

      node.setData( this.stockList[index++] );
    }.bind(this));
  }

  /*******************************************************************************
    설  명 : 바코드 입력 모달창
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openModalBarcode() {
    let warehouseNodes = this.gridApiWarehouse.getSelectedNodes();
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( "이동할 상품을 선택하세요.", '바코드 입력');
      return false;
    } else if( nodes.length > 1 ) {
      this.toastrService.error( "상품을 하나만 선택하시기 바랍니다.", '바코드 입력');
      return false;
    } else {
      let warehouseRowNode = warehouseNodes[0];
      let rowNode = nodes[0];

      if( rowNode.data.barcode_yn != '1' ) {
        this.toastrService.error( "바코드 상품만 입력이 가능합니다.", '바코드 입력');
        return false;
      } else {
        const modalRef = this.modalService.open(ACBarcodeInputComponent, optionsLG);

        modalRef.componentInstance.product_seq = rowNode.data.product_seq;
        modalRef.componentInstance.barcode_yn = rowNode.data.barcode_yn;
        modalRef.componentInstance.barcode = rowNode.data.barcode;
        modalRef.componentInstance.warehouse_seq_out = warehouseRowNode.data.seq;

        modalRef.result.then((result) => {
          if( typeof result !== 'undefined' ) {

            //let barcodeLines: any = barcode.replace(/\s/gi, "").split('\n');
            const barcodeLines: any = result.barcode.split('\n');

            let lineCount: number = 0;
            barcodeLines.forEach(line => {
              if( line != '' ) lineCount++;
            });

            rowNode.data.input_qty = lineCount;
            rowNode.data.barcode = result.barcode;

            rowNode.setData( rowNode.data );
          }
        }, (reason) => {
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 창고 이동 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    let nodes: any = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm('선택된 창고의 모든 재고를 이동하시겠습니까?') ) {
        this.gridApi.selectAll();
      } else {
        this.toastrService.error('창고이동할 재고를 선택하시기 바랍니다.', '창고이동')
        return false;
      }
    } else {
      if( confirm('재고를 선택된 창고로 이동 처리 하시겠습니까?') ) {
      } else {
        return false;
      }
    }

    let data: any = [];
    let zeroCheck: boolean;
    let overCheck: boolean;
    let warehouseCheck: boolean;
    this.gridApi.forEachNode(function(node, index) {
      if( node.selected ) {
        if( parseInt(node.data.input_qty) == 0 ) zeroCheck = true;
        if( parseInt(node.data.input_qty) > parseInt(node.data.total_stock) ) overCheck = true;
        if( !node.data.warehouse_in_seq || node.data.warehouse_in_seq == '' ) warehouseCheck = true;

        data.push( node.data );
      }
    }.bind(this));

    if( zeroCheck === true ) {
      this.toastrService.error( '이동수량이 0 으로 입력된 상품이 있습니다.', '창고이동' );
    } else if( overCheck === true ) {
      this.toastrService.error( '이동수량이 재고수량보다 많게 입력된 상품이 있습니다.', '창고이동' );
    } else if( warehouseCheck === true ) {
      this.toastrService.error( '이동창고가 입력되지 않은 상품이 있습니다.', '창고이동' );
    } else {
      // 창고 이동 처리
      this.aStockService.stockWarehouseMove( this.search.seq, data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '창고이동' );

          // 재고 데이터 갱신
          this.getWarehouseStockMove( this.search.seq );

        } else {
          this.toastrService.error( response.ResultMessage, '창고이동' );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 프로젝트 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedProject( event: any ) {
    if( this.search.seq !== '' )
      this.getWarehouseStockMove( this.search.seq );
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectList();
  }

  /*******************************************************************************
    설  명 : 검색에 사용할 프로젝트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let params: any = {
      warehouseSeq: this.search.seq,
      type: '',
      status: this.search.status,
      sdate: this.search.sdate,
      edate: this.search.edate,
      searchGroup: '',
      searchText: ''
    };

    this.aProjectService.getWarehouseProjectList(params).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        tmp.push({
          id: '',
          text: '프로젝트 검색'
        });

        for( let item of response.data ) {
          tmp.push({
            id: item.seq,
            text: item.name
          });
        }
        this.projectSelectList$.next(tmp);
      }
    });
  }


}
