import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class APaymentService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 수입지출 리스트 가져오기
  getPaymentList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 결제 내역 리스트 가져오기
  getPaymentMemberList( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentMemberList'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수입지출 일별 리스트 가져오기
  getPaymentDayList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentDayList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  getPaymentDayDetailList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentDayDetailList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수입지출 상세정보 가져오기
  getPaymentDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수입지출항목 리스트 가져오기
  getAccountItemList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getAccountItemList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수입지출항목 저장
  setPaymentItem( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'setPaymentItem'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수입지출 저장
  setPayment( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'setPayment'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  delAccountItem( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'delAccountItem'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  delPayment( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'delPayment'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 결제내역관리 위젯 통계 가져오기
  getPaymentHistoryStatistic( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentHistoryStatistic'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 결제내역 리스트 가져오기
  getPaymentHistoryList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentHistoryList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 결제수단 가져오기
  getPayMethodList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPayMethodList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 일용직관리 리스트 가져오기
  getPartTimeMember( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPartTimeMember'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 일용직관리 저장
  setPartTimeMember( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'setPartTimeMember'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 일용직관리 삭제
  delPartTimeMember( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'delPartTimeMember'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 근무내역리스트 가져오기
  getPartTimeHistory( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPartTimeHistory'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 근무내역리스트 저장
  setPartTimeHistory( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'setPartTimeHistory'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 근무내역리스트 삭제
  delPartTimeHistory( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'delPartTimeHistory'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  
  // 일용직관리 엑셀 업로드
  setAccountExcel( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'payment',
      version: '1.0',
      action: 'setAccountExcel'
    }, {
      data: params
    }
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 근무내역 엑셀 업로드
  setPartTimeHistoryExcel( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'payment',
      version: '1.0',
      action: 'setPartTimeHistoryExcel'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 일용직 리스트 가져오기
  getParttimeMemberList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getParttimeMemberList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 일용직관리 엑셀 업로드
  setMemberExcel( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'payment',
      version: '1.0',
      action: 'setMemberExcel'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 결제내역 리스트 가져오기
  getPaymentMonthAccountList( year: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentMonthAccountList'
      }, {year: year}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 계정과목별 합계표 리스트 가져오기
  getPaymentYearAccountList( year: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'payment',
        version: '1.0',
        action: 'getPaymentYearAccountList'
      }, {year: year}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  
}
