import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbInputDatepicker, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { APaymentService } from '@admin/service/payment.service';
import { AProjectService } from '@admin/service/project.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

import { ParttimeAddComponent } from '@admin/page/payment/parttime/parttime-add/parttime-add.component';
import { ParttimeHistoryComponent } from '@admin/page/payment/parttime/parttime-history/parttime-history.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-parttime',
  templateUrl: './parttime.component.html',
  styleUrls: ['./parttime.component.scss']
})
export class ParttimeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  parttimeMember: any = [];
  parttimeHistory: any = [];

  arrayBuffer: any;
  filelist: any[];

  selectedRows: number = 0;
  dataList: any = [];
  dataList1: any = [];

  gridApi: any;
  gridApi1: any;
  gridColumnApi: any;
  gridColumnApi1:  any;
  columnDefs: any;
  columnDefs1: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  search: any = {
    searchText: ''
  };

  searchGroup: any = {
    seq: '',
    project_name: ''
  };

  seq: any = '0';

  private excelFileName: string;
  private title: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aPaymentService: APaymentService,
    private aCommonService: ACommonService,
    private aProjectService: AProjectService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public authService: AuthService,
    private calendar: NgbCalendar
  ) {
    this.columnDefs = [
      {headerName: '순번', field: 'seq', width: 70, cellClass: 'cp center' },
      {headerName: '성명', field: 'name', width: 200, cellClass: 'cp center' },
      {headerName: '전화번호', field: 'hp', width: 200, cellClass: 'cp center' },
      {headerName: '생년월일', field: 'birth_date', width: 200, cellClass: 'cp center' },
      {headerName: '계좌번호', field: 'account_no', width: 200, cellClass: 'cp center' },
      {headerName: '총근무일수', field: 'totalWorkCount', width: 100, cellClass: 'cp right' },
      {headerName: '총지급금액', field: 'totalWorkPay', cellClass: 'cp right', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.totalWorkPay ) {
            var str = String(params.data.totalWorkPay);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          } else {
            return '';
          }
        }
      },
      {headerName: '비고', field: 'memo', width: 350, cellClass: 'cp center' },
      {headerName: '수정', field: 'modify', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid mr5"><i class="material-icons menu-icon fl micon-create f12 ml3">&#xe150;</i></button>`
        }
      },
    ];

    this.columnDefs1 = [
      {headerName: '순번', field: 'seq', width: 70, cellClass: 'cp center' },
      {headerName: '근무일', field: 'work_date', width: 100, cellClass: 'cp center' },
      {headerName: '프로젝트', field: 'project_name', width: 400, cellClass: 'cp center' },
      {headerName: '근무시간', field: 'work_time', width: 100, cellClass: 'cp center' },
      {headerName: '근무수당', field: 'work_pay', cellClass: 'cp right', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.work_pay);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '초과근무시간', field: 'over_time', width: 150, cellClass: 'cp' },
      {headerName: '초과근무수당', field: 'overtime_pay', cellClass: 'cp right', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.overtime_pay);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '총지급수당', field: 'total_pay', cellClass: 'cp right', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_pay);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '비고', field: 'memo', width: 250, cellClass: 'cp' },
    ];    

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getPartTimeMember();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

    /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridHistoryReady(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.seq = $event.data.seq
    if ( $event.colDef.field !== 'modify' ) {
      this.getPartTimeHistory();
    } else if ( $event.colDef.field == 'modify' ) {
      this.parttimeAdd( $event.data );
    }
  
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellHistory($event) {
    this.parttimeHistoryAdd( $event.data );
  }

  /*******************************************************************************
    설  명 : 일용직관리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPartTimeMember() {
    let search: any = {
      searchText: this.search.searchText
    }

    this.aPaymentService.getPartTimeMember( search ).then( response => {
      if( response.ResultCode ) {
        this.parttimeMember = response.data;

      } else {
        this.parttimeMember = [];
      }
    });
  }

 

  /*******************************************************************************
    설  명 : 일용직관리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPartTimeHistory() {
    let search: any = {
      project_name: this.searchGroup.project_name,
      seq: this.seq
    }

    this.aPaymentService.getPartTimeHistory( search ).then( response => {
      if( response.ResultCode ) {
        this.parttimeHistory = response.data;

      } else {
        this.parttimeHistory = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  parttimeAdd( $event ) {
    const modalRef = this.modalService.open(ParttimeAddComponent, options);

    modalRef.componentInstance.selectedItem = $event;
    modalRef.componentInstance.seq = this.seq;

    modalRef.result.then((result) => {
      this.getPartTimeMember();
    }, (reason) => {
    });
  }
 
  /*******************************************************************************
    설  명 : 수입지출항목 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  parttimeHistoryAdd( $event ) {

    if( this.seq == '0' ) {
      this.toastrService.warning('일용직관리를 선택해 주세요.', '근무내역 등록');
    } else {
      const modalRef = this.modalService.open(ParttimeHistoryComponent, options);

      modalRef.componentInstance.selectedItem = $event;
      modalRef.componentInstance.seq = $event.seq;
      modalRef.componentInstance.memberSeq = this.seq;

      modalRef.result.then((result) => {
        this.getPartTimeHistory();
      }, (reason) => {
      });
    }
  }  


   /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      searchText: ''
    }

    this.getPartTimeMember();
  }

   /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  initSearchHistory() {
    this.searchGroup = {
      project_name: ''
    }

    this.getPartTimeHistory();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getPartTimeMember();
    }
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchHistoryList( event ) {
    if( event.key == 'Enter' ) {
      this.getPartTimeHistory();
    }
  }

  /*******************************************************************************
    설  명 : 파일 업로드 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event: any) {
    if(!confirm('엑셀 업로드를 하시겠습니까?')) return

    const files: any = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(files);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary", cellDates: true, dateNF: 'YYYY-MM-DD'});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});

      let params: any = [];
      this.filelist.map((obj, i) => {
        if( obj['이름'] !== undefined ) {
          params.push({
            name: obj['이름'] ? obj['이름'] : '',
            number: obj['주민번호'] ? obj['주민번호'] : '',
            work_date: obj['근무날짜'] ? moment(obj['근무날짜']).add(1, 'hour').format('YYYY-MM-DD') : '',
            work_stime: obj['출근'] ? moment(obj['출근']).add(33, 'minutes').format('HH:mm') : '',
            work_eTime: obj['퇴근'] ? moment(obj['퇴근']).add(33, 'minutes').format('HH:mm') : '',
            work_time: obj['근무시간'] ? moment(obj['근무시간']).add(33, 'minutes').format('HH:mm') : '',
            work_pay: obj['금액'] ? obj['금액'] : '',
            overtime_pay: obj[' 추가금액 '] ? obj[' 추가금액 '] : '',
            total_pay: obj[' 합계 '] ? obj[' 합계 '] : '',
            real_pay: obj[' 차인지급액 '] ? obj[' 차인지급액 '] : '',
            writer_name: obj['담당자'] ? obj['담당자'] : '',
            pay_date: obj['지급일'] ? moment(obj['지급일']).add(1, 'hour').format('YYYY-MM-DD') : '',
            project_name: obj['프로젝트'] ? obj['프로젝트'] : '',
            memo: obj['비고'] ? obj['비고'] : ''
          });
        }
      });

      if( params.length < 1 ) {
        this.toastrService.error( '업로드 데이터를 다시 확인하시기 바랍니다.', '');
      } else {
        this.aPaymentService.setPartTimeHistoryExcel( {params: params} ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '엑셀업로드');
            this.getPartTimeHistory();
            this.getPartTimeMember();
          } else {
            this.toastrService.error( '근무내역 엑셀업로드를 저장하는데 실패하였습니다.', '엑셀업로드');
          }
        });
      }
    }
  }

  excelExport() {
    let columnWidth = [];
    let dataList = [];

    if( this.parttimeMember.length < 1 ) {
      this.toastrService.error( '엑셀로 출력할 데이터가 없습니다.', '' );
      return false;
    } else {

      this.excelFileName = "일용직관리 리스트" + moment().format('YYYYMMDD') + EXCEL_EXTENSION;

      let title = [ [ this.title ], [] ];
      var worksheet: XLSX.WorkSheet;

      // 제목 추가
      worksheet = XLSX.utils.sheet_add_aoa( worksheet, title );
      // 색상변경 등은 프로 유료 버전에서만 지원
      //worksheet['A1'] = {v: this.title, t: "s", s: { alignment: 'center', font: {size: 14, bold: true, color: '#FF00FF' }}};

      /*
        s: { alignment: {textRotation: 90 },
        font: {sz: 14, bold: true, color: #FF00FF }
      */

      let headerList = [
        '순번',
        '성명',
        '전화번호',
        '생년월일',
        '계좌번호',
        '비고'
      ];

      this.parttimeMember.forEach(page => {
        dataList.push({
          '순번': page.seq,
          '성명': page.name,
          '전화번호': page.hp,
          '생년월일': page.birth_date,
          '계좌번호': page.account_no,
          '비고': page.memo
        });
      });

      // 데이터 추가
      worksheet = XLSX.utils.sheet_add_json( worksheet, dataList, {header: headerList, origin: "A1"} );

      // 워크북 생성
      var workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      // 엑셀 버퍼로 보내기
      var excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // 파일로 저장
      this.saveAsExcelFile(excelBuffer, this.excelFileName);

      return true;
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

    FileSaver.saveAs( data, fileName );
 }

 /*******************************************************************************
  설  명 : 파일 업로드 시 처리
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
  handleUploadFileMemberChanged(event: any) {

    if(!confirm('엑셀 업로드를 하시겠습니까?')) return

    const files: any = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(files);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);

      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary", cellDates: true, dateNF: 'YYYY-MM-DD'});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});

      let params: any = [];
      this.filelist.map((obj, i) => {
        if( (obj['순번'] == '' || obj['순번'] == undefined) && (obj['성명'] !== undefined || obj['성명'] !== '') ) {
          params.push({
            name: obj['성명'] ? obj['성명'] : '',
            hp: obj['전화번호'] ? obj['전화번호'] : '',
            birth_date: obj['생년월일'] ? moment(obj['생년월일']).add(1, 'hour').format('YYYY-MM-DD') : '',
            account_no: obj["계좌번호"] ? obj["계좌번호"] : '',
            memo: obj["비고"] ? obj["비고"] : ''
          });
        }
      });

      if( params.length < 1 ) {
        this.toastrService.error( '업로드 데이터를 다시 확인하시기 바랍니다.', '');

      } else {
        this.aPaymentService.setMemberExcel( {params: params} ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '엑셀업로드');
            this.getPartTimeMember();
          } else {
            this.toastrService.error( '일용직관리 엑셀업로드를 저장하는데 실패하였습니다.', '엑셀업로드');
          }
        });
      }
    }
  }
   
}
  