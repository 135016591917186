import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AProductService } from '@admin/service/product.service';

import { ToastrService } from 'ngx-toastr';
import { NgxBarcodeModule } from 'ngx-barcode';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class ABarcodeComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수
  *******************************************************************************/
  public params: any = {
    seq: ''
  };

  public productInfo: any = {};
  public barcodeValue: any;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private aProductService: AProductService,
  ) {
    this.setPrint();
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;

      this.barcodeValue = this.lpad(this.params.seq, 13, '0');

      this.getProductDetail();
    });
  }

  /*******************************************************************************
    설  명 : 상품 정보를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductDetail() {
    this.aProductService.getProductDetail( this.params.seq ).then( response => {
      if( response.ResultCode ) {
          this.productInfo = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '' );
    });
  }

  /*******************************************************************************
    설  명 : 바코드 13자리 만들기
  *******************************************************************************/
  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
      console.log('오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다');
      return str;
    }
    str += ''; // 문자로
    padStr += ''; // 문자로
    while (str.length < padLen) {
      str = padStr + str;
    }
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }

  /*******************************************************************************
    설  명 : 프린트 설정
    입력값 : landscape(가로 출력 여부)
    리턴값 : 없음
  *******************************************************************************/
  setPrint() {
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    const source = `
      @media print {

        app-barcode {
          display: block;
        }

        .btn-area {
          display:none;
        }

        @page {
          size:80mm 45mm;
          margin:0mm;
        }

        .html, .body { border:0; margin:0; padding:0; }
      }
    `;

    style.appendChild(document.createTextNode(source));

    head.appendChild(style);
  }

}
