/*******************************************************************************
  설  명 : 바코드 입력
  작성일 : 2019-12-07
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACBarcodeFindComponent } from '@admin/component/barcode-find/barcode-find.component';
import { AnimationStyleMetadata } from '@angular/animations';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-barcode-input',
  templateUrl: './barcode-input.component.html',
  styleUrls: ['./barcode-input.component.scss']
})
export class ACBarcodeInputComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public product_seq: number;
  public product_name: string = '';
  public barcode_yn: any;
  public barcode: any;
  public warehouse_seq_out: any;

  public formBarcode: FormGroup;
  public formErrors = {};

  public addProductBarcodeFunc: any;         // 상품바코드검색

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formBarcode = this.formBuilder.group({
      product_seq:["", [] ],
      product_name:["", [] ],
      barcode:["", [] ],
      barcode_yn:["", [] ],
      warehouse_seq_out:["", [] ],
    });

    this.formBarcode.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formBarcode, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public authService: AuthService
  ) {
    this.buildForm();

    this.addProductBarcodeFunc = this.searchProductBarcode.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    this.formBarcode.patchValue({
      product_seq: this.product_seq,
      product_name: this.product_name,
      barcode_yn: this.barcode_yn,
      barcode: this.barcode,
      warehouse_seq_out: this.warehouse_seq_out
    });
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    const barcode = this.formBarcode.controls.barcode.value;

    const barcodeArr: any = barcode.split('\n');
    const uniqueBarcodeArr = barcodeArr.filter((value, index, array) => array.indexOf(value) == index);

    if( barcodeArr.length != uniqueBarcodeArr.length ) {
      this.toastrService.warning('중복된 바코드가 있어서 자동으로 제외하였습니다.', '바코드 입력');
    }

    const barcodeStr: any = uniqueBarcodeArr.join('\n');

    this.formBarcode.patchValue({
      barcode: barcodeStr
    });

    this.activeModal.close( this.formBarcode.value );
  }

  /*******************************************************************************
    설  명 : 상품 바코드 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProductBarcode() {
    const modalRef = this.modalService.open(ACBarcodeFindComponent, options);
    
    modalRef.componentInstance.product_seq = this.formBarcode.controls.product_seq.value;
    modalRef.componentInstance.warehouse_seq_out = this.formBarcode.controls.warehouse_seq_out.value;

    modalRef.result.then((result) => {
      if( typeof result !== 'undefined' && result.length > 0 ) {
        let barcodeOld = this.formBarcode.controls.barcode.value;

        if( typeof barcodeOld === 'undefined' || barcodeOld === null ) {
          barcodeOld = '';
        }
        
        let barcodeNew: string = result.join('\n');

        this.formBarcode.patchValue({
          barcode: barcodeOld + barcodeNew + '\n'
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 엔터키 시 별표 제거 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  checkAsterisk( $event ) {
    if( $event.key == 'Enter' ) {
      const tmp = this.formBarcode.controls.barcode.value;
      const barcode = tmp.replace(/\*/g, '');

      this.formBarcode.patchValue({
        barcode: barcode
      });
    }
  }
  
}
