/*******************************************************************************
  설  명 : 기초/기타입고
  작성일 : 2019-12-07
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';
import { Globals } from '@app/service/globals.service';
import { AWarehouseService } from '@admin/service/warehouse.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { ACBarcodeFindComponent } from '@admin/component/barcode-find/barcode-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};


@Component({
  selector: 'app-stock-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class AStockImportComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public product_seq: number;
  public product_name: string = '';
  public barcode_yn: any;

  public title: string = '추가';

  public formImport: FormGroup;
  public formErrors = {};

  public inout_gbn: any = [];
  public warehouseList: any = [];

  public addProductFunc: any;         // 상품검색
  public addCustomerFunc: any;         // 거래처검색
  public addProductBarcodeFunc: any;         // 상품바코드검색

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formImport = this.formBuilder.group({
      seq:["", [] ],
      inout_gbn:["", [Validators.required] ],
      inout_code: ["", [Validators.required] ],
      warehouse_seq_in:["", [Validators.required] ],
      product_seq: ["", []],
      product_name:["", [Validators.required, Validators.maxLength(50)] ],
      inout_date:[date, [Validators.required] ],
      inout_qty:["", [Validators.required, Validators.maxLength(50)] ],
      customer_seq:["", [] ],
      customer_name:["", [Validators.maxLength(255)] ],
      unit_amt:["", [Validators.maxLength(255)] ],
      amt:["", [Validators.maxLength(255)] ],
      memo:["", [Validators.maxLength(255)] ],
      barcode:["", [] ],
      barcode_yn:["", [] ],
    });

    this.formImport.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formImport, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private globals: Globals,
    private aCommonService: ACommonService,
    private aStockService: AStockService,
    private aWarehouseService: AWarehouseService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public authService: AuthService
  ) {
    this.buildForm();

    this.addProductFunc = this.searchProduct.bind(this);
    this.addCustomerFunc = this.searchCustomer.bind(this);
    this.addProductBarcodeFunc = this.searchProductBarcode.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();

    this.getWareHouseList();
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.product_seq !== null ) {
      this.formImport.patchValue({
        product_seq: this.product_seq,
        product_name: this.product_name
      });
    }

    if( this.barcode_yn !== null ) {
      this.formImport.patchValue({
        barcode_yn: this.barcode_yn
      });
    }

    if( this.seq == 0 )
      this.title = '추가';
    else {
      this.title = '수정';

      this.getStockDetailInfo();
    }

    if( this.formImport.controls.barcode_yn.value == '1' ) {
      this.formImport.get('inout_qty').clearValidators();
      this.formImport.get('inout_qty').updateValueAndValidity();
      this.formImport.get('barcode').setValidators([Validators.required] );
      this.formImport.get('barcode').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formImport, this.formErrors);

    if(this.formImport.valid) {
      this.formImport.patchValue({
        inout_date: this.ngbDateParserFormatter.format( this.formImport.controls.inout_date.value )
      });

      this.aStockService.setStock(this.formImport).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 - 입출고 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.inout_gbn = [];

    // 입출고 구분
    this.aCommonService.getCommonListCode('IOG').then( response => {
      if( response.ResultCode ) {
        for( let item of response.data ) {
          // 기초 / 기타 입고 만 표시
          if( item.common_code == '1000' || item.common_code == '1040' )
            this.inout_gbn.push( item );
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWareHouseList() {
    this.warehouseList = [];

    this.aWarehouseService.getWarehouseList({
      typeSelect: '',
      searchText: ''
    }).then( response => {
      if( response.ResultCode ) {
        for(let item of response.data ) {
          let data = {
            title: item.name,
            value: item.seq
          };
          this.warehouseList.push( data );
        }
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }

  /*******************************************************************************
    설  명 : 상품 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProduct() {
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.formImport.patchValue({
        product_seq: result.seq,
        product_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.formImport.patchValue({
        customer_seq: result.seq,
        customer_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상품 바코드 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    searchProductBarcode() {
    const modalRef = this.modalService.open(ACBarcodeFindComponent, options);
    
    modalRef.componentInstance.product_seq = this.formImport.controls.product_seq.value;
    modalRef.componentInstance.warehouse_seq_out = 'NULL';

    modalRef.result.then((result) => {
      if( typeof result !== 'undefined' && result.length > 0 ) {
        let barcodeOld = this.formImport.controls.barcode.value;
        let barcodeNew: string = result.join('\n');

        this.formImport.patchValue({
          barcode: barcodeOld + barcodeNew
        });
      }
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 입고 세부 내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStockDetailInfo() {
    this.aStockService.getStockDetailInfo( this.seq ).then( response => {
      if( response.ResultCode ) {
        let date: NgbDateStruct = {
          year: moment(response.data.inout_date).year(),
          month: moment(response.data.inout_date).month() + 1,
          day: moment(response.data.inout_date).date(),
        };

        this.formImport.patchValue({
          seq: response.data.seq,
          inout_gbn: response.data.inout_gbn,
          inout_code: response.data.inout_code,
          warehouse_seq_in: response.data.warehouse_seq_in,
          product_seq: response.data.product_seq,
          product_name: response.data.product_name,
          inout_date: date,
          inout_qty: response.data.inout_qty,
          customer_seq: response.data.customer_seq,
          customer_name: response.data.customer_name,
          unit_amt: response.data.unit_amt,
          amt: response.data.amt,
          memo: response.data.memo,
          barcode: response.data.barcode
        });
      } else {
        this.toastrService.error( response.ResultMessage, '입고내역');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( error, '입고내역');

      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 입고 세부 내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteStock() {
    if( confirm("해당 입고내역을 삭제하시겠습니까?") ) {
      this.aStockService.deleteStock(this.seq).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '입고삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '입고삭제');
        }

        this.activeModal.close();
      }, error => {
        this.toastrService.error( error, '입고삭제');

        this.activeModal.close();
      });
    }
  }

  /*******************************************************************************
    설  명 : 엔터키 시 별표 제거 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  checkAsterisk( $event ) {
    if( $event.key == 'Enter' ) {
      const tmp = this.formImport.controls.barcode.value;
      const barcode = tmp.replace(/\*/g, '');

      this.formImport.patchValue({
        barcode: barcode
      });
    }
  }

}
