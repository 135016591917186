/*******************************************************************************
  설  명 : 세금계산서 리스트
  작성일 : 2020-02-19
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { ATaxService } from '@admin/service/tax.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { InvoiceResendComponent } from '@admin/page/order/tax/invoice-resend/invoice-resend.component';
import { ATaxViewComponent } from '@admin/page/tax/tax-view/tax-view.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class ATaxComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  statistic: any = {
    total: 0,
    thisYear: 0,
    thisMonth: 0,
    unPublish: 0
  };

  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  taxList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    searchText: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    dateType: '',
    term: '',
    status: '',
    taxInvoiceType: '',
    taxType: '',
    purposeType: '',
    taxCalcType: ''
  };

  dateModel: NgbDateStruct;

  selectedRow: any = {
    seq: '',
    order_seq: '',
    customer_seq: '',
    taxInvoiceType: '',
    taxType: '',
    taxCalcType: '',
    purposeType: '',
    writeDate: '',
    amountTotal: '',
    taxTotal: '',
    totalAmount: '',
    mgtNum: '',
    corpNum: '',
    corpName: '',
    ceoName: '',
    addr: '',
    bizType: '',
    bizClass: '',
    contactID: '',
    contactName: '',
    tel: '',
    hp: '',
    email: '',
    reCorpNum: '',
    reCorpName: '',
    reCeoName: '',
    reAddr: '',
    reBizType: '',
    reBizClass: '',
    reContactName: '',
    reTel: '',
    reHp: '',
    reEmail: '',
    reEmail2: '',
    remark1: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private aTaxService: ATaxService,
    private calendar: NgbCalendar,
  ) {
    this.columnDefs = [
      {
        headerName: '번호',
        field: 'seq',
        width: 70,
        pinned: 'left',
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      { headerName: '발행결과', field: 'status', width: 70, cellClass: 'cp center', pinned: 'left', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1' ) {
            return '<span class="badge badge-success f12 fn">성공</span>';
          } else {
            return '<span class="badge badge-danger f12 fn">실패</span>';
          }
        },
        tooltipValueGetter: toolTipValueGetter
      },
      { headerName: '결과메세지', field: 'result', width: 90, cellClass: 'cp', pinned: 'left' },
      { headerName: '계산서정보',
        children: [
          { headerName: '구분', field: 'taxInvoiceType', width: 85, cellClass: 'cp center',
            valueGetter: function(params) {
              if( params.data.taxInvoiceType == '1' ) {
                return '세금계산서';
              } else if( params.data.taxInvoiceType == '2' ) {
                return '계산서';
              } else if( params.data.taxInvoiceType == '4' ) {
                return '위수탁세금계산서';
              } else if( params.data.taxInvoiceType == '5' ) {
                return '위수탁계산서';
              } else {
                return null;
              }
            },
          },
          { headerName: '과세구분', field: 'taxType', width: 70, cellClass: 'cp center',
            valueGetter: function(params) {
              if( params.data.taxType == '1' ) {
                return '과세';
              } else if( params.data.taxType == '2' ) {
                return '영세';
              } else if( params.data.taxType == '3' ) {
                return '면세(계산서)';
              } else {
                return null;
              }
            },
            cellStyle: function(params) {
              if (params.data.taxType == '1' ) {
                return {color: 'red', backgroundColor: '#ffdede'};
              } else if (params.data.taxType == '2' ) {
                return {color: 'blue', backgroundColor: '#dee7ff'};
              } else if (params.data.taxType == '3' ) {
                return {color: 'blue', backgroundColor: '#dee7ff'};
              } else {
                return null;
              }
            }
          },
          { headerName: '세율계산', field: 'taxCalcType', width: 70, cellClass: 'cp center',
            valueGetter: function(params) {
              if( params.data.taxCalcType == '1' ) {
                return '절상';
              } else if( params.data.taxCalcType == '2' ) {
                return '절사';
              } else if( params.data.taxCalcType == '3' ) {
                return '반올림';
              } else {
                return null;
              }
            },
            cellStyle: function(params) {
              if (params.data.taxCalcType == '1' ) {
                return {color: 'red', backgroundColor: '#ffdede'};
              } else if (params.data.taxCalcType == '2' ) {
                return {color: 'blue', backgroundColor: '#dee7ff'};
              } else if (params.data.taxCalcType == '3' ) {
                return {color: 'orange', backgroundColor: '#fffce5'};
              } else {
                return null;
              }
            }
          },
          { headerName: '청구/영수', field: 'purposeType', width: 80, cellClass: 'cp center',
            valueGetter: function(params) {
              if( params.data.purposeType == '1' ) {
                return '영수';
              } else if( params.data.purposeType == '2' ) {
                return '청구';
              } else {
                return null;
              }
            },
            cellStyle: function(params) {
              if (params.data.purposeType == '1' ) {
                return {color: 'blue', backgroundColor: '#dee7ff'};
              } else if (params.data.purposeType == '2' ) {
                return {color: 'red', backgroundColor: '#ffdede'};
              } else {
                return null;
              }
            }
          },
          { headerName: '청구일자', field: 'writeDate', width: 80, cellClass: 'cp center' },
          { headerName: '공급가액', field: 'amountTotal', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
          { headerName: '세액합계', field: 'taxTotal', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
          { headerName: '합계금액', field: 'totalAmount', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
        ]
      },
      {headerName: '공급자정보',
        children: [
          { headerName: '관리번호', field: 'mgtNum', width: 140, cellClass: 'cp' },
          { headerName: '사업자번호', field: 'corpNum', width: 100, cellClass: 'cp' },
          { headerName: '회사명', field: 'corpName', width: 90, cellClass: 'cp' },
          { headerName: '대표명', field: 'ceoName', width: 75, cellClass: 'cp' },
          { headerName: '사업장주소', field: 'addr', width: 100, cellClass: 'cp' },
          { headerName: '업태', field: 'bizType', width: 80, cellClass: 'cp' },
          { headerName: '업종', field: 'bizClass', width: 110, cellClass: 'cp' },
          { headerName: '담당자명', field: 'contactName', width: 90, cellClass: 'cp' },
          { headerName: '전화번호', field: 'tel', width: 100, cellClass: 'cp' },
          { headerName: '휴대폰', field: 'hp', width: 100, cellClass: 'cp' },
          { headerName: '이메일', field: 'email', width: 150, cellClass: 'cp' },
        ]
      },
      {headerName: '공급받는자정보',
        children: [
          { headerName: '사업자번호', field: 'reCorpNum', width: 100, cellClass: 'cp' },
          { headerName: '회사명', field: 'reCorpName', width: 110, cellClass: 'cp' },
          { headerName: '대표명', field: 'reCeoName', width: 75, cellClass: 'cp' },
          { headerName: '사업장주소', field: 'reAddr', width: 100, cellClass: 'cp' },
          { headerName: '업태', field: 'reBizType', width: 100, cellClass: 'cp' },
          { headerName: '업종', field: 'reBizClass', width: 110, cellClass: 'cp' },
          { headerName: '담당자명', field: 'reContactName', width: 90, cellClass: 'cp' },
          { headerName: '전화번호', field: 'reTel', width: 100, cellClass: 'cp' },
          { headerName: '휴대폰', field: 'reHp', width: 100, cellClass: 'cp' },
          { headerName: '이메일', field: 'reEmail', width: 150, cellClass: 'cp' },
        ]
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function toolTipValueGetter(params) {
      if( params.data.status != '1' ) {
        return params.data.result;
      }
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();
    this.getTaxStatistic();
    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    // 현재 선택된 행 데이터 저장
    this.selectedRow = event.data;
  }

  /*******************************************************************************
    설  명 : 세금계산서 위젯 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTaxStatistic() {
    this.aTaxService.getTaxStatistic().then( response => {
      if( response.ResultCode ) {
        this.statistic = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '세금계산서' );
    });
  }

  /*******************************************************************************
    설  명 : 세금계산서 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTaxList() {
    this.selectedRowInit();

    this.aTaxService.getTaxList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.taxList = response.data;

        this.selectedRow = this.taxList[0];
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '세금계산서' );
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDateStr( this.calendar.getToday() );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDateStr( this.calendar.getToday() );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.sdate = this.dateModel;
    this.search.edate = this.calendar.getToday();
  }

  /*******************************************************************************
    설  명 : 엔터키 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( $event ) {
    if( $event.key == 'Enter' ) {
      this.getTaxList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      dateType: '',
      term: '',
      status: '',
      taxInvoiceType: '',
      taxType: '',
      purposeType: '',
      taxCalcType: ''
    };

    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 그리드 선택 행 정보 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedRowInit() {
    this.selectedRow = {
      seq: '',
      order_seq: '',
      customer_seq: '',
      taxInvoiceType: '',
      taxType: '',
      taxCalcType: '',
      purposeType: '',
      writeDate: '',
      amountTotal: '',
      taxTotal: '',
      totalAmount: '',
      mgtNum: '',
      corpNum: '',
      corpName: '',
      ceoName: '',
      addr: '',
      bizType: '',
      bizClass: '',
      contactID: '',
      contactName: '',
      tel: '',
      hp: '',
      email: '',
      reCorpNum: '',
      reCorpName: '',
      reCeoName: '',
      reAddr: '',
      reBizType: '',
      reBizClass: '',
      reContactName: '',
      reTel: '',
      reHp: '',
      reEmail: '',
      reEmail2: '',
      remark1: ''
    };
  }

  /*******************************************************************************
    설  명 : 발행결과 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setStatus( value: any ) {
    this.search.status = value;

    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 구분 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTaxInvoiceType( value: any ) {
    this.search.taxInvoiceType = value;

    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 과세구분 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTaxType( value: any ) {
    this.search.taxType = value;

    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 세율계산 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPurposeType( value: any ) {
    this.search.purposeType = value;

    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 영수/청구 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTaxCalcType( value: any ) {
    this.search.taxCalcType = value;

    this.getTaxList();
  }

  /*******************************************************************************
    설  명 : 계산서 재발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openInvoiceResend() {
    const nodes: any = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( '계산서를 선택하시기 바랍니다.', '계산서 재발송' );
      return false;
    } else {
      const modalRef = this.modalService.open(InvoiceResendComponent, options);
      
      modalRef.componentInstance.taxSeq = nodes[0].seq;

      modalRef.result.then((result) => {
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 미리보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPreviewInovice() {
    const nodes: any = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( '계산서를 선택하시기 바랍니다.', '계산서 재발송' );
      return false;
    } else {
      const modalRef = this.modalService.open(ATaxViewComponent, optionsLG);
  
      let tmp = {
        year: this.selectedRow.write_date.substr(0,4),
        month: this.selectedRow.write_date.substr(5,2),
        day: this.selectedRow.write_date.substr(8,2)
      }

      this.selectedRow.writeDate = tmp;

      modalRef.componentInstance.formData = this.selectedRow;
  
      modalRef.result.then((result) => {
  
      }, (reason) => {
      });
    }
  }
  
}
