import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ARefundService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 환불 정보 가져오기
  getRefundList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'getRefundList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 취소/환불 상세정보 가져오기
  getRefundDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'getRefundDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 환불 처리
  setRefundPayment( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'setRefundPayment'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 취소/환불 삭제
  setRefundDelete( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'setRefundDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
