/*******************************************************************************
  설  명 : 창고이동
  작성일 : 2019-12-12
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';
import { Globals } from '@app/service/globals.service';
import { AWarehouseService } from '@admin/service/warehouse.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACBarcodeFindComponent } from '@admin/component/barcode-find/barcode-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-stock-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss']
})
export class AStockMoveComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public product_seq: number;
  public product_name: string = '';
  public barcode_yn: any;

  private warehouseList: any = [];
  public in_warehouse: any = []; // 입고창고
  public out_warehouse: any = []; // 출고창고

  public title: string = '추가';

  public formMove: FormGroup;
  public formErrors = {};

  public addProductFunc: any;         // 상품검색
  public changeOutWarehouseFunc: any;     // 출고창고 변경시 실행 함수
  public addProductBarcodeFunc: any;         // 상품바코드검색

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formMove = this.formBuilder.group({
      seq:["", [] ],
      group_seq:["", [] ],
      warehouse_seq_out:["", [Validators.required] ],
      warehouse_seq_in:["", [Validators.required] ],
      product_seq:["", [Validators.required] ],
      product_name:["", [Validators.required] ],
      inout_date:[date, [Validators.required] ],
      max_qty:["", [] ],
      input_qty:["", [Validators.required] ],
      memo:["", [] ],
      barcode:["", [] ],
      barcode_yn:["", [] ],
    });

    this.formMove.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formMove, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private globals: Globals,
    private aCommonService: ACommonService,
    private aStockService: AStockService,
    private aWarehouseService: AWarehouseService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public authService: AuthService
  ) {
    this.buildForm();

    this.addProductFunc = this.searchProduct.bind(this);
    this.changeOutWarehouseFunc = this.getWarehouseProductStock.bind(this);
    this.addProductBarcodeFunc = this.searchProductBarcode.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getWareHouseList();
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.product_seq !== null ) {
      this.formMove.patchValue({
        product_seq: this.product_seq,
        product_name: this.product_name
      });

      // 상품 선택 후 입고창고, 출고창고 정보를 표시
      this.in_warehouse = this.warehouseList;
      this.out_warehouse = this.warehouseList;
    }

    if( this.barcode_yn !== null ) {
      this.formMove.patchValue({
        barcode_yn: this.barcode_yn
      });
    }

    if( this.seq == 0 )
      this.title = '추가';
    else {
      this.title = '수정';

      this.getMoveStockDetail();
    }

    if( this.formMove.controls.barcode_yn.value == '1' ) {
      this.formMove.get('input_qty').clearValidators();
      this.formMove.get('input_qty').updateValueAndValidity();
      this.formMove.get('barcode').setValidators([Validators.required] );
      this.formMove.get('barcode').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    // 바코드 상품
    if( this.formMove.controls.barcode_yn.value == '1' ) {

      let barcode = this.formMove.controls.barcode.value;
      let barcodeLines: any = barcode.split('\n');

      let lineCount: number = 0;
      barcodeLines.forEach(line => {
        if( line != '' ) lineCount++;
      });

      if( parseInt(this.formMove.controls.max_qty.value) < lineCount ) {
        this.toastrService.error('이동 가능 수량을 초과하여 입력하였습니다.', '창고이동');
        return false;
      }

    } else {
      if( parseInt(this.formMove.controls.max_qty.value) < parseInt(this.formMove.controls.input_qty.value) ) {
        this.toastrService.error('이동 가능 수량을 초과하여 입력하였습니다.', '창고이동');
        return false;
      }
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.formMove, this.formErrors);

    if(this.formMove.valid) {
      this.formMove.patchValue({
        inout_date: this.ngbDateParserFormatter.format( this.formMove.controls.inout_date.value )
      });

      this.aStockService.moveStock(this.formMove).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '창고이동');
    }
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWareHouseList() {
    this.warehouseList = [];

    this.aWarehouseService.getWarehouseList({
      typeSelect: '',
      searchText: ''
    }).then( response => {
      if( response.ResultCode ) {
        for(let item of response.data ) {
          let data = {
            title: item.name,
            value: item.seq
          };
          this.warehouseList.push( data );
        }
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }

  /*******************************************************************************
    설  명 : 상품 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProduct() {
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.formMove.patchValue({
        product_seq: result.product_seq,
        product_name: result.name
      });

      // 상품 선택 후 입고창고, 출고창고 정보를 표시
      this.in_warehouse = this.warehouseList;
      this.out_warehouse = this.warehouseList;

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 출고 창고에 상품 재고 잔여 개수를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseProductStock() {
    this.aWarehouseService.getWarehouseProductStock(
      this.formMove.controls.warehouse_seq_out.value,
      this.formMove.controls.product_seq.value ).then( response => {

      if( response.ResultCode ) {
        this.formMove.patchValue({max_qty: response.data});
      } else {
        this.formMove.patchValue({max_qty: 0});
      }
    }, error => {
      this.toastrService.error( error, '재고 정보');
    });
  }

  /*******************************************************************************
    설  명 : 상품 바코드 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProductBarcode() {
    if( !this.formMove.controls.warehouse_seq_out.value || this.formMove.controls.warehouse_seq_out.value == '' ) {
      this.toastrService.error( '출고창고를 선택하시기 바랍니다.', '바코드 검색' );
      return false;
    } else {
      const modalRef = this.modalService.open(ACBarcodeFindComponent, options);
      
      modalRef.componentInstance.product_seq = this.formMove.controls.product_seq.value;
      modalRef.componentInstance.warehouse_seq_out = this.formMove.controls.warehouse_seq_out.value;

      modalRef.result.then((result) => {
        if( typeof result !== 'undefined' && result.length > 0 ) {
          let barcodeOld = this.formMove.controls.barcode.value;
          let barcodeNew: string = result.join('\n');

          this.formMove.patchValue({
            barcode: barcodeOld + barcodeNew
          });
        }
      }, (reason) => {
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 이동출고/입고 수정 시 데이터를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMoveStockDetail() {
    this.aStockService.getMoveStockDetail( this.seq ).then( response => {
      if( response.ResultCode ) {
        let date: NgbDateStruct = {
          year: moment(response.data.inout_date).year(),
          month: moment(response.data.inout_date).month() + 1,
          day: moment(response.data.inout_date).date()
        };

        this.formMove.patchValue({
          seq: response.data.seq,
          group_seq: response.data.group_seq,
          warehouse_seq_out: response.data.warehouse_seq_out,
          warehouse_seq_in: response.data.warehouse_seq_in,
          product_seq: response.data.product_seq,
          product_name: response.data.product_name,
          inout_date: date,
          max_qty: parseInt(response.data.max_qty) + parseInt(response.data.input_qty),
          input_qty: response.data.input_qty,
          memo: response.data.memo,
        });

      } else {
        this.toastrService.error( response.ResultMessage, '재고 정보');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( error, '재고 정보');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 창고이동 내역을 삭제한다
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteStock() {
    if( confirm("창고이동 내역을 삭제하시겠습니까?") ) {
      this.aStockService.deleteStock( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '창고이동 삭제');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '창고이동 삭제');
        }
      }, error => {
        this.toastrService.error( error, '창고이동 삭제');

        this.activeModal.close();
      });
    }
  }

  /*******************************************************************************
    설  명 : 엔터키 시 별표 제거 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  checkAsterisk( $event ) {
    if( $event.key == 'Enter' ) {
      const tmp = this.formMove.controls.barcode.value;
      const barcode = tmp.replace(/\*/g, '');

      this.formMove.patchValue({
        barcode: barcode
      });
    }
  }

}
