import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AProductService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 상품 리스트 가져오기
  getProductList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 정보 저장하기
  setProduct( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProduct'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 정보 삭제하기
  deleteProduct( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteProduct'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 리스트 가져오기
  getProductDetail( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 첨부파일 삭제
  deleteUploadFile( upload_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteUploadFile'
      }, {
        upload_seq: upload_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 컴포넌트 카테고리별 상품 리스트 가져오기
  getCategoryProductList( params: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getCategoryProductList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 컴포넌트 카테고리별 상품 리스트 가져오기
  getCategoryProductDiscountList( mem_no: any, project_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getCategoryProductDiscountList'
      }, {
        mem_no: mem_no,
        project_seq: project_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세트 상품 삭제
  deleteSetProduct( list: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteSetProduct'
      }, {
        list: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 부속 상품 삭제
  deletePartProduct( list: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deletePartProduct'
      }, {
        list: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 통계 가져오기
  getStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getStatistic'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 재고수량 가져오기
  getProductStock( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductStock'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 바코드상품 발행가능수량 가져오기
  getBarcodeProductStock( productSeq: any, warehouseSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getBarcodeProductStock'
      }, {
        product_seq: productSeq,
        warehouse_seq: warehouseSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 바코드 발행 리스트 가져오기
  getProductBarcodeList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductBarcodeList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세트상품에 포함된 상품 리스트 가져오기
  getSetProductList( seq: any ) : Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getSetProductList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 조립상품 서브 상품 가져오기
  getProductAssembleList( seq: any ) : Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductAssembleList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 순서저장
  setSortOrder( seqArray: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setSortOrder'
      }, {
        list: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 추천 상품 등록/해제
  setRecommend( check: any, seqArray: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setRecommend'
      }, {
        check: check,
        list: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  getProductAttachList( outWarehouse: any, data: any ) : Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductAttachList'
      }, {
        outWarehouse: outWarehouse,
        data: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


}
