import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { StatisticService } from '@admin/service/statistic.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';

import * as moment from 'moment';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectTypeList: any = [];
  public projectYearList: any = [];

  // 검색 조건
  public search: any = {
    year: moment().format('YYYY'),
    searchText: '',
    gbn: ''
  };

  // 매출 내역
  public purchaseList: any = [];

  // 공통코드 그룹 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  // 차트 관련 변수
  public barChartData: any = null;
  public chartType = 'bar';
  public chartLabel: string[] = [];

  // 차트 옵션
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private calendar: NgbCalendar,
    private statisticService: StatisticService,
    public authService: AuthService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: 'year', width: 120, cellClass: 'cp center'},
      { headerName: '1월', field: 'january', width: 120, cellClass: 'cp right',   valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '2월', field: 'february', width: 120, cellClass: 'cp right',  valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '3월', field: 'march', width: 120, cellClass: 'cp right',     valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '4월', field: 'april', width: 120, cellClass: 'cp right',     valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '5월', field: 'may', width: 120, cellClass: 'cp right',       valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '6월', field: 'june', width: 120, cellClass: 'cp right',      valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '7월', field: 'july', width: 120, cellClass: 'cp right',      valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '8월', field: 'august', width: 120, cellClass: 'cp right',    valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '9월', field: 'september', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '10월', field: 'october', width: 120, cellClass: 'cp right',  valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '11월', field: 'november', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
      { headerName: '12월', field: 'december', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, valueGetter: this.agGridExComponent.numberGetter },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getProjectPurchaseList();
  }

  /*******************************************************************************
     설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
     설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async onRowClicked($event) {
  }

  /*******************************************************************************
     설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      sdate: '',
      edate: '',
      projectName: '',
    };

    this.getProjectPurchaseList();
  }

  /*******************************************************************************
     설  명 : 엔터 입력 시 검색
    입력값 : $evnet
    리턴값 : 없음
  *******************************************************************************/
  setSearchInput( $event ) {
    if ( $event.key == 'Enter' ) {
      this.getProjectPurchaseList();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.calendar.getToday();
    } else {
      this.search.edate = this.calendar.getToday();
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 금일 기준으로 일자 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDays(month: number) {
    const todaysDate = moment();
    let toDate;
    if (month === 0) {
      toDate = moment().startOf('month');
    } else {
      toDate = moment().add(month * -1, 'months');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 차트 데이터 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  chartFilled() {
    let chartData: any = {
      data: [],
      label : this.purchaseList[0].year + '년'
    };

    let prevChartData: any = {
      data: [],
      label : this.purchaseList[1].year + '년'
    };

    this.chartLabel = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

    chartData.data.push( this.purchaseList[0].january );
    chartData.data.push( this.purchaseList[0].february );
    chartData.data.push( this.purchaseList[0].march );
    chartData.data.push( this.purchaseList[0].april );
    chartData.data.push( this.purchaseList[0].may );
    chartData.data.push( this.purchaseList[0].june );
    chartData.data.push( this.purchaseList[0].july );
    chartData.data.push( this.purchaseList[0].august );
    chartData.data.push( this.purchaseList[0].september );
    chartData.data.push( this.purchaseList[0].october );
    chartData.data.push( this.purchaseList[0].november );
    chartData.data.push( this.purchaseList[0].december );

    prevChartData.data.push( this.purchaseList[1].january );
    prevChartData.data.push( this.purchaseList[1].february );
    prevChartData.data.push( this.purchaseList[1].march );
    prevChartData.data.push( this.purchaseList[1].april );
    prevChartData.data.push( this.purchaseList[1].may );
    prevChartData.data.push( this.purchaseList[1].june );
    prevChartData.data.push( this.purchaseList[1].july );
    prevChartData.data.push( this.purchaseList[1].august );
    prevChartData.data.push( this.purchaseList[1].september );
    prevChartData.data.push( this.purchaseList[1].october );
    prevChartData.data.push( this.purchaseList[1].november );
    prevChartData.data.push( this.purchaseList[1].december );

    this.barChartData = [prevChartData, chartData];
  }

  /*******************************************************************************
    설  명 : 매입현황 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectPurchaseList() {
    this.statisticService.getProjectPurchaseList( this.search ).then( response => {
      if( response.ResultCode ) {
        let tmp: any = response.data;

        tmp.push({
          year: '전년대비(%)',
          january: ( response.data[1].january > 0 ) ? ( response.data[0].january - response.data[1].january ) / response.data[1].january * 100 : 0,
          february: ( response.data[1].february > 0 ) ? ( response.data[0].february - response.data[1].february ) / response.data[1].february * 100 : 0,
          march: ( response.data[1].march > 0 ) ? ( response.data[0].march - response.data[1].march ) / response.data[1].march * 100 : 0,
          april: ( response.data[1].april > 0 ) ? ( response.data[0].april - response.data[1].april ) / response.data[1].april * 100 : 0,
          may: ( response.data[1].may > 0 ) ? ( response.data[0].may - response.data[1].may ) / response.data[1].may * 100 : 0,
          june: ( response.data[1].june > 0 ) ? ( response.data[0].june - response.data[1].june ) / response.data[1].june * 100 : 0,
          july: ( response.data[1].july > 0 ) ? ( response.data[0].july - response.data[1].july ) / response.data[1].july * 100 : 0,
          august: ( response.data[1].august > 0 ) ? ( response.data[0].august - response.data[1].august ) / response.data[1].august * 100 : 0,
          september: ( response.data[1].september > 0 ) ? ( response.data[0].september - response.data[1].september ) / response.data[1].september * 100 : 0,
          october: ( response.data[1].october > 0 ) ? ( response.data[0].october - response.data[1].october ) / response.data[1].october * 100 : 0,
          november: ( response.data[1].november > 0 ) ? ( response.data[0].november - response.data[1].november ) / response.data[1].november * 100 : 0,
          december: ( response.data[1].december > 0 ) ? ( response.data[0].december - response.data[1].december ) / response.data[1].december * 100 : 0
        });

        this.purchaseList = tmp;

        this.chartFilled();
      } else {
        this.purchaseList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 이전 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  prevYear() {
    this.search.year = moment( this.search.year + '-01-01' ).add(-1, 'year').format('YYYY');

    this.getProjectPurchaseList();
  }

  /*******************************************************************************
    설  명 : 다음 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nextYear() {
    if( moment().format('YYYY') !== this.search.year ) {
      this.search.year = moment( this.search.year + '-01-01' ).add(1, 'year').format('YYYY');

      this.getProjectPurchaseList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectPurchaseList();
  }
}
