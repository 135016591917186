/*******************************************************************************
  설  명 : 상품추가
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { AceComponent, AceDirective, AceConfigInterface } from 'ngx-ace-wrapper';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { RestfulService } from '@app/service/restful.service';
import { ACommonService } from '@admin/service/common.service';
import { config } from '@app/service/config.service';
import { ACategoryService } from '@admin/service/category.service';
import { AProductService } from '@admin/service/product.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACCartComponent } from '@admin/component/cart/cart.component';
import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ABarcodeHistoryComponent } from '@admin/page/product/add/barcode-history/barcode-history.component';
import { AStockBarcodeComponent } from '@admin/page/stock/barcode/barcode.component';

const URL = config.apiFileUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AProductAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: string;
  public title: string = '추가';

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public changeFunc: any;
  public changedSubFunc: any;

  public form: FormGroup;
  public formErrors: any = {
    seq: '',
    content: ''
  };

  daumAddressOptions =  {
    class: ['btn', '']
  };

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public gbnList: any = [];
  public rentGbnList: any = [];
  public typeList: any = [];
  public unitList: any = [];
  public categoryList: any = [];
  public stockList: any = [];
  public barcodeList: any = [];

  public stockTotalCount: number = 0;

  public options = {
    multiple: true
  };

  public baseURL = config.baseUrl;

  public params: any = [];

  public summernoteConfig: any = config.summernoteConfig;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;

  gridApiBarcode: any;
  gridColumnApiBarcode: any;

  gridApiStock: any;
  gridColumnApiStock: any;

  gridApiParts: any;
  gridColumnApiParts: any;

  columnDefs: any;
  columnDefsParts: any;
  columnDefsStock: any;
  columnDefsBarcode: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionStock: any;

  noRowsTemplate: string;

  getRowHeight: any;

  public components: any;
  public assemble_name: any = '';
  public aceVlaue: any = '';

  public aceConfig: AceConfigInterface = {
    mode: 'text',
    theme: 'github',
    readOnly : false
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      sortOrder: ['', [] ],
      category_seq: [ '', [Validators.required] ],
      category_list: [ '', [] ],
      name: ['', [Validators.required, Validators.maxLength(255)] ],
      gbn: ['', [Validators.required] ],
      rent_gbn: ['', [] ],
      type: ['', [Validators.required] ],
      type_code: ['', [] ],
      assembled_yn: ['', [Validators.required] ],
      barcode_yn: ['', [Validators.required] ],
      pay_unit: ['', [Validators.required] ],
      inquiry_amt: [true, [] ],
      tax_yn: [true, [] ],
      sale_amt: ['', [Validators.required, Validators.maxLength(20), CustomValidator.amt] ],
      purchase_amt: ['', [CustomValidator.amt, Validators.maxLength(20)] ],
      order_amt: ['', [CustomValidator.amt, Validators.maxLength(20)] ],
      spec: ['', [] ],
      min_qty: ['', [] ],
      unit: ['', [Validators.maxLength(30)] ],
      associated_tag: ['', [Validators.maxLength(1000)] ],
      summary: ['', [] ],
      content: ['', [Validators.required] ],
      content_m: ['', [] ],
      recommend: [false, [] ],
      stock_yn: [true, [] ],
      pc_mobile_yn: [ true, [] ],
      use_yn: [ true, [Validators.required] ],
      display_yn: [ true, [Validators.required] ],
      file: ['', [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
      setProduct: [ [], [] ],
      parts: [ [], [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private aCommonService: ACommonService,
    private modalService: NgbModal,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCategoryService: ACategoryService,
    private globals: Globals,
    private aProductService: AProductService,
    private restful: RestfulService,
    public authService: AuthService
  ) {
    this.changeFunc = this.changedSelect2.bind(this);

    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center ag-cell50h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '이미지', field: 'file_path', cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.thumbnail_filename ) {
            return '<img src="' + config.baseUrl + params.data.thumbnail_filename + '" style="max-height:50px;" />';
          } else if ( params.data.file_path ) {
            return '<img src="' + config.baseUrl + params.data.file_path + '" style="max-height:50px;" />';
          } else {
            return `
              <div style="width:75px;height:50px;margin:0 auto;padding:10px;box-sizing:border-box">
                <div style="background:url(/assets/admin/images/board_img.png) no-repeat 50% 50%;background-size:contain;width:100%;height:100%;opacity:0.5"></div>
              </div>
            `;
          }
        }
      },
      { headerName: '카테고리', field: 'category_name', cellClass: 'cp left ag-cell50h' },
      { headerName: '상품명', field: 'name', cellClass: 'cp left ag-cell50h' },
      { headerName: '규격', field: 'spec', cellClass: 'cp left ag-cell50h' },
      { headerName: '단가', field: 'sale_amt', cellClass: 'cp right ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const str = String(params.data.sale_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '수량', field: 'qty', cellClass: 'cp right ag-cell50h' },
      { headerName: '금액', field: 'total_amt', cellClass: 'cp right ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const str = String(params.data.sale_amt * params.data.qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsParts = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center ag-cell50h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '이미지', field: 'file_path', cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.thumbnail_filename ) {
            return '<img src="' + config.baseUrl + params.data.thumbnail_filename + '" style="max-height:50px;" />';
          } else if ( params.data.file_path ) {
            return '<img src="' + config.baseUrl + params.data.file_path + '" style="max-height:50px;" />';
          } else {
            return `
              <div style="width:75px;height:50px;margin:0 auto;padding:10px;box-sizing:border-box">
                <div style="background:url(/assets/admin/images/board_img.png) no-repeat 50% 50%;background-size:contain;width:100%;height:100%;opacity:0.5"></div>
              </div>
            `;
          }
        }
      },
      { headerName: '카테고리', field: 'category_name', cellClass: 'cp left ag-cell50h' },
      { headerName: '상품명', field: 'name', cellClass: 'cp left ag-cell50h' },
      { headerName: '규격', field: 'spec', cellClass: 'cp left ag-cell50h' },
      { headerName: '단가', field: 'sale_amt', cellClass: 'cp right ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const str = String(params.data.sale_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '수량', field: 'qty', cellClass: 'cp right ag-cell-edit ag-cell50h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const str = String(params.data.qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: 'numericCellEditor'
      },
      { headerName: '금액', field: 'total_amt', cellClass: 'cp right ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const str = String(params.data.sale_amt * params.data.qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    // 상품재고
    this.columnDefsStock = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '창고명', field: 'name', cellClass: 'cp left' },
      { headerName: '창고주소', field: 'address', cellClass: 'cp left' },
      { headerName: '입고수량', field: 'in_qty', cellClass: 'cp right' },
      { headerName: '출고수량', field: 'out_qty', cellClass: 'cp right' },
      { headerName: '재고수량', field: 'cur_qty', cellClass: 'cp right' },
      { headerName: '비고', field: 'outline', cellClass: 'cp left' },
    ];

    // 바코드 리스트
    this.columnDefsBarcode = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', width: 100 },
      // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      { headerName: '바코드 발행번호', field: 'barcode', cellClass: 'cp center', width: 200 },
      { headerName: '폐기/분실 여부', field: 'barcode', cellClass: 'cp center', width: 150, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.use_yn == '0' || params.data.use_yn == false ) {
            return '<span class="badge badge-danger f12">폐기/분실</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '입고창고(현재창고)', field: 'warehouse_in_name', cellClass: 'cp center', width: 150 },
      { headerName: '입고일자(마지막입고/위치이동 일자)', field: 'inout_date', cellClass: 'cp center', width: 190 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'multiple';
    this.rowSelectionStock = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.getRowHeight = function(params) {
      return 50;
    };

    this.components = {
      numericCellEditor: this.getNumericCellEditor(),
    };
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCategoryList();

    this.activatedRoute.queryParams.subscribe( async params => {
      this.params = params;

      this.seq = params.seq;
      if( this.seq == '0' ) {
        this.title = '추가';

        const defaultContent: any = `<table style="width:100%;font-family: Helvetica;font-size: 14px;color:#2b2b2b;">
        <colgroup>
          <col style="width:20%;">
          <col style="width:80%;">
        </colgroup>
        <tbody>
        <tr>
          <th style="border:1px solid #d8d8d8;background-color:#f4f5f7; text-align: center;">
            <span style="font-family: Helvetica;">상품규격</span>
          </th>
          <td style="border:1px solid #d8d8d8;line-height:30px;padding:20px 30px;">
            <p style="margin-top: 0pt; margin-bottom: 0pt; margin-left: 0in; direction: ltr; unicode-bidi: embed; word-break: keep-all; line-height: 2;">
              <span style="font-family: Helvetica;"></span><br>
            </p>
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #d8d8d8;background-color:#f4f5f7; text-align: center;">
            <span style="font-family: Helvetica;">상품안내</span>
          </th>
          <td style="border:1px solid #d8d8d8;line-height:30px;padding:20px 30px;">
            <p style="margin-top: 0pt; margin-bottom: 0pt; margin-left: 0in; direction: ltr; unicode-bidi: embed; word-break: keep-all;">
              <span style="font-family: Helvetica;"></span><br>
            </p>
          </td>
        </tr>
        </tbody>
        </table>`;

        this.form.patchValue({content: defaultContent});

        this.aceVlaue = this.form.controls.content.value;
      } else {
        this.title = '수정';

        // 상품 정보 가져오기
        await this.getProductDetail();
      }
    });

    this.getCommonList();

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
      this.uploadProgress = '0%';
    };

    // 파일 업로드 전 사용자 세션 토큰 추가
    this.uploader.onBuildItemForm = this.onBuildItemForm.bind(this);

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        const files = this.form.controls.files.value;

        files.push({
          url: config.baseUrl + response.url,
          origin: response.origin_filename,
          size: response.size,
          thumbnail: response.thumbnail,
          thumbnail_result: response.thumbnail_result,
        });

        this.form.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 업로드 전에 토큰 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  private onBuildItemForm(fileItem: FileItem, form: FormData): any {
    form.append('token', this.globals.token);
    return { fileItem, form };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files:File[] = event.target.files;
    const filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm('선택하신 파일을 삭제하시겠습니까?') ) {
      const tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile( upload: any, index: any ) {
    if( confirm('선택하신 파일을 삭제하시겠습니까?') ) {
      this.aProductService.deleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          const uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '첨부파일 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '첨부파일 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    const extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertImage( file ){
    if( this.form.controls.pc_mobile_yn.value ) {
      if( this.checkImage(this.getExt(file.origin)) ) {
        const addHtml = this.form.controls.content.value + '<br /><img src="' + file.url + '" />';
        this.form.patchValue( {content: addHtml} );
      }
    } else {
      // PC 상품 상세에 이미지 추가
      if( confirm('PC 상품 상세에 이미지를 추가하시려면 [확인] 버튼을, 모바일 상품 상세에 이미지를 추가하시려면 [취소] 버튼을 선택하세요.') ) {
        const addHtml = this.form.controls.content.value + '<br /><img src="' + file.url + '" />';
        this.form.patchValue( {content: addHtml} );

      // 모바일 상품 상세에 이미지 추가
      } else {
        const addHtml = this.form.controls.content_m.value + '<br /><img src="' + file.url + '" />';
        this.form.patchValue( {content_m: addHtml} );
      }
    }
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
    ['/product/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 상품 종류
    this.aCommonService.getCommonList(23).then( response => {
      if( response.ResultCode ) {
        this.gbnList = response.data;
      } else {
        this.gbnList = [];
      }
    });

    // 임대 구분
    this.aCommonService.getCommonList(24).then( response => {
      if( response.ResultCode ) {
        this.rentGbnList = response.data;
      } else {
        this.rentGbnList = [];
      }
    });

    // 상품 구분
    this.aCommonService.getCommonList(26).then( response => {
      if( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });

    // 과금단위
    this.aCommonService.getCommonList(27).then( response => {
      if( response.ResultCode ) {
        this.unitList = response.data;
      } else {
        this.unitList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCategoryList() {
    await this.aCategoryService.getCategoryList2().then( async response => {
      this.categoryList = [];

      if( response.ResultCode ) {
        await response.data.forEach( row => {
          this.categoryList.push({
            id: String( row.seq ),
            text: row.category_nm
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'seq' ) {
      //this.addCustomer( $event.data.seq );

    // 수량 수정
    } else if($event.colDef.field == 'qty') {
      const qty = prompt('수량을 입력하세요', $event.data.qty );

      if( isNaN( parseInt(qty) ) ) {
        this.toastrService.error( '숫자로 입력하세요.', '수량 입력');
        return false;
      } else {
        $event.node.setDataValue( 'qty', qty );
      }

    } else {

    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyBarcode(params) {
    this.gridApiBarcode = params.api;
    this.gridColumnApiBarcode = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 바코드 이력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClickedBarcode( event: any ) {
    const modalRef = this.modalService.open(ABarcodeHistoryComponent, options);

    modalRef.componentInstance.product_seq = event.data.product_seq;
    modalRef.componentInstance.seq = event.data.seq;

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  /*******************************************************************************
  설  명 : 첫 로딩 시 컬럼사이즈 자동 조절
  입력값 :
  리턴값 : 없음
*******************************************************************************/
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyStock(params) {
    this.gridApiStock = params.api;
    this.gridColumnApiStock = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedStock($event) {
    if( $event.colDef.field == 'seq' ) {
      //this.addCustomer( $event.data.seq );

    } else {

    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyParts(params) {
    this.gridApiParts = params.api;
    this.gridColumnApiParts = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedParts($event) {
    if( $event.colDef.field == 'seq' ) {
      //this.addCustomer( $event.data.seq );

    } else {
      // 부속 상품 재고 리스트 가져오기
      this.assemble_name = $event.data.name;

      this.getProductStock( $event.data.product_seq );
    }
  }

  /*******************************************************************************
    설  명 : 별도문의 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInquiryAmt( value: boolean ) {
    this.form.get('inquiry_amt').setValue(value);

    if( value === true ) {
      this.form.get('sale_amt').setValidators([Validators.maxLength(20), CustomValidator.amt]);
      this.form.get('sale_amt').updateValueAndValidity();
    } else {
      this.form.get('sale_amt').setValidators([Validators.required, Validators.maxLength(20), CustomValidator.amt]);
      this.form.get('sale_amt').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 상품 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aProductService.setProduct(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.router.navigate(
          ['/product/list'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 상품 정보를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductDetail() {
    this.aProductService.getProductDetail( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          seq: response.data.seq,
          sortOrder: response.data.sort_order,
          category_seq: response.data.category_seq,
          category_list: response.data.category_list,
          name: response.data.name,
          gbn: response.data.gbn,
          rent_gbn: response.data.rent_gbn,
          type: response.data.type,
          type_code: response.data.type_code,
          assembled_yn: response.data.assembled_yn,
          barcode_yn: response.data.barcode_yn,
          pay_unit: response.data.pay_unit,
          tax_yn: response.data.tax_yn,
          inquiry_amt: Boolean(Number(response.data.inquiry_amt)),
          sale_amt: response.data.sale_amt,
          purchase_amt: response.data.purchase_amt,
          order_amt: response.data.order_amt,
          spec: response.data.spec,
          unit: response.data.unit,
          min_qty: response.data.min_qty,
          associated_tag: response.data.associated_tag,
          summary: response.data.summary,
          content: response.data.content,
          content_m: response.data.content_m,
          recommend: response.data.recommend,
          stock_yn: response.data.stock_yn,
          pc_mobile_yn: response.data.pc_mobile_yn,
          use_yn: response.data.use_yn,
          display_yn: response.data.display_yn,
          file: '',
          upload: response.data.upload || [],
          files: [],
          setProduct: response.data.setProduct || [],
          parts: response.data.parts || []
        });

        this.aceVlaue = this.form.controls.content.value;

        // 재고 수량 가져오기
        if( response.data.assembled_yn !== '1' ) {
          this.getProductStock( this.seq );
        }

        // 바코드 리스트 가져오기
        this.getProductBarcodeList( this.seq );

      } else {
        this.toastrService.error('존재하지 않는 상품입니다.', '상품정보 수정');

        this.router.navigate(
          ['/product/list'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );
      }
    }, error => {
      this.toastrService.error('상품을 조회하는데 실패하였습니다 : ' + error, '상품정보 수정');

      this.router.navigate(
        ['/product/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );
    });
  }

  /*******************************************************************************
    설  명 : 세트 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addSetProduct() {
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    //modalRef.componentInstance.item = item;

    modalRef.result.then((result) => {
      let setProductList: any = [];

      setProductList = this.form.controls.setProduct.value;
      result.qty = 1;
      setProductList.push( result );

      this.form.patchValue({setProduct: setProductList});

      this.gridApi.setRowData(this.form.controls.setProduct.value);
    }, (reason) => {
      console.log( reason );
    });
  }

  /*******************************************************************************
    설  명 : 세트 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delSetProduct() {
    const obj = this.gridApi.rowModel.nodeManager.allNodesMap;
    const tmp = [];
    const tmp1 = [];

    for( const index in obj ) {
      if( obj[index].selected ) {
        tmp.push( obj[index].data );
      } else {
        tmp1.push( obj[index].data );
      }
    }

    if( tmp.length < 1 ) {
      this.toastrService.error( '삭제할 세트 상품을 선택하세요.', '세트 상품 삭제');

    } else {
      if( confirm('선택한 세트 상품을 삭제하시겠습니까?') ) {
        this.aProductService.deleteSetProduct( tmp ).then( response => {
          this.toastrService.success( response.ResultMessage, '세트 상품 삭제');

          this.gridApi.setRowData( tmp1 );

        }, error => {
          this.toastrService.error( error, '세트 상품 삭제');
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 부속 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addPartProduct() {
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.part_yn = true;

    modalRef.result.then((result) => {
      let setProductList: any = [];

      setProductList = this.form.controls.parts.value;
      result.qty = 1;
      setProductList.push( result );

      this.form.patchValue({parts: setProductList});

      this.gridApiParts.setRowData(this.form.controls.parts.value);
    }, (reason) => {
      console.log( reason );
    });
  }

  /*******************************************************************************
    설  명 : 부속 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delPartProduct() {
    const obj = this.gridApiParts.rowModel.nodeManager.allNodesMap;
    const tmp = [];
    const tmp1 = [];

    for( const index in obj ) {
      if( obj[index].selected ) {
        tmp.push( obj[index].data );
      } else {
        tmp1.push( obj[index].data );
      }
    }

    if( tmp.length < 1 ) {
      this.toastrService.error( '삭제할 부속 상품을 선택하세요.', '부속 상품 삭제');

    } else {
      if( confirm('선택한 부속 상품을 삭제하시겠습니까?') ) {
        this.aProductService.deletePartProduct( tmp ).then( response => {
          this.toastrService.success( response.ResultMessage, '부속 상품 삭제');

          this.gridApiParts.setRowData( tmp1 );

        }, error => {
          this.toastrService.error( error, '부속 상품 삭제');
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 구성상품 전체 금액 리턴
    입력값 : 없음
    리턴값 : 구성상품 전체 금액
  *******************************************************************************/
  getTotalSetProduct() {
    const tmp = this.form.controls.setProduct.value;
    let total = 0;

    for( const i in tmp ) {
      total += tmp[i].sale_amt * tmp[i].qty;
    }

    return this.getComma(total);
  }

  /*******************************************************************************
    설  명 : 상품의 창고별 재고 현황 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductStock( seq: any ) {
    this.aProductService.getProductStock( seq ).then( response => {
      if( response.ResultCode ) {
        this.stockList = response.data;

        this.stockTotalCount = 0;
        for( const item of this.stockList ) {
          this.stockTotalCount += parseInt(item.cur_qty);
        }
      } else {
        this.stockList = [];
        this.stockTotalCount = 0;
      }
    }, error => {
      this.toastrService.error( error, '창고별 재고 현황');
    });
  }

  /*******************************************************************************
    설  명 : 바코드 발행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  barcodeAdd() {
    let nodes = this.gridApiStock.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( "창고를 선택하세요.", '바코드 발행');
      return false;
    } else {
      const modalRef = this.modalService.open(AStockBarcodeComponent, options);

      modalRef.componentInstance.product_seq = this.form.controls.seq.value;
      modalRef.componentInstance.product_name = this.form.controls.name.value;
      modalRef.componentInstance.barcode_yn = this.form.controls.barcode_yn.value;
      modalRef.componentInstance.warehouse_seq = nodes[0].data.seq;

      modalRef.result.then((result) => {
        this.getProductBarcodeList( this.form.controls.seq.value );
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 상품의 바코드 발행 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductBarcodeList( seq: any ) {
    let params = {
      product_seq: seq,
      use_yn: false,
    };

    this.aProductService.getProductBarcodeList( params ).then( response => {
      if( response.ResultCode ) {
        this.barcodeList = response.data;
      } else {
        this.barcodeList = [];
      }
    }, error => {
      this.toastrService.error( error, '바코드 발행 리스트');
    });
  }

  /*******************************************************************************
    설  명 : ace에디터 변경시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onValueChange(value: string): void {
    //console.log('Value change:', value);
    this.form.patchValue({
      content: value
    });
  }

  /*******************************************************************************
    설  명 : 셀 숫자 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }

    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }

    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    }

    function NumericCellEditor() {}

    NumericCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement('input');
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
      var that = this;
      this.eInput.addEventListener('keypress', function(event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };

    NumericCellEditor.prototype.getGui = function() {
      return this.eInput;
    };

    NumericCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };

    NumericCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };

    NumericCellEditor.prototype.isCancelAfterEnd = function() {};

    NumericCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };

    NumericCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };

    NumericCellEditor.prototype.focusOut = function() {
    };

    return NumericCellEditor;
  }

  /*******************************************************************************
    설  명 : 대표 카테고리 select2 값이 변경될 경우 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedSelect2( $event ) {
    this.form.patchValue({
      category_seq: $event.id
    });
  }

  /*******************************************************************************
    설  명 : 바코드 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onBarcodePrint() {
    const url = '/product/print/barcode?seq=' + this.seq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1000, height=650, left=0, top=0' );
  }
}
