import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AEmployeeService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 조직도 리스트 가져오기
  getGroupEmployeeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getGroupEmployeeList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 가져오기
  getEmployeeInfo( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getEmployeeInfo'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 저장하기
  setEmployee( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'setEmployee'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 삭제하기
  deleteEmployee( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'deleteEmployee'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 조직도 리스트 가져오기 - select 태그용
  getGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getGroupList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // select 용 사원정보 가져오기
  getEmployeeSelect(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getEmployeeSelect'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
