import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';

import * as moment from 'moment';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
import { _ } from 'ag-grid-community';

const MAXROW: number = 18;
const MAXPAGEROW: number = 36;

@Component({
  selector: 'app-order-memo-print',
  templateUrl: './order-memo-print.component.html',
  styleUrls: ['./order-memo-print.component.scss']
})
export class AOrderMemoPrintComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private projectSeq: any = '';

  public orderList: any = [];
  public orderOriginList: any = [];
  public projectInfo: any = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aStockService: AStockService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    await this.activatedRoute.queryParams.subscribe(async params => {
      this.projectSeq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;

      if( this.projectSeq == '' ) {
        this.toastrService.error("정상적인 경로로 접근하시기 바랍니다.", "출고지시서");
        window.close();
      }

      await this.getOrderMemoPrint();
    });
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray1( num: number ) {
    let tmp: any = [];
    for( let i = 0; i < num; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray( startNum: number ) {
    let tmp: any = [];

    startNum = startNum * MAXROW;

    for( let i = startNum; i < startNum + MAXROW; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 프로젝트별 신청내역 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getOrderMemoPrint() {
    await this.aStockService.getOrderMemoPrint( this.projectSeq ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.orderOriginList = response.data;
        this.projectInfo = response.info;

        let i: number = 0;
        this.orderOriginList.forEach(item => {
          item.number = ++i;
        });

        let tmpOrder: any = [];
        let tmpArray: any = [];
        if( this.orderOriginList != null ) {
          if( this.orderOriginList.length > MAXPAGEROW ) {

            for( let i = 0; i < this.orderOriginList.length; i++ ) {
              if( i > 0 && i % MAXPAGEROW === 0 ) {
                tmpOrder.push(tmpArray);

                tmpArray = [];
              }

              tmpArray.push(this.orderOriginList[i]);

              if( i === ( this.orderOriginList.length - 1 ) ) {
                tmpOrder.push(tmpArray);
              }
            }

          } else {
            tmpOrder.push(this.orderOriginList);
          }
        }

        this.orderList = tmpOrder;
  
      } else {
        this.orderList  = [];
        this.projectInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 배열에 index 키 값을 가져온다.
    입력값 : index, key
    리턴값 : value
  *******************************************************************************/
  getListIndex( pIndex, index, key ) {
    if( index >= this.orderList[pIndex].length ) return '';
    else return this.orderList[pIndex][index][key];
  }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }
}
  