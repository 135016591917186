import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbActiveModal, NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTreeModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSummernoteModule } from 'ngx-summernote';
import { AceModule, AceConfigInterface, ACE_CONFIG } from 'ngx-ace-wrapper';

import { ToastrModule } from 'ngx-toastr';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FileSelectDirective } from 'ng2-file-upload';
import { PioneerTreeModule } from '@pioneer-code/pioneer-tree';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { LSelect2Module } from 'ngx-select2';
import { ColorPhotoshopModule } from 'ngx-color/photoshop'; // <color-photoshop></color-photoshop>
import { ChartsModule, ThemeService } from 'ng2-charts';
import { NgxBarcodeModule } from 'ngx-barcode';

/* 서비스 모듈 */
/* 공통 서비스 모듈 */
import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { DaumAddressComponent } from '@app/component/daum-address/daum-address.component';
import { MemberService } from '@app/service/member.service';
import { CookieService } from 'ngx-cookie-service';

import { AutoFocusDirective } from '@app/directive/auto-focus.directive';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

import { PipesModule } from '@app/pipes/pipes.module';

/* 관리자 모튤 */
import { AheaderComponent } from '@admin/page/main/header/header.component';
import { AgnbComponent } from '@admin/page/main/gnb/gnb.component';
import { ADashboardComponent } from '@admin/page/dashboard/dashboard.component';
import { ALoginComponent } from '@admin/page/member/login/login.component';
import { AManagementComponent } from '@admin/page/board/management/management.component';
import { AAddTableComponent } from '@admin/page/board/management/add-table/add-table.component';
import { ABoardInfoAddComponent } from '@admin/page/board/management/add/add.component';
import { ABoardComponent } from '@admin/page/board/board/board.component';
import { ABoardViewComponent } from '@admin/page/board/board/view/view.component';
import { ABoardAddComponent } from '@admin/page/board/board/add/add.component';
import { ABoardReplyComponent } from '@admin/page/board/board/reply/reply.component';
import { ABoardListComponent } from '@admin/page/board/board/board-list/board-list.component';
import { ABoardGalleryComponent } from '@admin/page/board/board/board-gallery/board-gallery.component';
import { ABoardAlbumComponent } from '@admin/page/board/board/board-album/board-album.component';

import { ACommonCodeComponent } from '@admin/page/system/common/common.component';
import { ACommonCodeAddComponent } from '@admin/page/system/common/add/add.component';
import { ACommonCodeGroupAddComponent } from '@admin/page/system/common/group-add/group-add.component';
import { AGroupComponent } from '@admin/page/system/group/group.component';
import { APositionComponent } from '@admin/page/system/position/position.component';
import { AEmployeeComponent } from '@admin/page/system/employee/employee.component';
import { AAuthGroupComponent } from '@admin/page/system/auth-group/auth-group.component';
import { AAuthGroupAddComponent } from '@admin/page/system/auth-group/add/add.component';
import { AAccessIpComponent } from '@admin/page/system/access-ip/access-ip.component';

import { ACompanyComponent } from '@admin/page/basic/company/company.component';
import { AAccountAddComponent } from '@admin/page/basic/company/account-add/account-add.component';
import { ACustomerComponent } from '@admin/page/basic/customer/customer.component';
import { ACustomerDetailComponent } from '@admin/page/basic/customer/detail/detail.component';
import { AProjectComponent } from '@admin/page/basic/project/project.component';
import { AProjectDetailComponent } from '@admin/page/basic/project/detail/detail.component';
import { ACarrierComponent } from '@admin/page/basic/carrier/carrier.component';
import { ACarrierAddComponent } from '@admin/page/basic/carrier/add/add.component';
import { AWarehouseComponent } from '@admin/page/basic/warehouse/warehouse.component';
import { AWarehouseAddComponent } from '@admin/page/basic/warehouse/add/add.component';
import { AMemberComponent } from '@admin/page/member/member.component';
import { AMemberDetailComponent } from '@admin/page/member/detail/detail.component';
import { ASmsComponent } from '@admin/page/member/sms/sms.component';
import { ASmsFormComponent } from '@admin/page/member/sms-form/sms-form.component';
import { AEmailComponent } from '@admin/page/member/email/email.component';
import { MemberFindComponent } from '@admin/page/member/member-find/member-find.component';
import { AProductComponent } from '@admin/page/product/product.component';
import { AProductDetailComponent } from '@admin/page/product/detail/detail.component';
import { AProductAddComponent } from '@admin/page/product/add/add.component';
import { ABarcodeHistoryComponent } from '@admin/page/product/add/barcode-history/barcode-history.component';
import { ACategoryComponent } from '@admin/page/product/category/category.component';
import { ADiscountComponent } from '@admin/page/product/discount/discount.component';
import { ADiscountAddComponent } from '@admin/page/product/discount/add/add.component';
import { ABarcodeComponent } from '@admin/page/product/print/barcode/barcode.component';
import { AStockComponent } from '@admin/page/stock/stock.component';
import { AStockImportComponent } from '@admin/page/stock/import/import.component';
import { AStockMoveComponent } from '@admin/page/stock/move/move.component';
import { AStockLossComponent } from '@admin/page/stock/loss/loss.component';
import { AStockPurchaseComponent } from '@admin/page/stock/purchase/purchase.component';
import { AStockBarcodeComponent } from '@admin/page/stock/barcode/barcode.component';

import { AOrderComponent } from '@admin/page/stock/order/order.component';
import { AOrderAddComponent } from '@admin/page/stock/order/add/add.component';
import { AOrderDetailComponent } from '@admin/page/stock/order/detail/detail.component';
import { AOrderPrintComponent } from '@admin/page/order/print/print.component';
import { AOrderPrintEstimateComponent } from '@admin/page/order/print/estimate/estimate.component';
import { AOrderEstimateComponent } from '@admin/page/order/order-estimate/order-estimate.component';
import { AOrderEstimateDetailComponent } from '@admin/page/order/order-estimate/order-estimate-detail/order-estimate-detail.component';
import { ATaxInvoiceComponent } from '@admin/page/tax/tax.component';
import { ATaxViewComponent } from '@admin/page/tax/tax-view/tax-view.component';

import { AStockWarehouseComponent } from '@admin/page/stock/warehouse/warehouse.component';
import { AApplicationComponent } from '@admin/page/order/application/application.component';
import { AApplicationDetailComponent } from '@admin/page/order/detail/detail.component';
import { AApplicationInfoComponent } from '@admin/page/order/application/info/info.component';
import { ATaxComponent } from '@admin/page/order/tax/tax.component';
import { InvoiceResendComponent } from '@admin/page/order/tax/invoice-resend/invoice-resend.component';
import { ATaxPrintComponent } from '@admin/page/order/tax/print/print.component';
import { ADeliveryOrderComponent } from '@admin/page/process/delivery-order/delivery-order.component';
import { ADeliveryOrderAddComponent } from '@admin/page/process/delivery-order/add/add.component';
import { ADeliveryAddComponent } from '@admin/page/process/delivery-add/delivery-add.component';
import { APlaceComponent } from '@admin/page/process/place/place.component';
import { AWarehousingComponent } from '@admin/page/process/warehousing/warehousing.component';
import { AWithdrawPrintComponent } from '@admin/page/process/warehousing/withdraw-print/withdraw-print.component';
import { AProductHistoryComponent } from '@admin/page/process/product-history/product-history.component';
import { APaymentComponent } from '@admin/page/payment/payment.component';
import { APaymentItemComponent } from '@admin/page/payment/item/item.component';
import { APaymentItemAddComponent } from '@admin/page/payment/item/add/add.component';
import { APaymentStatisticComponent } from '@admin/page/payment/statistic/statistic.component';
import { APaymentAddComponent } from '@admin/page/payment/add/add.component';
import { ARefundComponent } from '@admin/page/order/refund/refund.component';
import { ARefundDetailComponent } from '@admin/page/order/refund/detail/detail.component';
import { APaymentHistoryComponent } from '@admin/page/order/payment/history/history.component';
import { APaymentHistoryDetailComponent } from '@admin/page/order/payment/history/detail/detail.component';

// 공용 컴포넌트
import { ACApplicationComponent } from '@admin/component/application/application.component';
import { ACOrderComponent } from '@admin/component/order/order.component';
import { ACManagerComponent } from '@admin/component/manager/manager.component';
import { ACCustomerAddComponent } from '@admin/component/manager/add/add.component';
import { ACProjectComponent } from '@admin/component/project/project.component';
import { ACCartComponent } from '@admin/component/cart/cart.component';
import { ACMemberLogComponent } from '@admin/component/member-log/member-log.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { ACProjectFindComponent } from '@admin/component/project-find/project-find.component';
import { ACSendEmailComponent } from '@admin/component/send-email/send-email.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';
import { ACSmsModalComponent } from '@admin/component/send-sms/sms-modal/sms-modal.component';
import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACProductFindGridComponent } from '@admin/component/product-find-grid/product-find-grid.component';
import { ACStockHistoryComponent } from '@admin/component/stock-history/stock-history.component';
import { ACStockMoveComponent } from '@admin/component/stock-move/stock-move.component';
import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';
import { ACPurchaseFindComponent } from '@admin/component/purchase-find/purchase-find.component';
import { ACMemberQnaComponent } from '@admin/component/member-qna/member-qna.component';
import { ACMemberOrderRefundComponent } from '@admin/component/member-order-refund/member-order-refund.component';
import { ACMemberOrderQuotationComponent } from '@admin/component/member-order-quotation/member-order-quotation.component';
import { ACMemberPaymentComponent } from '@admin/component/member-payment/member-payment.component';
import { ACBarcodeFindComponent } from '@admin/component/barcode-find/barcode-find.component';
import { ACBarcodeInputComponent } from '@admin/component/barcode-input/barcode-input.component';
import { ACWidgetComponent } from '@admin/component/widget/widget.component';
import { ACTooltipComponent } from '@admin/component/tooltip/tooltip.component';

import { InputExComponent } from '@app/component/input-ex/input-ex.component';
import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHeaderCheckboxComponent } from '@app/component/ag-grid-header-checkbox/ag-grid-header-checkbox.component';
import { AgGridButtonComponent } from '@app/component/ag-grid-button/ag-grid-button.component';

import { AngularSplitModule } from 'angular-split';
import { AutoDepositComponent } from '@admin/page/order/auto-deposit/auto-deposit.component';
import { ADiscountCompanyComponent } from '@admin/page/product/discount-company/discount-company.component';
import { AEmployeeModifyComponent } from '@admin/page/system/employee/employee-modify/employee-modify.component';
import { ProjectSalesComponent } from '@admin/page/statistic/project-sales/project-sales.component';
import { MonthSalesComponent } from '@admin/page/statistic/month-sales/month-sales.component';
import { ReceivableComponent } from '@admin/page/statistic/receivable/receivable.component';
import { ReceivableCustomerComponent } from '@admin/page/statistic/receivable/receivable-customer/receivable-customer.component';
import { ReceivableProjectComponent } from '@admin/page/statistic/receivable/receivable-project/receivable-project.component';
import { PurchaseComponent } from '@admin/page/statistic/purchase/purchase.component';
import { NonMemberAddComponent } from '@admin/page/order/application/non-member-add/non-member-add.component';
import { AStockOutOrderPrintComponent } from '@admin/page/process/delivery-add/stock-out-order-print/stock-out-order-print.component';
import { AProjectOrderPrintComponent } from '@admin/page/process/delivery-add/order-print/order-print.component';
import { AOrderMemoPrintComponent } from '@admin/page/process/delivery-add/order-memo-print/order-memo-print.component';
import { PlaceCompleteComponent } from '@admin/page/process/place/place-complete/place-complete.component';
import { PlaceOrderComponent } from '@admin/page/process/place/place-order/place-order.component';
import { ParttimeComponent } from '@admin/page/payment/parttime/parttime.component';
import { ParttimeAddComponent } from './admin/page/payment/parttime/parttime-add/parttime-add.component';
import { ParttimeHistoryComponent } from './admin/page/payment/parttime/parttime-history/parttime-history.component';
import { StatisticIncomepaymentStatusComponent } from './admin/page/payment/statistic/statistic-incomepayment-status/statistic-incomepayment-status.component';
import { StatisticBystatementlistComponent } from './admin/page/payment/statistic/statistic-bystatementlist/statistic-bystatementlist.component';
import { StatisticBymonthlistComponent } from './admin/page/payment/statistic/statistic-bymonthlist/statistic-bymonthlist.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },
};

/* 달력 컴포넌트 한글 화 */
const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
  // 에디터 옵션
  /*
  selectionStyle: "line",
  highlightActiveLine: true,
  highlightSelectedWord: true,
  readOnly: false,
  cursorStyle: 'slim', //"ace"|"slim"|"smooth"|"wide"
  mergeUndoDeltas: true, //false|true|"always"
  behavioursEnabled: true,
  wrapBehavioursEnabled: true,
  autoScrollEditorIntoView: false, //boolean (defaults to false) // this is needed if editor is inside scrollable page
  copyWithEmptySelection: true, //boolean // copy/cut the full line if selection is empty, defaults to false
  //useSoftTabs: false, //boolean (defaults to false)
  navigateWithinSoftTabs: false, //boolean (defaults to false)

  // 렌더러 옵션
  hScrollBarAlwaysVisible: false,
  vScrollBarAlwaysVisible: false,
  highlightGutterLine: true,
  animatedScroll: true,
  showInvisibles: true,
  showPrintMargin: false,
  printMarginColumn: true, //80, //number (defaults to 80)
  printMargin: 10, //false|number // shortcut for showPrintMargin and printMarginColumn
  fadeFoldWidgets: false, //boolean
  showFoldWidgets: true, //boolean (defaults to true)
  showLineNumbers: true, //boolean (defaults to true)
  showGutter: true, //boolean (defaults to true)
  displayIndentGuides: true, //boolean (defaults to true)
  fontSize: '12px', //number or css font-size string
  // option fontFamily: css font-family value
  maxLines: 10000, //number // resize editor based on the contents of the editor until the number of lines reaches maxLines
  minLines: 0, //number
  scrollPastEnd: false, //number|boolean // number of page sizes to scroll after document end (typical values are 0, 0.5, and 1)
  fixedWidthGutter: false, //boolean (defaults to false)
  theme: 'ace', //path to a theme e.g "ace/theme/textmate"

  // mouseHandler 옵션
  scrollSpeed: 0,
  dragDelay:  0,
  dragEnabled: true, //boolean (defaults to true)
  tooltipFollowsMouse: true, //boolean

  // 세션 옵션
  firstLineNumber: 1, // number defaults to 1
  overwrite: true, //boolean
  newLineMode: "auto", // "auto" | "unix" | "windows"
  useWorker: true, //boolean
  tabSize: 4, // number
  wrap: true, // boolean|number
  foldStyle: "markbeginend", // "markbegin"|"markbeginend"|"manual"
  mode: "html", //path to a mode e.g "ace/mode/text"
  */
  tabSize: 2
};

@NgModule({
  declarations: [
    AppComponent,

    AutoFocusDirective,

    AheaderComponent,
    AgnbComponent,
    ADashboardComponent,
    ALoginComponent,
    AManagementComponent,
    AAddTableComponent,
    ABoardInfoAddComponent,
    ABoardComponent,
    ABoardViewComponent,
    ABoardAddComponent,
    ABoardReplyComponent,
    ABoardListComponent,
    ABoardGalleryComponent,
    ABoardAlbumComponent,
    ACommonCodeComponent,
    ACommonCodeGroupAddComponent,
    ACommonCodeAddComponent,
    AGroupComponent,
    APositionComponent,
    AEmployeeComponent,
    AAuthGroupComponent,
    AAuthGroupAddComponent,
    AAccessIpComponent,

    DaumAddressComponent,

    ACompanyComponent,
    AAccountAddComponent,
    ACustomerComponent,
    ACustomerDetailComponent,
    AProjectComponent,
    AProjectDetailComponent,
    ACarrierComponent,
    ACarrierAddComponent,
    AWarehouseComponent,
    AWarehouseAddComponent,
    AMemberComponent,
    AMemberDetailComponent,
    ASmsComponent,
    ASmsFormComponent,
    AEmailComponent,
    AProjectComponent,
    AProductComponent,
    AProductDetailComponent,
    AProductAddComponent,
    ACategoryComponent,
    ADiscountComponent,
    ADiscountAddComponent,
    AStockComponent,
    AStockImportComponent,
    AStockMoveComponent,
    AStockLossComponent,
    AStockPurchaseComponent,
    AStockBarcodeComponent,
    AOrderComponent,
    AOrderAddComponent,
    AOrderEstimateComponent,
    AOrderEstimateDetailComponent,
    AStockWarehouseComponent,
    AApplicationComponent,
    AApplicationDetailComponent,
    AApplicationInfoComponent,
    ATaxComponent,
    AOrderDetailComponent,
    AOrderPrintComponent,
    AOrderPrintEstimateComponent,
    ATaxPrintComponent,
    ADeliveryOrderComponent,
    ADeliveryOrderAddComponent,
    ADeliveryAddComponent,
    APlaceComponent,
    AWarehousingComponent,
    AProductHistoryComponent,
    APaymentComponent,
    APaymentItemComponent,
    APaymentItemAddComponent,
    APaymentStatisticComponent,
    APaymentAddComponent,
    ARefundComponent,
    ARefundDetailComponent,
    APaymentHistoryComponent,
    APaymentHistoryDetailComponent,
    ATaxInvoiceComponent,
    ATaxViewComponent,

    ACApplicationComponent,
    ACOrderComponent,
    ACManagerComponent,
    ACProjectComponent,
    ACCartComponent,
    ACMemberLogComponent,
    ACCustomerFindComponent,
    ACProjectFindComponent,
    ACSendEmailComponent,
    ACSendSmsComponent,
    ACSmsModalComponent,
    ACProductFindComponent,
    ACProductFindGridComponent,
    ACStockHistoryComponent,
    ACStockMoveComponent,
    ACMemberFindComponent,
    ACPurchaseFindComponent,
    ACCustomerAddComponent,
    ACMemberQnaComponent,
    ACMemberOrderRefundComponent,
    ACMemberOrderQuotationComponent,
    ACMemberPaymentComponent,
    MemberFindComponent,

    InputExComponent,
    AgGridImageComponent,
    //FileSelectDirective,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,
    AgGridHeaderCheckboxComponent,
    AgGridButtonComponent,
    ACWidgetComponent,
    AutoDepositComponent,
    ADiscountCompanyComponent,
    AEmployeeModifyComponent,
    ProjectSalesComponent,
    MonthSalesComponent,
    ReceivableComponent,
    ReceivableCustomerComponent,
    ReceivableProjectComponent,
    PurchaseComponent,
    NonMemberAddComponent,
    AStockOutOrderPrintComponent,
    AProjectOrderPrintComponent,
    ABarcodeComponent,
    PlaceCompleteComponent,
    PlaceOrderComponent,
    ACBarcodeFindComponent,
    ABarcodeHistoryComponent,
    AOrderMemoPrintComponent,
    ACBarcodeInputComponent,
    ACTooltipComponent,
    AWithdrawPrintComponent,
    InvoiceResendComponent,
    ParttimeComponent,
    ParttimeAddComponent,
    ParttimeHistoryComponent,
    StatisticIncomepaymentStatusComponent,
    StatisticBystatementlistComponent,
    StatisticBymonthlistComponent,
  ],
  imports: [
    PipesModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //NgbModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    MatTooltipModule,
    DragDropModule,
    DpDatePickerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    AgGridModule.withComponents([
      AgGridImageComponent,
      AgGridHtmlComponent,
      AgGridHeaderCheckboxComponent,
      AgGridButtonComponent
    ]),
    PioneerTreeModule,
    DeviceDetectorModule.forRoot(),
    SwiperModule,
    NgxSummernoteModule,
    AngularSplitModule.forRoot(),
    ColorPhotoshopModule,
    AceModule,
    ChartsModule,
    LSelect2Module,
    NgxBarcodeModule
  ],
  providers: [
    RestfulService,
    MemberService,
    UtilService,
    Globals,
    AuthService,
    AuthGuard,
    AuthLoginCheck,
    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,
    ACApplicationComponent,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    CookieService,
    NgbActiveModal,
    I18n,
    ThemeService,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig,
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ACommonCodeGroupAddComponent,
    ACommonCodeAddComponent,
    AAccountAddComponent,
    ACarrierAddComponent,
    AWarehouseAddComponent,
    ADiscountAddComponent,
    AStockImportComponent,
    AStockMoveComponent,
    AStockLossComponent,
    AStockPurchaseComponent,
    AStockBarcodeComponent,
    AOrderPrintComponent,
    AOrderPrintEstimateComponent,
    ATaxPrintComponent,
    APaymentItemAddComponent,
    APaymentAddComponent,
    AAuthGroupAddComponent,
    ABoardInfoAddComponent,
    AAddTableComponent,
    ARefundDetailComponent,
    APaymentHistoryDetailComponent,
    ATaxViewComponent,

    ACApplicationComponent,
    ACOrderComponent,
    ACManagerComponent,
    ACProjectComponent,
    ACCartComponent,
    ACMemberLogComponent,
    ACCustomerFindComponent,
    ACProjectFindComponent,
    ACSendEmailComponent,
    ACSendSmsComponent,
    ACSmsModalComponent,
    ACProductFindComponent,
    ACProductFindGridComponent,
    ACStockHistoryComponent,
    ACStockMoveComponent,
    ACMemberFindComponent,
    ACPurchaseFindComponent,
    ACCustomerAddComponent,
    ACMemberQnaComponent,
    ACMemberOrderRefundComponent,
    ACMemberOrderQuotationComponent,
    ACMemberPaymentComponent,
    MemberFindComponent,
    NonMemberAddComponent,
    PlaceCompleteComponent,
    PlaceOrderComponent,
    ACBarcodeFindComponent,
    ABarcodeHistoryComponent,
    ACBarcodeInputComponent,
    ACTooltipComponent,
    InvoiceResendComponent,
    ParttimeAddComponent,
    ParttimeHistoryComponent
  ]
})
export class AppModule { }
