import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { StatisticService } from '@admin/service/statistic.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-project-sales',
  templateUrl: './project-sales.component.html',
  styleUrls: ['./project-sales.component.scss']
})
export class ProjectSalesComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectTypeList: any = [];
  public projectYearList: any = [];
  public projectMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  // 검색 조건
  public search: any = {
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    projectType: '',
    projectName: '',
    projectSeq: ''
  };

  // 매출 내역
  public totalList: any = [];
  public totalSumList: any = [];
  public incomeList: any = [];
  public spendingList: any = [];
  public spendingTotalList: any = [];

  // 공통코드 그룹 그리드 관련 선언
  gridApiTotal: any;
  gridColumnApiTotal: any;
  columnDefsTotal: any;

  gridApiTotalSum: any;
  gridColumnApiTotalSum: any;
  columnDefsTotalSum: any;

  gridApiIncome: any;
  gridColumnApiIncome: any;
  columnDefsIncome: any;

  gridApiSpending: any;
  gridColumnApiSpending: any;
  columnDefsSpending: any;

  gridApiTotalSpending: any;
  gridColumnApiTotalSpending: any;
  columnDefsTotalSpending: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  totalPinnedBottomRowData = [];
  incomePinnedBottomRowData = [];
  spendingPinnedBottomRowData = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private aCommonService: ACommonService,
    private statisticService: StatisticService,
    public authService: AuthService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefsTotal = [
      { headerName: '프로젝트명', field: 'project_name', width: 330, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent' },
      { headerName: '프로젝트기간', field: 'project_period', width: 200, cellClass: 'cp center' },
      { headerName: '수입금(공급가)', field: 'income_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '수입금(부가세)', field: 'income_vat', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '수입총금액', field: 'income', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '지출금액', field: 'spending', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '손익합계', field: 'total', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];

    this.columnDefsTotalSum = [
      { headerName: '', field: 'project_name', width: 530, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent' },
      { headerName: '', field: 'income_amt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '', field: 'income_vat', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '', field: 'spending', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '', field: 'total', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];

    this.columnDefsIncome = [
      { headerName: '항목', field: 'item_name', width: 200, cellClass: 'cp center'},
      { headerName: '거래처구분', field: 'kind_name', width: 190, cellClass: 'cp center'},
      { headerName: '일자', field: 'saleDate', width: 150, cellClass: 'cp center' },
      { headerName: '수입금(공급가)', field: 'income_amt', width: 120, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '수입금(부가세)', field: 'income_vat', width: 120, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '금액', field: 'totalAmt', width: 120, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];

    this.columnDefsSpending = [
      { headerName: '항목', field: 'item_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.saleDate == null ) {
            return '<span>' + params.data.item_name + '총합</span>';
          } else {
            return params.data.item_name;
          }
        }
      },
      { headerName: '거래처명', field: 'customer_name', width: 150, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.saleDate == null ) {
            return '';
          } else {
            return params.data.customer_name;
          }
        }
      },
      { headerName: '일자', field: 'saleDate', width: 100, cellClass: 'cp center' },
      { headerName: '수입금(공급가)', field: 'income_amt', width: 120, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '수입금(부가세)', field: 'income_vat', width: 120, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '금액', field: 'totalAmt', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '비고', field: 'memo', width: 200, cellClass: 'cp left' },
    ];

    this.columnDefsTotalSpending = [
      { headerName: '항목', field: 'item_name', width: 200, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return '<span>' + params.data.item_name + ' 합계</span>';
        }
      },
      // { headerName: '거래처명', field: 'customer_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     if( params.data.saleDate == null ) {
      //       return '';
      //     } else {
      //       return params.data.customer_name;
      //     }
      //   }
      // },
      // { headerName: '일자', field: 'saleDate', width: 125, cellClass: 'cp center' },
      { headerName: '수입금(공급가)', field: 'income_amt', width: 250, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '수입금(부가세)', field: 'income_vat', width: 250, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
      { headerName: '금액', field: 'totalAmt', width: 250, cellClass: 'cp right',
        valueFormatter: currencyFormatter, valueGetter: numberGetter
      },
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      return parseInt(params.data[ params.column.colId ], 10);
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.getCommonListCode();
    // this.getProjectYearList();
    this.getProjectSalesTotalList();
  }
  
  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.projectYearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 주문상태 리스트 가져오기
  *******************************************************************************/
  getCommonListCode() {
    this.aCommonService.getCommonListCode( 'PJT' ).then( response => {
      if( response.ResultCode ) {
        this.projectTypeList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 연도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getProjectYearList() {
    // 매출현황 리스트
    await this.statisticService.getProjectYearList().then( response => {
      if (response.ResultCode) {
        this.projectYearList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 매출현황 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getProjectSalesTotalList() {
    this.incomeList = [];
    this.spendingList = [];

    // 매출현황 리스트
    await this.statisticService.getProjectSalesTotalList( this.search ).then( response => {
      if (response.ResultCode) {
        this.totalList = response.data;
        this.getGridSum(response.data);

        let nodes = '';
        setTimeout(() => {
          nodes = this.gridApiTotal.getSelectedNodes();

          if( nodes.length < 1 ) {
            let index = 0;
            this.gridApiTotal.forEachNode(function(node) {

              if (index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }
        }, 0);

        let total: any = {
          project_seq: '',
          project_name: '<b>합계</b>',
          income_amt: 0,
          income_vat: 0,
          spending: 0,
          total: 0
        };

        if( this.totalList.length > 0 ) {
          this.totalList.forEach(item => {
            total.income_amt += parseInt(item.income_amt);
            total.income_vat += parseInt(item.income_vat);
            total.spending += parseInt(item.spending);
            total.total += parseInt(item.total);
          });
  
          this.search.projectSeq = this.totalList[0].project_seq;

          this.getProjectSalesIncomeList();
          this.getProjectSalesSpendingList();
        }

        this.totalSumList = [total];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getProjectSalesIncomeList() {
    // 수입 리스트
    await this.statisticService.getProjectSalesIncomeList( this.search.projectSeq ).then( response => {
      if (response.ResultCode) {
        this.incomeList = response.data;
        this.getInconmGridSum(response.data);
      }
    });
  }

  /*******************************************************************************
    설  명 : 지출 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getProjectSalesSpendingList() {
    // 지출 리스트
    await this.statisticService.getProjectSalesSpendingList( this.search.projectSeq ).then( response => {
      if (response.ResultCode) {
        this.spendingList = response.data;
        this.spendingTotalList = response.totalData;
        this.getSpendingGridSum(response.totalData);
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyTotal(params) {
    this.gridApiTotal = params.api;
    this.gridColumnApiTotal = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyTotalSum(params) {
    this.gridApiTotalSum = params.api;
    this.gridColumnApiTotalSum = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyIncome(params) {
    this.gridApiIncome = params.api;
    this.gridColumnApiIncome = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadySpending(params) {
    this.gridApiSpending = params.api;
    this.gridColumnApiSpending = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyTotalSpending(params) {
    this.gridApiTotalSpending = params.api;
    this.gridColumnApiTotalSpending = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async onRowClicked($event) {
    this.search.projectSeq = $event.data.project_seq;

    await this.getProjectSalesIncomeList();
    await this.getProjectSalesSpendingList();
  }

  /*******************************************************************************
    설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      projectType: '',
      projectName: '',
      projectSeq: ''
    };

    this.getProjectSalesTotalList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectSalesTotalList();
  }

  /*******************************************************************************
    설  명 : 엔터 입력 시 검색
    입력값 : $evnet
    리턴값 : 없음
  *******************************************************************************/
  setSearchInput( $event ) {
    if ( $event.key == 'Enter' ) {
      this.getProjectSalesTotalList();
    }
  }

  /*******************************************************************************
    설  명 : 그리드 하단 합계 계산
  *******************************************************************************/
  getGridSum = (data: any) => this.totalPinnedBottomRowData = [{
    project_name: '총 합계',
    income_amt: data.reduce((a: any, c: any) => a + parseInt(c.income_amt), 0),
    income_vat: data.reduce((a: any, c: any) => a + parseInt(c.income_vat), 0),
    income: data.reduce((a: any, c: any) => a + parseInt(c.income), 0),
    spending: data.reduce((a: any, c: any) => a + parseInt(c.spending), 0),
    total: data.reduce((a: any, c: any) => a + parseInt(c.total), 0),
  }]
  getInconmGridSum = (data: any) => this.incomePinnedBottomRowData = [{
    item_name: '총 합계',
    income_amt: data.reduce((a: any, c: any) => a + parseInt(c.income_amt), 0),
    income_vat: data.reduce((a: any, c: any) => a + parseInt(c.income_vat), 0),
    totalAmt: data.reduce((a: any, c: any) => a + parseInt(c.totalAmt), 0),
  }]
  getSpendingGridSum = (data: any) => this.spendingPinnedBottomRowData = [{
    item_name: '총',
    income_amt: data.reduce((a: any, c: any) => a + parseInt(c.income_amt), 0),
    income_vat: data.reduce((a: any, c: any) => a + parseInt(c.income_vat), 0),
    totalAmt: data.reduce((a: any, c: any) => a + parseInt(c.totalAmt), 0),
  }]

}
