/*******************************************************************************
  설  명 : 프로젝트 추가/수정
  작성일 : 2020-02-15
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';
import { ColorEvent } from 'ngx-color';

import { config } from '@app/service/config.service';
import { RestfulService } from '@app/service/restful.service';
import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { ACommonService } from '@admin/service/common.service';
import { AProjectService } from '@admin/service/project.service';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { AuthService } from '@app/service/auth.service';

import * as moment from 'moment';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-project-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class AProjectDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number = 0; // 0 = 추가, 0 != 수정
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public typeList = [];
  public scaleList = [];
  public warehouseList = [];
  public placeList = [];
  private warehouseOrgList = [];

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public uploader: FileUploader;
  public uploadProgress: any = 0;
  public baseURL = config.baseUrl;

  public bgcolor: any = '#000000';
  public isColorPicker: boolean = false;

  /*******************************************************************************
    설  명 : 빌드 폼
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let todayModel: NgbDateStruct = this.utilService.getDate('');

    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      name:["", [Validators.required, Validators.maxLength(100)] ],
      type:["", [Validators.required] ],
      scale:["", [Validators.required] ],
      type_name:["", [] ],
      start_date:[ todayModel, [Validators.required, Validators.maxLength(10)] ],
      end_date:[ todayModel, [Validators.required, Validators.maxLength(10)] ],
      time:["09:00", [Validators.maxLength(8)] ],
      place:["", [Validators.maxLength(100)] ],
      zipcode:["", [Validators.maxLength(5)] ],
      address:["", [Validators.maxLength(255)] ],
      address_detail:["", [Validators.maxLength(255)] ],
      supervisor:["", [Validators.maxLength(100)] ],
      s_person_name:["", [Validators.maxLength(50)] ],
      s_person_tel:["", [Validators.maxLength(30)] ],
      s_person_HP:["", [Validators.maxLength(30)] ],
      s_person_email:["", [Validators.maxLength(100)] ],
      organiser:["", [Validators.maxLength(100)] ],
      o_person_name:["", [Validators.maxLength(50)] ],
      o_person_tel:["", [Validators.maxLength(30)] ],
      o_person_hp:["", [Validators.maxLength(30)] ],
      o_person_email:["", [Validators.maxLength(100)] ],
      link:["", [Validators.maxLength(200)] ],
      bgcolor:["#000000", [] ],
      outline:["", [] ],
      schedule_date: ["", [] ],
      warehouse_seq: ["", [] ],
      file: ["", [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aProjectService: AProjectService,
    private aCommonService: ACommonService,
    private restful: RestfulService,
    private globals: Globals,
    private aWarehouseService: AWarehouseService,
    public authService: AuthService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();

    this.getWarehouseList();

    if( this.seq == 0 )
      this.title = '추가';
    else {
      this.title = '수정';

      // 프로젝트 데이터 가져오기
      this.aProjectService.getProjectInfo( this.seq ).then( response => {
        if( response.ResultCode ) {
          let start_date: NgbDateStruct = this.utilService.getDate( response.data.start_date );
          let end_date: NgbDateStruct = this.utilService.getDate( response.data.end_date );

          let schedule_date: NgbDateStruct = this.utilService.getDate( response.data.schedule_date );

          response.data.start_date = start_date;
          response.data.end_date = end_date;
          response.data.schedule_date = schedule_date;

          if( response.data.bgcolor )
            this.bgcolor = response.data.bgcolor;
          else
            this.bgcolor = '#000000';

          this.formAdd.patchValue( response.data );
        }
      });
    }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량 50M
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 파일 업로드 전 사용자 세션 토큰 추가
    this.uploader.onBuildItemForm = this.onBuildItemForm.bind(this);

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.formAdd.controls.files.value;

        files.push({
          url: config.baseUrl + response.url,
          origin: response.origin_filename,
          size: response.size,
          thumbnail: response.thumbnail,
          thumbnail_result: response.thumbnail_result,
        });

        this.formAdd.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aProjectService.setProject(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.formAdd.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 프로젝트 구분
    this.aCommonService.getCommonList(12).then( response => {
      for( const index in response.data ) {
        this.typeList.push({
          title: response.data[index].common_name,
          value: response.data[index].seq
        });
      }
    });

    // 프로젝트 규모
    this.aCommonService.getCommonListCode('PJS').then( response => {
      for( const index in response.data ) {
        this.scaleList.push({
          title: response.data[index].common_name,
          value: response.data[index].common_code
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm( "해당 프로젝트를 삭제하시겠습니까?" ) ) {
      this.aProjectService.deleteProject( this.formAdd ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 파일 업로드 전에 토큰 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  private onBuildItemForm(fileItem: FileItem, form: FormData): any {
    form.append('token', this.globals.token);
    return { fileItem, form };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.formAdd.controls.files.value;

      tmp.splice( index, 1 );

      this.formAdd.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.aProjectService.deleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.formAdd.controls.upload.value;
          uploadData.splice( index, 1 );
          this.formAdd.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '첨부파일 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '첨부파일 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 선택 시 처리
    입력값 : $event: ColorEvent
    리턴값 : 없음
  *******************************************************************************/
  changeComplete($event: ColorEvent) {
    //this.formAdd.patchValue({bgcolor: $event.color.hex});
    this.bgcolor = $event.color.hex;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 표시 여부
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  toggleColorPicker() {
    this.isColorPicker = !this.isColorPicker;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  accept( $event: any ) {
    this.formAdd.patchValue({bgcolor: this.bgcolor});

    this.isColorPicker = false;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancel( $event: any ) {
    this.bgcolor = this.formAdd.controls.bgcolor.value;
    this.isColorPicker = false;
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseList() {
    this.aWarehouseService.getWarehouseList({typeSelect: '', searchText: ''}).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        let tmp1: any = [];

        for( let item of response.data ) {
          tmp.push({
            'title': item.name,
            'value': item.seq
          });

          tmp1.push({
            'title': item.name,
            'value': item.name
          });
        }

        this.warehouseList = tmp;
        this.placeList = tmp1;
        this.warehouseOrgList = response.data;

      } else {
        this.warehouseList = [];
        this.placeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 장소 변경 시 주소 적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  placeChange() {
    for(let item of this.warehouseOrgList ) {
      if( item.name == this.formAdd.controls.place.value ) {
        this.formAdd.patchValue({
          zipcode: item.zipcode,
          address: item.address,
          address_detail: item.address_detail
        });
        break;
      }
    }
  }
}
