/*******************************************************************************
  설  명 : 조직관리
  작성일 : 2019-09-21
  작성자 : 송영석
*******************************************************************************/
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PioneerTreeConfiguration, IPioneerTreeConfiguration, IPioneerTreeComponent } from '@pioneer-code/pioneer-tree';
import { Subject, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';
import { ASystemGroupService } from '@admin/service/system.group.service';

@Component({
  selector: 'app-system-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class AGroupComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild ( 'pt', {static: false} ) pt: IPioneerTreeComponent ;

  public groupList = [];

  public groupForm: FormGroup;
  public formErrors = {};

  public isExpand: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public selectedNode = {
    seq: '',
    dept_cd: '',
    dept_name: '',
    memo: '',
    use_yn: true,
    children: []
  };

  public configuration = {
    "childPropertyName" : "children",
    "sortPropertyName" : "id",
    "collapseAllOnLoad" : false
  } as IPioneerTreeConfiguration;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.groupForm = this.formBuilder.group({
      seq:["", [] ],
      dept_cd:["", [Validators.required, Validators.maxLength(9)] ],
      dept_name:["", [Validators.required, Validators.maxLength(45)] ],
      use_yn:["", [Validators.required] ],
    });

    this.groupForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.groupForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private aSystemGroupService: ASystemGroupService,
    private aCommonService: ACommonService,
    public authService: AuthService,
  ) {
    this.buildForm();
  }

  ngOnInit() {

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngAfterViewInit() {
    // 트리 열기/닫기 처리
    this.isExpand.subscribe( value => {
      if( typeof this.pt == 'undefined' ) return;

      if( ! value )
        this.pt.pioneerTree.collapseAllNodes();
      else
        this.pt.pioneerTree.expandAllNodes();
    });

    // 카테고리 리스트 가져오기
    this.getGroupList();
  }

  /*******************************************************************************
    설  명 : 카테고리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.groupForm, this.formErrors);

    if(this.groupForm.valid) {
      this.aSystemGroupService.setGroup(this.groupForm).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getGroupList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getGroupList() {
    await this.aSystemGroupService.getGroupList().then( response => {
      this.groupList = response.data;
    });

    // 데이터 로딩 후에 트리 노드 펼침/닫힘 처리, this.isExpand로 처리할 경우 데이터 변경으로 인해 정상 반영 안됨
    if( this.isExpand.value === true ) {
      this.configuration = { "childPropertyName" : "children", "sortPropertyName" : "id", "collapseAllOnLoad" : false };
    } else {
      this.configuration = { "childPropertyName" : "children", "sortPropertyName" : "id", "collapseAllOnLoad" : true };
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 노드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedGroup( node ) {

    if( typeof this.selectedNode.seq == 'undefined' || this.selectedNode.seq == node.seq ) {
      this.selectedNode = {
        seq: '',
        dept_cd: '',
        dept_name: '',
        memo: '',
        use_yn: true,
        children: []
      }
    } else {
      this.selectedNode = node;
    }

    this.groupForm.patchValue( this.selectedNode );
  }

  /*******************************************************************************
    설  명 : lpad
    입력값 : str = 문자열, padLen = 채울 문자 수, padStr = 채울 문자
    리턴값 : 문자열
  *******************************************************************************/
  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
        console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
        return str;
    }
    str += ""; // 문자로
    padStr += ""; // 문자로
    while (str.length < padLen)
        str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  /*******************************************************************************
    설  명 : 카테고리 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addGroup() {
    let code1: string = '';

    // 중분류, 소분류 추가
    if( typeof this.selectedNode.seq != 'undefined' && this.selectedNode.seq !== '' ) {
      if( typeof this.selectedNode.children == 'undefined' )
        code1 = this.selectedNode.dept_cd + '001';
      else
        code1 = this.selectedNode.dept_cd + this.lpad( this.selectedNode.children.length  + 1, 3, '0');

    // 대분류 추가
    } else {
      code1 = this.lpad( this.groupList.length + 1, 3, '0');
    }

    if( code1 == '002' ) {
      this.toastrService.error("1단계 조직을 추가할 수 없습니다.");
      return false;
    }

    this.groupForm.patchValue({
      seq: '',
      dept_cd: code1,
      dept_name: '',
      memo: '',
      use_yn: true,
      children: []
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 펼침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  expandTree() {
    if( this.isExpand.value === true )
      this.isExpand.next( false );
    else
      this.isExpand.next( true );
  }

  /*******************************************************************************
    설  명 : 트리노드 변경시 처리
    입력값 : event
    리턴값 : 없음
  *******************************************************************************/
  async onNodeDropped( event ) {
    // 루트 노드 가져오기
    let rootNode = this.getTreeNodeRoot( event.pioneerTreeNode );

    // 현재 노드 가져오기
    if( typeof rootNode.currentNode == 'undefined' ) rootNode = rootNode.pioneerTreeNode.currentNode;
    else rootNode = rootNode.currentNode;

    // 레벨 가져오기
    rootNode.level = 0;
    let level = this.getTreeNodeLevel( rootNode, rootNode.level, rootNode.level );

    // 3단계 이상은 오류 처리
    if( level > 3 ) {
      await this.getGroupList();

      this.toastrService.error( '3단계 이상으로 노드를 확장할 수 없습니다.', '');

    // 정상 이동일 경우 처리
    } else {
      // 노드  생성
      let key1 = rootNode.dept_cd.substr( 0, 3 );
      let key2 = 1;
      rootNode.children.forEach( function( node ) {
        let key3 = 1;
        let keyCode2 = '';

        keyCode2 = this.lpad( key2++, 3, '0' );
        node.dept_cd = key1 + keyCode2;

        if( typeof node.children !== 'undefined' ) {
          node.children.forEach( function( subnode ) {
            subnode.dept_cd = key1 + keyCode2 + this.lpad( key3++, 3, '0' );
          }.bind(this));
        }
      }.bind(this));

      let nodeArray = [];
      this.getTreeNodeArray( rootNode, nodeArray );

      this.aSystemGroupService.updateGroup( JSON.stringify( nodeArray ) ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getGroupList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    }
  }

  /*******************************************************************************
    설  명 : 트리노드 단계를 가져온다.
    입력값 : node, 현재 레벨, 맥스 레벨
    리턴값 : 최대 레벨 값
  *******************************************************************************/
  getTreeNodeLevel( node, currLevel, maxLevel ) {
    if( typeof node.children == 'undefined' ) {
      if( maxLevel < currLevel+1 ) return currLevel+1;
      else return maxLevel;
    }

    node.children.forEach( function(c) {
      maxLevel = this.getTreeNodeLevel(c, currLevel + 1, maxLevel);
    }.bind(this));

    return maxLevel;
  }

  /*******************************************************************************
    설  명 : 트리노드 루트 노드 값을 가져온다
    입력값 : node
    리턴값 : rootNode
  *******************************************************************************/
  getTreeNodeRoot( node ) {
    if( typeof node.parentNode !== 'undefined' ) {
      return this.getTreeNodeRoot( node.parentNode );

    } else if( typeof node.pioneerTreeNode !== 'undefined' ) {
      if( typeof node.pioneerTreeNode.parentNode !== 'undefined' ) {
        return this.getTreeNodeRoot( node.pioneerTreeNode.parentNode );
      } else {
        return node;
      }

    } else {
      return node;
    }
  }

  /*******************************************************************************
    설  명 : 트리노드 단계를 무시하고 변경할 리스트를 배열로 리턴
    입력값 : node
    리턴값 : 배열
  *******************************************************************************/
  getTreeNodeArray( node, nodeArray ) {
    if( typeof node.children == 'undefined' ) {
      nodeArray.push({
        seq: node.seq,
        dept_cd: node.dept_cd
      });

    } else {
      if( node.dept_cd.substr( 3, 6 ) !== '000000' )
        nodeArray.push({
          seq: node.seq,
          dept_cd: node.dept_cd
        });

      node.children.forEach( function(c) {
        this.getTreeNodeArray( c, nodeArray );
      }.bind(this));
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteGroup() {
    if( this.groupForm.controls.seq.value == '' ) {
      this.toastrService.error( '삭제할 조직을 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 조직을 삭제하시겠습니까?") ) {
      this.aSystemGroupService.deleteGroup( this.groupForm ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.groupForm.patchValue({
            seq: '',
            dept_cd: '',
            dept_name: '',
            memo: '',
            use_yn: true,
            children: []
          });

          this.getGroupList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 조직도 삭제 할 수 있는지 검증
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkDeleteGroup() {
    let dept_cd = this.selectedNode.dept_cd;

    if( typeof this.selectedNode.children == 'undefined' ) {
      return true;
    } else {
      if( this.selectedNode.children.length > 0 ) {
        return false;
      } else {
        return true;
      }
    }
  }

}
