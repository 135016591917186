/*******************************************************************************
  설  명 : 창고 관리
  작성일 : 2019-09-16
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { CustomValidator } from '@app/service/custom.validators';
import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { AWarehouseAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class AWarehouseComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 창고 종류
  public typeList = [];

  // 검색 필드
  public search = {
    searchText: '',
    typeSelect: '',
  }

  selectedRows: number = 0;
  warehouseList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    public authService: AuthService,
    private aWarehouseService: AWarehouseService
  ) {
    this.columnDefs = [
      {headerName: '창고코드', field: 'seq', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '창고명', field: 'name', cellClass: 'cp' },
      {headerName: '창고구분', field: 'type', cellClass: 'cp center' },
      {headerName: '재고반영', field: 'stock_yn', cellClass: 'cp center' },
      {headerName: '담당자', field: 'person', cellClass: 'cp center' },
      {headerName: '핸드폰', field: 'person_hp', cellClass: 'cp' },
      {headerName: '우편번호', field: 'zipcode', cellClass: 'cp center' },
      {headerName: '주소', field: 'address', cellClass: 'cp' },
      {headerName: '상세주소', field: 'address_detail', cellClass: 'cp' },
      {headerName: '메인배너 표시여부', field: 'main_banner_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.main_banner_yn == 1 ) {
            return '<span class="badge badge-primary f12">표시함</span>';
          } else {
            return '<span class="badge badge-secondary f12">표시안함</span>';
          }
        }
      },
      {headerName: '로고 이미지', field: 'thumbnail_filename', cellClass: 'cp', cellRenderer: 'agGridImageComponent' },
      {headerName: '메인배너 링크주소', field: 'main_banner_link', cellClass: 'cp' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 거래처분류, 거래처 종류 가져오기
    this.getCommonList();

    this.getWarehouseList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.warehouseAdd( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 창고 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  warehouseAdd( seq ) {
    const modalRef = this.modalService.open(AWarehouseAddComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      this.getWarehouseList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 창고 구분
    await this.aCommonService.getCommonList(16).then( response => {
      this.typeList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseList() {
    this.aWarehouseService.getWarehouseList( this.search ).then( response => {
      this.warehouseList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 검색 조건 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    // 검색 필드
    this.search = {
      searchText: '',
      typeSelect: '',
    }

    this.getWarehouseList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getWarehouseList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getWarehouseList();
    }
  }
}
