/*******************************************************************************
 설  명 : 회원관리
 작성일 : 2019-10-13
 작성자 : 송영석
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { AMemberListService } from '@admin/service/member.service';
import { ACommonService } from '@admin/service/common.service';
import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

import { ISms } from '@admin/component/send-sms/send-sms.component';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class AMemberComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  memberList: any = [];

  public joinYearList: any = [];
  public joinMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  public orderYearList: any = [];
  public orderMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  sendList: ISms[] = [];

  search: any = {
    mem_no: '',
    searchField: 'name',
    searchText: '',
    customerField: 'name',
    customerText: '',
    project: '',
    haveId: '',
    grade: '',
    status: '',
    joinSearchYear: '',
    joinSearchMonth: '',
    joindate_group: 'A',
    orderSearchYear: '',
    orderSearchMonth: '',
    orderdate_group: 'A',
    range_group: 'amt',
    range_start: '',
    range_end: '',
    grade_group: ''
  };

  joindate_group: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: 0},
    {title: '어제', value: -1},
    {title: '3일', value: -3},
    {title: '7일', value: -7},
    {title: '15일', value: -15},
    {title: '1개월', value: -30},
    {title: '3개월', value: -90},
    {title: '6개월', value: -180}
  ];

  gradeList: any = [];

  memberInfo: any = {
    total: 0,
    normal: 0,
    dormant: 0,
    memout: 0
  }

  today: string;

  // 그리드 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aMemberListService: AMemberListService,
    private aCommonService: ACommonService,
    private calendar: NgbCalendar,
    public authService: AuthService
  ) {
    this.columnDefs = [
      { headerName: '번호', field: 'mem_no', width: 80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true  },
      { headerName: '등록일', field: 'write_date', width: 140, cellClass: 'cp center' },
      { headerName: '성명', field: 'name', width: 170, cellClass: 'cp left' },
      { headerName: '아이디', field: 'id', width: 150, cellClass: 'cp left' },
      { headerName: '회원구분', field: '', cellClass: 'cp center', width: 70, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.id ) {
            return '<span class="badge badge-success f11">회원</span>';
          } else {
            return '<span class="badge badge-secondary f11">비회원</span>';
          }
        }
      },
      { headerName: '회원등급', field: 'grade_name', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.level >= '80' ) {
            return '<span class="badge badge-warning f11">관리자</span>';
          } else {
            if( params.data.grade_name ) {
              return `<span class="badge badge-success f11">${params.data.grade_name}</span>`;
            } else {
              return '';
            }
          }
        }
      },
      { headerName: '회원상태', field: 'status', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == 'Y' ) return '<span class="badge badge-primary f12 fn">정상회원</span>';
          else if( params.data.status == 'H' ) return '<span class="badge badge-success f12 fn">휴면회원</span>';
          else return '<span class="badge badge-secondary f12 fn">탈퇴회원</span>';
        }
      },
      { headerName: '휴대폰', field: 'hp', width: 140, cellClass: 'cp left' },
      { headerName: '이메일', field: 'email', width: 190, cellClass: 'cp left' },
      { headerName: '거래처명', field: 'customer_name', width: 180, cellClass: 'cp left' },
      { headerName: '프로젝트', field: 'project_name', width: 270, cellClass: 'cp left' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.today = moment().format('YYYY-MM-DD');
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    let dateModel: NgbDateStruct;

    dateModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'name' : params.searchField;
      this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.search.customerField = ( typeof params.customerField == 'undefined' || params.customerField == '' ) ? 'name' : params.customerField;
      this.search.customerText = ( typeof params.customerText == 'undefined' || params.customerText == '' ) ? '' : params.customerText;
      this.search.project = ( typeof params.project == 'undefined' || params.project == '' ) ? '' : params.project;
      this.search.haveId = ( typeof params.haveId == 'undefined' || params.haveId == '' ) ? '' : params.haveId;
      this.search.grade = ( typeof params.grade == 'undefined' || params.grade == '' ) ? '' : params.grade;
      this.search.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
      this.search.joinSearchYear = ( typeof params.joinSearchYear == 'undefined' || params.joinSearchYear == '' ) ? '' : params.joinSearchYear;
      this.search.joinSearchMonth = ( typeof params.joinSearchMonth == 'undefined' || params.joinSearchMonth == '' ) ? '' : params.joinSearchMonth;
      this.search.joindate_group = ( typeof params.joindate_group == 'undefined' || params.joindate_group == '' ) ? '' : params.joindate_group;
      this.search.orderSearchYear = ( typeof params.orderSearchYear == 'undefined' || params.orderSearchYear == '' ) ? '' : params.orderSearchYear;
      this.search.orderSearchMonth = ( typeof params.orderSearchMonth == 'undefined' || params.orderSearchMonth == '' ) ? '' : params.orderSearchMonth;
      this.search.orderdate_group = ( typeof params.orderdate_group == 'undefined' || params.orderdate_group == '' ) ? '' : params.orderdate_group;
      this.search.range_group = ( typeof params.range_group == 'undefined' || params.range_group == '' ) ? '' : params.range_group;
      this.search.range_start = ( typeof params.range_start == 'undefined' || params.range_start == '' ) ? '' : params.range_start;
      this.search.range_end = ( typeof params.range_end == 'undefined' || params.range_end == '' ) ? '' : params.range_end;
      this.search.grade_group = ( typeof params.grade_group == 'undefined' || params.grade_group == '' ) ? '' : params.grade_group;     
    });

    this.getGradeList();

    this.getMemberInfo();

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.joinYearList.push(i.toString());
      this.orderYearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 회원 정보를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberInfo() {
    this.aMemberListService.getMemberInfo().then( response => {
      if( response.ResultCode ) {
        this.memberInfo = {
          total: response.total,
          normal: response.normal,
          dormant: response.dormant,
          memout: response.memout
        }
      } else {
        this.memberInfo = {
          total: 0,
          normal: 0,
          dormant: 0,
          memout: 0
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberList() {
    let form: any = $.extend({}, this.search);

    this.aMemberListService.getMemberList( form ).then( response => {
      if( response.ResultCode ) {
        this.memberList = response.data;
      } else {
        this.memberList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원등급 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGradeList() {
    this.aCommonService.getCommonList(19).then( response => {
      if( response.ResultCode ) {
        this.gradeList = response.data;

        this.search.grade_group = this.gradeList[0].seq;

      } else {
        this.gradeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  onSelectionChanged($event) {
    this.sendList = [];
    this.gridApi.getSelectedRows().forEach( row => {
      this.sendList.push({
        mem_no: row.mem_no,
        name: row.name,
        mobile: row.hp,
        id: row.id,
        smsYn: row.sms_yn,
        leavedate: row.leavedate,
      });
    });
  }

  /*******************************************************************************
    설  명 : 회원 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  memberAdd( mem_no ) {

    this.search.mem_no = mem_no;

    this.router.navigate(
    ['/member/list/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'mem_no' ) {
      this.memberAdd( $event.data.mem_no );
    }
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onJoindateClick( val ) {
    this.search.joindate_group = val;

    if( val == 'A' ) {
      this.search.join_edate = '';
      this.search.join_sdate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('DD') )
      };

      this.search.join_edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY') ),
        month: parseInt( moment().add(val, 'day').format('MM') ),
        day: parseInt( moment().add(val, 'day').format('DD') )
      };

      this.search.join_sdate = dateModel;
    }

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onOrderDateClick( val ) {
    this.search.orderdate_group = val;

    if( val == 'A' ) {
      this.search.order_edate = '';
      this.search.order_sdate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('DD') )
      };

      this.search.order_edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY') ),
        month: parseInt( moment().add(val, 'day').format('MM') ),
        day: parseInt( moment().add(val, 'day').format('DD') )
      };

      this.search.order_sdate = dateModel;
    }

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      seq: '',
      searchField: 'name',
      searchText: '',
      customerField: 'name',
      customerText: '',
      project: '',
      haveId: '',
      grade: '',
      status: '',
      joinSearchYear: '',
      joinSearchMonth: '',
      joindate_group: 'A',
      orderSearchYear: '',
      orderSearchMonth: '',
      orderdate_group: 'A',
      range_group: 'amt',
      range_start: '',
      range_end: ''
    };

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 선택 리스트 리턴
    입력값 : 없음
    리턴값 : 리스트
  *******************************************************************************/
  getSelectedGrid() {
    let obj = this.gridApi.rowModel.nodeManager.allNodesMap;
    let header = this.gridApi.columnController.gridColumns;
    let dataList = [];

    for( const index in obj ) {
      let addCheck = false;

      if( obj[index].selected ) {
        dataList.push({mem_no: obj[index].data['mem_no']});
      }
    }

    return dataList;
  }

  /*******************************************************************************
    설  명 : 등급 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  gradeChange() {
    if( ! confirm("선택하신 회원들의 등급을 변경하시겠습니까?") ) return false;

    let list = this.getSelectedGrid();

    if( list.length < 1 ) {
      this.toastrService.error('등급을 변경할 회원을 선택해 주세요.', '등급변경');
    } else {
      this.aMemberListService.setMemberGrade( list, this.search.grade_group ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '등급변경');

          this.getMemberList();
        } else {
          this.toastrService.error( response.ResultMessage, '등급변경');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 회원 탈퇴 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberOutList() {
    if( ! confirm("선택하신 회원들을 탈퇴 처리하시겠습니까?") ) return false;

    let list = this.getSelectedGrid();

    if( list.length < 1 ) {
      this.toastrService.error('등급을 변경할 회원을 선택해 주세요.', '등급변경');
    } else {
      this.aMemberListService.setMemberOutList( list ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '등급변경');

          this.getMemberList();
        } else {
          this.toastrService.error( response.ResultMessage, '등급변경');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.join_sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 2 ) {
      this.search.join_edate = this.calendar.getToday();
      obj.close();
    } else if( check == 3 ) {
      this.search.order_sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 4 ) {
      this.search.order_edate = this.calendar.getToday();
      obj.close();
    }
  }
}
