import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ASiteManagementService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  // 프로젝트의 부스별 신청 정보 가져오기
  getProjectOrderPlaceList( projectSeq: any, customerName: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'siteManagement',
        version: '1.0',
        action: 'getProjectOrderPlaceList'
      }, {
        projectSeq: projectSeq,
        customerName: customerName
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 선택된 부스의 신청 정보를 가져온다.
  getProjectOrderPlaceDetailList( orderSeq: any, setIndex: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'siteManagement',
        version: '1.0',
        action: 'getProjectOrderPlaceDetailList'
      }, {
        orderSeq: orderSeq,
        setIndex: setIndex
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 선택된 부스/장소에 담당자를 배정한다.
  setCharge( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'siteManagement',
        version: '1.0',
        action: 'setCharge'
      }, {
        data: data
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 선택된 출고리스트에 정보를 저장한다.
  setSiteManagementDetail( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'siteManagement',
        version: '1.0',
        action: 'setSiteManagementDetail'
      }, {
        projectSeq: params.projectSeq,
        orderSeq: params.orderSeq,
        setIndex: params.setIndex,
        data: params.data
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 선택된 출고리스트의 배송/회수를 취소한다.
  cancelSiteManagementDetail( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'siteManagement',
        version: '1.0',
        action: 'cancelSiteManagementDetail'
      }, {
        data: data
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 전체 출고리스트 저장
  setSiteManagementAll( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'siteManagement',
        version: '1.0',
        action: 'setSiteManagementAll'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
}
