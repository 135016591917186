/*******************************************************************************
  설  명 : 할인 추가
  작성일 : 2019-10-22
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateStruct, NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { Globals } from '@app/service/globals.service';
import { ADiscountService } from '@admin/service/discount.service';

import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { ACProjectFindComponent } from '@admin/component/project-find/project-find.component';
import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options_product: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
//  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-discount-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ADiscountAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: any = ''; // true = 추가, false = 수정
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  discount_gbn: any = [];
  discount_type: any = [];
  truncation_type: any = [];

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let todayModel: NgbDateStruct;

    todayModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };

    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      discount_name:["", [Validators.required, Validators.maxLength(100)] ],
      discount_gbn:["", [Validators.required] ],
      sdate: [todayModel, [Validators.required] ],
      edate: [todayModel, [Validators.required] ],
      use_yn:[true, [Validators.required] ],
      sc_seq:["", [] ],
      sc_name:["", [] ],
      project_seq:["", [] ],
      project_name:["", [] ],
      product_seq:["", [] ],
      product_name:["", [] ],
      discount_type:["", [Validators.required] ],
      discount_rate:["", [] ],
      discount_amt:["", [] ],
      truncation_type:["", [Validators.required] ],
      truncation_unit:["1", [Validators.required] ],
      memo:["", [] ]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aCommonService: ACommonService,
    private globals: Globals,
    private modalService: NgbModal,
    private aDiscountService: ADiscountService,
    public authService: AuthService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 할인 구분
    this.aCommonService.getCommonListCode('DG').then( response => {
      if( response.ResultCode ) {
        this.discount_gbn = response.data;
      } else {
        this.discount_gbn = [];
      }
    });

    // 할인 방법
    this.aCommonService.getCommonListCode('DT').then( response => {
      if( response.ResultCode ) {
        this.discount_type = response.data;
      } else {
        this.discount_type = [];
      }
    });

    // 절사 방법
    this.aCommonService.getCommonListCode('TT').then( response => {
      if( response.ResultCode ) {
        this.truncation_type = response.data;
      } else {
        this.truncation_type = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 뷰 생성 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.seq == 0 )
      this.title = '추가';
    else {
      this.title = '수정';

      this.getDiscountInfo();
    }
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aDiscountService.setDiscount(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '할인 저장');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '할인 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '할인 저장');
    }
  }

  /*******************************************************************************
    설  명 : 거래처 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, options);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        sc_seq: result.seq,
        sc_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProject() {
    const modalRef = this.modalService.open(ACProjectFindComponent, options);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        project_seq: result.seq,
        project_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상품 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProduct() {
    const modalRef = this.modalService.open(ACProductFindComponent, options_product);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        product_seq: result.product_seq,
        product_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 할인 구분 변경 시 처리
    입력값 : item
    리턴값 : 없음
  *******************************************************************************/
  discountChange( item ) {
    let data = {
      discount_gbn: item.seq,
      sc_seq: this.formAdd.controls.sc_seq.value,
      sc_name: this.formAdd.controls.sc_name.value,
      project_seq: this.formAdd.controls.project_seq.value,
      project_name: this.formAdd.controls.project_name.value,
      product_seq: this.formAdd.controls.product_seq.value,
      product_name: this.formAdd.controls.product_name.value
    }

    // 복합 할인
    if( item.seq == 217 ) {

    // 거래처 할인
    } else if( item.seq == 108 ) {
      data.project_seq = '';
      data.project_name = '';
      data.product_seq = '';
      data.product_name = '';

    // 프로젝트 할인
    } else if( item.seq == 105 ) {
      data.sc_seq = '';
      data.sc_name = '';
      data.product_seq = '';
      data.product_name = '';

    // 상품 할인
    } else {
      data.sc_seq = '';
      data.sc_name = '';
      data.project_seq = '';
      data.project_name = '';
    }

    this.formAdd.patchValue( data );
  }

  /*******************************************************************************
    설  명 : 수정 시 할인 정보를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDiscountInfo() {
    this.aDiscountService.getDiscountInfo( this.seq ).then( response => {
      if( response.ResultCode ) {
        let sdate: NgbDateStruct;
        let edate: NgbDateStruct;

        sdate = {
          year: parseInt( moment(response.data.sdate).format('YYYY') ),
          month: parseInt( moment(response.data.sdate).format('MM') ),
          day: parseInt( moment(response.data.sdate).format('DD') )
        };

        edate = {
          year: parseInt( moment(response.data.edate).format('YYYY') ),
          month: parseInt( moment(response.data.edate).format('MM') ),
          day: parseInt( moment(response.data.edate).format('DD') )
        };

        this.formAdd.patchValue({
          seq: response.data.seq,
          discount_name: response.data.discount_name,
          discount_gbn: response.data.discount_gbn,
          sdate: sdate,
          edate: edate,
          use_yn: ( response.data.use_yn == '1' ) ? true : false,
          sc_seq: response.data.sc_seq,
          sc_name: response.data.sc_name,
          project_seq: response.data.project_seq,
          project_name: response.data.project_name,
          product_seq: response.data.product_seq,
          product_name: response.data.product_name,
          discount_type: response.data.discount_type,
          discount_rate: response.data.discount_rate,
          discount_amt: response.data.discount_amt,
          truncation_type: response.data.truncation_type,
          truncation_unit: response.data.truncation_unit,
          memo: response.data.memo
        });
      } else {
        this.toastrService.error('존재하지 않는 할인 항목입니다.', '할인내역 불러오기');
        this.activeModal.close();
      }
    }, error => {
      console.log( error );
      this.toastrService.error('존재하지 않는 할인 항목입니다.', '할인내역 불러오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 할인 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteDiscount() {
    if( confirm("선택된 할인을 삭제하시겠습니까?") ) {
      this.aDiscountService.deleteDiscount( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '할인삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '할인삭제');
        }

        this.activeModal.close();
      }, error => {
        this.toastrService.error( error, '할인삭제');
        this.activeModal.close();
      });
    }
  }
}
