import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ACompanyService } from '@admin/service/company.service';
import { AOrderService } from '@admin/service/order.service';
import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { RestfulService } from '@app/service/restful.service';

import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import * as $ from 'jquery';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

const URL = config.apiFileUploadUrl;

const MAX_PAGE = 30;
const ADD_PAGE = 10;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.csv';

@Component({
  selector: 'app-print-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class AOrderPrintEstimateComponent implements OnInit {

  public today: any = moment().format('YYYY년 MM월 DD일');

  public project: any = {};
  public account: any = {};
  public uploadFile: any = {};

  public detailOriginList: any = [];
  public detailList: any = [];
  public payList: any = [];

  public params: any = {
    order_seq: '',
    mem_no: '',
    email: '',
    type: '1'
  };

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  private title: string;
  private excelFileName: string;

  public baseURL = config.baseUrl;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globals: Globals,
    private restful: RestfulService,
    private toastrService: ToastrService,
    private aOrderService: AOrderService,
    private aCompanyService: ACompanyService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  async ngOnInit() {
    // 파라미터 가져오기
    await this.activatedRoute.queryParams.subscribe(async params => {
      this.params.order_seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
      this.params.mem_no = ( typeof params.memNo == 'undefined' || params.memNo == '' ) ? '' : params.memNo;
      this.params.email = ( typeof params.email == 'undefined' || params.email == '' ) ? '' : params.email;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;

      await this.getAccountList();
      await this.getOrderPrintList();
    });

    // 업로드 허용 파일 설정
    var filetype: string[] = ['pdf'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
      this.uploadProgress = '0%';
    };

    // 파일 업로드 전 사용자 세션 토큰 추가
    this.uploader.onBuildItemForm = this.onBuildItemForm.bind(this);

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        this.uploadFile = {
          url: this.baseURL + response.url,
          origin: response.origin_filename,
          size: response.size,
          thumbnail: response.thumbnail,
          thumbnail_result: response.thumbnail_result,
        };

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {

  }

  /*******************************************************************************
    설  명 : 파일 업로드 전에 토큰 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  private onBuildItemForm(fileItem: FileItem, form: FormData): any {
    form.append('token', this.globals.token);
    return { fileItem, form };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files:File[] = event.target.files;
    const filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 계좌정보 가져오기
  *******************************************************************************/
  getAccountList() {
    this.aCompanyService.getAccountList().then( response => {
      if( response.ResultCode ) {
        response.data.forEach(item => {
          if( item.default == '1' ) {
            this.account = item;
          }
        });
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 가져오기
  *******************************************************************************/
  getOrderPrintList() {
    this.aOrderService.getOrderPrintList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.detailOriginList = response.data;

        let tmpOrder: any = [];
        let tmpArray: any = [];
        let index: number = 1;
        let rowCount: number = 0;
        let maxPage: number;
        if( this.detailOriginList != null ) {
          for( let i = 0; i < this.detailOriginList.length; i++ ) {
            if( index === 1 ) maxPage = MAX_PAGE * index;
            else maxPage = (MAX_PAGE * index) + ADD_PAGE;

            if( (rowCount + parseInt(this.detailOriginList[i].detail.length)) > maxPage ) {
              tmpOrder.push(tmpArray);

              tmpArray = [];
              index++;
            }

            tmpArray.push(this.detailOriginList[i]);

            rowCount += parseInt(this.detailOriginList[i].detail.length);

            if( i === ( this.detailOriginList.length - 1 ) ) {
              tmpOrder.push(tmpArray);
            }
          }
        }

        this.detailList = tmpOrder;

      } else {
        this.detailList = [];
        this.toastrService.error( response.ResultMessage, '');
      }

      this.getOrderPayList();
    });
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 가져오기
  *******************************************************************************/
  getOrderPayList() {
    this.aOrderService.getOrderPayList( this.params.order_seq ).then( response => {
      if( response.ResultCode ) {
        this.project = response.data;
        this.payList = response.pay;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }

      var data = $(".table tbody").children();
    });
  }


 /*******************************************************************************
  설  명 : Moment
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
  getMoment( date: any ) {
    return moment(date).format( 'YYYY년 MM월 DD일' );
  }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }

  /*******************************************************************************
    설  명 : 이메일 발송
  *******************************************************************************/
  sendEmail() {
    if( this.params.email == '' ) {
      this.toastrService.error( "이메일주소를 입력해 주시기 바랍니다.", '견적서');
      return false;
    }

    if( ! confirm("입력하신 이메일로 견적서를 발송하시겠습니까?") ) {
      return false;
    }

    let contents = $(".print").html();
    contents = contents.replace(/src="\/assets\/images\/thefirstpro_seal.jpg"/g, 'src="https://www.thefirstpro.co.kr/assets/images/thefirstpro_seal.jpg"');

    let params: any = {
      title: '견적서 메일발송',
      subject: '주식회사 더퍼스트에서 견적서를 보내드립니다.',
      contents:  contents,
      email: this.params.email,
      mem_no: this.params.mem_no,
      upload_file: this.uploadFile
    }

    this.aOrderService.sendEmailEstimate( params ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '메일발송');
      } else {
        this.toastrService.error( response.ResultMessage, '메일발송');
      }
    });

  }

  public downloadAsPDF() {
    var data = document.getElementById('estimateContents');  //Id of the table
    html2canvas(data).then(canvas => {
      let filename = ( this.project.name ) + moment().format('YYYYMMDDHHmmss') + '.pdf';
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let imgData = canvas.toDataURL('image/png');
      let imgWidth = 208;
      let pageHeight = 297;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while(heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(filename); // Generated PDF
    });
  }

  excelExport() {
    let columnWidth = [];
    let dataList = [];

    if( this.detailOriginList.length < 1 ) {
      this.toastrService.error( '엑셀로 출력할 데이터가 없습니다.', '' );
      return false;
    } else {

      this.excelFileName = ( this.project.customer_name ? this.project.customer_name : this.project.mem_name ) + "_견적서_품목리스트_" + moment().format('YYYYMMDD') + EXCEL_EXTENSION;

      let title = [ [ this.title ], [] ];
      var worksheet: XLSX.WorkSheet;

      // 제목 추가
      worksheet = XLSX.utils.sheet_add_aoa( worksheet, title );
      // 색상변경 등은 프로 유료 버전에서만 지원
      //worksheet['A1'] = {v: this.title, t: "s", s: { alignment: 'center', font: {size: 14, bold: true, color: '#FF00FF' }}};

      /*
        s: { alignment: {textRotation: 90 },
        font: {sz: 14, bold: true, color: #FF00FF }
      */

      let headerList = [
        '구분',
        '품목 및 규격',
        '수량',
        '단가',
        '공급가액',
        '세액'
      ];

      this.detailOriginList.forEach(page => {
        page.detail.forEach(set => {
          dataList.push({
            '구분': set.place,
            '품목 및 규격': set.product_name + ( set.product_spec ? '\n' + set.product_spec : ''),
            '수량': set.qty,
            '단가': set.amt / set.qty,
            '공급가액': set.amt,
            '세액': set.vat
          });
        });
      });

      // 데이터 추가
      worksheet = XLSX.utils.sheet_add_json( worksheet, dataList, {header: headerList, origin: "A1"} );

      // 워크북 생성
      var workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      // 컬럼 사이즈 지정
      // worksheet['!cols'] = columnWidth;

      // 열 머지 처리
      // worksheet['!merges'] = [
      //   {s:{r:0,c:0},e:{r:0,c:4}}
      // ];

      // 엑셀 버퍼로 보내기
      var excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });

      // 파일로 저장
      this.saveAsExcelFile(excelBuffer, this.excelFileName);

      return true;
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

     FileSaver.saveAs( data, fileName );
  }

  isNumeric(num, opt){
    // 좌우 trim(공백제거)을 해준다.
    num = String(num).replace(/^\s+|\s+$/g, "");

    // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
    if(typeof opt == "undefined" || opt == "1" || opt == '' ) {
      var regex = /^[+\-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

    // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
    } else if(opt == "2") {
      var regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

    // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
    } else if(opt == "3") {
      var regex = /^[0-9]+(\.[0-9]+)?$/g;

    // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
    } else {
      var regex = /^[0-9]$/g;
    }

    if( regex.test(num) ) {
      num = num.replace(/,/g, "");
      return isNaN(num) ? false : true;
    } else {
      return false;
    }
  }

}
