import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';
import { Globals } from '@app/service/globals.service';
import { AProductService } from '@admin/service/product.service';
import { AWarehouseService } from '@admin/service/warehouse.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACBarcodeFindComponent } from '@admin/component/barcode-find/barcode-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class AStockBarcodeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public product_seq: number;
  public product_name: string = '';
  public barcode_yn: any;
  public warehouse_seq: any;

  public formBarcode: FormGroup;
  public formErrors = {};

  public addProductFunc: any;         // 상품검색

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formBarcode = this.formBuilder.group({
      seq:['', [] ],
      group_seq:['', [] ],
      product_seq:['', [Validators.required] ],
      product_name:['', [Validators.required] ],
      warehouse_seq: ["", [] ],
      warehouse_name: ["", [] ],
      inout_date: [date, [Validators.required] ],
      max_qty:['', [] ],
      input_qty:['', [Validators.required] ],
      barcode_yn:['', [] ],
    });

    this.formBarcode.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formBarcode, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private globals: Globals,
    private aCommonService: ACommonService,
    private aStockService: AStockService,
    private aProductService: AProductService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aWarehouseService: AWarehouseService,
    public authService: AuthService
  ) {
    this.buildForm();

    this.addProductFunc = this.searchProduct.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getWareHouseList();
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.product_seq !== null ) {
      this.formBarcode.patchValue({
        product_seq: this.product_seq,
        product_name: this.product_name
      });
    }

    if( this.barcode_yn !== null ) {
      this.formBarcode.patchValue({
        barcode_yn: this.barcode_yn
      });
    }

    if( this.warehouse_seq !== null ) {
      this.formBarcode.patchValue({
        warehouse_seq: this.warehouse_seq
      });

      this.getBarcodeProductStock();
    }
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWareHouseList() {
    this.aWarehouseService.getWarehouseList({
      typeSelect: '',
      searchText: ''
    }).then( response => {
      if( response.ResultCode ) {
        for(let item of response.data ) {
          if( item.seq == this.warehouse_seq ) {
            this.formBarcode.patchValue({
              warehouse_name: item.name
            });
          }
        }
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }
  
  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formBarcode, this.formErrors);

    if(this.formBarcode.valid) {
      if( parseInt(this.formBarcode.controls.input_qty.value) < 1 ) {
        this.toastrService.error('발행수량은 1 이상 입력하시기 바랍니다.', '바코드 발행');
        return false;
      } else if( parseInt(this.formBarcode.controls.max_qty.value) < parseInt(this.formBarcode.controls.input_qty.value) ) {
        this.toastrService.error('발행 가능 수량을 초과하여 입력하였습니다.', '바코드 발행');
        return false;
      } else {
        this.formBarcode.patchValue({
          inout_date: this.ngbDateParserFormatter.format( this.formBarcode.controls.inout_date.value )
        });

        this.aStockService.setBarcodeStock(this.formBarcode).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.activeModal.close();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '바코드 발행');
    }
  }

  /*******************************************************************************
    설  명 : 상품 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProduct() {
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.formBarcode.patchValue({
        product_seq: result.product_seq,
        product_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 바코드 상품 발행 가능 수량을 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBarcodeProductStock() {
    this.aProductService.getBarcodeProductStock( this.formBarcode.controls.product_seq.value, this.formBarcode.controls.warehouse_seq.value ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.formBarcode.patchValue({max_qty: response.data});
      } else {
        this.formBarcode.patchValue({max_qty: 0});
      }
    }, error => {
      this.toastrService.error( error, '재고 정보');
    });
  }
}
