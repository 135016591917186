import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AGnbService {

  constructor(
    public restful: RestfulService,
  ) { }

  // 주문접수 갯수 가져오기
  getHeaderCount(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'gnb',
        version: '1.0',
        action: 'getHeaderCount'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 왼쪽 메뉴 하단 통계 가져오기
  getGnbStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'gnb',
        version: '1.0',
        action: 'getGnbStatistic'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
