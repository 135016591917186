/*******************************************************************************
  설  명 : 공통코드 그룹 추가
  작성일 : 2019-09-21
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';
import { ApiResponse } from '@app/service/api-response';

import { CustomValidator } from '@app/service/custom.validators';

@Component({
  selector: 'app-common-code-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.scss']
})
export class ACommonCodeGroupAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedItem: any = {};
  private title: string = '추가';

  errorResponse: ApiResponse;
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ["", []],
      group_cd:['S', [Validators.required]],
      code:["", [Validators.required, Validators.minLength(1), Validators.maxLength(3), CustomValidator.alphaUpper]],
      name:["", [Validators.required, Validators.maxLength(50)]],
      use_yn:[ true, [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private commonService: ACommonService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    public authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if( this.selectedItem.seq !== 0 ) {
      this.form.patchValue( this.selectedItem );
      this.title = '수정';
    } else {
      this.title = '추가';
    }
  }

  /*******************************************************************************
    설  명 : 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid){
      this.commonService.setAddCommonGroupCode( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.toastrService.error('등록에 실패하였습니다.', '');
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 그룹 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 공통코드 그룹을 삭제하시겠습니까? 공통코드 그룹을 삭제하면 프로그램이 정상적으로 작동되지 않을 수 있습니다.") ) {
      this.commonService.deleteCommonGroup( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
}

