import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.scss']
})
export class AProductHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
