/*******************************************************************************
  설  명 : 상품 찾기 컴포넌트
  작성일 : 2019-12-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';

import { AProductService } from '@admin/service/product.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-find',
  templateUrl: './product-find.component.html',
  styleUrls: ['./product-find.component.scss']
})
export class ACProductFindComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public customer_seq: any = '';  // 거래처 코드 : 할인율 계산을 위해
  public project_seq: any = '';   // 프로젝트 코드 : 할인율 계산을 위해
  public mem_no: any = '';        // 회원번호 : 할인율 계산을 위해

  public set_yn: boolean = true; // 세트 상품 표시 여부 true , false = 표시안함
  public part_yn: boolean = false; // 부속 상품만 가져올지 여부 true, false = 전체 상품 가져오기
  public part_view_yn: boolean = false; // 부속 상품도 같이 가져올지 여부 true, false = 부속 상품 빼고 가져오기

  public productList: any = [];

  public selectedCategory: number = 0;     // 선택된 카테고리 index
  public selectedSubCategory: number = 0;  // 선택된 카테고리 서브 카테고리 index

  public baseURL: any = config.baseUrl;

  public searchText: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private aProductService: AProductService
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 상품 리스트 가져오기
    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 카테고리별 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductList() {
    let params: any = {
      set_yn: this.set_yn,
      part_yn: this.part_yn,
      part_include: false,
      customer_seq: this.customer_seq,
      project_seq: this.project_seq,
      mem_no: this.mem_no,
      part_view_yn: this.part_view_yn
    };

    this.aProductService.getCategoryProductList( params ).then( response => {
      // console.log( response );

      if( response.ResultCode ) {
        this.productList = response.data;

      } else {
        this.productList = [];

        this.toastrService.error( response.ResultMessage, '상품 가져오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '상품 가져오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 선택 시 값 초기화
    입력값 : 선택된 카테고리 index
    리턴값 : 없음
  *******************************************************************************/
  selectCategory( index ) {
    this.selectedCategory = index;     // 선택된 카테고리 index
    this.selectedSubCategory = 0;  // 선택된 카테고리 서브 카테고리 index
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 결과 돌려주기
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  selectProduct( product ) {
    this.activeModal.close( product );
  }

  /*******************************************************************************
    설  명 : 카테고리에 상품 검색갯수가 있는지 확인
    입력값 : 카테고리
    리턴값 : 없음
  *******************************************************************************/
  getCount( nodes: any ) {
    let count: number = 0;

    if( typeof nodes.sub !== 'undefined' ) {
      for( let item of nodes.sub ) {
        let tmp = this.getSubCount( item );

        count += tmp;
      }
    }

    return count;
  }

  /*******************************************************************************
    설  명 : 카테고리에 상품 검색갯수가 있는지 확인
    입력값 : 카테고리
    리턴값 : 없음
  *******************************************************************************/
  getSubCount( nodes: any ) {
    let count: number = 0;

    if( typeof nodes.product !== 'undefined' ) {
      for( let product of nodes.product ) {
        if( product.name.indexOf( this.searchText ) > -1 ) count++;
      }
    }

    return count;
  }

}
