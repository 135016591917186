/*******************************************************************************
  설  명 : 회원 환불신청 컴포넌트
  작성일 : 2020-08-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { AApplicationService } from '@admin/service/application.service';
import { ARefundService } from '@admin/service/refund.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-order-refund',
  templateUrl: './member-order-refund.component.html',
  styleUrls: ['./member-order-refund.component.scss']
})
export class ACMemberOrderRefundComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() mem_no: any;

  selectedRows: number = 0;
  refundList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aApplicationService: AApplicationService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    private aRefundService: ARefundService,
  ) {
    this.columnDefs = [
      {headerName: '환불번호', field: 'seq', cellClass: 'cp center', width: 80 },
      {headerName: '요청일', field: 'request_date', cellClass: 'cp center', width: 120 },
      {headerName: '구분', field: 'request_gbn', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.request_gbn == 'C') return '<span class="badge badge-warning f12 fn">취소</span>';
          else return '<span class="badge badge-danger f12 fn">환불</span>';
        }
      },
      {headerName: '회원정보', field: 'name', cellClass: 'cp', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.name + '(' + params.data.id + ')';
        }
      },
      {headerName: '환불금액', field: 'refund_app_amt', width: 100, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.refund_app_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '환불은행', field: 'refund_bank', cellClass: 'cp', width: 100 },
      {headerName: '환불계좌', field: 'refund_account', cellClass: 'cp', width: 120 },
      {headerName: '환불계좌주', field: 'refund_depositary', cellClass: 'cp', width: 100 },
      {headerName: '요청메모', field: 'refund_memo', cellClass: 'cp', width: 200 },
      {headerName: '상태', field: 'refund_status', cellClass: 'cp center', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.refund_status == '0001' ) return '<span class="badge badge-secondary f12 fn">' + params.data.refund_status_name + '</span>';
          else if( params.data.refund_status == '0002' ) return '<span class="badge badge-success f12 fn">' + params.data.refund_status_name + '</span>';
          else if( params.data.refund_status == '0004' ) return '<span class="badge badge-primary f12 fn">' + params.data.refund_status_name + '</span>';
          else return '<span class="badge badge-danger f12 fn">' + params.data.refund_status_name + '</span>';
        }
      },
      {headerName: '취소수량', field: 'refund_qty', cellClass: 'cp right', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.refund_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '환불신청금액', field: 'amt', cellClass: 'cp right', width: 120, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '취소부가세', field: 'vat', cellClass: 'cp right', width: 120, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.vat);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      }
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getRefundList();
  }

   /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /* 환불 리스트 */
  getRefundList() {
    const search = { mem_no: this.mem_no };

    this.aRefundService.getRefundList( search ).then( response => {
      if( response.ResultCode ) {
        this.refundList = response.data;
      } else {
        this.refundList = [];
      }
    });
  }

}
