import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ACarrierService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 캐리어 정보 가져오기
  getCarrierList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'carrier',
        version: '1.0',
        action: 'getCarrierList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 캐리어 카테고리 정보 가져오기
  getCarrierCategory(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'carrier',
        version: '1.0',
        action: 'getCarrierCategory'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 캐리어 정보 가져오기
  getCarrierInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'carrier',
        version: '1.0',
        action: 'getCarrierInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 캐리어 정보 저장하기
  setCarrier( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'carrier',
        version: '1.0',
        action: 'setCarrier'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 삭제하기
  deleteCarrier( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'carrier',
        version: '1.0',
        action: 'deleteCarrier'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
