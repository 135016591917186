import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AgGridService } from '@admin/service/ag-grid.service';
import { ACommonService } from '@admin/service/common.service';

@Component({
  selector: 'btn-ag-grid-save',
  templateUrl: './ag-grid-save.component.html',
  styleUrls: ['./ag-grid-save.component.scss']
})
export class AgGridSaveComponent implements OnInit {

  @Input() gridColumnsApi: any;
  @Input() gridNumber: number;
  @Input() btnGroup: number;

  constructor(
    private toastrService: ToastrService,
    private agGridService: AgGridService,
    private aCommonService: ACommonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.gridLoad();
  }

  // 저장된 그리드를 가져온다.
  gridLoad() {
    this.agGridService.getGridInfo( this.aCommonService.menuseq ).then( response => {
      if( response.ResultCode ) {
        setTimeout (() => {
          for( let index in response.data ) {
            // 그리드 동기화
            if( response.data[index].grid_num == this.gridNumber ) {
              let json = $.parseJSON( response.data[index].json );
              let moveCol = [];

              // 컬럼 순서 저장
              for( let i in json ) {
                moveCol.push( json[i].field );
              }

              // 컬럼 순서 변경
              try {
                if( typeof this.gridColumnsApi !== 'undefined' ) {
                  if( typeof this.gridColumnsApi.moveColumns !== 'undefined' ) {
                    this.gridColumnsApi.moveColumns( moveCol, 0 );
                  }

                  // 컬럼 사이즈 조정
                  for( let i in json ) this.gridColumnsApi.setColumnWidth( json[i].field, json[i].actualWidth );
                }
              } catch(e) {
                console.log( e, this.gridColumnsApi, moveCol );
              }
              break;
            }
          }
        });
      }
    });
  }

  // 그리드 정보를 저장한다.
  gridSave() {
    if( typeof this.gridColumnsApi == 'undefined' ) {
      this.toastrService.error( '그리드 컬럼 API가 연결되지 않았습니다. 해당 값을 먼저 선언하세요.', '');
      return false;
    } else {
      let saveJSON = [];

      for( let index in this.gridColumnsApi.columnController.allDisplayedColumns ) {
        let column = this.gridColumnsApi.columnController.allDisplayedColumns[index];

        let json = { actualWidth: column.actualWidth, field: column.colId };

        saveJSON.push( json );
      }

      this.agGridService.setGridInfo(
        this.aCommonService.menuseq,
        this.gridNumber,
        JSON.stringify(saveJSON)
      ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( '그리드 정보를 성공적으로 저장하였습니다.', '');
          return true;
        } else {
          this.toastrService.error( '그리드 정보를 저장하는데 실패하였습니다.', '');
          return false;
        }
      });
    }
  }

}
