import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ACommonService {

  // 메뉴 번호
  public menuseq: number;

  // 메뉴 리스트
  public menuList : any = [];

  public menuSmall$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public classNameH100$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  
  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 메뉴 리스트 가져오기
  getMenuList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'common',
        version: '1.0',
        action: 'getMenuList'
      }, {
      }
    ).then( response => {
      if( response['ResultCode'] == true ) {
        this.menuList = Object.values( response['data'] );
      } else {
        this.menuList = [];
      }

      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 리스트 가져오기
  getCommonGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonGroupList'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 상세 가져오기
  getCommonGroupView(seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonGroupView'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 추가하기(POST방식)
  setAddCommonGroupCode( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setAddCommonGroupCode'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  deleteCommonGroup( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'deleteCommonGroup'
      }, {
        seq: form.controls.seq.value
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 삭제하기
  setCommonGroupDelete(seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setCommonGroupDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonList( seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonListCode'
      }, {
        code: code
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 상세 가져오기
  getCommonView(seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonView'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 추가하기(POST방식)
  setAddCommonCode(form): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setAddCommonCode'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 삭제하기
  setCommonDelete(seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setCommonDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
