/*******************************************************************************
  설  명 : 프로젝트 관리
  작성일 : 2019-09-12
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { CustomValidator } from '@app/service/custom.validators';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AProjectService } from '@admin/service/project.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AProjectDetailComponent } from './detail/detail.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

interface SearchType {
  searchGroup: any;
  searchText: any;
  type: any;
  status: any;
  sdate: any;
  edate: any;
  dateType: any;
  term: any;
}

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class AProjectComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 검색 필드
  public search: any = {
    searchGroup: 'name',
    searchText: '',
    type: '',
    status: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    dateType: '',
    term: ''
  }

  public projectYearList: any = [];
  public projectMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  dateModel: NgbDateStruct;

  selectedRows: number = 0;
  projectList: any = [];
  typeList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aProjectService: AProjectService,
    private calendar: NgbCalendar,
    private aCommonService: ACommonService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {headerName: '프로젝트 정보',
        children:[
          {headerName: '선택', field: 'seq', width: 80, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
          {headerName: '구분', field: 'type', width: 100, cellClass: 'cp center' },
          {headerName: '프로젝트명', field: 'name', cellClass: 'cp' },
          {headerName: '시작일', field: 'start_date', width: 100, cellClass: 'cp center' },
          {headerName: '종료일', field: 'end_date', width: 100, cellClass: 'cp center' },
          {headerName: '출고예정일', field: 'schedule_date', width: 100, cellClass: 'cp center' },
          {headerName: '시간', field: 'time', width: 80, cellClass: 'cp center' },
          {headerName: '장소', field: 'place', cellClass: 'cp' },
          {headerName: '우편번호', field: 'zipcode', width: 106, cellClass: 'cp center' },
          {headerName: '주소', field: 'address', cellClass: 'cp' },
          {headerName: '상세주소', field: 'address_detail', cellClass: 'cp' },
          {headerName: '링크 주소', field: 'link', cellClass: 'cp' },
          {headerName: '개요', field: 'outline', cellClass: 'cp' }
        ]
      },
      {headerName: '주관사명',
        children: [
          {headerName: '주관사명', field: 'supervisor', cellClass: 'cp' },
          {headerName: '담당자명', field: 's_person_name', cellClass: 'cp center' },
          {headerName: '전화번호', field: 's_person_tel', cellClass: 'cp center' },
          {headerName: '핸드폰', field: 's_person_HP', cellClass: 'cp center' },
          {headerName: '이메일', field: 's_person_email', cellClass: 'cp' }
        ]
      },
      {headerName: '주최사',
        children: [
          {headerName: '주최사', field: 'organiser', cellClass: 'cp' },
          {headerName: '담당자명', field: 'o_person_name', cellClass: 'cp center' },
          {headerName: '전화번호', field: 'o_person_tel', cellClass: 'cp center' },
          {headerName: '핸드폰', field: 'o_person_hp', cellClass: 'cp center' },
          {headerName: '이메일', field: 'o_person_email', cellClass: 'cp' },
        ]
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    this.setYearList();

    // 프로젝트 구분 가져오기
    await this.getCommonList();

    // 프로젝트 정보가져오기
    this.getProjectList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.projectYearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 프로젝트 구분 가져오기
    await this.aCommonService.getCommonList(12).then( response => {
      this.typeList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    this.aProjectService.getProjectList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.projectList = response.data;
      } else {
        this.projectList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 프로젝트 추가/수정 모달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  projectAdd( seq ) {
    const modalRef = this.modalService.open(AProjectDetailComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      this.getProjectList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 그리드 셀 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.projectAdd( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    // 검색 필드
    this.search = {
      searchGroup: 'name',
      searchText: '',
      type: '',
      status: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      dateType: '',
      term: ''
    }

    this.getProjectList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProjectList();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.sdate = this.dateModel;
    this.search.edate = this.calendar.getToday();

    this.getProjectList();
  }
}
