import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { APaymentService } from '@admin/service/payment.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ACustomerService } from '@app/admin/service/customer.service';
import { ACCustomerFindComponent } from '@app/admin/component/customer-find/customer-find.component';

@Component({
  selector: 'app-parttime-add',
  templateUrl: './parttime-add.component.html',
  styleUrls: ['./parttime-add.component.scss']
})
export class ParttimeAddComponent implements OnInit {
/*******************************************************************************
  설명 : 전역 변수 선언부
*******************************************************************************/
  public seq: string; // 0 = 추가, 0 != 수정
  public title: string = '추가';
  public selectedItem: any;

  public formAdd: FormGroup;
  public formErrors = {};

  public search: any = {
    searchText: '',
    type: ''
  };

  private dateModel: NgbDateStruct = this.utilService.getDate('');

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq: ['', [] ],
      name: ['', [] ],
      hp: ['', [] ],
      birth_date: [this.dateModel, [] ],
      account_no: ['', [] ],
      memo: ['', [] ]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aPaymentService: APaymentService,
    public authService: AuthService,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    this.buildForm();
  }
  
  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if ( this.seq == '0' ) {
      this.title = '추가';
   
    } else {
      this.title = '수정';
      this.formAdd.patchValue(this.selectedItem)

      let dateModel: NgbDateStruct = this.utilService.getDate(this.selectedItem.birth_date);

      this.formAdd.patchValue({
        birth_date: dateModel,
      })
    }
  }
  
  /*******************************************************************************
    설  명 : 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    this.formAdd.patchValue({
      birth_date: this.ngbDateParserFormatter.format( this.formAdd.value.birth_date ),
    })

    if (this.formAdd.valid) {
      this.aPaymentService.setPartTimeMember(this.formAdd).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '일용직관리 저장');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '일용직관리 저장');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if ( confirm('선택하신 일용직관리 내역을 삭제하시겠습니까?') ) {
      this.aPaymentService.delPartTimeMember( this.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.resultMessage, '일용직관리 삭제');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.resultMessage, '일용직관리 삭제');
        }
      });
    }
  }
 
}
  