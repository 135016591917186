import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { AStockService } from '@admin/service/stock.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-barcode-history',
  templateUrl: './barcode-history.component.html',
  styleUrls: ['./barcode-history.component.scss']
})
export class ABarcodeHistoryComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() product_seq: any;
  @Input() seq: any;

  barcodeHistoryList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activeModal: NgbActiveModal,
    private aStockService: AStockService,
    private agGridHtmlComponent: AgGridHtmlComponent
  ) {
    this.columnDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', hide: true },
      { headerName: '바코드 발행번호', field: 'barcode', cellClass: 'cp left', width: 130 },
      { headerName: '입출고구분', field: 'inout_gbn_name', cellClass: 'cp left', width: 110 },
      { headerName: '창고', field: 'warehouse_name', cellClass: 'cp left', width: 110 },
      { headerName: '입고일자', field: 'inout_date', cellClass: 'cp left', width: 100 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getBarcodeStockList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 바코드 이력을 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBarcodeStockList() {
    console.log(this.product_seq);
    console.log(this.seq);
    this.aStockService.getBarcodeStockList( {product_seq: this.product_seq, product_barcode_seq: this.seq} ).then( response => {
      if( response.ResultCode ) {
        this.barcodeHistoryList = response.data;
      } else {
        this.barcodeHistoryList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 창닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }
  
}
