/*******************************************************************************
  설  명 : SMS 발송양식 관리
  작성일 : 2019-09-18
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { ASMSService } from '@admin/service/sms.service';

import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import * as $ from 'jquery';

@Component({
  selector: 'app-sms-form',
  templateUrl: './sms-form.component.html',
  styleUrls: ['./sms-form.component.scss']
})
export class ASmsFormComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 검색 필드
  public search = {
    searchText: '',
  }

  public form: FormGroup;
  public formErrors = {
    contents: ''
  };

  public autoList: any = [];
  public sendList: any = [];

  // 그리드 관련 선언
  selectedRows: number = 0;
  smsList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  /*******************************************************************************
    설  명 : SMS 입력폼 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      auto_seq:["", [] ],
      contents:["", [Validators.required, Validators.maxLength(80)] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private aCommonService: ACommonService,
    private toastrService: ToastrService,
    private aSMSService: ASMSService,
    public authService: AuthService
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '번호', field: 'seq', cellClass: 'cp center', width:70 },
      {headerName: '자동발송', field: 'common_name', cellClass: 'cp center', width:200 },
      {headerName: '제목', field: 'title', cellClass: 'cp', width:300 },
      {headerName: '발송내용', field: 'contents', cellClass: 'cp', width:500 },
      {headerName: '등록일', field: 'write_date', cellClass: 'cp center', width:150 },
      {headerName: '자동발송 코드', field: 'auto_seq', hide:true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    console.log( this.authService.currAuth );
    this.getAutoSMS();

    this.getSMSFormList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.form.patchValue( $event.data );
  }

  /*******************************************************************************
    설  명 : 자동 발송 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAutoSMS() {
    this.autoList = [];
    this.autoList.push({title: '자동발송 안함', value:''});

    this.aCommonService.getCommonList(38).then( response => {
      for( const index in response.data ) {
        this.autoList.push({
          title: response.data[index].common_name,
          value: response.data[index].seq
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : SMS 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSMSFormList() {
    this.aSMSService.getSMSFormList( this.search ).then( response => {
      this.smsList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : SMS 발송양식 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aSMSService.setSMSForm(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getSMSFormList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 한글 포함 문자열 갯수 처리
    입력값 : 문자열
    리턴값 : 문자수
  *******************************************************************************/
  getTextLength(str) {
    var len = 0;
    for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length == 6) {
            len++;
        }
        len++;
    }
    return len;
  }

  /*******************************************************************************
    설  명 : SMS 발송양식 등록 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addSMSForm() {
    this.form.patchValue({
      seq: '',
      title: '',
      auto_seq: '',
      contents: ''
    });

    $("#title").focus();
  }

  /*******************************************************************************
    설  명 : SMS 발송양식 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delSMSForm() {
    if( this.form.controls.seq.value == '' || this.form.controls.seq.value == null ) return false;

    if( confirm("선택하신 SMS 발송 양식을 삭제하시겠습니까?") ) {
      this.aSMSService.delSMSForm( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getSMSFormList();

          this.addSMSForm();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 검색 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search.searchText = '';

    this.getSMSFormList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSMSFormList();
    }
  }
}
