import { NgModule } from '@angular/core';
import {
  Router, Routes, RouterModule,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { Location } from '@angular/common';

/* 관리자 */
import { ADashboardComponent } from '@admin/page/dashboard/dashboard.component';
import { ALoginComponent } from '@admin/page/member/login/login.component';
import { AManagementComponent } from '@admin/page/board/management/management.component';
import { ABoardComponent } from '@admin/page/board/board/board.component';
import { ABoardViewComponent } from '@admin/page/board/board/view/view.component';
import { ABoardAddComponent } from '@admin/page/board/board/add/add.component';
import { ABoardReplyComponent } from '@admin/page/board/board/reply/reply.component';

import { ACommonCodeComponent } from '@admin/page/system/common/common.component';
import { AGroupComponent } from '@admin/page/system/group/group.component';
import { APositionComponent } from '@admin/page/system/position/position.component';
import { AEmployeeComponent } from '@admin/page/system/employee/employee.component';
import { AEmployeeModifyComponent } from '@admin/page/system/employee/employee-modify/employee-modify.component';
import { AAuthGroupComponent } from '@admin/page/system/auth-group/auth-group.component';
import { AAccessIpComponent } from '@admin/page/system/access-ip/access-ip.component';

import { ACompanyComponent } from '@admin/page/basic/company/company.component';
import { ACustomerComponent } from '@admin/page/basic/customer/customer.component';
import { ACustomerDetailComponent } from '@admin/page/basic/customer/detail/detail.component';
import { AProjectComponent } from '@admin/page/basic/project/project.component';
import { AProjectDetailComponent } from '@admin/page/basic/project/detail/detail.component';
import { ACarrierComponent } from '@admin/page/basic/carrier/carrier.component';
import { AWarehouseComponent } from '@admin/page/basic/warehouse/warehouse.component';

import { AMemberComponent } from '@admin/page/member/member.component';
import { AMemberDetailComponent } from '@admin/page/member/detail/detail.component';
import { ASmsComponent } from '@admin/page/member/sms/sms.component';
import { ASmsFormComponent } from '@admin/page/member/sms-form/sms-form.component';
import { AEmailComponent } from '@admin/page/member/email/email.component';

import { AProductComponent } from '@admin/page/product/product.component';
import { AProductDetailComponent } from '@admin/page/product/detail/detail.component';
import { AProductAddComponent } from '@admin/page/product/add/add.component';
import { ABarcodeComponent } from '@admin/page/product/print/barcode/barcode.component';
import { ACategoryComponent } from '@admin/page/product/category/category.component';
import { ADiscountComponent } from '@admin/page/product/discount/discount.component';
import { ADiscountCompanyComponent } from '@admin/page/product/discount-company/discount-company.component';

import { AOrderComponent } from '@admin/page/stock/order/order.component';
import { AOrderDetailComponent } from '@admin/page/stock/order/detail/detail.component';
import { AOrderEstimateComponent } from '@admin/page/order/order-estimate/order-estimate.component';
import { AOrderEstimateDetailComponent } from '@admin/page/order/order-estimate/order-estimate-detail/order-estimate-detail.component';
import { AStockComponent } from '@admin/page/stock/stock.component';
import { AStockWarehouseComponent } from '@admin/page/stock/warehouse/warehouse.component';
import { ATaxInvoiceComponent } from '@admin/page/tax/tax.component';

import { AApplicationComponent } from '@admin/page/order/application/application.component';
import { AApplicationDetailComponent } from '@admin/page/order/detail/detail.component';
import { AApplicationInfoComponent } from '@admin/page/order/application/info/info.component';
import { ATaxComponent } from '@admin/page/order/tax/tax.component';

import { ADeliveryOrderComponent } from '@admin/page/process/delivery-order/delivery-order.component';
import { ADeliveryOrderAddComponent } from '@admin/page/process/delivery-order/add/add.component';
import { ADeliveryAddComponent } from '@admin/page/process/delivery-add/delivery-add.component';
import { APlaceComponent } from '@admin/page/process/place/place.component';
import { AWarehousingComponent } from '@admin/page/process/warehousing/warehousing.component';
import { AWithdrawPrintComponent } from '@admin/page/process/warehousing/withdraw-print/withdraw-print.component';
import { AProductHistoryComponent } from '@admin/page/process/product-history/product-history.component';

import { APaymentComponent } from '@admin/page/payment/payment.component';
import { APaymentItemComponent } from '@admin/page/payment/item/item.component';
import { APaymentStatisticComponent } from '@admin/page/payment/statistic/statistic.component';
import { ParttimeComponent } from '@admin/page/payment/parttime/parttime.component';

import { ARefundComponent } from '@admin/page/order/refund/refund.component';
import { APaymentHistoryComponent } from '@admin/page/order/payment/history/history.component';

import { AOrderPrintEstimateComponent } from '@admin/page/order/print/estimate/estimate.component';
import { AutoDepositComponent } from '@admin/page/order/auto-deposit/auto-deposit.component';

import { ProjectSalesComponent } from '@admin/page/statistic/project-sales/project-sales.component';
import { MonthSalesComponent } from '@admin/page/statistic/month-sales/month-sales.component';
import { ReceivableComponent } from '@admin/page/statistic/receivable/receivable.component';
import { PurchaseComponent } from '@admin/page/statistic/purchase/purchase.component';

import { AStockOutOrderPrintComponent } from '@admin/page/process/delivery-add/stock-out-order-print/stock-out-order-print.component';
import { AProjectOrderPrintComponent } from '@admin/page/process/delivery-add/order-print/order-print.component';
import { AOrderMemoPrintComponent } from '@admin/page/process/delivery-add/order-memo-print/order-memo-print.component';

/* 공통 서비스 모듈 */
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';
import { MemberService } from '@app/service/member.service';
import { ACommonService } from '@admin/service/common.service';
import { AuthService } from '@app/service/auth.service';

const routes: Routes = [

  /* 관리자 path */
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'login', component: ALoginComponent },
  { path: 'dashboard', component: ADashboardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'board', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'list', component: ABoardComponent },
      { path: 'management', component: AManagementComponent },
      { path: 'view', component: ABoardViewComponent },
      { path: 'add', component: ABoardAddComponent },
      { path: 'reply', component: ABoardReplyComponent },
    ]
  },
  { path: 'basic', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'company', component: ACompanyComponent },
      { path: 'customer', component: ACustomerComponent },
      { path: 'customer/detail', component: ACustomerDetailComponent },
      { path: 'project', component: AProjectComponent },
      { path: 'project/detail', component: AProjectDetailComponent },
      { path: 'carrier', component: ACarrierComponent },
      { path: 'warehouse', component: AWarehouseComponent },
    ]
  },
  { path: 'member', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'list', component: AMemberComponent },
      { path: 'list/detail', component: AMemberDetailComponent },
      { path: 'sms', component: ASmsComponent },
      { path: 'form/sms', component: ASmsFormComponent },
      { path: 'email', component: AEmailComponent },
    ]
  },
  { path: 'product', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'list', component: AProductComponent },
      { path: 'list/add', component: AProductAddComponent },
      { path: 'print/barcode', component: ABarcodeComponent },
      { path: 'detail', component: AProductDetailComponent },
      { path: 'category', component: ACategoryComponent },
      { path: 'discount', component: ADiscountComponent },
      { path: 'company/discount', component: ADiscountCompanyComponent },
    ]
  },
  { path: 'stock', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'product', component: AStockComponent },
      { path: 'order', component: AOrderComponent },
      { path: 'order/detail', component: AOrderDetailComponent },
      { path: 'warehouse', component: AStockWarehouseComponent },
    ]
  },
  { path: 'order', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'list', component: AOrderComponent },
      { path: 'detail', component: AOrderDetailComponent },
      { path: 'application', component: AApplicationComponent },
      { path: 'application/detail', component: AApplicationDetailComponent },
      { path: 'application/info', component: AApplicationInfoComponent },
      { path: 'tax', component: ATaxComponent },
      { path: 'tax/invoice', component: ATaxInvoiceComponent },
      { path: 'refund', component: ARefundComponent },
      { path: 'payment/history', component: APaymentHistoryComponent },
      { path: 'print/estimate', component: AOrderPrintEstimateComponent },
      { path: 'estimate', component: AOrderEstimateComponent },
      { path: 'estimate/detail', component: AOrderEstimateDetailComponent },
      { path: 'deposit/auto', component: AutoDepositComponent },
    ]
  },
  { path: 'process', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'delivery/add', component: ADeliveryAddComponent },
      { path: 'delivery/add/print', component: AStockOutOrderPrintComponent },
      { path: 'delivery/add/print1', component: AProjectOrderPrintComponent },
      { path: 'delivery/add/print2', component: AOrderMemoPrintComponent },
      { path: 'delivery/order', component: ADeliveryOrderComponent },
      { path: 'delivery/order/add', component: ADeliveryOrderAddComponent },
      { path: 'place', component: APlaceComponent },
      { path: 'warehousing', component: AWarehousingComponent },
      { path: 'warehousing/print', component: AWithdrawPrintComponent },
      { path: 'product/history', component: AProductHistoryComponent },
    ]
  },
  { path: 'payment', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'item', component: APaymentItemComponent },
      { path: 'list', component: APaymentComponent },
      { path: 'statistic', component: APaymentStatisticComponent },
      { path: 'parttime', component: ParttimeComponent },
    ]
  },
  { path: 'statistic', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'sales/project', component: ProjectSalesComponent },
      { path: 'sales/month', component: MonthSalesComponent },
      { path: 'receivable', component: ReceivableComponent },
      { path: 'purchase', component: PurchaseComponent },
    ]
  },
  { path: 'system', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'common', component: ACommonCodeComponent },
      { path: 'group', component: AGroupComponent },
      { path: 'position', component: APositionComponent },
      { path: 'employee', component: AEmployeeComponent },
      { path: 'employee/modify', component: AEmployeeModifyComponent },
      { path: 'auth/group', component: AAuthGroupComponent },
      { path: 'access/ip', component: AAccessIpComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  deviceInfo = null;

  constructor(
    private router: Router,
    public location: Location,
    private aCommonService: ACommonService,
    private authService: AuthService
  ) {
    // 로딩 처리
    this.router.events.subscribe(async (event: Event) => {
      switch( true ) {
        case event instanceof NavigationStart:
          break;

        case event instanceof NavigationEnd:
          await this.authService.getPageAuth();

          this.aCommonService.menuseq = this.authService.currAuth.seq;

          if( this.location.path().indexOf('/order/application/info') > -1 ) {
            this.aCommonService.classNameH100$.next( false );
          } else {
            this.aCommonService.classNameH100$.next( true );
          }

          // 페이지 전환 시 최 상단으로 자동 스크롤
          $(window).scrollTop(0);
          break;

        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          break;

        default: {
          break;
        }
      }
    });
  };
}
