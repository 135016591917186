/*******************************************************************************
  설  명 : 공통코드 관리
  작성일 : 2019-09-21
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ACommonService } from '@admin/service/common.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import { ACommonCodeGroupAddComponent } from './group-add/group-add.component';
import { ACommonCodeAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-common-code',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class ACommonCodeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  selectedItem: any = {
    seq: ''
  };

  // 공통코드 그룹 그리드 관련 선언
  selectedRows: number = 0;
  commonGroupList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 공통코드 그리드 관련 선언
  selectedRowsCommon: number = 0;
  commonList: any = [];

  gridApiCommon: any;
  gridColumnApiCommon: any;
  columnDefsCommon: any;
  defaultColDefCommon: any;
  domLayoutCommon: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  // 그리드 이미지 처리
  frameworkComponentsCommon = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private commonService: ACommonService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    public authService: AuthService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '코드', field: 'seq', hide: true },
      {headerName: '코드', field: 'group_cd', hide: true },
      {headerName: '코드', field: 'code', cellClass: 'cp center', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.group_cd == 'S' )
            return '<span class="badge badge-success f12 fn" style="width:50px;">' + params.data.code + '</span>';
          else
            return '<span class="badge badge-secondary f12 fn" style="width:50px;">' + params.data.code + '</span>';
        }
      },
      {headerName: '그룹명', field: 'name', cellClass: 'cp center' },
    ];

    // ag grid 컬럼 선언
    this.columnDefsCommon = [
      {headerName: '코드', field: 'common_code', cellClass: 'cp center' },
      {headerName: '코드명', field: 'common_name', cellClass: 'cp center'},
      {headerName: '코드명(영문)', field: 'common_ename', cellClass: 'cp center'},
      {headerName: '사용여부', field: 'use_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == 1 )
            return '<span class="badge badge-primary f12 fn">사용</span>';
          else
            return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      {headerName: '작성자', field: 'writer', cellClass: 'cp center' },
      {headerName: '작성일시', field: 'write_date', cellClass: 'cp center' },
      {headerName: '코드', field: 'seq', hide: true },
      {headerName: '그룹코드', field: 'code', hide: true },
      {headerName: '그룹코드명', field: 'name', hide: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: false
    };

    // default 컬럼 옵션
    this.defaultColDefCommon = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonGroupList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.selectedItem = $event.data;

    this.getCommonList();
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 공통코드 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( $event ) {
    this.addCommonGroup( $event.data );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCommon(params) {
    this.gridApiCommon = params.api;
    this.gridColumnApiCommon = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedCommon($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRowsCommon = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClickedCommon($event) {
    this.addCommon( $event.data );
  }

  /*******************************************************************************
    설  명 : 그룹 코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonGroupList() {
    this.commonService.getCommonGroupList().then( response => {
      this.commonGroupList = response.data;

      if( this.selectedItem.seq == '' ) {
        this.selectedItem = this.commonGroupList[0];
      }

      this.getCommonList();
    });
  }


  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonService.getCommonList( this.selectedItem.seq ).then( response => {
      this.commonList = response.data;

      let node = this.gridApi.getSelectedNodes();

      if( node.length < 1 ) {
        let index = 0;
        this.gridApi.forEachNode(function(node) {
          if (index++ == 0 ) {
            node.setSelected(true);
          }
        });
      }
    });
  }


  /*******************************************************************************
    설  명 : 그룹 코드 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCommonGroup( item ) {
    const modalRef = this.modalService.open(ACommonCodeGroupAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      // 계좌 정보 가져오기
      this.getCommonGroupList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 코드 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCommon( item ) {
    const modalRef = this.modalService.open(ACommonCodeAddComponent, options);

    item.group_seq = this.selectedItem.seq;
    item.code = this.selectedItem.code;
    item.name = this.selectedItem.name;

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      // 계좌 정보 가져오기
      this.getCommonList();
    }, (reason) => {
    });
  }

}
