/*******************************************************************************
  설  명 : 수입지출현황 > 월별 합계표
  작성일 : 2023-02-07
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/service/auth.service';
import { APaymentService } from '@admin/service/payment.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import * as moment from 'moment';

@Component({
  selector: 'app-statistic-bystatementlist',
  templateUrl: './statistic-bystatementlist.component.html',
  styleUrls: ['./statistic-bystatementlist.component.scss']
})
export class StatisticBystatementlistComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 검색 조건
  public search: any = {
    nowYear: moment().format('YYYY'),
  };

  // 지출 내역
  public paymentList: any = [];
  public pinnedBottomRowData = [];

  // 공통코드 그룹 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    public paymentService: APaymentService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '항목', field: 'name', width: 200, cellClass: 'cp left'},
      {headerName: '2023년', field: 'thisYear',
        children:[
          {headerName: '총금액(공급가액)', field: 'thisYear', width: 200, cellClass: 'cp right',
            valueFormatter: currencyFormatter, valueGetter: numberGetter
          }
        ]
      },
      {headerName: '2022년', field: 'lastYear',
        children: [
          {headerName: '총금액(공급가액)', field: 'lastYear', width: 200, cellClass: 'cp right',
            valueFormatter: currencyFormatter, valueGetter: numberGetter
          }
        ]
      },
      {headerName: '증감률(%)', field: 'changeRate', cellClass: 'cp right', width: 200, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if ( params.data.changeRate == null ) {
            return 0;
          } else {
            return params.data.changeRate;
          }
        },
        cellStyle: function(params) {
          if ( params.data.changeRate < 0 ) {
            return {color: 'red'};
          } else if ( params.data.changeRate > 0 ) {
            return {color: 'blue'};
          } else {
            return null;
          }
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      return parseInt(params.data[ params.column.colId ], 10);
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getPaymentYearAccountList();
  }

  /*******************************************************************************
    설  명 : 월별 매출현황 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentYearAccountList() {
    this.paymentService.getPaymentYearAccountList( this.search.nowYear ).then( response => {
      if( response.ResultCode ) {
        this.paymentList = response.data;
        this.calcSum(this.paymentList);
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      nowYear: moment().format('YYYY'),
    };

    let tmp = this.search.nowYear;

    this.columnDefs.forEach(function (colDef) {
      if ( colDef.field == 'thisYear' ) {
        colDef.headerName = tmp + '년';
      } else if ( colDef.field == 'lastYear' ) {
        colDef.headerName = tmp - 1 + '년';
      }
    });
    this.gridApi.setColumnDefs(this.columnDefs);

    this.getPaymentYearAccountList();
  }

  /*******************************************************************************
    설  명 : 이전 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  prevYear() {
    this.search.nowYear = moment( this.search.nowYear + '-01-01' ).add(-1, 'year').format('YYYY');
    let tmp = this.search.nowYear;

    this.columnDefs.forEach(function (colDef) {
      if ( colDef.field == 'thisYear' ) {
        colDef.headerName = tmp + '년';
      } else if ( colDef.field == 'lastYear' ) {
        colDef.headerName = tmp - 1 + '년';
      }
    });
    this.gridApi.setColumnDefs(this.columnDefs);

    this.getPaymentYearAccountList();
  }

  /*******************************************************************************
    설  명 : 다음 년도 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nextYear() {
    if( moment().format('YYYY') !== this.search.nowYear ) {
      this.search.nowYear = moment( this.search.nowYear + '-01-01' ).add(1, 'year').format('YYYY');
      let tmp = this.search.nowYear;

      this.columnDefs.forEach(function (colDef) {
        if ( colDef.field == 'thisYear' ) {
          colDef.headerName = tmp + '년';
        } else if ( colDef.field == 'lastYear' ) {
          colDef.headerName = tmp - 1 + '년';
        }
      });
      this.gridApi.setColumnDefs(this.columnDefs);

      this.getPaymentYearAccountList();
    }
  }

  /*******************************************************************************
    설  명 : 총합계 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcSum(data) {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    let e = 0;
    let f = 0;

    data.map(row => {
      if ( row.type == '200' ) {
        a += parseInt(row.thisYear);
        b += parseInt(row.lastYear);
        e += parseInt(row.thisYear);
        f += parseInt(row.lastYear);
      } else {
        c += parseInt(row.thisYear);
        d += parseInt(row.lastYear);
        e -= parseInt(row.thisYear);
        f -= parseInt(row.lastYear);
      }
    });
    this.pinnedBottomRowData = [
      {
        name: '총 수입', 
        thisYear: a,
        lastYear: b,
      },
      {
        name: '총 지출', 
        thisYear: c,
        lastYear: d,
      },
      {
        name: '총 합계', 
        thisYear: e,
        lastYear: f,
      },
    ]
  }
}
