import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { MemberService } from '@app/service/member.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-find',
  templateUrl: './member-find.component.html',
  styleUrls: ['./member-find.component.scss']
})
export class MemberFindComponent implements OnInit {
  @Output() emitData = new EventEmitter();

  errorResponse: ApiResponse;
  public searchMemberForm: FormGroup;
  public formErrors = {
    searchText: ''
  };

  public memberList = [];
  public memberIndex: any = -1;

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public memberService: MemberService,
    public authService: AuthService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.searchMemberForm = this.formBuilder.group({
      searchText: ["", [Validators.required]],
    });

    this.searchMemberForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.searchMemberForm, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {

  }

  /*******************************************************************************
    설  명 : 적용하기
  *******************************************************************************/
  submitSelectedMember() {
    if( this.memberIndex == -1 ) {
      this.toastrService.warning( '추가할 회원을 선택하시기 바랍니다.', '');
      return false;
    }

    this.emitData.next( this.memberList[this.memberIndex] );

    this.activeModal.close();
  }

  /*******************************************************************************
    설  명 : 회사검색 처리
  *******************************************************************************/
  getMemberSearchList() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.searchMemberForm, this.formErrors);

    if( this.searchMemberForm.valid ) {
      this.memberList = [];
      this.memberService.getMemberSearchList( this.searchMemberForm ).then( response => {
        if( response.ResultCode ) {
          this.memberList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.searchMemberForm, this.formErrors);
      });
    }
  }

}
