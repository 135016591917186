/*******************************************************************************
 설  명 : 주문관리
 작성일 : 2020-02-18
 작성자 : 송영석
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbDateStruct, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject, BehaviorSubject } from 'rxjs';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AApplicationService } from '@admin/service/application.service';
import { ACustomerService } from '@admin/service/customer.service';
import { AProjectService } from '@admin/service/project.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import { ACProjectFindComponent } from '@admin/component/project-find/project-find.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

import { ISms } from '@admin/component/send-sms/send-sms.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class AApplicationComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedRows: number = 0;

  public applicationList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  applicationList: any = [];
  orderStatusList: any = [];
  customerList: any = [];
  scaleList = [];
  projectList: any = [];
  searchYearList: any = [];
  searchMonthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  widgetData: any = {
    month_application: '',
    month_pay_wait: '',
    month_pay_complete: '',
    month_refund_cancel: '',
  };

  /* 위젯 Parameter */
  public widgetParams = {};

  private dateModel: NgbDateStruct;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  sendList: ISms[] = [];

  search: any = {
    date_group: 'order_date',
    date_type: 'ymd',
    sdate: '',
    edate: '',
    year: moment().format('YYYY'),
    month: '',
    date_index: '',
    project_seq: '',
    project_name: '',
    customer_seq: '',
    customer_name: '',
    status: '',
    searchText: '',
    taxbill_yn: '',
    scale: '',
    pay_method: '',
    charge_status: ''
  }

  private ICustomer: any = {
    seq: '',
    type: '',
    name: '',
    business_no: '',
    business_type: '',
    business_item: '',
    kind: '',
    ceo: '',
    tel: '',
    fax: '',
    manager_id: '',
    charge_hp: '',
    zipcode: '',
    address: '',
    address_detail: '',
    project_name: '',
    write_date: ''
  }

  private IProject: any = {
    seq: '',
    type: '',
    name: '',
    start_date: '',
    end_date: '',
    time: '',
    place: '',
    zipcode: '',
    address: '',
    address_detail: '',
    link: '',
    outline: '',
    supervisor: '',
    s_person_name: '',
    s_person_tel: '',
    s_person_HP: '',
    s_person_email: '',
    organiser: '',
    o_person_name: '',
    o_person_tel: '',
    o_person_HP: '',
    o_person_email: ''
  }

  selectedRow: any = {
    seq: '',
    status: '',
    status_code: '',
    order_datetime: '',
    customer_name: '',
    name: '',
    product_name: '',
    project_name: '',
    address: '',
    place: '',
    set_cnt: '',
    unit_amt: '',
    delivery_amt: '',
    discount: '',
    vat: '',
    total_amt: '',
    pay_amt: '',
    customer: this.ICustomer,
    project: this.IProject
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aApplicationService: AApplicationService,
    private aCustomerService: ACustomerService,
    private aProjectService: AProjectService,
    private calendar: NgbCalendar,
    private aCommonService: ACommonService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {
        headerName: '주문번호',
        field: 'seq',
        cellClass: 'cp right',
        width: 90,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {headerName: '확인', field: 'charge_status', cellClass: 'cp center', width: 70, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.charge_status == '1' ) {
            return '<span class="badge badge-success f11">확인</span>';
          } else {
            return '<span class="badge badge-secondary f11">미확인</span>';
          }
        }
      },
      {headerName: '상태', field: 'status', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.status;
        },
        cellStyle: function(params) {
          if (params.data.status_code == '1000' ) {
            return {color: 'green', backgroundColor: '#eeffee'};
          } else if (params.data.status_code == '2000' ) {
            return {color: 'green', backgroundColor: '#d8f7d2'};
          } else if (params.data.status_code == '3000' || params.data.status_code == '3010' || params.data.status_code == '4000' ) {
            return {color: 'blue', backgroundColor: '#dee7ff'};
          } else if (params.data.status_code == '8000' || params.data.status_code == '8010' || params.data.status_code == '8020' ) {
            return {color: 'red', backgroundColor: '#ffdede'};
          } else if (params.data.status_code == '9000' || params.data.status_code == '9010' || params.data.status_code == '9020' ) {
            return {color: 'orange', backgroundColor: '#fffce5'};
          } else {
            return null;
          }
        }
      },
      {headerName: '주문일자', field: 'order_datetime', width: 130, cellClass: 'cp center' },
      {headerName: '거래처', field: 'customer_name', width: 110, cellClass: 'cp' },
      {headerName: '회원구분', field: '', cellClass: 'cp center', width: 70, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.id ) {
            return '<span class="badge badge-success f11">회원</span>';
          } else {
            return '<span class="badge badge-secondary f11">비회원</span>';
          }
        }
      },
      {headerName: '회원명', field: 'name', cellClass: 'cp', width:200 },
      // {headerName: '상품명', field: 'product_name', cellClass: 'cp', width:150 },
      {headerName: '전시/행사', field: 'project_name', cellClass: 'cp', width:200 },
      // {headerName: '주소', field: 'address', cellClass: 'cp', width:200 },
      {headerName: '장소', field: 'place', cellClass: 'cp', width:150 },
      // {headerName: '세트', field: 'set_cnt', cellClass: 'cp right', width:60 },
      {headerName: '주문금액', field: 'unit_amt', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.unit_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      // {headerName: '배송금액', field: 'delivery_amt', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     var str = String(params.data.delivery_amt);
      //     return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      //   }
      // },
      // {headerName: '할인금액', field: 'discount', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     var str = String(params.data.discount);
      //     return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      //   }
      // },
      {headerName: '부가세', field: 'vat', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.vat);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '금액', field: 'total_amt', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '결제금액', field: 'pay_amt', cellClass: 'cp right', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.pay_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '결제구분', field: 'pay_method', cellClass: 'cp center', width: 120, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.pay_method == 'CARD' )
            return '<span class="badge badge-success f12 fn">신용카드</span>';
          else if( params.data.pay_method == 'BANK' )
            return '<span class="badge badge-primary f12 fn">실시간계좌이체</span>';
          else if( params.data.pay_method == 'NBANK' )
            return '<span class="badge badge-warning f12 fn">무통장입급</span>';
          else
            return '<span class="badge badge-secondary f12 fn">현금지급</span>';
        }
      },
      {headerName: '세금계산서', field: 'taxbill_yn', cellClass: 'cp center', width: 100,
        cellRenderer: 'agGridHtmlComponent',
          valueGetter: function(params) {
            if ( params.data.pay_method == 'CASH' && params.data.status_code == '3000' ) {
              return '';
            } else if( params.data.taxbill_yn == '1' ) {
              return '<span class="badge badge-success f11">발행</span>';
            } else {
              return '<span class="badge badge-secondary f11">미발행</span>';
            }
          }
      },
      {headerName: '판매구분', field: 'is_order_type', cellClass: 'cp center', width: 80,
        cellRenderer: 'agGridHtmlComponent',
          valueGetter: function(params) {
            if( params.data.is_order_type == '1' ) {
              return '<span class="badge badge-success f11">온라인</span>';
            } else {
              return '<span class="badge badge-secondary f11">현장</span>';
            }
          }
      },
      {headerName: 'SMS수신동의', field: 'sms_yn', cellClass: 'cp center', width: 100, cellRenderer: 'agGridHtmlComponent',
          valueGetter: function(params) {
            if( params.data.sms_yn == '1' ) {
              return '<span class="badge badge-primary f11">수신동의</span>';
            } else {
              return '<span class="badge badge-danger f11">수신거부</span>';
            }
          }
      },
      {headerName: '등록자', field: 'writer_name', cellClass: 'cp center', width: 100 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    // 데이터 로딩 시 처리
    let applicationList = this.applicationList$.asObservable();
    applicationList.subscribe( data => {
      if( data.length < 1 ) return false;

      this.applicationList = data;

      this.onCellClicked( {colDef: {field: ''}, data: this.applicationList[0]} );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    // 연도 리스트 가져오기
    // this.getApplicationYearList();

    // 주문상태 공통코드 가져오기
    this.getCommonListCode();

    // 거래처 리스트 가져오기
    this.getCustomerList();

    // 프로젝트 리스트 가져오기
    this.getProjectList();

    this.activatedRoute.queryParams.subscribe(params => {
      this.search.date_group = ( typeof params.date_group == 'undefined' || params.date_group == '' ) ? 'order_date' : params.date_group;
      this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.search.date_index = ( typeof params.date_index == 'undefined' || params.date_index == '' ) ? '' : params.date_index;
      this.search.project_seq = ( typeof params.project_seq == 'undefined' || params.project_seq == '' ) ? '' : params.project_seq;
      this.search.project_name = ( typeof params.project_name == 'undefined' || params.project_name == '') ? '' : params.project_name;
      this.search.customer_seq = ( typeof params.customer_seq == 'undefined' || params.customer_seq == '') ? '' : params.customer_seq;
      this.search.customer_name = ( typeof params.customer_name == 'undefined' || params.customer_name == '') ? '' : params.customer_name;
      this.search.status = ( typeof params.status == 'undefined' || params.status == '') ? '' : params.status;
      this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '') ? '' : params.searchText;
      this.search.taxbill_yn = ( typeof params.taxbill_yn == 'undefined' || params.taxbill_yn == '') ? '' : params.taxbill_yn;

      // 시작날짜
      if( this.search.sdate != '' && this.search.sdate != null ) {
        if( typeof this.search.sdate != 'object' ) {
          this.dateModel = {
            year: parseInt( this.search.sdate.substr( 0, 4 ) ),
            month: parseInt( this.search.sdate.substr( 5, 2 ) ),
            day: parseInt( this.search.sdate.substr( 8, 2 ) )
          };

          this.search.sdate = this.dateModel;
        }
      } else {
        this.search.sdate = null;
      }

      // 종료날짜
      if( this.search.edate != '' && this.search.edate != null ) {
        if( typeof this.search.edate != 'object' ) {
          this.dateModel = {
            year: parseInt( this.search.edate.substr( 0, 4 ) ),
            month: parseInt( this.search.edate.substr( 5, 2 ) ),
            day: parseInt( this.search.edate.substr( 8, 2 ) )
          };

          this.search.edate = this.dateModel;
        }
      } else {
        this.search.edate = null;
      }

      // 주문내역 가져오기
      // this.searchInit();
      this.getApplicationList();
    });

    // 위젯 데이터 가져오기
    // this.getApplicationStatistic();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.searchYearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }

  /*******************************************************************************
    설  명 : 연도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getApplicationYearList() {
    // 매출현황 리스트
    await this.aApplicationService.getApplicationYearList().then( response => {
      if (response.ResultCode) {
        this.searchYearList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문상태 리스트 가져오기
  *******************************************************************************/
  getCommonListCode() {
    this.aCommonService.getCommonListCode( 'ORS' ).then( response => {
      if( response.ResultCode ) {
        this.orderStatusList = response.data;
      }
    });

    // 프로젝트 규모
    this.aCommonService.getCommonListCode('PJS').then( response => {
      if( response.ResultCode ) {
        this.scaleList = response.data;
      } else {
        this.scaleList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  /*******************************************************************************
    설  명 : select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
    changed( item: any ) {
      this.search.project_seq = item.id;
    }
  

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  onSelectionChanged($event) {
    this.sendList = [];
    this.gridApi.getSelectedRows().forEach( row => {
      this.sendList.push({
        mem_no: row.mem_no,
        name: row.name,
        mobile: row.hp,
        id: row.id,
        smsYn: row.sms_yn,
        leavedate: row.leavedate,
      });
    });
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'seq' ) {
      this.applicationInfo( $event.data.seq );
    } else {
      let tmp: any = $event.data;
      tmp.customer = Object.create( this.ICustomer );
      tmp.project = Object.create( this.IProject );

      // 현재 선택된 행 데이터 저장
      this.selectedRow = tmp;

      // 거래처 가져오기
      this.setCustomer();

      // 프로젝트 가져오기
      this.setProject();
    }
  }

  /*******************************************************************************
    설  명 : 셀 더블 클릭 시 데이터 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked( $event ) {
    if ( this.authService.currAuth.auth_modify === '1' ||  this.authService.currAuth.auth_write === '1') {
      if ( $event.colDef.field !== 'seq' ) {

        this.applicationInfo( $event.data.seq );
        /*
        // 주문접수 상태만
        if( $event.data.status_code == '1000' ) {
          this.addApplication( $event.data.seq );

        // 이외의 상태
        } else {
          this.applicationInfo( $event.data.seq );
        }
        */
      }
    }
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 거래처 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomer() {
    for( let item of this.customerList ) {
      if( item.seq == this.selectedRow.customer_seq ) {
        this.selectedRow.customer = item;
        break;
      }
    }
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 프로젝트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProject() {
    for( let item of this.projectList ) {
      if( item.seq == this.selectedRow.project_seq ) {
        this.selectedRow.project = item;
        break;
      }
    }
  }

  /*******************************************************************************
    설  명 : 부스별 신청내역 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOrderPrint() {

    this.findProject();
  }

  /*******************************************************************************
    설  명 : 관리자주문 등록 - 주문접수 상태만
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addApplication(seq) {
    this.search.seq = seq;

    this.router.navigate(
    ['/order/application/info'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세정보 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  applicationInfo(seq) {
    this.search.seq = seq;

    if( this.search.sdate != '' ) {
      this.search.sdate = this.utilService.getDateStr( this.search.sdate );
    }

    if( this.search.edate != '' ) {
      this.search.edate = this.utilService.getDateStr( this.search.edate );
    }

    this.router.navigate(
    ['/order/application/info'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 주문리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApplicationList() {
    this.widgetParams = {sdate: this.search.sdate, edate: this.search.edate};
    this.applicationList = [];

    this.selectedRow = {
      seq: '',
      status: '',
      status_code: '',
      order_datetime: '',
      customer_name: '',
      name: '',
      product_name: '',
      project_name: '',
      address: '',
      place: '',
      set_cnt: '',
      unit_amt: '',
      delivery_amt: '',
      discount: '',
      vat: '',
      total_amt: '',
      pay_amt: '',
      customer: this.ICustomer,
      project: this.IProject
    };

    this.aApplicationService.getApplicationList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.applicationList$.next( response.data );
      }
    }, error => {
      this.toastrService.error( error, '주문내역');
    });

    this.deleteParams();
  }

  /*******************************************************************************
    설  명 : 거래처 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    this.customerList = [];

    this.aCustomerService.getCustomerList({typeSelect: '', kindSelect: '', searchGroup: '', searchText: ''}).then( response => {
      if( response.ResultCode ) {
        this.customerList = response.data;
      }

    }, error => {
      this.toastrService.error( error, '주문내역');
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {


    this.aProjectService.getProjectList({type: '', searchGroup: '', searchText: ''}).then( response => {
      if( response.ResultCode ) {
        this.projectList = [];
        if( response.ResultCode ) {
          this.projectList.push({id: '', text: '프로젝트 선택'});
  
          response.data.forEach( row => {
            this.projectList.push({
              id: row.seq,
              text: row.name
            });
          });
        }
      }
    }, error => {
      this.toastrService.error( error, '주문내역');
    });
  }

  // /* 위젯 데이터 가져오기 */
  // getApplicationStatistic() {
  //   this.aApplicationService.getApplicationStatistic().then( response => {
  //     if ( response.ResultCode ) {
  //       this.widgetData = response.data;
  //     }
  //   });
  // }

  /*******************************************************************************
    설  명 : 프로젝트 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findProject() {
    const modalRef = this.modalService.open(ACProjectFindComponent, optionsLG);

    modalRef.result.then((result) => {
      //this.search.project_seq = result.seq;
      //this.search.project_name = result.name;

      const url = '/process/delivery/add/print1?seq=' + result.seq + '&location=order';
      window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1000, height=800, left=0, top=0' );

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.search.customer_seq = result.seq;
      this.search.customer_name = result.name;

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate('');
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate('');
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      date_group: 'order_date',
      date_type: '',
      sdate: '',
      edate: '',
      year: moment().format('YYYY'),
      month: '',
      date_index: '',
      project_seq: '',
      project_name: '',
      customer_seq: '',
      customer_name: '',
      status: '',
      searchText: '',
      taxbill_yn: '',
      scale: '',
      pay_method: '',
      charge_status: ''
    }

    this.setDate('30');

    this.getApplicationList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getApplicationList();
  }

  /*******************************************************************************
    설  명 : 날짜 검색 클릭 시 처리
    입력값 : date_index
    리턴값 : 없음
  *******************************************************************************/
  setDate( date_index: any ) {
    if( date_index === '' ) {
      this.search.sdate = '';
      this.search.edate = '';
    } else if( date_index === '0' ) {
      this.search.sdate = this.utilService.getDate( moment().format('YYYY-MM-DD') );
    } else if( parseInt( date_index ) < 30 ) {
      this.search.sdate = this.utilService.getDate( moment().add(-1 * parseInt(date_index), 'day').format('YYYY-MM-DD') );
    } else if( parseInt( date_index ) == 30 ) {
      this.search.sdate = this.utilService.getDate( moment().add(-1, 'month').format('YYYY-MM-DD') );
    } else if( parseInt( date_index ) == 90 ) {
      this.search.sdate = this.utilService.getDate( moment().add(-3, 'month').format('YYYY-MM-DD') );
    }

    if( date_index !== '' ) {
      //this.search.edate = this.utilService.getDate( moment().format('YYYY-MM-DD') );
      this.search.edate = '';
    }

    this.search.date_index = date_index;

    this.getApplicationList();
  }

  /*******************************************************************************
    설  명 : 엔터키 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchData( $event ) {
    if( $event.key == 'Enter' ) {
      this.getApplicationList();
    }
  }

  /*******************************************************************************
    설  명 : 세금계산서 발행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  taxInvoice() {
    let nodes: any = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( '세금계산서를 발행할 주문을 선택하시기 바랍니다.', '계산서발행');
      return false;
    }

    // 같은 거래처만 일괄 발행이 가능
    let isStatusChecked: boolean;
    let isTaxbillChecked: boolean;

    let customerSeqArray: any = [];
    nodes.forEach(item => {
      if( item.status_code > '3000' ) {
        isStatusChecked = true;
      }

      if( item.taxbill_yn == '1' ) {
        isTaxbillChecked = true;
      }

      customerSeqArray.push(item.customer_seq);
    });

    let newCustomerSeqArray = Array.from(new Set(customerSeqArray));

    if( newCustomerSeqArray.length > 1 ) {
      this.toastrService.error( '같은 거래처만 일괄 발행이 가능합니다.', '계산서발행');
      return false;
    }

    if( isStatusChecked === true ) {
      this.toastrService.error( '취소 또는 환불된 주문은 발행할 수 없습니다.', '계산서발행');
      return false;
    }

    if( isTaxbillChecked === true ) {
      this.toastrService.error( '이미 발행된 주문이 있습니다.', '계산서발행');
      return false;
    }

    let seqArray: any = [];
    nodes.forEach(item => {
      seqArray.push(item.seq);
    });

    this.search.seq = encodeURIComponent(seqArray);

    this.router.navigate(
    ['/order/tax/invoice'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상태값 변경시 바로 검색
    입력값 : 상태값
    리턴값 : 없음
  *******************************************************************************/
  searchStatus( value: any ) {
    this.search.status = value;

    this.getApplicationList();
  }
}
