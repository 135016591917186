/*******************************************************************************
  설  명 : 앱 설정
  작성일 :
  작성자 : 송영석
*******************************************************************************/
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { RestfulService } from '@app/service/restful.service';
import { AuthService } from '@app/service/auth.service';

import { ACommonService } from '@admin/service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public title = 'The First';
  public checkLoginPage: boolean = false;    // 관리자 로그인 페이지 여부
  public checkPrintPage: boolean = false;    // 출력 페이지 여부

  public isQuickOpen: boolean = false;      // 빠른주문 열림 상태
  public quickOrderList: any;               // 빠른주문 데이터

  public params: any = {
    projectSeq: ''
  };

  public classNameH100: boolean = true;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public location: Location,
    public sanitizer: DomSanitizer,
    public restful: RestfulService,
    private authService: AuthService,
    public commonService: ACommonService,
    private ngbDatepickerConfig: NgbDatepickerConfig
  ) {
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

    // 로그인 여부 체크
    let loginCheck$: any = this.authService.getIsLogin;

    loginCheck$.subscribe( check => {
      this.checkLoginPage = ! check;
      //console.log(this.checkLoginPage);
    });

    this.commonService.classNameH100$.subscribe( check => {
      this.classNameH100 = check;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if( this.location.path().indexOf('/print') > -1 ) {
      this.checkPrintPage = true;
    }
  }

}
