import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AProjectService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 프로젝트 리스트 가져오기
  getProjectList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getProjectList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 현재 진행 중 혹은 예정 프로젝트 리스트 가져오기
  getCurrProjectList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getCurrProjectList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 프로젝트 리스트 가져오기
  getACProjectList( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getACProjectList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 프로젝트 정보 가져오기
  getProjectInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getProjectInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 거래처 정보 저장하기
  setProject( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'setProject'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 삭제하기
  deleteProject( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'deleteProject'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 첨부파일 삭제
  deleteUploadFile( upload_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'deleteUploadFile'
      }, {
        upload_seq: upload_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고 프로젝트 리스트
  getProjectOutList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getProjectOutList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고 프로젝트 선택시 상세 출고 상품 리스트 가져오기
  getProjectOutDetailList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getProjectOutDetailList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 선택한 창고에 출고된 프로젝트 리스트 가져오기
  getWarehouseProjectList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'project',
        version: '1.0',
        action: 'getWarehouseProjectList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
