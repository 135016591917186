/*******************************************************************************
  설  명 : 세금계산서발행
  작성일 : 2020-08-28
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbInputDatepicker, NgbCalendar, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { AuthService } from '@app/service/auth.service';

import { ACommonService } from '@admin/service/common.service';
import { ACompanyService } from '@admin/service/company.service';
import { AApplicationService } from '@admin/service/application.service';
import { ACustomerService } from '@admin/service/customer.service';
import { ATaxService } from '@admin/service/tax.service';

import { ATaxViewComponent } from '@admin/page/tax/tax-view/tax-view.component';
import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';

import * as moment from 'moment';
import { MonthSalesComponent } from '../statistic/month-sales/month-sales.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class ATaxInvoiceComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public companyInfo: any = {};
  public orderInfo: any = {};
  public memberInfo: any = {};
  public customerInfo: any = {};

  public params: any = {
    seq: '',
    date_group: '',
    sdate: '',
    edate: '',
    date_index: '',
    project_seq: '',
    project_name: '',
    customer_seq: '',
    customer_name: '',
    status: '',
    searchText: ''
  };

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    const todayModel: NgbDateStruct = this.utilService.getDate('');

    this.form = this.formBuilder.group({
      orderSeq: ['', [Validators.required]],
      customerSeq: ['', []],
      taxInvoiceType: ['1', [Validators.required]],
      taxType: ['1', [Validators.required]],
      taxCalcType: ['1', [Validators.required]],
      purposeType: ['2', [Validators.required]],
      writeDate: [todayModel, [Validators.required]],
      amountTotal: ['', [Validators.required]],
      taxTotal: ['', [Validators.required]],
      totalAmount: ['', [Validators.required]],
      corpNum: ['', [Validators.required, Validators.maxLength(20)]],
      corpName: ['', [Validators.required, Validators.maxLength(50)]],
      ceoName: ['', [Validators.required, Validators.maxLength(20)]],
      addr: ['', [Validators.required, Validators.maxLength(255)]],
      bizType: ['', [Validators.maxLength(50)] ],
      bizClass: ['', [Validators.maxLength(50)] ],
      contactName: ['', [Validators.required, Validators.maxLength(50)]],
      tel: ['', [Validators.maxLength(20)]],
      hp: ['', [Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      reCorpNum: ['', [Validators.required, Validators.maxLength(20)]],
      reCorpName: ['', [Validators.required, Validators.maxLength(50)]],
      reCeoName: ['', [Validators.required, Validators.maxLength(20)]],
      reAddr: ['', [Validators.required, Validators.maxLength(255)]],
      reBizType: ['', [Validators.maxLength(50)]],
      reBizClass: ['', [Validators.maxLength(50)]],
      reContactName: ['', [Validators.required, Validators.maxLength(50)]],
      reTel: ['', [Validators.maxLength(20)]],
      reHp: ['', [Validators.maxLength(20)]],
      reEmail: ['', [Validators.email, Validators.maxLength(100)]],
      reEmail2: ['', [Validators.email, Validators.maxLength(100)]],
      remark1: ['', [Validators.maxLength(150)]],
      items: [[], [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aCompanyService: ACompanyService,
    private aApplicationService: AApplicationService,
    private aCustomerService: ACustomerService,
    private aTaxService: ATaxService,
    private agGridExComponent: AgGridExComponent,
  ) {
    // 폼 빌드
    this.buildForm();

    this.columnDefs = [
      { headerName: '번호', field: 'rowIndex', width: 80, cellClass: 'cp center', valueGetter: 'node.rowIndex + 1',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      // { headerName: '공급일자', field: 'purchaseExpiry', width: 120, cellClass: 'cp center ag-cell-edit', editable: true, cellEditor: 'datePicker' },
      { headerName: '공급일자', field: 'purchaseExpiry', width: 120, cellClass: 'cp center ag-cell-edit', editable: true },
      { headerName: '품목', field: 'name', width: 400, cellClass: 'cp ag-cell-edit', editable: true },
      { headerName: '규격', field: 'information', width: 200, cellClass: 'cp ag-cell-edit', editable: true },
      { headerName: '수량', field: 'chargeableUnit', width: 100, cellClass: 'cp center ag-cell-edit', 
        editable: true, 
        cellEditor: 'numericCellEditor',
        // valueFormatter: currencyFormatter
      },
      { headerName: '단가', field: 'unitPrice', width: 120, cellClass: 'cp right ag-cell-edit', 
        editable: true, 
        cellEditor: 'numericCellEditor',
        // valueFormatter: currencyFormatter
      },
      { headerName: '공급가액', field: 'amount', width: 120, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: 'numericCellEditor',
        valueFormatter: currencyFormatter
      },
      { headerName: '세액', field: 'tax', width: 120, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: 'numericCellEditor',
        valueFormatter: currencyFormatter
      },
      { headerName: '비고', field: 'description', width: 300, cellClass: 'cp ag-cell-edit', editable: true },

    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: false
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;

      this.getCompanyInfo();
      this.getAccountList();
    });       

    this.activatedRoute.queryParams.subscribe( async params => {
      this.params = {
        seq: params.seq,
        date_group: params.date_group,
        sdate: params.sdate,
        edate: params.edate,
        date_index: params.date_index,
        project_seq: params.project_seq,
        project_name: params.project_name,
        customer_seq: params.customer_seq,
        customer_name: params.customer_name,
        status: params.status,
        searchText: params.searchText
      };

      this.form.patchValue({
        orderSeq: decodeURIComponent(this.params.seq)
      });

      this.getMultipleApplicationDetail();
    });
  }

  /*******************************************************************************
    설  명 : 계좌정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAccountList() {
    this.aCompanyService.getAccountList().then( response => {
      this.form.patchValue({
        remark1: `${response.data[0].name} ${response.data[0].account}(${response.data[0].account_name})`,
      })
    });
  }

  /*******************************************************************************
    설  명 : 업체정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCompanyInfo() {
    await this.aCompanyService.getCompanyInfo().then( response => {
      if( response.ResultCode ) {
        this.companyInfo = response.data;

        this.form.patchValue({
          corpNum: this.companyInfo.business_no,
          corpName: this.companyInfo.company_name,
          ceoName: this.companyInfo.ceo,
          addr: this.companyInfo.address + ' ' + this.companyInfo.address_detail,
          bizType: this.companyInfo.business_type,
          bizClass: this.companyInfo.business_item,
          //contactName: this.companyInfo.manager_name,
          contactName: this.memberInfo.name,
          tel: this.companyInfo.cs_tel,
          hp: this.companyInfo.manager_hp,
          email: this.companyInfo.email,
        });

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '' );
    });
  }

  /*******************************************************************************
    설  명 : 주문정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getMultipleApplicationDetail() {
    await this.aApplicationService.getMultipleApplicationDetail( this.form.controls.orderSeq.value ).then( response => {
      if( response.ResultCode ) {

        this.orderInfo = response.data;

        const productList: any = [];

        let sumAmt: number = 0;
        let sumVat: number = 0;
        let sumTotalAmt: number = 0;

        this.orderInfo.forEach(data => {
          sumAmt += data.sum_amt * 1;
          sumVat += data.sum_vat * 1;
          sumTotalAmt += data.sum_total_amt * 1;

          const items: any = {
            purchaseExpiry: moment().format('YYYY-MM-DD'),
            name: '',
            information: '',
            chargeableUnit: '',
            unitPrice: '',
            amount: 0,
            tax: 0,
            description: null
          };
  
          let totalCount: number = 0;

          let sumUnit: number = 0;
          let sumPrice: number = 0;
          let sumAmount: number = 0;
          let sumTax: number = 0;
  
          if( typeof data.detail !== undefined ) {
            data.detail.forEach(item => {
              if( typeof item.product !== undefined ) {
                item.product.forEach(item2 => {
                  // items.purchaseExpiry = item.install_date;
                  items.name = data.project_name;
  
                  sumUnit += item2.qty * 1;
                  sumPrice += item2.unit_amt * 1.1;
                  sumAmount += item2.amt * 1;
                  sumTax += item2.vat * 1;
  
                  totalCount += 1;
                });
              }
            });
          }
  
          // items.chargeableUnit = sumUnit.toString();
          items.chargeableUnit = '';
          items.unitPrice = '';
          items.orgAmount = sumAmount.toString();
          items.amount = sumAmount.toString();
          items.orgTax = sumTax.toString();
          items.tax = sumTax.toString();
  
          // if( totalCount > 1 ) {
          //   items.name = `${items.name} 외 ${totalCount}건`;
          // }
  
          productList.push( items );

        });

        this.form.patchValue({
          amountTotal: sumAmt,
          taxTotal: sumVat,
          totalAmount: sumTotalAmt,
          items: productList
        });

        // 회원정보 가져오기
        if( this.orderInfo[0] !== null ) {
          this.getCustomerInfo();
        }

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

    }, error => {
      this.toastrService.error( error, '' );
    });
  }

  /*******************************************************************************
    설  명 : 거래처정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCustomerInfo() {
    await this.aCustomerService.getCustomerInfo( this.orderInfo[0].customer_seq ).then( response => {
      if( response.ResultCode ) {
        this.customerInfo = response.data;

        if( this.customerInfo !== null ) {
          this.form.patchValue({
            customerSeq: this.customerInfo.seq,
            reCorpNum: this.customerInfo.business_no,
            reCorpName: this.customerInfo.name,
            reCeoName: this.customerInfo.ceo,
            reAddr: this.customerInfo.address + ' ' + this.customerInfo.address_detail,
            reBizType: this.customerInfo.business_type,
            reBizClass: this.customerInfo.business_item,
            reContactName: this.customerInfo.person_memnm,
            reTel: this.customerInfo.tel,
            reHp: this.customerInfo.tel,
            reEmail: this.customerInfo.taxinvoice_email,
          });
        }

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '' );
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

  }

  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped(event) {
    const field: any = event.colDef.field;

    // 변경셀이 수량 또는 단가일 때
    if( field == 'chargeableUnit' || field == 'unitPrice' ) {

      const unitAmt = event.data.unitPrice;
      const qty = event.data.chargeableUnit;
      const amt = event.data.amount;

      event.data.amount = unitAmt * qty;
      event.data.tax = ( unitAmt * qty ) / 10;

    }

    this.gridApi.updateRowData({update: [event.data]});

    const product: any = this.form.controls.items.value;

    let amountSum: number = 0;
    let taxSum: number = 0;
    product.forEach(item => {
      amountSum += parseInt(item.amount);
      taxSum += parseInt(item.tax);
    });

    this.form.patchValue({
      amountTotal: amountSum,
      taxTotal: taxSum,
      totalAmount: (amountSum * 1) + (taxSum * 1),
    });
  }

  /*******************************************************************************
    설  명 : 종류 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTaxInvoiceType( value: any ) {
    this.form.patchValue({
      taxInvoiceType: value,
      taxType: ''
    });
  }

  /*******************************************************************************
    설  명 : 과세구분 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTaxType( value: any ) {
    this.form.patchValue({
      taxType: value
    });

    const product: any = this.form.controls.items.value;

    let amountSum: number = 0;
    let taxSum: number = 0;
    product.forEach(item => {
      if( value == '1' ) {
        item.tax = item.orgAmount / 10;
      } else {
        item.tax = 0;
      }

      item.amount = item.orgAmount;

      amountSum += parseInt(item.amount);
      taxSum += parseInt(item.tax);

      this.gridApi.updateRowData({update: [item]});
    });

    this.form.patchValue({
      items: product,
      amountTotal: amountSum,
      taxTotal: taxSum,
      totalAmount: (amountSum * 1) + (taxSum * 1),
    });
  }

  /*******************************************************************************
    설  명 : 선택행 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRowDelete() {
    const selectedRow = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedRow });
  }

  /*******************************************************************************
    설  명 : 행 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRowAdd() {
    const newRow = {
      purchaseExpiry: null,
      name: null,
      information: null,
      chargeableUnit: '',
      unitPrice: '',
      amount: '0',
      tax: '0',
      description: null
    };

    this.gridApi.updateRowData({ add: [newRow] });
  }

  /*******************************************************************************
    설  명 : 계산서 발행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aTaxService.setInvoicePublish(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.goList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.params.seq = '';

    this.router.navigate(
    ['/order/application'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 미리보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPreviewInovice() {
    const modalRef = this.modalService.open(ATaxViewComponent, options);

    modalRef.componentInstance.formData = this.form.value;

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

}
