import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ADashboardService {

  constructor(
    public restful: RestfulService,
  ) { }

  // 위젯 통계 가져오기
  getWidgetStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getWidgetStatistic'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이번달 프로젝트별 매출
  getProjectSalesStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getProjectSalesStatistic'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 진행중인 프로젝트
  getIngProjectList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getIngProjectList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 진행 예정인 프로젝트
  getScheduleProjectList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getScheduleProjectList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 매출 년도 가져오기
  getYearGoodSales(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getYearGoodSales'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 년도별 상품 매출 순위
  getRankGoodSales( year: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getRankGoodSales'
      }, {
        year: year
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 년도별 매출
  getYearSalesStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'dashboard',
        version: '1.0',
        action: 'getYearSalesStatistic'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
}
