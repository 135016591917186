/*******************************************************************************
  설  명 : 거래처 관리 - 상세보기
  작성일 : 2019-09-11
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbModal, NgbActiveModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { ACustomerService } from '@admin/service/customer.service';

import { AApplicationService } from '@admin/service/application.service';
import { AOrderService } from '@admin/service/order.service';
import { AMemberListService } from '@admin/service/member.service';
import { AProjectService } from '@admin/service/project.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ACustomerDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: string;
  public title: string = '추가';
  public isModal: boolean = false;

  public form: FormGroup;
  public formErrors = {};

  public typeList = [];
  public kindList = [];

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public tabIndex: any = 0;

  refreshCustomer$: Observable<boolean>;

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq:["", [] ],
      name:["", [Validators.required, Validators.maxLength(50)] ],
      business_no:["", [Validators.required, Validators.maxLength(20)] ],
      type:["", [Validators.required] ],
      kind:["", [Validators.required] ],
      ceo:["", [Validators.maxLength(50)] ],
      tel:["", [Validators.maxLength(20), CustomValidator.phone] ],
      fax:["", [Validators.maxLength(20), CustomValidator.phone] ],
      manager_id:["", []],
      person_memno:["", [] ],
      business_type:["", [Validators.maxLength(100)] ],
      business_item:["", [Validators.maxLength(100)] ],
      taxinvoice_email:["", [Validators.email, Validators.maxLength(100)] ],
      zipcode:["", [Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric] ],
      address:["", [Validators.maxLength(100)] ],
      address_detail:["", [Validators.maxLength(100)] ],
      account_owner: [''],
      account_no: [''],
      bank_name: ['']
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    private aCustomerService: ACustomerService,
    private modalService: NgbModal,
    private aApplicationService: AApplicationService,
    private aOrderService: AOrderService,
    private aMemberService: AMemberListService,
    private aProjectService: AProjectService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();

    this.refreshCustomer$ = this.aCustomerService.getRefreshCustomer;

    this.refreshCustomer$.subscribe( refresh => {
      if( refresh ) {
        // 거래처 담당자 데이터 가져오기
        this.aCustomerService.getCustomerInfo( this.seq ).then( response => {
          if( response.ResultCode ) {
            this.form.patchValue({
              manager_id: response.data.manager_id,
              person_seq: response.data.person_seq
            });
          }
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.seq = params.seq;

      if( this.seq == '' || this.seq == '0' ) {
        this.title = '추가';
      } else {
        this.title = '수정';

        this.aApplicationService.selectSeq = parseInt( this.seq );
        this.aOrderService.selectSeq = parseInt( this.seq );
        this.aMemberService.selectSeq = parseInt( this.seq );
        this.aProjectService.selectSeq = parseInt( this.seq );

        // 거래처 데이터 가져오기
        this.aCustomerService.getCustomerInfo( this.seq ).then( response => {
          if( response.ResultCode ) {
            this.form.patchValue( response.data );
          }
        });
      }

      this.tabIndex = params.tabIndex || 0;
    });

    if ( this.isModal ) {
      this.seq = '0';
    }

    this.getCommonList();
  }

  /*******************************************************************************
    설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(['/basic/customer']);
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aCustomerService.setCustomer(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          // this.router.navigate([`/basic/customer/detail?seq=${response.last_insert_id}`]);

          if ( !this.isModal ) {
            if( this.seq == '' || this.seq == '0' ) {
  
              this.form.patchValue({
                seq: response.customerSeq
              });
  
              this.seq = response.customerSeq;
  
              this.router.navigate(
                ['/basic/customer/detail'],
                { relativeTo: this.activatedRoute,
                  queryParams: {'seq': this.seq},
                  queryParamsHandling: '', // remove to replace all query params by provided
                }
              );            
            }
          } else {
            this.activeModal.close();
          }

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '거래처 저장');
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래처 구분
    this.aCommonService.getCommonList(2).then( response => {
      for( const index in response.data ) {
        this.typeList.push({
          title: response.data[index].common_name,
          value: response.data[index].seq
        });
      }
    });

    // 거래처 분류
    this.aCommonService.getCommonList(3).then( response => {
      for( const index in response.data ) {
        this.kindList.push({
          title: response.data[index].common_name,
          value: response.data[index].seq
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 거래처 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteCustomer() {
    if( confirm( "선택하신 거래처를 삭제하시겠습니까?" ) ) {
      this.aCustomerService.deleteCustomer( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '거래처 저장');

          this.goList();
        } else {
          this.toastrService.error( response.ResultMessage, '거래처 저장');
        }
      }, error => {
        this.toastrService.error( error, '거래처 저장');
      });
    }
  }
}
