import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AMemberListService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 회원검색 - 컴포넌트용
  getACMemberList( searchText ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getACMemberList'
      }, {
        searchText: searchText
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원검색 - 컴포넌트용
  getMemberList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원검색
  getManagerList( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getACManagerList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 중복아이디 검사
  checkIdDuplicate( id: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'checkIdDuplicate'
      }, {
        id: id
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 저장
  setMemberSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 관리자 회원 정보 가져오기
  getMemberInfo(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberInfo'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 정보 가져오기
  getMemberDetail( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberDetail'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 저장
  setMemberGrade( list: any, grade_group ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberGrade'
      }, {
        list: list,
        grade_group: grade_group
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  setMemberOutList( list: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberOutList'
      }, {
        list: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 저장
  addManager( mem_no: any, customer_seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'addManager'
      }, {
        mem_no: mem_no,
        customer_seq: customer_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 삭제
  delManager( list: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'delManager'
      }, {
        list: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 컴포넌트용 - 거래처 주 담당자 설정
  getACMemberSelect( customer_seq: any, mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getACMemberSelect'
      }, {
        customer_seq: customer_seq,
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 컴포넌트용 - 거래처 주 담당자 설정
  getACMemberLogList( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getACMemberLogList'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 비회원 정보 저장
  setNonMember( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setNonMember'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
