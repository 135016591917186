/*******************************************************************************
  설  명 : 회원의 장바구니 컴포넌트
  작성일 : 2019-08-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UtilService } from '@app/service/util.service';
import { ACartService } from '@admin/service/cart.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-cart-component',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class ACCartComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() mem_no: any;

  public type: boolean = true; // 거래처/회원 구분, true = 거래처, false = 회원
  public seq: number; // DB 키값 거래처 seq, 회원 seq

  selectedRows: number = 0;
  cartList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCartService: ACartService
  ) {
    this.columnDefs = [
      {headerName: '상품번호', field: 'sp_seq', cellClass: 'ag-cell80h' },
      {headerName: '상품이미지', field: 'thumbnail_filename', cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      {headerName: '상품명', field: 'name', cellClass: 'cp ag-cell80h' },
      {headerName: '상품정보', field: 'spec', cellClass: 'cp ag-cell80h' },
      {headerName: '단가', field: 'unit_amt', cellClass: 'cp right ag-cell80h', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '수량', field: 'qty', cellClass: 'cp right ag-cell80h', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '금액', field: 'amt', cellClass: 'cp right ag-cell80h', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCartList( this.mem_no );
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 장바구니 가져오기
    입력값 : mem_no = 회원번호
    리턴값 : 없음
  *******************************************************************************/
  getCartList( mem_no: any ) {
    this.cartList = [];

    this.aCartService.getCartList( mem_no ).then( response => {
      if( response.ResultCode ) {
        this.cartList = response.data;
      }

    }, error => {
      this.toastrService.error( error, '장바구니');
    });
  }

}
