/*******************************************************************************
  설  명 : 발주서 관리
  작성일 : 2019-12-08
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { ACommonService } from '@admin/service/common.service';
import { APurchaseOrderService } from '@admin/service/purchase.order.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import { AStockPurchaseComponent } from '../purchase/purchase.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class AOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  purchaseList: any = [];
  purchaseDetailList: any = [];
  purchaseBarcodeList: any = [];

  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  gbnList: any = [];
  warehouseList: any = [];

  gridApi: any;
  gridColumnApi: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;

  gridApiBarcode: any;
  gridColumnApiBarcode: any;

  columnDefs: any;
  columnDetailDefs: any;
  columnBarcodeDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionDetail: any;
  rowSelectionBarcode: any;
  noRowsTemplate: string;

  dateModel: NgbDateStruct;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    gbn_seq: '',
    in_complete: '',
    customer_seq: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    status: false,
    dateType: '',
    term: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aPurchaseOrderService: APurchaseOrderService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aWarehouseService: AWarehouseService,
    public authService: AuthService
  ) {
    this.columnDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '확정', field: 'status', cellClass: 'cp center', width:80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.status == 1 ? '<span class="badge badge-success">확정</span>' : '<span class="badge badge-danger">미확정</span>';
        }
      },
      {headerName: '발주구분', field: 'gbn_name', width: 80, cellClass: 'cp center' },
      {headerName: '발주일자', field: 'date', width: 120, cellClass: 'cp center' },
      {headerName: '거래처', field: 'customer_name', width: 120, cellClass: 'cp left' },
      {headerName: '발주금액', field: 'amt', width: 80, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
      },
      {headerName: '비고', field: 'memo', width: 120, cellClass: 'cp left' },
      {headerName: '입고여부', field: 'complete_qty', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.complete_qty == 0 ? '입고완료' : '미입고(' + ( params.data.max_qty - params.data.in_qty ) + ')';
        },
        cellStyle: function(params) {
          if (params.value !== '입고완료') {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return {color: '#337ab7', backgroundColor: '#dbeeff'};
          }
        }
      }
    ];

    this.columnDetailDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '대분류', field: 'parent_category_name', width: 100, cellClass: 'cp left' },
      {headerName: '중분류', field: 'category_name', width: 100, cellClass: 'cp left' },
      {headerName: '상품명', field: 'product_name', width: 120, cellClass: 'cp left'},
      {headerName: '수량', field: 'qty', width: 80, cellClass: 'cp right' },
      {headerName: '입고', field: 'in_qty', width: 80, cellClass: 'cp right' },
      {headerName: '미입고', field: 'not_qty', width: 80, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.qty - params.data.in_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellStyle: function(params) {
          if (parseInt(params.value) !== 0) {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return null;
          }
        }
      },
      {headerName: '입고일자', field: 'in_date', width: 120, cellClass: 'cp center' }
    ];

    this.columnBarcodeDefs = [
      { headerName: '선택', field: 'seq', cellClass: 'cp center', width: 100,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '바코드 발행번호', field: 'barcode', cellClass: 'cp left', width: 480 },
      { headerName: '등록일', field: 'write_date', cellClass: 'cp left', width: 200 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";
    this.rowSelectionDetail = "single";
    this.rowSelectionBarcode = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWareHouse() {
    this.aWarehouseService.getWarehouseList({
      typeSelect: '',
      searchText: ''
    }).then( response => {
      if( response.ResultCode ) {
        this.warehouseList = response.data;
      } else {
        this.warehouseList = [];
      }
    }, error => {
      this.toastrService.error( error, '창고정보');
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.getCommonList();

    this.getWareHouse();

    this.initSearch();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 발주서 마스터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseOrderMaster() {
    this.purchaseList = [];

    let tmp: any = Object.assign({}, this.search );

    // tmp.sdate = this.ngbDateParserFormatter.format( this.search.sdate );
    // tmp.edate = this.ngbDateParserFormatter.format( this.search.edate );

    let nodes: any = null;

    if( typeof this.gridApi !== 'undefined' )
      nodes = this.gridApi.getSelectedRows();

    this.aPurchaseOrderService.getPurchaseOrderMaster(tmp).then( response => {
      if( response.ResultCode ) {
        this.purchaseList = response.data;

        // 기존에 선택되어 있던 노드를 재선택 시킨다.
        if( nodes !== null && nodes.length > 0) {
          setTimeout( () => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( nodes[0].seq == node.data.seq ) {
                node.setSelected(true);
              }
            });

            this.getPurchaseOrderSlave( nodes[0].seq );
          }, 500);
        }
      }
    }, error => {
      this.toastrService.error( error, '발주서 가져오기');
    });
  }

  /*******************************************************************************
    설  명 : 발주서 디테일 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseOrderSlave( seq: any ) {
    this.purchaseDetailList = [];

    this.aPurchaseOrderService.getPurchaseOrderSlave( seq, true ).then( response => {
      if( response.ResultCode ) {
        this.purchaseDetailList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '발주서 가져오기' );
      }

    }, error => {
      this.toastrService.error( error, '발주서 가져오기' );
    });
  }

  /*******************************************************************************
    설  명 : 발주서 바코드 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseBarcodeList( po_seq: number, product_seq: number ) {
    this.purchaseBarcodeList = [];

    this.aPurchaseOrderService.getPurchaseBarcodeList( po_seq, product_seq ).then( response => {
      if( response.ResultCode ) {
        this.purchaseBarcodeList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '바코드 정보 가져오기' );
      }

    }, error => {
      this.toastrService.error( error, '바코드 정보 가져오기' );
    });
  }
  
  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.gbnList = [];

    // 발주 구분
    this.aCommonService.getCommonListCode('POG').then( response => {
      if( response.ResultCode ) {
        this.gbnList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onGridReadyBarcode(params) {
      this.gridApiBarcode = params.api;
      this.gridColumnApiBarcode = params.columnApi;
    }
  
  /*******************************************************************************
    설  명 : 발주서 수정/추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderAdd( seq ) {
    if( seq == null || seq !== 0) {
      let nodes = this.gridApi.getSelectedNodes();

      seq = nodes[0].data.seq;

      if( nodes[0].data.status == '1' ) {
        this.toastrService.error( '확정 상태인 발주서는 수정하실 수 없습니다.', '발주서 수정');
        return false;
      }
    }

    this.router.navigate(
    ['/stock/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { seq: seq },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.getPurchaseOrderSlave( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 셀 더블 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( $event ) {
    this.orderAdd( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClickedDetail($event) {
    this.getPurchaseBarcodeList( $event.data.po_seq, $event.data.product_seq );
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClickedBarcode($event) {

  }
  
  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.search.customer_seq = result.seq;
      this.search.customer_name = result.name;
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 오늘 날짜 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj, data ) {
    if( data == 'sdate' )
      this.search.sdate = this.calendar.getToday();
    else
      this.search.edate = this.calendar.getToday();

    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      gbn_seq: '',
      in_complete: '',
      customer_seq: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
    };

    this.setSearchDate('1', 'months');

    this.getPurchaseOrderMaster();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getPurchaseOrderMaster();
  }

  /*******************************************************************************
    설  명 : 발주서 확정 / 취소
    입력값 : true = 확정, false = 취소
    리턴값 : 없음
  *******************************************************************************/
  statusChange( check: boolean ) {
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '발주서 상태를 변경할 발주서를 선택하세요.', '발주서 상태변경');
    } else if( confirm("선택한 발주서의 상태를 변경하시겠습니까?") ) {

      let list: any = [];
      for( let item of nodes ) {
        list.push( item.data );
      }

      this.aPurchaseOrderService.setPurchaseStatus( list, check ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '발주서 상태변경');

          this.getPurchaseOrderMaster();
        } else {
          this.toastrService.error( response.ResultMessage, '발주서 상태변경');
        }
      }, error => {
        this.toastrService.error( error, '발주서 상태변경');
      });
    }
  }

  /*******************************************************************************
    설  명 : 미입고 상품 입고 처리
    입력값 : true = 확정, false = 취소
    리턴값 : 없음
  *******************************************************************************/
  stockImport() {
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '발주서를 선택하세요.', '입고 처리');

    } else {
      let data:any = [];

      if( nodes[0].data.status != '1' ) {
        this.toastrService.error( '발주서 상태가 미확정인 상품은 입고하실 수 없습니다.', '입고 처리');

      } else {
        // 발주서 입고 폼 띄우기 -> 재고관리 입고 창
        const modalRef = this.modalService.open(AStockPurchaseComponent, optionsXL);

        modalRef.componentInstance.seq = nodes[0].data.seq;
        modalRef.componentInstance.check = 'P';
        modalRef.componentInstance.warehouseList = this.warehouseList;

        modalRef.result.then((result) => {
          this.getPurchaseOrderMaster();
        }, (reason) => {
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    this.dateModel = {
      year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
      month: parseInt( moment().subtract(term, dateType).format('MM') ),
      day: parseInt( moment().subtract(term, dateType).format('DD') )
    };

    this.search.sdate = this.dateModel;
    this.search.edate = this.calendar.getToday();

    this.getPurchaseOrderMaster();
  }
}
