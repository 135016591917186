/*******************************************************************************
  설  명 : 출고관리 - 부스별신청내역 출력
  작성일 : 2020-10-03
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { _ } from 'ag-grid-community';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';

@Component({
  selector: 'app-order-print',
  templateUrl: './order-print.component.html',
  styleUrls: ['./order-print.component.scss']
})
export class AProjectOrderPrintComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private projectSeq: any = '';
  private location: any = '';

  public orderList: any = [];
  public orderOriginList: any = [];
  public projectInfo: any = {};

  public maxRow: number = 36;   // 한 페이지에 한 줄마다 표시될 항목 수
  public maxPageRow: number = 108;  // 한 페이지에 표시될 항목 수

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aStockService: AStockService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    await this.activatedRoute.queryParams.subscribe(async params => {
      this.projectSeq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
      this.location = ( typeof params.location == 'undefined' || params.location == '' ) ? '' : params.location;

      if( this.projectSeq == '' ) {
        this.toastrService.error("정상적인 경로로 접근하시기 바랍니다.", "출고지시서");
        window.close();
      }

      await this.getOrderPrint();
    });
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray1( num: number ) {
    let tmp: any = [];
    for( let i = 0; i < num; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 배열 갯수만큼 리턴
    입력값 : 배열 갯수
    리턴값 : array
  *******************************************************************************/
  getArray( startNum: number ) {
    let tmp: any = [];

    startNum = startNum * this.maxRow;

    for( let i = startNum; i < startNum + this.maxRow; i++ ) tmp.push(i);

    return tmp;
  }

  /*******************************************************************************
    설  명 : 프로젝트별 신청내역 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getOrderPrint() {
    await this.aStockService.getOrderPrint( this.projectSeq, this.location ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.orderOriginList = response.data;
        this.projectInfo = response.info;

        let i: number = 0;
        let prev: string = '';
        let index: number = 1;
        this.orderOriginList.forEach(item => {

          if( item.place == '' ) item.place = '＊부스입력필요';

          if( prev != item.place ) {
            item.number = ++i;
          } else {
            item.number = null;
          }

          prev = item.place;
        });

        let tmpOrder: any = [];
        let tmpArray: any = [];
        if( this.orderOriginList != null ) {
          if( this.orderOriginList.length > this.maxPageRow ) {

            for( let i = 0; i < this.orderOriginList.length; i++ ) {
              if( i > 0 && i % this.maxPageRow === 0 ) {
                tmpOrder.push(tmpArray);

                tmpArray = [];
              }

              tmpArray.push(this.orderOriginList[i]);

              if( i === ( this.orderOriginList.length - 1 ) ) {
                tmpOrder.push(tmpArray);
              }
            }

          } else {
            tmpOrder.push(this.orderOriginList);
          }
        }

        this.orderList = tmpOrder;
 
      } else {
        this.orderList  = [];
        this.projectInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 배열에 index 키 값을 가져온다.
    입력값 : index, key
    리턴값 : value
  *******************************************************************************/
  getListIndex( pIndex, index, key ) {
    if( index >= this.orderList[pIndex].length ) return '';
    else return this.orderList[pIndex][index][key];
  }

  /*******************************************************************************
    설  명 : 카테고리가 이전 카테고리와 같을 경우 rowspan
    입력값 : index
    리턴값 : true / false
  *******************************************************************************/
  checkPrev( pIndex: number, index: number ) {
    if( ( index % this.maxRow ) == 0 ) return true;
    if( index >= this.orderList[pIndex].length ) return true;

    if( this.orderList[pIndex][index-1].customer_name == this.orderList[pIndex][index].customer_name ) {
      return false;
    } else return true;
  }

  /*******************************************************************************
    설  명 : rowspan count 가져오기
    입력값 : index
    리턴값 : rowspan count
  *******************************************************************************/
  rowSpanCount( pIndex: number, index: number ) {
    let count = 1;

    for( let i = index; i < this.orderList[pIndex].length - 1; i++ ) {
      if( this.orderList[pIndex][i]['customer_name'] == this.orderList[pIndex][i+1]['customer_name'] ) count++;
      else break;
    }

    return count;
  }

  /*******************************************************************************
    설  명 : 카테고리가 이전 카테고리와 같을 경우 rowspan
    입력값 : index
    리턴값 : true / false
  *******************************************************************************/
  checkPrevBooth( pIndex: number, index: number ) {
    if( ( index % this.maxRow ) == 0 ) return true;
    else if( index > this.orderList[pIndex].length - 1 ) return true;
    else if( typeof this.orderList[pIndex][index] != 'undefined' && this.orderList[pIndex][index-1]['place'] == this.orderList[pIndex][index]['place'] ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 카테고리가 이전 카테고리와 같을 경우 rowspan
    입력값 : index
    리턴값 : true / false
  *******************************************************************************/
  checkNextBooth( pIndex: number, index: number ) {
    if( index == this.orderList[pIndex].length - 1 ) return true;
    //else if( index >= this.orderList[pIndex].length - 2 ) return false;
    else if( typeof this.orderList[pIndex][index+1] == 'undefined' || typeof this.orderList[pIndex][index+1].place == 'undefined' ) return false;
    else if( this.orderList[pIndex][index+1]['place'] != this.orderList[pIndex][index]['place'] ) return true;
    else if( ( index % this.maxRow ) == 0 ) return false;
    else return false;
  }

  /*******************************************************************************
    설  명 : rowspan count 가져오기
    입력값 : index
    리턴값 : rowspan count
  *******************************************************************************/
  rowSpanBoothCount( pIndex: number, index: number ) {
    let count = 1;

    for( let i = index; i < this.orderList[pIndex].length - 1; i++ ) {
      if( this.orderList[pIndex][i]['place'] == this.orderList[pIndex][i+1]['place'] ) count++;
      else break;
    }

    return count;
  }

  /*******************************************************************************
    설  명 : 부스 카운트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCurrCount( index, i ) {
    if( index == null || i == null ) return 0;
    else if( index == 0 && i == 0 ) return 0;
    else return ( index + (i * this.maxRow) )+1;
  }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }
}
