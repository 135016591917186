import { Component, OnInit } from '@angular/core';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { ADashboardService } from '@admin/service/dashboard.service';
import { ABoardService } from '@admin/service/board.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-adashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class ADashboardComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public dashHeaderData: any = {
    memberCount: 0,
    CompleteProjectCnt: 0,
    scheduleProjectCnt: 0,
    MonthSaleAmt: 0,
    autoDeposit: 0,
    depositNot: 0,
    bankAccount: '',
    balanceAmt: 0
  };

  public updateList: any = [];
  public isUpdate: boolean = false;

  public baseURL: any = config.baseUrl;

  public todayMonth: any = moment().format('YYYY년 MM월');
  public today: any = moment().format('YYYY년 MM월 DD일');
  public currYear: any = moment().format('YYYY');

  public projectIngList: any = [];
  public projectScheduleList: any = [];
  public salesYearList: any = [];
  public productSalesRank: any = [];

  /*******************************************************************************
    설  명 : 차트 옵션
  *******************************************************************************/
  public chartType: string = "bar";

  chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') + ' 원';
        }
    }
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad"
    },
    responsive: true,
    legend: {
      display: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: "Data Value"
        },
        ticks: {
          beginAtZero: true,
          callback : value => value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }
      }]
    }
  }

  public projectSales: any = [];
  public projectSalesChartLabel: string[] = [];
  public projectSalesBarChartData: any = [{
          data: [],
          label : 'count'
        }];

  public yearSales: any = [];
  public yearSalesChartLabel: string[] = [];
  public yearSalesBarChartData: any = [{
          data: [],
          label : 'count'
        }];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private globals: Globals,
    private utilService: UtilService,
    public authService: AuthService,
    private dashboardService: ADashboardService,
    private aBoardService: ABoardService
  ) { }

  /*******************************************************************************
    설  명 : 데이터로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 업데이트 내역이 있는 가져오기
    this.getUpdateList();

    // 위젯 통계 가져오기
    this.getWidgetStatistic();

    // 이번달 프로젝트별 매출
    this.getProjectSalesStatistic();

    // 진행중 프로젝트 리스트
    this.getIngProjectList();

    // 진행예정 프로젝트 리스트
    this.getScheduleProjectList();

    // 상품매출 연도 리스트
    this.getYearGoodSales();

    // 이번년도 상품매출 순위
    this.getRankGoodSales();

    // 이번년도 매출현황
    this.getYearSalesStatistic();
  }

  /*******************************************************************************
    설  명 : Moment
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMoment( type: any ) {
    return moment().format( type );
  }

  /*******************************************************************************
    설  명 : 위젯 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWidgetStatistic() {
    this.dashboardService.getWidgetStatistic().then( response => {
      if( response.ResultCode ) {
        this.dashHeaderData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 년도 매출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getYearSalesStatistic() {
    this.dashboardService.getYearSalesStatistic().then( response => {
      if( response.ResultCode ) {
        this.yearSales = response.data[0];

        let data = {
          data: [],
          label : 'count'
        };

        this.yearSalesChartLabel = [];

        for( let i = 0; i < 12; i++ ) {
          // 라벨 추가
          this.yearSalesChartLabel.push( (i+1) + '월' );

          // 데이터 추가
          data.data.push( this.yearSales['month' + (i+1)] );
        }

        this.yearSalesBarChartData = [data];
      }
    });
  }


  /*******************************************************************************
    설  명 : 이번달 프로젝트별 매출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectSalesStatistic() {
    this.dashboardService.getProjectSalesStatistic().then( response => {
      if( response.ResultCode ) {
        this.projectSales = response.data;

        let data = {
          data: [],
          label : 'count'
        };

        this.projectSalesChartLabel = [];

        for( let item of this.projectSales ) {
          // 라벨 추가
          this.projectSalesChartLabel.push( item.name );

          // 데이터 추가
          data.data.push( item.total_amt );
        }

        this.projectSalesBarChartData = [data];
      }
    });
  }

  /*******************************************************************************
    설  명 : 진행중 프로젝트 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getIngProjectList() {
    this.dashboardService.getIngProjectList().then( response => {
      if( response.ResultCode ) {
        this.projectIngList = response.data;
      } else {
        this.projectIngList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 진행예정 프로젝트 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getScheduleProjectList() {
    this.dashboardService.getScheduleProjectList().then( response => {
      if( response.ResultCode ) {
        this.projectScheduleList = response.data;
      } else {
        this.projectScheduleList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품매출 연도 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getYearGoodSales() {
    this.dashboardService.getYearGoodSales().then( response => {
      if( response.ResultCode ) {
        this.salesYearList = response.data;
      } else {
        this.salesYearList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 이번년도 상품매출 순위
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRankGoodSales() {
    this.dashboardService.getRankGoodSales( this.currYear ).then( response => {
      if( response.ResultCode ) {
        this.productSalesRank = response.data;
      } else {
        this.productSalesRank = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 업데이트 내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getUpdateList() {
    this.aBoardService.getUpdateList().then( response => {
      if( response.ResultCode ) {
        this.updateList = response.data;
        if( this.updateList.length > 0 ) this.isUpdate = true;

      } else {
        this.updateList = [];
        this.isUpdate = false;
      }
    });

  }

  /*******************************************************************************
    설  명 : 팝업 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPopupToday( row: any ) {
    this.globals.save( 'popup' + row.board_seq, moment().format('YYYY-MM-DD hh:mm:ss') );

    this.isUpdate = false;
  }

   /*******************************************************************************
    설  명 : 팝업 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPopupToday( boardSeq ) {
    let data: any = this.globals.load( 'popup' + boardSeq );

    if( data !== false ) return false
    else return true;
  }

}
