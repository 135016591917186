/*******************************************************************************
  설  명 : 담당자 추가
  작성일 : 2020-02-15
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACustomerService } from '@admin/service/customer.service';

import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ACCustomerAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public mem_no: any = '0';  // 담당자 코드
  public sc_seq: any = ''; // 거래처 코드

  public formAdd: FormGroup;
  public formErrors: any = {};

  public title: any = '추가';

  public addFunc: any;           // 회원검색 시

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      mem_no:["", [] ],
      customer_seq:["", [] ],
      name:["", [Validators.required, Validators.maxLength(100)] ],
      gender:["", [] ],
      hp:["", [] ],
      tel: ["", [] ],
      email:["", [] ],
      extension_number:["", [] ],
      memo:["", [] ]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCustomerService: ACustomerService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public authService: AuthService
  ) {
    this.buildForm();

    this.addFunc = this.findMember.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.formAdd.patchValue({
      customer_seq: this.sc_seq
    });

    if( this.mem_no == '0' ) {
      this.title = '추가';
    } else {
      this.title = '수정';

      // 데이터 로딩 바인딩
      this.getCustomerManager(this.mem_no);
    }
  }

  /*******************************************************************************
    설  명 : 쇼핑몰 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findMember() {
    const modalRef = this.modalService.open(ACMemberFindComponent, options);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        mem_no: result.data.mem_no,
        name: result.data.name,
        gender: result.data.gender,
        hp: result.data.hp,
        email: result.data.email,
        extension_number: result.data.extension_number
      });
      console.log( result );
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 담당자 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aCustomerService.setCustomerManager(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '담당자 저장');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '담당자 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '담당자 저장');
    }
  }

  /*******************************************************************************
    설  명 : 담당자 정보를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerManager( mem_no: any ) {
    this.aCustomerService.getCustomerManager( mem_no ).then( response => {
      if( response.ResultCode ) {
        this.formAdd.patchValue({
          mem_no: response.data.mem_no,
          name: response.data.name,
          gender: response.data.gender,
          hp: response.data.hp,
          tel: response.data.tel,
          email: response.data.email,
          extension_number:response.data.extension_number,
          memo: response.data.memo
        });

      } else {
        this.toastrService.error( response.ResultMessage, '담당자 불러오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( error, '담당자 불러오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 담당자 정보를 삭제한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteCustomerManager() {
    if( confirm("해당 담당자의 정보를 거래처에서 삭제하시겠습니까?" ) ) {
      this.aCustomerService.deleteCustomerManager( this.mem_no ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '담당자 삭제');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '담당자 삭제');
        }
      }, error => {
        this.toastrService.error( error, '담당자 삭제');
      });
    }
  }
}
