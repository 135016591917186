/*******************************************************************************
  설  명 : 거래처 관리
  작성일 : 2019-09-11
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { CustomValidator } from '@app/service/custom.validators';
import { ACommonService } from '@admin/service/common.service';
import { ACustomerService } from '@admin/service/customer.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class ACustomerComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 거래처분류, 거래처종류 리스트
  public typeList = [];
  public kindList = [];

  public sendList = [];

  // 검색 필드
  public search = {
    searchGroup: 'name',
    searchText: '',
    typeSelect: '',
    kindSelect: ''
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aCustomerService: ACustomerService,
    public authService: AuthService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '등록일', field: 'write_date', cellClass: 'cp center' },
      {headerName: '거래처명', field: 'name', cellClass: 'cp' },
      {headerName: '사업자번호', field: 'business_no', cellClass: 'cp center' },
      {headerName: '업태', field: 'business_type', cellClass: 'cp center' },
      {headerName: '업종', field: 'business_item', cellClass: 'cp center' },
      {headerName: '거래처구분', field: 'type', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type == '매입') return '<span class="badge badge-primary f12 fn">매입</span>';
          else if( params.data.type == '매출') return '<span class="badge badge-success f12 fn">매출</span>';
          else return '<span class="badge badge-secondary f12 fn">매입+매출</span>';
        }
      },
      {headerName: '거래처분류', field: 'kind', cellClass: 'cp center' },
      {headerName: '대표자', field: 'ceo', cellClass: 'cp center' },
      {headerName: '연락처', field: 'tel', cellClass: 'cp center' },
      {headerName: '팩스', field: 'fax', cellClass: 'cp center' },
      {headerName: '계산서발행이메일', field: 'taxinvoice_email', cellClass: 'cp left' },
      {headerName: '담당자정보', field: 'manager_id', cellClass: 'cp center' },
      {headerName: '담당자연락처', field: 'charge_hp', cellClass: 'cp center' },
      {headerName: '우편번호', field: 'zipcode', cellClass: 'cp center' },
      {headerName: '주소', field: 'address', cellClass: 'cp' },
      {headerName: '상세주소', field: 'address_detail', cellClass: 'cp' },
      {headerName: '참여프로젝트', field: 'project_name', cellClass: 'cp' },
      {headerName: '담당자번호', field: 'person_seq', hide:true },
      {headerName: '담당자이름', field: 'person_name', hide:true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 거래처 구분
    await this.aCommonService.getCommonList(2).then( response => {
      this.typeList = response.data;
    });

    // 거래처 분류
    await this.aCommonService.getCommonList(3).then( response => {
      this.kindList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 거래처분류, 거래처 종류 가져오기
    this.getCommonList();

    // 거래처 리스트 가져오기
    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchGroup: 'name',
      searchText: '',
      typeSelect: '',
      kindSelect: ''
    }

    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.addCustomer( $event.data.seq );
    } else {

    }
  }

  /*******************************************************************************
    설  명 : ag grid 데이터 처음 랜더링 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectionChanged(event) {
    var nodes = event.api.getSelectedNodes();

    this.sendList = [];

    nodes.forEach( node => {
      if( node.data.charge_hp != '' && node.data.charge_hp != null ) {
        this.sendList.push({
          hp: node.data.charge_hp,
          mem_no: node.data.person_memno,
          name: node.data.name
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 거래처 추가/수정
    입력값 : seq = 0 일 경우 추가, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addCustomer(seq) {
    this.router.navigate(
    ['/basic/customer/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { seq: seq },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 거래처 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    this.aCustomerService.getCustomerList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.customerList = response.data;
      } else {
        this.customerList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCustomerList();
    }
  }

}
