/*******************************************************************************
  설  명 : 구매입고
  작성일 : 2019-12-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';
import { APurchaseOrderService } from '@admin/service/purchase.order.service';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridButtonComponent } from '@app/component/ag-grid-button/ag-grid-button.component';

import { ACPurchaseFindComponent } from '@admin/component/purchase-find/purchase-find.component';
import { ACBarcodeInputComponent } from '@admin/component/barcode-input/barcode-input.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

declare var $: any;

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class AStockPurchaseComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public check: any;  // 'S' = stock_history.seq, 'P' = purchase_order.seq

  public title: string = '추가';

  public formPurchase: FormGroup;
  public formErrors = {};

  public warehouseList: any = [];

  public addPurchaseFunc: any;         // 발주서 검색

  purchaseDetailList: any = [];

  public wareHouse_seq: any = '';

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public components: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formPurchase = this.formBuilder.group({
      seq:["", [] ],
      purchase_order_seq:["", [Validators.required] ],
      gbn_name:["", [Validators.required] ],
      date:["", [Validators.required] ],
      customer_name:["", [Validators.required] ],
      customer_seq:["", [] ],
      memo:["", [] ],
    });

    this.formPurchase.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formPurchase, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aStockService: AStockService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aPurchaseOrderService: APurchaseOrderService,
    private agGridExComponent: AgGridExComponent,
    public authService: AuthService
  ) {
    this.buildForm();

    this.addPurchaseFunc = this.searchPurchase.bind(this);

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    //this.frameworkComponents = { genderCellRenderer: GenderCellRenderer };
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 부모창에서 보낸 창고 리스트를 표시하기 위해서 consturctor 가 아닌 ngOnInit에서 컬럼 선언
    this.columnDefs = [
      {headerName: '선택', field: 'seq', width: 80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '대분류', field: 'parent_category_name', width: 100, cellClass: 'cp left' },
      {headerName: '중분류', field: 'category_name', width: 100, cellClass: 'cp left' },
      {headerName: '상품코드', field: 'product_seq', hide:true },
      {headerName: '상품명', field: 'product_name', width: 120, cellClass: 'cp left'},
      {headerName: '바코드', field: 'barcode_yn', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if ( params.data.barcode_yn == '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
      {headerName: '수량', field: 'qty', width: 80, cellClass: 'cp right' },
      {headerName: '입고', field: 'in_qty', width: 80, cellClass: 'cp right' },
      {headerName: '입고수량', field: 'now_qty', width: 100, 
        cellClass: function(params) {
          if( params.data.barcode_yn == '1' ) {
            return 'cp right';
          } else {
            return 'cp right ag-cell-edit';
          }
        },
        editable: function(params) {
          if( params.data.barcode_yn == '1' ) {
            return false;
          } else {
            return true;
          }
        },
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.now_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          if (params.value > parseInt( params.data.qty ) - parseInt( params.data.in_qty ) ) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return {color: 'black', backgroundColor: '#fff'};
          }
        }
      },
      {headerName: '바코드 입력', field: 'barcode', width: 220, cellClass: 'cp center', 
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'barcode',  // 바코드용 그리드버튼
          applyField: 'now_qty',  // 적용할 필드
          btnName: '바코드 입력'  // 버튼명
        }
      },
      {headerName: '입고일자', field: 'in_date', width: 120, cellClass: 'cp center ag-cell-edit', editable: true, cellEditor: "datePicker" },
      {headerName: '입고창고', field: 'warehouse_in_seq', width: 120, cellClass: 'cp center ag-cell-edit', editable: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.warehouseList, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {

          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.warehouse_in_seq ) selected = item;
          }

          return (selected == null ) ? '' : selected.name;
        },
      }
    ];
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.seq == 0 )
      this.title = '추가';
    else {
      this.title = '수정';

      this.getStockPurchase();
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formPurchase, this.formErrors);

    if(this.formPurchase.valid) {
      // 상품의 값을 정상적으로 입력했는지 검사
      for( let item of this.purchaseDetailList ) {
        if( item.barcode_yn == '1' ) {
          if( item.barcode == '' || item.barcode == null ) {
            this.toastrService.error( item.product_name + '의 수량만큼 바코드를 입력하시기 바랍니다.', '구매입고 저장');
            return false;
          }
        }

        // if( item.now_qty < 1 ) {
        //   this.toastrService.error( item.product_name + ' 입고수량을 확인하시기 바랍니다.', '구매입고 저장');
        //   return false;

        // } else 
        if( parseInt(item.now_qty) > parseInt( item.qty ) - parseInt( item.in_qty ) ) {
          this.toastrService.error( item.product_name + ' 입고수량이 입고가능 수량을 초과하였습니다.', '구매입고 저장');
          return false;

        } else if( item.in_date.length < 10 ) {
          this.toastrService.error( item.product_name + '입고일자를 선택하시기 바랍니다.', '구매입고 저장');
          return false;

        } else if( parseInt(item.now_qty) > 0 && item.warehouse_in_seq.length < 1 ) {
          this.toastrService.error(item.product_name + '입고창고를 선택하시기 바랍니다.', '구매입고 저장');
          return false;
        }
      }

      this.aStockService.setStockPurchase(this.formPurchase, this.purchaseDetailList).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '구매입고 저장');
    }
  }

  /*******************************************************************************
    설  명 : 발주서 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchPurchase() {
    const modalRef = this.modalService.open(ACPurchaseFindComponent, options);

    modalRef.result.then((result) => {
      this.formPurchase.patchValue({
        purchase_order_seq: result.seq,
        gbn_name: result.gbn_name,
        date: result.date,
        customer_name: result.customer_name,
        customer_seq: result.customer_seq
      });

      // 발주서 상품 상세 리스트 가져오기(미입고 상품만)
      this.getPurchaseOrderSlave( result.seq, false );

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 바코드 입력 모달창
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openModalBarcode() {
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( "발주서 상품을 선택하세요.", '바코드 입력');
      return false;
    } else {
      let rowNode = nodes[0];

      if( rowNode.data.barcode_yn != '1' ) {
        this.toastrService.error( "바코드 상품만 입력이 가능합니다.", '바코드 입력');
        return false;
      } else {
        const modalRef = this.modalService.open(ACBarcodeInputComponent, optionsLG);
        
        modalRef.componentInstance.product_seq = rowNode.data.product_seq;
        modalRef.componentInstance.barcode_yn = rowNode.data.barcode_yn;
        modalRef.componentInstance.barcode = rowNode.data.barcode;
        modalRef.componentInstance.warehouse_seq_out = 'NULL';

        modalRef.result.then((result) => {
          if( typeof result !== 'undefined' ) {

            //let barcodeLines: any = barcode.replace(/\s/gi, "").split('\n');
            const barcodeLines: any = result.barcode.split('\n');
      
            let lineCount: number = 0;
            barcodeLines.forEach(line => {
              if( line != '' ) lineCount++;
            });
      
            rowNode.data.now_qty = lineCount;
            rowNode.data.barcode = result.barcode;

            rowNode.setData( rowNode.data );
          }
        }, (reason) => {
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteStock() {
    if( confirm( "구매입고 된 내역을 삭제하시겠습니까?" ) ) {
      this.aStockService.deleteStockList( this.purchaseDetailList ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '구매입고 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '구매입고 삭제');
        }

        this.activeModal.close();
      }, error => {
        this.toastrService.error( error, '구매입고 삭제');
        this.activeModal.close();
      });
    }
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    //this.getPurchaseOrderSlave( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped( event: any ) {
    const field: any = event.colDef.field;

    // 변경셀이 수량 또는 단가일 때
    if( field == 'barcode' ) {
      const barcode = event.data.barcode;

      //let barcodeLines: any = barcode.replace(/\s/gi, "").split('\n');
      const barcodeLines: any = barcode.split('\n');

      let lineCount: number = 0;
      barcodeLines.forEach(line => {
        if( line != '' ) lineCount++;
      });

      this.purchaseDetailList[ event.node.rowIndex ].now_qty = lineCount;

      event.node.setData( this.purchaseDetailList[ event.node.rowIndex ] );
    }
  }
  
  /*******************************************************************************
    설  명 : 발주서 디테일 가져오기
    입력값 : seq - purchase_order_seq, check = true 전체 가져오기, check = false 입고 완료된 내역 제외
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseOrderSlave( seq: any, check: boolean ) {
    this.purchaseDetailList = [];

    this.aPurchaseOrderService.getPurchaseOrderSlave( seq, check ).then( response => {
      if( response.ResultCode ) {
        this.purchaseDetailList = response.data;

        for( let item of this.purchaseDetailList ) {
          if( item.barcode_yn == '1' ) {
            item.now_qty = 0;
          } else {
            item.now_qty = parseInt(item.qty) - parseInt( item.in_qty );
          }
          item.in_date = moment().format('YYYY-MM-DD');
        }
      }
    }, error => {
      this.toastrService.error( error, '발주서 가져오기');
    });
  }

  /*******************************************************************************
    설  명 : 구매 입고 세부 내역 가져오기
    입력값 : seq - stock_history.seq
    리턴값 : 없음
  *******************************************************************************/
  getStockHistory( seq: any ) {
  }

  /*******************************************************************************
    설  명 : 구매입고 내역을 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStockPurchase() {

    // stock_history.seq
    if( this.check == 'S' ) {
      this.aStockService.getStockPurchase( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.formPurchase.patchValue({
            seq: response.data.seq,
            purchase_order_seq: response.data.purchase_order_seq,
            gbn_name: response.data.gbn_name,
            date: response.data.date,
            customer_name: response.data.customer_name,
            customer_seq: response.data.customer_seq,
            memo: response.data.memo
          });

          // 발주서 상품 상세 리스트 가져오기(미입고 상품만)
          this.purchaseDetailList = response.list;

          for( let item of this.purchaseDetailList ) {
            item.in_qty = parseInt( item.in_qty ) - parseInt( item.now_qty );
          }

        } else {
          this.toastrService.error( response.ResultMessage, '구매입고 가져오기');
          this.activeModal.close();
        }
      }, error => {
        this.toastrService.error( error, '구매입고 가져오기');
        this.activeModal.close();
      });

    // purchase_order.seq
    } else {
      this.aStockService.getPurchaseStock( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.formPurchase.patchValue({
            seq: response.data.seq,
            purchase_order_seq: response.data.purchase_order_seq,
            gbn_name: response.data.gbn_name,
            date: response.data.date,
            customer_name: response.data.customer_name,
            customer_seq: response.data.customer_seq,
            memo: response.data.memo
          });

          // 발주서 상품 상세 리스트 가져오기(미입고 상품만)
          this.getPurchaseOrderSlave( response.data.purchase_order_seq, false );

        } else {
          this.toastrService.error( response.ResultMessage, '구매입고 가져오기');
          this.activeModal.close();
        }
      }, error => {
        this.toastrService.error( error, '구매입고 가져오기');
        this.activeModal.close();
      });
    }
  }

  /*******************************************************************************
    설  명 : 선택한 창고를 일괄 등록한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectWareHouse() {
    let index = 0;

    this.gridApi.forEachNode(function(node, index) {
      this.purchaseDetailList[index].warehouse_in_seq = this.wareHouse_seq;
      node.setData( this.purchaseDetailList[index++] );
    }.bind(this));
  }
}
