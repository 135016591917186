/*******************************************************************************
  설  명 : 현장판매, 현장주문
  작성일 : 2021-01-21
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct, NgbInputDatepicker, NgbCalendar, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';

import { AApplicationService } from '@admin/service/application.service';
import { ACommonService } from '@admin/service/common.service';
import { AProjectService } from '@admin/service/project.service';
import { APaymentService } from '@admin/service/payment.service';
import { AProductService } from '@admin/service/product.service';
import { ACustomerService } from '@admin/service/customer.service';

import { ACProductFindGridComponent } from '@admin/component/product-find-grid/product-find-grid.component';
import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { NonMemberAddComponent } from '@admin/page/order/application/non-member-add/non-member-add.component';

import * as moment from 'moment';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectSeq: any = '';
  public orderSeq: any = '';
  public setIndex: any = '';
  public statusCode: any = '';
  public deliveryAdd: boolean;
  
  public form: FormGroup;
  public formErrors = {};

  public orderInfo: any = {};
  public projectInfo: any = {};
  public payInfo: any = {};
  public productInfo: any = {};
  public payMethodCardList: any = [];
  public payMethodBankList: any = [];

  public params: any = {
    seq: '',
    date_group: '',
    sdate: '',
    edate: '',
    date_index: '',
    project_seq: '',
    project_name: '',
    customer_seq: '',
    customer_name: '',
    status: '',
    searchText: ''
  };

  public baseURL: string = config.baseUrl;

  public projectTypeList: any = [];
  public orderStatusList: any = [];
  public originProjectList: any = [];
  public projectList: any = [];

  public addMemberFunc: any;            // 회원검색
  public addCustomerFunc: any;          // 거래처검색
  public changedFunc: any;              // 전시/행사 변경시 처리 함수
  public dateChangedFunc: any;              // 주문일자 변경시 처리 함수

  public select2Options: any = {
    multiple: false
  };

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  // 주문시 원래 주소 정보
  private addressInfo: any = {
    zipcode: '',
    address: '',
    address_detail: ''
  };

  title: any = '';

  /*******************************************************************************
     설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let todayModel: NgbDateStruct = this.utilService.getDate('');

    this.form = this.formBuilder.group({
      seq: ['', []],
      old_status_code: ['', []],
      status_code: ['', [Validators.required]],
      order_date: [todayModel, []],
      confirmation_date: [todayModel, []],
      customer_seq: ['', []],
      customer_name: ['', []],
      mem_no: ['', [Validators.required]],
      mem_name: ['', [Validators.required, Validators.maxLength(20)]],
      mem_hp: ['', [Validators.maxLength(20), CustomValidator.phone]],
      mem_email: ['', []],
      project_group: ['', [Validators.required]],
      project_seq: ['', []],
      project_name: ['', []],
      rental_sdate: [todayModel, [Validators.required]],
      rental_edate: [todayModel, [Validators.required]],
      zipcode:['', [Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric] ],
      address:['', [Validators.maxLength(100)] ],
      address_detail:['', [Validators.maxLength(100)] ],
      project_place: ['', []],
      delivery_amt: ['', []],
      memo: ['', [Validators.maxLength(255)]],
      admin_memo: ['', []],
      tax_yn: ['', []],
      detail: [[], []],
      install_date: [[], []],
      install_time: [[], []],
      place: [[], []],
      person_name: [[], []],
      person_hp: [[], []],
      person_email: [[], []],
      qty: [[], []],
      unit_amt: [[], []],
      dc_amt: [[], []],
      vat: [[], []],
      amt: [[], []],
      charge_status: ['', []],
      pay_method: ['', [Validators.required]],
      pay_amt: ['', [Validators.required]],
      app_date: ['', [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aApplicationService: AApplicationService,
    private aProjectService: AProjectService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    private aPaymentService: APaymentService,
    private aProductService: AProductService,
    private aCustomerService: ACustomerService
  ) {
    // 폼 빌드
    this.buildForm();

    this.addMemberFunc = this.searchMember.bind(this);
    this.addCustomerFunc = this.searchCustomer.bind(this);
    this.changedFunc = this.select2Changed.bind(this);
    this.dateChangedFunc = this.orderDateChanged.bind(this);
  }

  /*******************************************************************************
     설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    this.form.patchValue({
      status_code: this.statusCode
    });

    //if( this.deliveryAdd != true ) {
      //this.form.patchValue({
        //seq: this.orderSeq
      //});
    //}

    if( this.deliveryAdd == true ) {
      this.title = '배송료 추가주문';
    } else if( this.orderSeq != '' ) {
      this.title = '현장주문';
    } else {
      this.title = '현장판매';
    }

    if( this.orderSeq != '' ) {
      // 주문 정보 가져오기
      await this.getApplicationDetail();
    }

    // 셀렉트박스 리스트 가져오기
    await this.getSelectBoxList();

    // 결제방법 리스트 가져오기
    await this.getPayMethodList();

    // 프로젝트 정보 가져오기
    await this.getProjectInfo();

    if( this.deliveryAdd == true ) {
      this.form.get('pay_method').setValidators( [] );
      this.form.get('pay_method').updateValueAndValidity();
      this.form.get('pay_amt').setValidators( [] );
      this.form.get('pay_amt').updateValueAndValidity();
      this.form.get('app_date').setValidators( [] );
      this.form.get('app_date').updateValueAndValidity();

      // 배송료 추가 시 초기셋팅
      await this.insertDeliverySet();
    } else {
      // 부스 초기셋팅
      await this.insertSet();
    }
  }

  /*******************************************************************************
    설  명 : 관리자주문 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getApplicationDetail() {
    await this.aApplicationService.getApplicationDetail( this.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;

        this.form.patchValue({
          mem_no: this.orderInfo.mem_no,
          mem_name: this.orderInfo.mem_name,
          mem_hp: this.orderInfo.mem_hp,
          customer_seq: this.orderInfo.customer_seq,
          customer_name: this.orderInfo.customer_name,
          memo: this.orderInfo.memo,
          admin_memo: this.orderInfo.admin_memo
        });

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

    }, error => {
      this.toastrService.error( error, '' );
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectInfo() {
    this.aProjectService.getProjectInfo( this.projectSeq ).then( response => {
      if( response.ResultCode ) {        
        this.form.patchValue({
          project_group: response.data.type,
          project_seq: response.data.seq,
          project_name: response.data.name,
          project_place: response.data.place,
          zipcode: response.data.zipcode,
          address: response.data.address,
          address_detail: response.data.address_detail
        })
        
      } else {
        this.toastrService.error( response.ResultMessage, "프로젝트 정보" );
        this.activeModal.close(false);
        return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 결제 방법 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPayMethodList() {
    this.aPaymentService.getPayMethodList().then( response => {
      if( response.ResultCode ) {
        let card: any = [];
        for(let item of response.card ) {
          card.push({
            title: item.name,
            value: item.code
          })
        }

        let bank: any = [];
        for(let item of response.bank ) {
          bank.push({
            title: item.name,
            value: item.code
          })
        }

        this.payMethodCardList = card;
        this.payMethodBankList = response.bank;
      } else {
        this.payMethodCardList = [];        
        this.payMethodBankList = [];
      }
    })
  }

  /*******************************************************************************
     설  명 : 셀렉트박스 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getSelectBoxList() {
    // 프로젝트 구분
    await this.aCommonService.getCommonListCode('PJT').then( response => {
      if ( response.ResultCode ) {
        this.projectTypeList = response.data;
      } else {
        this.projectTypeList = [];
      }
    });

    // 주문상태
    await this.aCommonService.getCommonListCode('ORS').then( response => {
      if ( response.ResultCode ) {
        let data: any = [];
        for( let item of response.data ) {
          data.push({
            title: item.common_name,
            value: item.common_code
          })
        }

        this.orderStatusList = data;
      } else {
        this.orderStatusList = [];
      }
    });

    // 전시/행사
    await this.aProjectService.getProjectList({type: '', searchGroup: '', searchText: '', status: '', sdate: '', edate: ''}).then( response => {
      if( response.ResultCode ) {
        this.originProjectList = response.data;
        let data: any = [];

        data.push({
          text: '전시/행사를 선택하세요',
          id: 0
        });

        for( let item of this.originProjectList ) {
          data.push({
            text: item.name + ' (' + item.start_date + '~' + item.end_date + ')',
            id: item.seq
          })
        }

        this.projectList = data;
      } else {
        this.projectList = [];
      }
    });
  }

  /*******************************************************************************
     설  명 : 주문일자 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderDateChanged( event: any ) {
    let data: any = [];

    data.push({
      text: '전시/행사를 선택하세요',
      id: 0
    });

    for( let item of this.originProjectList ) {
      const orderDate = event.year + '-' + ( event.month < 10 ? '0' + event.month : event.month ) + '-' + ( event.day < 10 ? '0' + event.day : event.day );

      const orderDateMonth2 = moment(orderDate).add(2, 'months').format('YYYY-MM-DD');

      if( orderDate <= item.start_date && item.start_date <= orderDateMonth2 ) {
        data.push({
          text: item.name + ' (' + item.start_date + '~' + item.end_date + ')',
          id: item.seq
        });
      }
    }

    this.projectList = data;
  }

  /*******************************************************************************
     설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( item: any, obj: NgbInputDatepicker ) {
    item.install_date = this.calendar.getToday();
    obj.close();
  }

  /*******************************************************************************
     설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(ACMemberFindComponent, optionsLG);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        mem_name: result.data.name,
        mem_hp: result.data.hp,
        mem_email: result.data.email
      });

      $(window).scrollTop(0);

    }, (reason) => {
    });
  }

  /*******************************************************************************
     설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        customer_seq: result.seq,
        customer_name: result.name
      });

      let set: any = [];
      
      set = this.form.controls.detail.value;

      this.aCustomerService.getProductDiscountList( result.seq ).then( response => {
        //console.log(response);
        if( response.ResultCode ) {
          
          set.forEach(row => {
            row.product.forEach(item2 => {
              for( let item of response.data ) {
                if( item.seq == item2.product_seq ) {
                  item2.discount_product_amt = parseInt(item.discount_amt);
                  item2.dc_amt = parseInt(item2.unit_amt) - item2.discount_product_amt;

                  if( item2.qty === 0 ) item2.qty = 1;

                  item2.amt = ( ( item2.unit_amt - item2.dc_amt ) * item2.qty );
                  item2.vat = ( item2.tax_yn == '1' ) ? item2.amt / 10 : 0;
                  item2.total_amt = parseInt(item2.amt) + parseInt(item2.vat);
                }
              }
            });

          });

          this.calcSetTotalAmt();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        console.log( error );
      });

      $(window).scrollTop(0);

    }, (reason) => {
    });
  }

  /*******************************************************************************
     설  명 : 전시/행사 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  select2Changed( e: any): void {
    if( e.id == 0 ) {
      this.form.patchValue({
        project_seq: 0,
        rental_sdate: null,
        rental_edate: null,
        zipcode: this.addressInfo.zipcode,
        address: this.addressInfo.address,
        address_detail: this.addressInfo.address_detail
      });

    } else {
      for( let item of this.originProjectList ) {
        if( item.seq == e.id ) {
          let start_date: NgbDateStruct = this.utilService.getDate( item.start_date );
          let end_date: NgbDateStruct = this.utilService.getDate( item.end_date );

          if( item.type_code == '0002' ) {
            this.form.patchValue({
              project_seq: item.seq,
              project_place: item.place,
              rental_sdate: start_date,
              rental_edate: end_date,
            });
          } else {
            this.form.patchValue({
              project_seq: item.seq,
              project_place: item.place,
              rental_sdate: start_date,
              rental_edate: end_date,
              zipcode: item.zipcode,
              address: item.address,
              address_detail: item.address_detail
            });
          }

          break;
        }
      }
    }
  }

  /*******************************************************************************
     설  명 : 부스 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  insertSet() {
    let set: any = [];
    set = this.form.controls.detail.value;

    const tmp: any = {
      seq: '',
      order_seq: ( this.orderSeq ) ? this.orderSeq : '',
      set_index: ( this.setIndex ) ? this.setIndex : 0,
      rental_sdate: ( this.orderInfo.rental_sdate ) ? this.orderInfo.rental_sdate : '',
      rental_edate: ( this.orderInfo.rental_edate  ) ? this.orderInfo.rental_edate : '',
      install_date: this.calendar.getToday(),
      install_time: '14:00',
      place: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].place : '',
      person_name: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].person_name : '',
      person_hp: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].person_hp : '',
      person_email: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].person_email : '',
      mng_person: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].mng_person : '',
      //set_total_amt: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].set_total_amt : 0,
      set_total_amt: 0,
      //product: ( this.orderInfo.detail ) ? this.orderInfo.detail[ this.setIndex ].product : [],
      product: [],
      isChecked: false
    };

    set.push( tmp );

    this.form.patchValue({
      detail: set
    });
  }

  /*******************************************************************************
     설  명 : 부스 추가 후 배송료 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  insertDeliverySet() {
    let set: any = [];
    set = this.form.controls.detail.value;

    const tmp: any = {
      seq: '',
      order_seq: ( this.orderSeq ) ? this.orderSeq : '',
      set_index: 0,
      rental_sdate: ( this.orderInfo.rental_sdate ) ? this.orderInfo.rental_sdate : '',
      rental_edate: ( this.orderInfo.rental_edate  ) ? this.orderInfo.rental_edate : '',
      install_date: this.calendar.getToday(),
      install_time: '14:00',
      place: '',
      person_name: '',
      person_hp: '',
      person_email: '',
      mng_person: '',
      set_total_amt: 0,
      product: [],
      isChecked: ( this.orderSeq ) ? true : false
    };

    set.push( tmp );

    this.form.patchValue({
      detail: set
    });

    this.aProductService.getCategoryProductDiscountList( this.form.controls.mem_no.value, this.form.controls.project_seq.value ).then( response => {
      if( response.ResultCode ) {

        response.data.forEach(item => {
          if( item.category_code == '009' ) {   // 관리상품(category_code:009)
            item.sub.forEach(subItem => {
              if( subItem.product_seq == '1' ) {    // 배송료(product.seq:1)
                this.productInfo = subItem;
              }
            });
          }
        });

        let set: any = [];

        set = this.form.controls.detail.value;

        const duplicate = set[0].product.filter(function (product) {
          return product.product_seq === '1';
        });

        if( duplicate.length > 0 ) {
          this.toastrService.error( '이미 배송료가 추가되어 있습니다.', '' );
          return false;
        } else {
          // 상품 정보
          set[0].product.push({
            amt: this.productInfo.sale_amt,
            category_name: this.productInfo.category_name,
            category_seq: this.productInfo.category_seq,
            dc_amt: '0',
            dc_rate: '0',
            dc_seq: '0',
            file_path: this.productInfo.file_path,
            parent_seq: null,
            product_name: this.productInfo.name,
            product_seq: this.productInfo.product_seq,
            product_spec: this.productInfo.spec,
            product_unit: this.productInfo.unit,
            qty: 1,
            rental_daycount: null,
            rental_diff: ( this.orderInfo.detail[0] ) ? this.orderInfo.detail[0].rental_diff : null,
            rental_edate: ( this.orderInfo.detail[0] ) ? this.orderInfo.detail[0].rental_edate : null,
            rental_sdate: ( this.orderInfo.detail[0] ) ? this.orderInfo.detail[0].rental_sdate : null,
            seq: null,
            set_index: '0',
            status: null,
            status_code: null,
            status_name: null,
            thumbnail_filename: this.productInfo.thumbnail_filename,
            total_amt: this.productInfo.total_amt,
            unit_amt: this.productInfo.sale_amt,
            vat: ( this.productInfo.tax_yn == '1' ) ? this.productInfo.sale_amt / 10 : 0,
            tax_yn: this.productInfo.tax_yn
          });
        }

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '' );
    });
  
  }

  /*******************************************************************************
     설  명 : 부스 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteSet() {
    let set: any = [];
    const tmp: any = [];

    set = this.form.controls.detail.value;

    let index = 0;
    set.forEach(item => {
      if( typeof item.isChecked != 'undefined' && item.isChecked === true ) {
        index++;
      } else {
        tmp.push(item);
      }
    });

    if( index < 1 ) {
      this.toastrService.error( '삭제할 부스를 선택하시기 바랍니다.', '' );
    } else {
      this.form.patchValue({
        detail: tmp
      });
    }
  }

  /*******************************************************************************
     설  명 : 부스 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addSetProduct() {
    let set: any = [];

    set = this.form.controls.detail.value;

    let index = 0;
    set.forEach(item => {
      if( typeof item.isChecked != 'undefined' && item.isChecked === true ) {
        index++;
      }
    });

    if( index < 1 ) {
      this.toastrService.error( '추가할 부스를 선택하시기 바랍니다.', '' );
    } else {
      const modalRef = this.modalService.open(ACProductFindGridComponent, optionsXXL);

      modalRef.componentInstance.customer_seq = this.form.controls.customer_seq.value;
      modalRef.componentInstance.project_seq = this.form.controls.project_seq.value;
      modalRef.componentInstance.mem_no = this.form.controls.mem_no.value;

      modalRef.result.then(( result ) => {
        result.forEach(obj => {
          set.forEach(item => {
            if( typeof item.isChecked != 'undefined' && item.isChecked === true ) {

              const duplicate = item.product.filter(function (product) {
                return product.product_seq === obj.product_seq;
              });

              if( duplicate.length > 0 ) {
                if( duplicate[0].status > 10 ) {
                  this.toastrService.error( '취소/환불 상태의 같은 상품이 존재합니다. 기존 상품을 삭제 후 등록하시기 바랍니다.', '' );
                  return false;
                } else {
                  item.product.forEach(row => {
                    if( duplicate[0].product_seq == row.product_seq ) {
                      row.qty = parseInt(row.qty) + 1;

                      this.setCalc( row );
                    }
                  });
                }
              } else {

                const calc_amt: number = obj.discount_product_amt * 1;
                const dc_amt: number = obj.sale_amt - obj.discount_product_amt;
                const calc_vat: number = ( obj.tax_yn == '1' ) ? calc_amt / 10 : 0;
                const total_amt: number = calc_amt + calc_vat; 
                
                item.product.push({
                  amt: this.setComma(calc_amt),
                  category_name: obj.category_name,
                  category_seq: obj.category_seq,
                  dc_amt: this.setComma(dc_amt),
                  dc_rate: '',
                  dc_seq: '',
                  file_path: obj.file_path,
                  parent_seq: null,
                  product_name: obj.name,
                  product_seq: obj.product_seq,
                  product_spec: obj.spec,
                  product_unit: obj.unit,
                  qty: '1',
                  rental_daycount: null,
                  rental_diff: item.rental_diff,
                  rental_edate: item.rental_edate,
                  rental_sdate: item.rental_sdate,
                  seq: null,
                  set_index: item.set_index,
                  status: null,
                  status_code: null,
                  status_name: null,
                  thumbnail_filename: obj.thumbnail_filename,
                  total_amt: this.setComma(total_amt),
                  unit_amt: this.setComma(obj.sale_amt),
                  vat: this.setComma(calc_vat),
                  tax_yn: obj.tax_yn
                });
              }
            }
       
          });

        });

        this.calcSetTotalAmt();

      }, (reason) => {
        // console.log( reason );
      });
    }
  }

  /*******************************************************************************
     설  명 : 주문정보 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderInfoOfflineSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {

      // 상품이 있는지 체크
      let tmp: any = [];
      let check: boolean;

      tmp = this.form.controls.detail.value;

      tmp.forEach(item => {
        if( item.product.length < 1 ) {
          check = true;
        }
      });

      if( check === true ) {
        this.toastrService.error('부스마다 하나 이상의 상품을 추가하시기 바랍니다.', '' );

      } else {
        this.aApplicationService.setOrderInfoOfflineSave( this.form ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.activeModal.close(true);
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 주문 공통 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailCommonInfoSave( item: any ) {
    if( confirm( '저장하시겠습니까?' ) ) {
      const params: any = {
        order_seq: item.order_seq,
        set_index: item.set_index,
        install_date: item.install_date,
        install_time: item.install_time,
        place: item.place,
        person_name: item.person_name,
        person_hp: item.person_hp,
        person_email: item.person_email
      };

      this.aApplicationService.setOrderDetailCommonInfoSave( params ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      }, error => {
        this.toastrService.error( error, '' );
      });
    }
  }

  /*******************************************************************************
     설  명 : 주문 상품 정보 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailProductInfoSave( item: any ) {
    if( confirm( '변경 시 화면이 새로고침 됩니다. 진행하시겠습니까?' ) ) {
      const params: any = {
        seq: item.seq,
        qty: item.qty,
        unit_amt: item.unit_amt,
        dc_amt: item.dc_amt,
        vat: item.vat,
        amt: item.amt,
        tax_yn: item.tax_yn
      };

      this.aApplicationService.setOrderDetailProductInfoSave( params ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );

          //this.getApplicationDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      }, error => {
        this.toastrService.error( error, '' );
      });
    }
  }

  /*******************************************************************************
     설  명 : 주문 상품 정보 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailProductInfoDelete( item: any, detailIndex: number, productIndex: number ) {
    if( item.seq !== null ) {
      if( confirm( '삭제 시 화면이 새로고침 됩니다. 진행하시겠습니까?' ) ) {
        const params: any = {
          seq: item.seq
        };

        this.aApplicationService.setOrderDetailProductInfoDelete( params ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '' );

            //this.getApplicationDetail();
          } else {
            this.toastrService.error( response.ResultMessage, '' );
          }
        }, error => {
          this.toastrService.error( error, '' );
        });
      }
    } else {
      if( confirm( '삭제하시겠습니까?' ) ) {
        const set = this.form.controls.detail.value;

        set[ detailIndex ].product.splice( productIndex, 1 );

        this.form.patchValue({detail: set});

        this.calcSetTotalAmt();
      }
    }
  }

  /*******************************************************************************
     설  명 : 주문 상품 수량/금액 변경 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalc( item: any ) {
    if( item.qty === 0 ) item.qty = 1;

    item.unit_amt = this.deleteComma(item.unit_amt);
    item.dc_amt = this.deleteComma(item.dc_amt);
    item.vat = this.deleteComma(item.vat);
    item.total_amt = this.deleteComma(item.total_amt);

    item.amt = ( ( item.unit_amt - item.dc_amt ) * item.qty );
    item.vat = ( item.tax_yn == '1' ) ? item.amt / 10 : 0;
    item.total_amt = parseInt(item.amt) + parseInt(item.vat);

    item.unit_amt = this.setComma(item.unit_amt);
    item.dc_amt = this.setComma(item.dc_amt);
    item.vat = this.setComma(item.vat);
    item.total_amt = this.setComma(item.total_amt);

    this.calcSetTotalAmt();
  }

  /*******************************************************************************
     설  명 : 주문 상품 부가세 변경 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalcVat( item: any ) {
    item.unit_amt = this.deleteComma(item.unit_amt);
    item.dc_amt = this.deleteComma(item.dc_amt);
    item.vat = this.deleteComma(item.vat);
    item.total_amt = this.deleteComma(item.total_amt);

    item.total_amt = parseInt(item.amt) + parseInt(item.vat);

    item.unit_amt = this.setComma(item.unit_amt);
    item.dc_amt = this.setComma(item.dc_amt);
    item.vat = this.setComma(item.vat);
    item.total_amt = this.setComma(item.total_amt);

    this.calcSetTotalAmt();
  }
  
  /*******************************************************************************
     설  명 : 소계 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcSetTotalAmt() {
    let set: any = [];

    set = this.form.controls.detail.value;

    set.forEach(item => {
      let setTotalAmt = 0;
      item.product.forEach(item2 => {
        setTotalAmt += parseInt(this.deleteComma(item2.total_amt));
      });

      item.set_total_amt = setTotalAmt;
    });
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  removeCommas( row: any, key: any ) {
    row[ key ] = this.deleteComma(row[ key ]);
  }

  /*******************************************************************************
    설  명 : 콤마 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCommas( row: any, key: any ) {
    row[ key ] = this.setComma(row[ key ]);
  }
  
  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  setComma( value: any ) {
    let str = String(value);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  deleteComma( value: any ) {
    let str = String(value);
    return str.replace(/,/g, '');
  }

  /*******************************************************************************
     설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
     설  명 : 비회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nonMemberAdd() {
    const modalRef = this.modalService.open(NonMemberAddComponent, options);

    modalRef.result.then((result) => {
      this.form.patchValue({
        mem_no: result.mem_no,
        mem_name: result.name,
        mem_hp: result.hp,
        mem_email: result.email
      });

    }, (reason) => {
      // console.log( reason );
    });
  }

}
