/*******************************************************************************
  설  명 : 회원 1:1문의 컴포넌트
  작성일 : 2020-08-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ABoardService } from '@admin/service/board.service';
import { UtilService } from '@app/service/util.service';
import { AApplicationService } from '@admin/service/application.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-qna',
  templateUrl: './member-qna.component.html',
  styleUrls: ['./member-qna.component.scss']
})
export class ACMemberQnaComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() mem_no: any;

  selectedRows: number = 0;
  boardList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aApplicationService: AApplicationService,
    private agGridHtmlComponent: AgGridHtmlComponent,
    private aBoardService: ABoardService
  ) {
    this.columnDefs = [
      {headerName: '번호', field: 'board_seq', cellClass: 'cp center', width: 80 },
      {headerName: '진행상태', field: 'status', cellClass: 'cp center', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '0' )
            return '<span class="badge badge-success f12 fn">진행</span>';
          else
            return '<span class="badge badge-primary f12 fn">완료</span>';
        }
      },
      {headerName: '제목', field: 'subject', cellClass: 'cp', width: 500 },
      {headerName: '등록일', field: 'write_date', cellClass: 'cp center', width: 130 },
      {headerName: '답변자', field: 'reply_name', cellClass: 'cp center', width: 130 },
      {headerName: '답변일자', field: 'reply_date', cellClass: 'cp center', width: 130 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getBoardQNAList();
  }

   /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 처리 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 1:1 문의 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardQNAList() {
    this.aBoardService.getBoardQNAList( this.mem_no ).then( response => {
      if ( response.ResultCode ) {
        this.boardList = response.data;
      } else {
        this.boardList = [];
      }
    });
  }

}
