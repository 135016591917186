import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-move',
  templateUrl: './stock-move.component.html',
  styleUrls: ['./stock-move.component.scss']
})
export class ACStockMoveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
