import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ASystemAuthGroupService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 권한그룹 리스트 가져오기
  getAuthGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'authgroup',
        version: '1.0',
        action: 'getAuthGroupList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 권한그룹 리스트 가져오기
  getAuthGroupInfo(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'authgroup',
        version: '1.0',
        action: 'getAuthGroupInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 권한그룹 정보 저장하기
  setAuthGroup( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'authgroup',
        version: '1.0',
        action: 'setAuthGroup'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 권한그룹 리스트 저장하기
  setAuthGroupList( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'authgroup',
        version: '1.0',
        action: 'setAuthGroupList'
      }, form
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 정보 삭제하기
  deleteAuthGroup( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'authgroup',
        version: '1.0',
        action: 'deleteAuthGroup'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
