import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ATaxService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 세금계산서 위젯 통계 가져오기
  getTaxStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'tax',
        version: '1.0',
        action: 'getTaxStatistic'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세금계산서 리스트 가져오기
  getTaxList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'tax',
        version: '1.0',
        action: 'getTaxList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세금계산서 상세정보 가져오기
  getTaxDetail( taxSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'tax',
        version: '1.0',
        action: 'getTaxDetail'
      }, {
        seq: taxSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세금계산서 발행
  setInvoicePublish( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'tax',
        version: '1.0',
        action: 'setInvoicePublish'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 세금계산서 재발송
  setInvoiceReSend( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'tax',
        version: '1.0',
        action: 'setInvoiceReSend'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
