/*******************************************************************************
  설  명 : 회원 견적요청 컴포넌트
  작성일 : 2020-08-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { ABoardService } from '@admin/service/board.service';
import { AApplicationService } from '@admin/service/application.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';


@Component({
  selector: 'app-order-estimate',
  templateUrl: './order-estimate.component.html',
  styleUrls: ['./order-estimate.component.scss']
})
export class AOrderEstimateComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  estimateList: any = [];

  public sendList: any = [];
  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  dateModel: NgbDateStruct;

  totalAmt: number = 0;

  search: any = {
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    searchText: '',
    dateType: '',
    term: ''
  };

  statistic: any = {
    complete_count: 0,
    uncompleted_count: 0
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aBoardService: ABoardService,
    private aApplicationService: AApplicationService,
    private calendar: NgbCalendar,
    private agGridHtmlComponent: AgGridHtmlComponent
  ) {
    this.columnDefs = [
      { headerName: '', field: 'seq', cellClass: 'cp', width: 40,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '회원번호', field: 'mem_no', cellClass: 'cp center', width: 80 },
      { headerName: '아이디', field: 'id', cellClass: 'cp center', width: 80 },
      { headerName: '회원명', field: 'name', cellClass: 'cp center', width: 80 },
      { headerName: '제목', field: 'subject', cellClass: 'cp center', width: 200 },
      { headerName: '구분', field: 'project_group_name', cellClass: 'cp center', width: 100 },
      { headerName: '전시/행사명', field: 'project_name', cellClass: 'cp', width: 160 },
      { headerName: '장소', field: 'project_place', width: 180, cellClass: 'cp right'},
      { headerName: '기간', field: 'notice_date', cellClass: 'cp center', width: 150 },
      { headerName: '요청내용', field: 'contents', cellClass: 'cp', width: 300 },
      { headerName: '신청일시', field: 'write_date', cellClass: 'cp center', width: 130 },
      { headerName: '상태', field: 'status', cellClass: 'cp center', width: 90, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1' ) return '<span class="badge badge-success f12 fn">처리완료</span>';
          else return '<span class="badge badge-secondary f12 fn">진행중</span>';
        }
      }
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.searchInit();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

   /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( event: any ) {
    this.router.navigate(
      ['/order/estimate/detail'],
      {
        relativeTo: this.activatedRoute,
        queryParams: { boardSeq: event.data.board_seq },
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    // 검색 필드
    this.search = {
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      searchText: '',
      dateType: '',
      term: ''
    };

    this.setSearchDate('1', 'months');

    this.getEstimateList();
  }

  /*******************************************************************************
    설  명 : 날짜 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    this.search.term = term;
    this.search.dateType = dateType;

    if( term == '' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      this.dateModel = {
        year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
        month: parseInt( moment().subtract(term, dateType).format('MM') ),
        day: parseInt( moment().subtract(term, dateType).format('DD') )
      };

      this.search.sdate = this.dateModel;
      this.search.edate = this.calendar.getToday();
    }

    this.getEstimateList();
  }

  /*******************************************************************************
    설  명 : 견적문의 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEstimateList() {
    this.aBoardService.getEstimateList( this.search ).then( response => {
      // console.log( response );
      if( response.ResultCode ) {
        this.estimateList = response.data;
        this.statistic = response.statistic;
      } else {
        this.estimateList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getEstimateList();
    }
  }

}
