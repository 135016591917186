/*******************************************************************************
  설  명 : 헤더 영역
  작성일 : 2020-09-16
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';
import { AGnbService } from '@admin/service/gnb.service';

import { ToastrService } from 'ngx-toastr';

import { ACommonService } from '@admin/service/common.service';

import * as moment from 'moment';

@Component({
  selector: 'app-aheader',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AheaderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isUserToggle: boolean = false;
  public isSearchToggle: boolean = false;
  public isMenuSmallToggle: boolean = false;

  @ViewChild('inputSearch', {static: false}) inputEl:ElementRef;

  public statistic: any = {
    asCount: 0,
    orderCount: 0,
    qnaCount: 0,
    estimateCount: 0,
    memberIcon: ''
  }

  public searchText: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private memberService: MemberService,
    private gnbService: AGnbService,
    private aCommonService: ACommonService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 헤더 통계 데이터 가져오기
    this.getHeaderCount();
  }

  /*******************************************************************************
    설  명 : 메뉴 슬라이드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  menu_slide() {
    this.isMenuSmallToggle = ! this.isMenuSmallToggle;
    this.aCommonService.menuSmall$.next( this.isMenuSmallToggle );
  }

  /*******************************************************************************
    설  명 : 검색창 toggle
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  search_toggle() {
    this.isSearchToggle = ! this.isSearchToggle;

    if( this.isSearchToggle ) {
      setTimeout(() => this.inputEl.nativeElement.focus());
    }
  }

  /*******************************************************************************
    설  명 : 로그인 유저 메뉴 토글
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loginUserTooltip() {
    this.isUserToggle = ! this.isUserToggle;
  }

  /*******************************************************************************
    설  명 : 로그아웃 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onLogout() {
    this.memberService.logout().then( response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response['ResultMessage'], '');

        this.authService.logout();
        this.router.navigate(['/login']);
      } else {
        this.toastrService.error(response['ResultMessage'], '');
      }
    })
    .catch(response => {
    });
  }

  /*******************************************************************************
    설  명 : 주문 갯수 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getHeaderCount() {
    this.gnbService.getHeaderCount().then( response => {
      if( response.ResultCode ) {
        this.statistic = response.statistic;

      } else {
        this.statistic = {
          asCount: 0,
          orderCount: 0,
          qnaCount: 0,
          estimateCount: 0,
          memberIcon: ''
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      let params: any = {
        date_group: '',
        sdate: '',
        edate: '',
        date_index: '',
        project_seq: '',
        project_name: '',
        customer_seq: '',
        customer_name: '',
        status: '',
        searchText: this.searchText,
        refresh: moment().format('YYYYMMDDhhmmss')
      };

      this.router.navigate(
        ['/order/application'],
        {
          relativeTo: this.activatedRoute,
          queryParams: params,
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );
    }
  }

}
