import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ASMSService } from '@admin/service/sms.service';
import { MemberFindComponent } from '@admin/page/member/member-find/member-find.component';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss']
})
export class ACSmsModalComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 발송 휴대폰 리스트
  public sendList: any;

  public autoList: any = [];

  public formSMS: FormGroup;
  public formErrors = {
    message: ''
  };

  // 그리드 관련 선언
  selectedRows = 0;
  selectedRowsMember = 0;

  smsList: any = [];
  memberList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  gridApiMember: any;
  gridColumnApiMember: any;
  columnDefsMember: any;
  rowSelectionMember: any;

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formSMS = this.formBuilder.group({
      message: [ '', [Validators.required, Validators.maxLength(80)] ],
      title: [ '', [Validators.required, Validators.maxLength(100)] ],
      receipt_tel: [ '', [Validators.required] ],
      send_type: [ 'N', [] ],
      send_date: [ '', [Validators.maxLength(20)] ],
      callback: [ '0319185320', [Validators.required, Validators.maxLength(20)] ],
    });

    this.formSMS.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formSMS, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private aSMSService: ASMSService
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefsMember = [
      {
        headerName: '', field: 'mem_no', cellClass: 'cp right', width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {headerName: '회원', field: 'name', cellClass: 'cp', width: 70 },
      {headerName: '휴대폰', field: 'mobile', cellClass: 'cp', width: 100 },
      {headerName: '아이디', field: 'id', cellClass: 'cp', width: 80 },
    ];

    this.columnDefs = [
      { headerName: '번호', field: 'seq', cellClass: 'cp center', width: 85,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {headerName: '자동발송', field: 'common_name', cellClass: 'cp', width: 100 },
      {headerName: '제목', field: 'title', cellClass: 'cp', width: 120 },
      {headerName: '발송내용', field: 'contents', cellClass: 'cp', width: 180 },
      {headerName: '자동발송 코드', field: 'auto_seq', hide: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true
    };

    this.rowSelectionMember = 'multiple';
    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }


  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

    setTimeout(() => {      // ==> ExpressionChangedAfterItHasBeenCheckedError 에러처리

      // SMS 발송 양식 가져오기
      this.getSMSFormList().then( () => {
        const tmp: any = [];

        this.sendList.forEach( row => {
          if ( row.memberHp !== '' ) {
            tmp.push({
              mem_no: row.mem_no,
              name: row.name,
              mobile: row.mobile,
              id: row.id,
            });
          }
        });

        this.memberList = tmp;

        this.formSMS.patchValue({
          receipt_tel: this.memberList
        });
      });

    });
  }

  /*******************************************************************************
    설  명 : SMS 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSMSFormList() {
    return this.aSMSService.getSMSFormList({searchText: ''}).then( response => {
      if ( response.ResultCode ) {
        this.smsList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });
  }

  /*******************************************************************************
    설  명 : 한글 포함 문자열 갯수 처리
    입력값 : 문자열
    리턴값 : 문자수
  *******************************************************************************/
  getTextLength(str) {
    let len = 0;

    for (let i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }
    return len;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.formSMS.patchValue({
      title: $event.data.title,
      message: $event.data.contents
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyMember(params) {
    this.gridApiMember = params.api;
    this.gridColumnApiMember = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedMember($event) {
    let filteredRowCount = 0;
    this.gridApiMember.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRowsMember = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 선택된 발송 회원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteMember() {
    const nodes: any = this.gridApiMember.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error('삭제할 휴대폰 번호를 선택하세요.');

      return false;
    }

    if ( ! confirm('선택하신 휴대폰 번호를 삭제하시겠습니까?') ) {
      return false;
    }

    const removedRows: any = [];

    for (let node of nodes ) {
      removedRows.push( node );
      this.gridApiMember.updateRowData({remove: [node]});

      const idx = this.memberList.indexOf(node);
      if (idx > -1) {
        this.memberList.splice(idx, 1);
      }
    }
  }

  /*******************************************************************************
    설  명 : 발송 인원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMember() {
    const modalRef = this.modalService.open(MemberFindComponent, options);

    modalRef.componentInstance.emitData.subscribe(($e) => {
      const checkOverlap = this.memberList.filter( item => item.mem_no === $e.mem_no );

      if ( checkOverlap.length > 0 ) {
        this.toastrService.error('이미 추가된 회원입니다.');
        return false;
      } else {
        let newRow: any = {};
        newRow = {
          mem_no: $e.mem_no,
          name: $e.name,
          mobile: $e.hp,
          id: $e.id,
        };

        this.memberList.push(newRow);
        this.gridApiMember.updateRowData({add: [newRow]});
      }
    });

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  // /*******************************************************************************
  //   설  명 : SMS 발송
  //   입력값 : 없음
  //   리턴값 : 없음
  // *******************************************************************************/
  // submit() {
  //   const word = ['#고객명', '#오늘날짜', '#결제일', '#신청일', '#아이디', '#결제금액' ];
  //   let smsMessage = this.formSMS.controls.message.value;
  //   const messageTemp = smsMessage;
  //   const smsMembers = this.gridApiMember.getSelectedRows();

  //   if (smsMembers.length < 1) {
    //     this.toastrService.error('발송할 회원을 선택하세요.');
  //     return;
  //   }

  //   // 발송될 회원수를 확인하여 메세지 변수 값을 members Param에 넣어주고, 메세지 변수는 [NODE'n'] 형식으로 replace 한다.
  //   for (const member of smsMembers) {
  //     let i = 0;
  //     word.forEach((value, key) => {
  //       if (messageTemp.indexOf(value) > -1 && i < 5) {
  //         let val = '';
  //         switch (key) {
  //           case 0:
  //             val = member.name;
  //             break;
  //           case 1:
  //             val = moment(new Date()).format('M월 D일');
  //             break;
  //           case 4:
  //             val = member.id;
  //             break;
  //         }
  //         if (val !== '') {
  //           smsMessage = smsMessage.replace(value, '[$NOTE' + (i + 1) + ']');
  //           member['note' + (i + 1)] = val;
  //           i++;
  //         }
  //       }
  //     });
  //   }

  //   const params = {
  //     seq: typeof this.gridApi.getSelectedRows()[0] === 'undefined' ? '' :  this.gridApi.getSelectedRows()[0].auto_seq,
  //     title: this.formSMS.controls.title.value,
  //     message: smsMessage,
  //     members: smsMembers,
  //   };

  //   this.aSMSService.setSendSMS(params).then( response => {
  //     if (response.ResultCode) {
  //       this.toastrService.success(response.ResultMessage);
  //     } else {
  //       this.toastrService.error(response.ResultMessage);
  //     }
  //   });
  // }

  /*******************************************************************************
    설  명 : SMS 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    const smsMembers = this.gridApiMember.getSelectedRows();

    if (smsMembers.length < 1) {
      this.toastrService.error('발송할 회원을 선택하세요.');
      return;
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.formSMS, this.formErrors);

    if (this.formSMS.valid) {
      const params = {
        seq: typeof this.gridApi.getSelectedRows()[0] === 'undefined' ? '' :  this.gridApi.getSelectedRows()[0].seq,
        title: this.formSMS.controls.title.value,
        message: this.formSMS.controls.message.value,
        members: smsMembers,
        callback: this.formSMS.controls.callback.value,
      };

      this.aSMSService.setSendSMS(params).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage, 'SMS 발송');
        } else {
          this.toastrService.error(response.ResultMessage, 'SMS 발송');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', 'SMS 발송');
    }
  }
}
