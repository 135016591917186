/*******************************************************************************
  설  명 : 회원관리
  작성일 : 2019-10-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { ACommonService } from '@admin/service/common.service';
import { APaymentService } from '@admin/service/payment.service';
import { ACustomerService } from '@admin/service/customer.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { ACProjectFindComponent } from '@admin/component/project-find/project-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-payment-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class APaymentAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: string; // 0 = 추가, 0 != 수정
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public typeList: any = [];
  public paymentItemList: any = [];
  private paymentItemListOrg: any = [];

  search: any = {
    searchText: '',
    type: ''
  };

  private dateModel: NgbDateStruct = this.utilService.getDate('');

  public addOnProjectFunc: any;           // 프로젝트 검색
  public addOnCustomerFunc: any;          // 거래처 검색
  public accountChangeFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq: ['', [] ],
      type: ['', [] ],
      type_code: ['', [] ],
      account_seq: ['', [Validators.required, Validators.maxLength(50)] ],
      project_seq: ['', [Validators.maxLength(50)] ],
      project_name: ['', [] ],
      customer_seq: ['', [Validators.maxLength(255)] ],
      customer_name: ['', [] ],
      amt: ['', [Validators.maxLength(50)] ],
      vat: ['', [Validators.maxLength(50)] ],
      total_amt: ['', [Validators.required, Validators.maxLength(50)] ],
      reg_date: [this.dateModel, [Validators.required] ],
      proof_date: [this.dateModel, [] ],
      issue_date: [null, [] ],
      payment_date: [null, [] ],
      memo: ['', [] ],
      card_no: ['', [Validators.maxLength(30)]],
      use_detail: ['', [Validators.maxLength(50)]],
      attendance: ['', [Validators.maxLength(50)]],
      people_count: ['', []]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aCommonService: ACommonService,
    private aPaymentService: APaymentService,
    private modalService: NgbModal,
    private aCustomerService: ACustomerService,
    public authService: AuthService,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    this.buildForm();

    this.addOnProjectFunc = this.findProjectAddOn.bind(this);
    this.addOnCustomerFunc = this.findCustomerAddOn.bind(this);
    this.accountChangeFunc = this.changedAccount.bind(this);

  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    await this.getTypeList();

    await this.getAccountItemList();

    if ( this.seq == '0' ) {
      this.title = '추가';

      this.paymentItemList = [];

      this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

      this.paymentItemListOrg.forEach( row => {
        if ( row.type == this.typeList[0].seq ) this.paymentItemList.push( row );
      });
    } else {
      this.title = '수정';

      this.getPaymentDetail();
    }
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {

  }

  /*******************************************************************************
    설  명 : 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if (this.formAdd.valid) {
      this.aPaymentService.setPayment(this.formAdd).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getTypeList() {
    await this.aCommonService.getCommonList(40).then( response => {
      if ( response.ResultCode ) {
        this.typeList = response.data;

        this.formAdd.patchValue({
          type: this.typeList[0].seq,
          type_code: this.typeList[0].common_code
        });

      } else {
        this.typeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출 구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentDetail() {
    this.aPaymentService.getPaymentDetail( this.seq ).then( response => {
      if ( response.ResultCode ) {
        this.paymentItemList = [];

        this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

        this.paymentItemListOrg.forEach( row => {
          if ( row.type == response.data.type ) this.paymentItemList.push( row );
        });

        let dateModel: NgbDateStruct;

        if( response.data.reg_date ) {
          dateModel = this.utilService.getDate(response.data.reg_date);
          response.data.reg_date = dateModel;
        }

        if( response.data.proof_date ) {
          dateModel = this.utilService.getDate(response.data.proof_date);
          response.data.proof_date = dateModel;
        }

        if( response.data.issue_date ) {
          dateModel = this.utilService.getDate(response.data.issue_date);
          response.data.issue_date = dateModel;
        }

        if( response.data.payment_date ) {
          dateModel = this.utilService.getDate(response.data.payment_date);
          response.data.payment_date = dateModel;
        }

        // indicator 표시
        setTimeout(() => {
          this.formAdd.patchValue( response.data );
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 수입지출항목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getAccountItemList() {
    await this.aPaymentService.getAccountItemList( this.search ).then( response => {
      this.paymentItemList = [];
      this.paymentItemListOrg = [];

      if ( response.ResultCode ) {
        this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

        response.data.forEach( row => {
          this.paymentItemList.push({
            id: row.seq,
            text: row.name,
            type: row.type
          });
        });

        this.paymentItemListOrg = this.paymentItemList.map(x => Object.assign({}, x));
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findProjectAddOn() {
    const modalRef = this.modalService.open(ACProjectFindComponent, options);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        project_seq: result.seq,
        project_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findCustomerAddOn() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, options);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        customer_seq: this.aCustomerService.selectedItem.seq,
        customer_name: this.aCustomerService.selectedItem.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 타입 변경 시 셀렉트2 데이터 변경 처리
    입력값 : type seq
    리턴값 : 없음
  *******************************************************************************/
  typeChange( seq: any, code: any ) {
    this.paymentItemList = [];

    this.paymentItemList.push({id: '', text: '항목 선택', type: ''});

    this.paymentItemListOrg.forEach( row => {
      if ( row.type == seq ) this.paymentItemList.push( row );
    });

    this.formAdd.patchValue({
      type: seq,
      type_code: code
    });

    if( code === '2000' ) {
      this.formAdd.get('proof_date').setValidators( [Validators.required ] );
    }
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if ( confirm('선택하신 수입지출 내역을 삭제하시겠습니까?') ) {
      this.aPaymentService.delPayment( this.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '수입지출 삭제');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '수입지출 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 항목 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedAccount( $event ) {
    this.formAdd.patchValue({
      account_seq: $event.id
    });
  }

  /*******************************************************************************
    설  명 : 공급가액 입력시 부가세+합계 자동 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcAmt($event) {
    let amt: any = this.formAdd.controls.amt.value.replace(/,/gi, '');
    let vat: any = 0;
    let total: any = 0;

    vat = Math.round( amt / 10 );
    total = parseInt(amt) + parseInt(vat);

    var str = String(vat);
    var str1 = String(total);

    this.formAdd.patchValue({
      vat: vat ? str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0',
      total_amt: total ? str1.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0'
    });
  }

  /*******************************************************************************
    설  명 : 부가세 입력 시 공급가액+합계 자동 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcVAT($event) {
    let amt: any = 0;
    let vat: any = this.formAdd.controls.vat.value.replace(/,/gi, '');
    let total: any = 0;

    amt = Math.round( vat * 10 );
    total = parseInt(amt) + parseInt(vat);

    var str = String(amt);
    var str1 = String(total);

    this.formAdd.patchValue({
      amt: amt ? str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0',
      total_amt: total ? str1.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0'
    });
  }

  /*******************************************************************************
    설  명 : 합계 입력 시 공급가액+부가세 자동 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcTotal($event) {
    let amt: number = 0;
    let vat: number = 0;
    let total: number = this.formAdd.controls.total_amt.value.replace(/,/gi, '');

    vat = Math.round( total / 11 );
    amt = total - vat;

    var str = String(amt);
    var str1 = String(vat);

    this.formAdd.patchValue({
      amt: amt ? str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0',
      vat: vat ? str1.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0'
    });
  }

  dateReset1() {
    this.formAdd.patchValue({
      issue_date: null
    })
  }

  dateReset2() {
    this.formAdd.patchValue({
      payment_date: null
    })
  }
}
