/*******************************************************************************
  설  명 : 현장관리
  작성일 : 2020-08-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModalOptions, NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { AProjectService } from '@admin/service/project.service';
import { ACommonService } from '@admin/service/common.service';
import { ASiteManagementService } from '@admin/service/site.management.service';
import { AEmployeeService } from '@admin/service/employee.service';
import { AApplicationService } from '@admin/service/application.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';

import { PlaceCompleteComponent } from '@admin/page/process/place/place-complete/place-complete.component';
import { PlaceOrderComponent } from '@admin/page/process/place/place-order/place-order.component';

const modalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const modalOptionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown modal-xxl'
};

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss']
})
export class APlaceComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  projectOrderPlaceList: any = [];
  projectOrderPlaceDetailList: any = [];

  public projectSelectList$: BehaviorSubject<[]> = new BehaviorSubject([]);

  gbnList: any = [];
  public warehouseList: any = [];
  public selectedInfo: any = {};
  public employeeList: any = [];
  public employeeListSelect: any = [];
  applicationList: any = [];

  projectSeq: any = '';

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDetailDefs: any;

  gridApiOrder: any;
  gridColumnApiOrder: any;
  columnOrderDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionDetail: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  todayModel: NgbDateStruct = this.utilService.getDate('');

  search: any = {
    orderSeq: '',
    setIndex: '',
    place: '',
    gbn_seq: '',
    in_complete: '',
    customer_seq: '',
    sdate: '',
    edate: '',
    status: '',
    customerName: '',
    chargeMemno: '',
    group: 'D',
    date: this.todayModel,
    chargeMemnoDetail: '',
  };

  selectedNodes: any = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    public calendar: NgbCalendar,
    private aSiteManagementService: ASiteManagementService,
    private agGridExComponent: AgGridExComponent,
    private aProjectService: AProjectService,
    private modalService: NgbModal,
    public authService: AuthService,
    private aEmployeeService: AEmployeeService,
  ) {
    this.columnDefs = [
      {headerName: '', field: 'seq', cellClass: 'cp center', width:40, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '주문번호', field: 'order_seq', hide: true },
      {headerName: '세트번호', field: 'set_index', hide: true },
      {headerName: '부스/장소', field: 'place', width: 130, cellClass: 'cp' },
      {headerName: '거래처', field: 'customer_name', width: 130, cellClass: 'cp' },
      {headerName: '담당자', field: 'person_name', width: 75, cellClass: 'cp' },
      {headerName: '연락처', field: 'person_hp', width: 90, cellClass: 'cp' },
      {headerName: '상품수', field: 'qty', width: 75, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
      },
      {headerName: '본사담당자', field: 'charge_name', width: 100, cellClass: 'cp' },
    ];

    this.columnOrderDefs = [
      {
        headerName: '주문번호',
        field: 'seq',
        width: 80,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {headerName: '주문일자', width: 130, field: 'order_datetime', cellClass: 'cp center' },
      {headerName: '거래처', width: 120, field: 'customer_name', cellClass: 'cp' },
      {headerName: '회원명', width: 120, field: 'name', cellClass: 'cp' },
      {headerName: '상품명', width: 200, field: 'product_name', cellClass: 'cp' },
      {headerName: '수량', width: 80, field: 'qty', cellClass: 'cp right' },
      {headerName: '부스', width: 120, field: 'place', cellClass: 'cp' },
      {headerName: '설치요청일', width: 120, field: 'install_date', cellClass: 'cp' },
      {headerName: '시간', width: 80, field: 'install_time', cellClass: 'cp' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";
    this.rowSelectionDetail = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 사원 리스트 가져오기
    await this.getEmployeeSelect();

    // 프로젝트 리스트 가져오기
    this.getProjectList();

    this.columnDetailDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', width:40, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '상태', field: 'status', width: 75, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1' ) {
            return '<span class="badge badge-success f11">배송완료</span>';
          } else if( params.data.status == '2' ) {
            return '<span class="badge badge-primary f11">회수완료</span>';
          } else if( params.data.status == '3' ) {
            return '<span class="badge badge-danger f11">미회수</span>';
          } else {
            return '<span class="badge badge-secondary f11">배송대기</span>';
          }
        }
      },
      {headerName: '주문', field: 'is_order_type', width: 80, cellClass: 'cp center ', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.is_order_type == '1' ) {
            return '<span class="badge badge-success f11">온라인</span>';
          } else {
            return '<span class="badge badge-secondary f11">현장주문</span>';
          }
        }
      },
      {headerName: '이미지', field: 'thumbnail_filename', width: 120, cellClass: 'cp center ', cellRenderer: 'agGridImageComponent'},
      {headerName: '상품명', field: 'product_name', width: 150, cellClass: 'cp ' },
      {headerName: '주문일', field: 'order_date', width: 85, cellClass: 'cp center ' },
      {headerName: '주문수량', field: 'qty', width: 90, cellClass: 'cp right ', cellRenderer: this.agGridExComponent.currencyFormatter },
      {headerName: '배송수량', field: 'delivery_qty', width: 90, cellClass: 'cp right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.delivery_qty == undefined ) return '';

          var str = String(params.data.delivery_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          if (parseInt(params.data.qty) > parseInt( params.data.delivery_qty ) ) {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return {color:'#000', backgroundColor:'#f2f2f2'};
          }
        }
      },
      {headerName: '배송일자', field: 'delivery_date', width: 150, cellClass: 'cp ag-cell-edit', editable: true, cellEditor: "datePicker" },
      {headerName: '배송처리', field: 'delivery_charge_memno', width: 100, hide: true },
      {headerName: '배송처리', field: 'delivery_charge_name', width: 90, cellClass: 'cp ag-cell-edit', editable: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.employeeListSelect, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {
          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.delivery_charge_name ) {
              selected = item;
              params.data.delivery_charge_memno = item.mem_no;
              break;
            }
          }

          return (selected == null ) ? '' : selected.name;
        },
      },
      {headerName: '회수수량', field: 'pickup_qty', width: 90, cellClass: 'cp right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.pickup_qty == undefined ) return '';
          var str = String(params.data.pickup_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          if (parseInt(params.data.qty) > parseInt( params.data.pickup_qty ) ) {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return {color:'#000', backgroundColor:'#f2f2f2'};
          }
        }
      },
      {headerName: '회수일자', field: 'pickup_date', width: 150, cellClass: 'cp ag-cell-edit', editable: true, cellEditor: "datePicker" },
      {headerName: '회수처리', field: 'pickup_charge_memno', width: 100, hide: true},
      {headerName: '회수처리', field: 'pickup_charge_name', width: 90, cellClass: 'cp ag-cell-edit', editable: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.employeeListSelect, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {
          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.pickup_charge_name ) {
              selected = item;
              params.data.pickup_charge_memno = item.mem_no;
              break;
            }
          }

          return (selected == null ) ? '' : selected.name;
        },
      },
      {headerName: '금액', field: 'total_amt', width: 80, cellClass: 'cp right ', cellRenderer: this.agGridExComponent.currencyFormatter },
    ];
  }

  /*******************************************************************************
    설  명 : 사원 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getEmployeeSelect() {
    await this.aEmployeeService.getEmployeeSelect().then( response => {
      this.employeeListSelect = [];

      if( response.ResultCode ) {
        this.employeeList = response.data;

        for(let item of this.employeeList ) {
          this.employeeListSelect.push({
            seq: item.mem_no,
            mem_no: item.mem_no,
            name: item.name,
            position_name: item.position_name
          })
        }
      } else {
        this.employeeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색에 사용할 프로젝트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let params: any = {
      type: '',
      status: this.search.status,
      sdate: this.search.sdate,
      edate: this.search.edate,
      searchGroup: '',
      searchText: ''
    };

    this.aProjectService.getProjectList(params).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        tmp.push({
          id: '',
          text: '프로젝트 검색'
        });

        for( let item of response.data ) {
          tmp.push({
            id: item.seq,
            text: item.name
          });
        }
        this.projectSelectList$.next(tmp);
      }
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 필터 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedDetail($event) {
    let filteredRowCount = 0;
    this.gridApiDetail.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 그리드 필터 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      orderSeq: '',
      setIndex: '',
      gbn_seq: '',
      in_complete: '',
      customer_seq: '',
      sdate: '',
      edate: '',
      status: '',
      customerName: '',
      chargeMemno: '',
      group: '',
      date: '',
      chargeMemnoDetail: ''
    };

    this.getProjectOrderPlaceList();
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.search.orderSeq = $event.data.seq;
    this.search.setIndex = $event.data.set_index;
    this.search.place = $event.data.place;

    this.getProjectOrderPlaceDetailList( $event.data.seq, $event.data.set_index );
  }

  /*******************************************************************************
    설  명 : 셀 더블 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked( $event ) {

  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectList();
  }

  /*******************************************************************************
    설  명 : 프로젝트 선택 시 부스/장소 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectOrderPlaceList() {
    if( this.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
      return false;
    }

    var nodes = this.gridApi.getSelectedRows();

    this.aSiteManagementService.getProjectOrderPlaceList( this.projectSeq, this.search.customerName ).then( response => {
      if( response.ResultCode ) {
        this.projectOrderPlaceList = response.data;

        if( response.data.length < 1 ) {
          this.projectOrderPlaceList = [];
          return false;
        } 

        setTimeout(() => {
          if( nodes.length < 1 ) {
            let index = 0;
            this.gridApi.forEachNode(function(node) {
              if (index++ == 0 ) {
                node.setSelected(true);
              }
            });

            let tmp: any = {
              data: this.projectOrderPlaceList[0]
            }

            this.onCellClicked(tmp);
          } else {
            this.gridApi.forEachNode( (node) => {
              if (node.data.seq == nodes[0].seq ) {
                node.setSelected(true);
                this.onCellClicked(node);
              }
            }); 
          }
                    
        });

      } else {
        this.projectOrderPlaceList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 부스/장소 상세 리스트 가져오기
    입력값 : orderSeq = 주문번호, setIndex = 세트번호
    리턴값 : 없음
  *******************************************************************************/
  getProjectOrderPlaceDetailList( orderSeq, setIndex ) {
    if( this.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
      return false;
    }

    this.aSiteManagementService.getProjectOrderPlaceDetailList( orderSeq, setIndex ).then( response => {
      if( response.ResultCode ) {
        this.projectOrderPlaceDetailList = response.data;
        this.selectedInfo = response.info;
      } else {
        this.projectOrderPlaceDetailList = [];
        this.selectedInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 변경 시 데이터 로딩
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  changeProject( $event ) {
    this.projectSeq = $event.id;

    this.getProjectOrderPlaceList();
  }

  /*******************************************************************************
    설  명 : 선택한 부스/장소에 담당자를 배정한다.
    입력값 : allCheck = setChargeAll에서 넘어올 경우 true, 이외엔 false
    리턴값 : 없음
  *******************************************************************************/
  setCharge(allCheck: boolean) {
    if( this.search.chargeMemno == '' ) {
      this.toastrService.error("선택된 부스/장소에 배정할 담당자를 선택하시기 바랍니다.");
      return false;
    }

    let nodes: any = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error("담당자를 배정할 부스/장소를 선택하시기 바랍니다.", "담당자 배정");
      return false;
    }

    let tmp: any = [];
    for( let node of nodes ) {
      tmp.push({
        projectSeq: this.projectSeq,
        orderSeq: node.seq,
        setIndex: node.set_index,
        chargeMemno: this.search.chargeMemno
      });
    }

    this.aSiteManagementService.setCharge( tmp ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, "담당자 배정");

        if( allCheck ) {
          nodes = this.selectedNodes;
          this.gridApi.forEachNode( (node) => {
            if( node.data.seq === nodes[0].seq ) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          });
        }

        this.getProjectOrderPlaceList();
      } else {
        this.toastrService.error(response.ResultMessage, "담당자 배정");
      }
    });

  }

  /*******************************************************************************
    설  명 : 부스/장소에 담당자를 일괄 배정한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setChargeAll() {
    this.selectedNodes = this.gridApi.getSelectedRows();

    this.gridApi.forEachNode( (node) => {
      node.setSelected(true);
    });

    this.setCharge(true);
  }

  /*******************************************************************************
    설  명 : 선택된 부스/장소에 배송/회수 입력값을 검증한다.
    입력값 : title = toastr 메시지 타이틀
    리턴값 : 없음
  *******************************************************************************/
  checkInput( title: any ) {
    if( this.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.", title);
      return false;
    } else if( this.search.orderSeq == '' ) {
      this.toastrService.error("부스/장소를 선택하시기 바랍니다.", title);
      return false;
    } else if( this.search.group == '' ) {
      this.toastrService.error("배송/회수 구분을 선택하시기 바랍니다.", title);
      return false;
    } else if( this.search.date == '' ) {
      this.toastrService.error("배송/회수 날짜를 선택하시기 바랍니다.", title);
      return false;
    } else if( this.search.chargeMemnoDetail == '' ) {
      this.toastrService.error("배송/회수 담당자를 선택하시기 바랍니다.", title);
      return false;
    } else {
      let nodes: any = this.gridApiDetail.getSelectedRows();

      if( nodes.length < 1 ) {
        if( confirm("출고리스트 전체에 설정하시겠습니까?") ) {
          this.gridApiDetail.selectAll();
        } else {
          this.toastrService.error( "출고리스트를 선택하시기 바랍니다.", title);
          return false;
        }
      }
    }

    return true;
  }

  /*******************************************************************************
    설  명 : 선택된 부스/장소에 배송/회수 수량을 주문수량으로 설정한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setQty() {
    if( ! this.checkInput("수량 자동입력") ) return false;
    
    this.gridApiDetail.forEachNode( (node, index) => {
      if( node.selected ) {
        if( this.search.group == 'D') {
          node.data.delivery_qty = node.data.qty;
        } else {
          node.data.pickup_qty = node.data.delivery_qty;
        }
        
        node.setData( node.data );
      }
    })
  }

  /*******************************************************************************
    설  명 : 선택된 부스/장소에 배송/회수 일자를 설정한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDate() {
    if( ! this.checkInput("일자 선택") ) return false;

    this.gridApiDetail.forEachNode( (node, index) => {
      if( node.selected ) {
        if( this.search.group == 'D') {
          node.data.delivery_date = this.utilService.getDateStr( this.search.date );
        } else {
          node.data.pickup_date = this.utilService.getDateStr( this.search.date );
        }
        
        node.setData( node.data );
      }
    })
  }
  
  /*******************************************************************************
    설  명 : 선택된 부스/장소에 배송/회수 담당자를 설정한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setChargeDetail() {
    if( ! this.checkInput("담당자 선택") ) return false;

    this.gridApiDetail.forEachNode( (node, index) => {
      if( node.selected ) {
        if( this.search.group == 'D') {
          node.data.delivery_charge_name = this.search.chargeMemnoDetail;
        } else {
          node.data.pickup_charge_name = this.search.chargeMemnoDetail;
        }
        
        node.setData( node.data );
      }
    })
  }

  /*******************************************************************************
    설  명 : 선택된 부스/장소에 배송/회수 정보를 설정한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setGroupSave() {
    if( ! this.checkInput("수량 자동입력") ) return false;
    if( ! this.checkInput("일자 선택") ) return false;
    if( ! this.checkInput("담당자 선택") ) return false;

    this.gridApiDetail.forEachNode( (node, index) => {
      if( node.selected ) {
        if( this.search.group == 'D') {
          node.data.delivery_qty = node.data.qty;
          node.data.delivery_date = this.utilService.getDateStr( this.search.date );
          node.data.delivery_charge_name = this.search.chargeMemnoDetail;
        } else {
          if( node.data.delivery_qty == null) node.data.pickup_qty = node.data.qty;
          else node.data.pickup_qty = node.data.delivery_qty;

          node.data.pickup_date = this.utilService.getDateStr( this.search.date );
          node.data.pickup_charge_name = this.search.chargeMemnoDetail;
        }
        
        node.setData( node.data );
      }
    })
  }
  
  /*******************************************************************************
    설  명 : 오늘 날짜 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj ) {
    this.search.date = this.calendar.getToday();
    
    obj.close();
  }

  /*******************************************************************************
    설  명 : 출고리스트 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  saveChargeDetail() {
    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm("출고리스트 전체를 저장하시겠습니까?") ) {
        this.gridApiDetail.selectAll();
      } else {
        this.toastrService.error( "출고리스트를 선택하시기 바랍니다.");
        return false;
      }
    }
    
    let params: any = {
      projectSeq: this.projectSeq,
      orderSeq: this.search.orderSeq,
      setIndex: this.search.setIndex,
      data: this.gridApiDetail.getSelectedRows()
    }

    this.aSiteManagementService.setSiteManagementDetail( params ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, "출고리스트 저장");

        this.getProjectOrderPlaceDetailList( this.search.orderSeq, this.search.setIndex );

        return true;
      } else {
        this.toastrService.error( response.ResultMessage, "출고리스트 저장");
        return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 출고리스트 저장 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancelChargeDetail() {
    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm("출고리스트 전체를 취소하시겠습니까?") ) {
        this.gridApiDetail.selectAll();
      } else {
        this.toastrService.error( "출고리스트를 선택하시기 바랍니다.");
        return false;
      }
    }

    nodes = this.gridApiDetail.getSelectedRows();

    this.aSiteManagementService.cancelSiteManagementDetail( nodes ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, "출고리스트 취소");

        this.getProjectOrderPlaceDetailList( this.search.orderSeq, this.search.setIndex );

        return true;
      } else {
        this.toastrService.error( response.ResultMessage, "출고리스트 취소");
        return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 출고 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setComplete() {
    if( this.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
      return false;
    }

    // 상품 선택 모달 중복된 상품은 검색 안되도록 처리
    const modalRef = this.modalService.open(PlaceCompleteComponent, modalOptions);

    modalRef.componentInstance.projectSeq = this.projectSeq;

    modalRef.result.then(( result ) => {
      if( result ) {

      }
    });
  }

   /*******************************************************************************
    설  명 : 현장주문, 현장출고
    입력값 : orderSeq = 주문번호, '' 일 경우 현장주문, 주문번호가 있을 경우 현장출고
            place = 부스/장소
    리턴값 : 없음
  *******************************************************************************/
  addPlaceOrder( orderSeq: any, setIndex: any ) {
    if( this.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
      return false;
    }

    if( !orderSeq || orderSeq == '' ) {
      this.toastrService.error("추가로 주문하실 부스/장소를 선택하시기 바랍니다.");
      return false;
    }

    // 상품 선택 모달 중복된 상품은 검색 안되도록 처리
    const modalRef = this.modalService.open(PlaceOrderComponent, modalOptionsXL);

    modalRef.componentInstance.projectSeq = this.projectSeq;
    modalRef.componentInstance.orderSeq = orderSeq;
    modalRef.componentInstance.setIndex = setIndex;
    modalRef.componentInstance.statusCode = '3000';

    modalRef.result.then(( result ) => {
      if( result ) {
        this.getProjectOrderPlaceList();
      }
    });
  }

   /*******************************************************************************
    설  명 : 현장판매
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addNewOrder() {
    if( this.projectSeq == '' ) {
      this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
      return false;
    }

    // 상품 선택 모달 중복된 상품은 검색 안되도록 처리
    const modalRef = this.modalService.open(PlaceOrderComponent, modalOptionsXL);

    modalRef.componentInstance.projectSeq = this.projectSeq;
    modalRef.componentInstance.orderSeq = '';
    modalRef.componentInstance.setIndex = '';
    modalRef.componentInstance.statusCode = '3000';

    modalRef.result.then(( result ) => {
      if( result ) {
        this.getProjectOrderPlaceList();
      }
    });
  }

}
