/*******************************************************************************
  설  명 : 환불 상세정보 추가/수정
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { ARefundService } from '@admin/service/refund.service';
import { APaymentService } from '@admin/service/payment.service';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-refund-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ARefundDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number = 0; // 0 = 추가, 0 != 수정

  public formAdd: FormGroup;
  public formErrors = {};

  refundStatus:any = [];
  refundReason:any = [];

  // 그리드 관련 선언
  selectedRows: number = 0;
  refundProduct: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드 폼
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      name:["", [] ],
      mem_no:["", [] ],
      id:["", [] ],
      hp:["", [] ],
      tel:["", [] ],
      email:["", [] ],
      pay_method:["", [] ],
      refund_app_amt: ["", [Validators.required] ],
      request_gbn:["", [Validators.required] ],
      old_refund_status:["", [] ],
      refund_status:["", [Validators.required] ],
      refund_status_name:["", [] ],
      order_seq:["", [Validators.required] ],
      refund_reason:["", [Validators.required] ],
      refund_reason_name: ["", [] ],
      refund_memo:["", [Validators.required] ],
      refund_depositary:["", [] ],
      refund_bank:["", [] ],
      refund_account:["", [] ],
      deposit_date: ["", [] ],
      deposit_duedate: ["", [] ],
      deposit_bank:["", [] ],
      deposit_account:["", [] ],
      deposit_depositary:["", [] ],
      deposit_app_amt:["", [] ],
      op_seq:["", [] ],
      mid:["", [] ],
      tid:["", [] ],
      product:[[], [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aRefundService: ARefundService,
    private aCommonService: ACommonService,
    public authService: AuthService,
    private aPaymentService: APaymentService,
    private globals: Globals
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', cellClass: 'cp center ag-cell80h', width: 50,
        //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '이미지', field: 'thumbnail_filename', cellClass: 'cp center ag-cell80h', width: 150, cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', cellClass: 'cp ag-cell80h', width: 180, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.product_name + '<br />' + params.data.product_spec;
        }
      },
      { headerName: '주문수량', field: 'qty', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '요청수량', field: 'refund_qty', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.refund_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '단가', field: 'unit_amt', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.unit_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '할인', field: 'discount_amt', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.discount_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '금액', field: 'amt', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '부가세', field: 'vat', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.vat);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      { headerName: '합계', field: 'total_amt', cellClass: 'cp right ag-cell80h', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      }      
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();

    this.getRefundDetail( this.seq );
  }

  /*******************************************************************************
    설  명 : 공통코드 - 취소사유, 환불취소요청상태 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 요청상태
    this.aCommonService.getCommonListCode('RST').then( response => {
      if( response.ResultCode ) {
        this.refundStatus = response.data;
      }
    });

    // 취소사유
    this.aCommonService.getCommonListCode('RSN').then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        for( let item of response.data ) {
          tmp.push({
            value: item.common_code,
            title: item.common_name
          });
        }
        this.refundReason = tmp;
      }
    });
  }

  /*******************************************************************************
    설  명 : 환불 내역 가져오기
    입력값 : seq = refund.seq
    리턴값 : 없음
  *******************************************************************************/
  getRefundDetail( seq: any ) {
    this.aRefundService.getRefundDetail( seq ).then( response => {
      //console.log( response );
      if( response.ResultCode ) {
        let deposit_date: NgbDateStruct;
        let deposit_duedate: NgbDateStruct;

        this.utilService.getDate( response.data.deposit_date );

        // 입금예정일
        if( response.data.deposit_duedate != '' && response.data.deposit_duedate != null ) {
          deposit_duedate = this.utilService.getDate( response.data.deposit_duedate );
        } else {
          deposit_duedate = null;
        }

        // 입금일
        if( response.data.deposit_date != '' && response.data.deposit_date != null ) {
          deposit_date = this.utilService.getDate( response.data.deposit_date );
        } else {
          deposit_date = null;
        }

        this.formAdd.patchValue({
          seq: response.data.seq,
          mem_no: response.data.mem_no,
          id: response.data.id,
          hp: response.data.hp,
          tel: response.data.tel,
          email: response.data.email,
          name: response.data.name,
          pay_method: response.data.pay_method,
          request_gbn: response.data.request_gbn,
          old_refund_status: response.data.refund_status,
          refund_status: response.data.refund_status,
          refund_status_name: response.data.refund_status_name,
          refund_app_amt: response.data.refund_app_amt,
          order_seq: response.data.order_seq,
          refund_reason: response.data.refund_reason,
          refund_reason_name: response.data.refund_reason_name,
          refund_memo: response.data.refund_memo,
          refund_depositary: response.data.refund_depositary,
          refund_bank: response.data.refund_bank,
          refund_account: response.data.refund_account,
          deposit_date: deposit_date,
          deposit_duedate: deposit_duedate,
          deposit_bank: response.data.deposit_bank,
          deposit_account: response.data.deposit_account,
          deposit_depositary: response.data.deposit_depositary,
          deposit_app_amt: response.data.deposit_app_amt,
          op_seq: response.data.op_seq,
          mid: response.data.mid,
          tid: response.data.tid
        });

        this.refundProduct = response.product;

      } else {
        this.toastrService.error( '환불 신청내역이 없습니다.', '환불 관리');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( error, '환불 관리');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      //this.addRefund( $event.data.seq );
    } else {

    }
  }

  /*******************************************************************************
    설  명 : 상태 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRefundStatus( value: any ) {
    this.formAdd.patchValue({
      refund_status: value
    });
  }

  /*******************************************************************************
    설  명 : 환불 처리
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  setRefundPayment() {
    // 상태가 완료일 때
    if( this.formAdd.controls.old_refund_status.value == '0001' && this.formAdd.controls.refund_status.value == '0004' ) {
      if( ! confirm( "환불처리를 하시겠습니까?" ) ) return false;
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aRefundService.setRefundPayment(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '환불 관리' );
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '환불 관리' );
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '환불 관리');
    }
  }

  /*******************************************************************************
    설  명 : 환불 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRefundDelete() {
    if( confirm( "해당 환불 내역을 삭제하시겠습니까?" ) ) {
      this.aRefundService.setRefundDelete( this.formAdd ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '환불내역 삭제');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '환불내역 삭제');
        }
      });
    }
  }

}
