/*******************************************************************************
  설  명 : 업체별 할인관리
  작성일 : 2020-08-31
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { ACustomerService } from '@admin/service/customer.service';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';

import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-discount-company',
  templateUrl: './discount-company.component.html',
  styleUrls: ['./discount-company.component.scss']
})
export class ADiscountCompanyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  customerList: any = [];
  discountList: any = [];

  gridApi: any;
  gridColumnApi: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;

  columnDefs: any;
  columnDetailDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionDetail: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  public components: any;

  search: any = {
    seq: '',
    rateAmt: 'rate',
    amt: 0
  };

  public fileName: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aCustomerService: ACustomerService,
    private agGridExComponent: AgGridExComponent,
    public authService: AuthService
  ) {
    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    this.columnDefs = [
      {headerName: '', field: 'seq', cellClass: 'cp center', width:50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '구분', field: 'type', cellClass: 'cp center', width:90, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type == '매입') return '<span class="badge badge-primary f12 fn">매입</span>';
          else if( params.data.type == '매출') return '<span class="badge badge-success f12 fn">매출</span>';
          else return '<span class="badge badge-secondary f12 fn">매입+매출</span>';
        }
      },
      {headerName: '거래처명', field: 'name', width: 100, cellClass: 'cp' },
      {headerName: '사업자번호', field: 'business_no', width: 120, cellClass: 'cp' },
      {headerName: '거래처분류', field: 'kind', width: 100, cellClass: 'cp' },
      {headerName: '대표자명', field: 'ceo', width: 100, cellClass: 'cp right' },
    ];

    this.columnDetailDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '카테고리', field: 'category_name', width: 200, cellClass: 'cp left' },
      {headerName: '상품명', field: 'name', width: 200, cellClass: 'cp left'},
      {headerName: '상품종류', field: 'assembled_yn', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.assembled_yn == '1') return '<span class="badge badge-primary f12 fn">조립상품</span>';
          else return '<span class="badge badge-success f12 fn">자체상품</span>';
        }
      },
      {headerName: '단가', field: 'sale_amt', width: 120, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      {headerName: '판매금액', field: 'discount_amt', width: 120, cellClass: 'cp right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.discount_amt.replace(',',''));
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        valueFormatter: this.agGridExComponent.currencyFormatter,
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          let value: number = parseInt(params.value.replace(',', ''));

          if (value != parseInt( params.data.sale_amt.replace(',','') ) ) {
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return {color: '#000', backgroundColor: '#f2f2f2'};
          }
        }
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";
    this.rowSelectionDetail = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid detail ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi.setQuickFilter(searchText.value);
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged2( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApiDetail.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 업체정보 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.search.seq = $event.data.seq;

    this.fileName = $event.data.name + ' 할인내역';

    this.getProductDiscountList();
  }

  /*******************************************************************************
    설  명 : 할인 금액 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {

  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : inout 입고/출고 구분
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      warehouse_seq: '',
      category_code: '',
      product_name: '',
      product_seq: '',
      date: ''
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker ) {
    this.search.date = this.calendar.getToday();
    obj.close();
  }

  /*******************************************************************************
    설  명 : 거래처 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
     // 검색 필드
    let search: any = {
      searchGroup: 'name',
      searchText: '',
      typeSelect: '',
      kindSelect: ''
    }

    this.aCustomerService.getCustomerList( search ).then( response => {
      if( response.ResultCode ) {
        this.customerList = response.data;
      } else {
        this.customerList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 거래처 별 할인금액 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductDiscountList() {
    this.aCustomerService.getProductDiscountList( this.search.seq ).then( response => {
      if( response.ResultCode ) {
        this.discountList = response.data;
      } else {
        this.discountList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 일괄 할인율&할인금액 적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductAmt() {
    if( this.search.seq == '' ) {
      this.toastrService.error("할인율을 적용할 업체를 선택하시기 바랍니다.");
      return false;
    }

    //this.gridApiDetail.selectAll();

    let title: any = '';

    this.gridApiDetail.forEachNode(function(node, index) {
      let discount_amt: any = 0;

      // 비율 할인
      if( this.search.rateAmt == 'rate' ) {
        title = '할인율';
        discount_amt = parseInt( node.data.sale_amt.replace(',','') ) - ( parseInt( node.data.sale_amt.replace(',','') ) * ( parseInt(this.search.amt) / 100 ) );

      } else {
        title = '할인금액';
        discount_amt = parseInt( node.data.sale_amt.replace(',','') ) - parseInt(this.search.amt);
        if( discount_amt < 0 ) {
          discount_amt = 0;
        }
      }

      let data: any = node.data;

      data.discount_amt = String(discount_amt);
      node.setData( data );
    }.bind(this));

    this.toastrService.success( title + "을 업체에 적용하였습니다. 저장버튼을 클릭하여 해당 내역을 저장하시기 바랍니다.", title + " 일괄적용");
  }

  /*******************************************************************************
    설  명 : 엔터 입력 시 상품 일괄 할인율&할인금액 적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductAmtKeyPress( $event ) {
    if( $event.key == 'Enter' ) {
      this.setProductAmt();
    }
  }

  /*******************************************************************************
    설  명 : 타 업체 할인내역 복사
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCopyAmt() {
    if( this.search.seq == '' ) {
      this.toastrService.error('적용할 업체를 선택하시기 바랍니다.', '복사하기');
      return false;
    } else {
      const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

      modalRef.result.then((result) => {

        // 선택한 업체의 할인리스트 가져오기
        this.aCustomerService.getProductDiscountList( result.seq ).then( response => {
          if( response.ResultCode ) {
            this.discountList = response.data;

            this.toastrService.success('금액을 동일하게 복사하였습니다. 저장버튼을 클릭하여 해당 내역을 저장하시기 바랍니다.', '복사하기');
          } else {
            this.toastrService.error('선택한 업체의 할인리스트를 가져오는데 실패하였습니다.', '복사하기');
          }
        });

      }, (reason) => {
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 적용된 할인율 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInitProductAmt() {
    if( this.search.seq == '' ) {
      this.toastrService.error("할인율을 초기화할 업체를 선택하시기 바랍니다.", "할인금액 초기화");
      return false;
    }

    this.search.rateAmt == 'rate';
    this.search.amt = 0;

    this.setProductAmt();
  }

  /*******************************************************************************
    설  명 : 업체 할인율을 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    if( this.search.seq == '' ) {
      this.toastrService.error("할인금액을 저장할 업체를 선택하시기 바랍니다.", "업체 할인금액 저장");
      return false;
    }

    let data: any = [];

    this.gridApiDetail.forEachNode(function(node, index) {
      data.push({
        seq: node.data.seq,
        discount_amt: node.data.discount_amt
      });
    }.bind(this));

    this.aCustomerService.setProductDiscountAmt( this.search.seq, JSON.stringify(data) ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, "업체 할인금액 저장");

      } else {
        this.toastrService.error(response.ResultMessage, "업체 할인금액 저장");
      }
    });
  }

}
