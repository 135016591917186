import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DomSanitizer } from  '@angular/platform-browser';

interface ToolTipParams extends ICellRendererParams {
  lineBreak?: boolean;
  toolTipArray?: string[];
  toolTip?: string;
}

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class ACTooltipComponent implements ICellRendererAngularComp {

  public params: ToolTipParams;
  public toolTip: any;

  constructor(
    public sanitizer: DomSanitizer
  ) { }

  agInit(params: ToolTipParams): void {
    this.params = params;
    
    this.toolTip = params.value;
  }

  refresh(params: ToolTipParams): boolean {
    this.params = params;
    return true;
  }

}
