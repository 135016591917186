import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { ACommonService } from '@admin/service/common.service';
import { APaymentService } from '@admin/service/payment.service';
import { ACustomerService } from '@admin/service/customer.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ACProjectFindComponent } from '@app/admin/component/project-find/project-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-parttime-history',
  templateUrl: './parttime-history.component.html',
  styleUrls: ['./parttime-history.component.scss']
})
export class ParttimeHistoryComponent implements OnInit {

/*******************************************************************************
  설명 : 전역 변수 선언부
*******************************************************************************/
  public seq: string; // 0 = 추가, 0 != 수정
  public title: string = '추가';
  public selectedItem: any;
  public memberSeq: any = '0';

  public formAdd: FormGroup;
  public formErrors = {};

  public typeList: any = [];
  public paymentItemList: any = [];
  private paymentItemListOrg: any = [];

  search: any = {
    searchText: '',
    type: ''
  };

  private dateModel: NgbDateStruct = this.utilService.getDate('');

  public addOnProjectFunc: any;           // 프로젝트 검색
  public addOnCustomerFunc: any;          // 거래처 검색
  public accountChangeFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq: ['', [] ],
      parttime_member_seq: ['', [] ],
      project_seq: ['', [] ],
      project_name: ['', [] ],
      work_date: [this.dateModel, [Validators.required] ],
      work_time: ['', [] ],
      work_stime: ['', [] ],
      work_eTime: ['', [] ],
      work_pay: ['', [] ],
      over_time: ['', [] ],
      overtime_pay: ['', [] ],
      total_pay: ['', [] ],
      memo: ['', [] ],
      real_pay: ['', [] ],
      pay_date: [this.dateModel, [] ]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aCommonService: ACommonService,
    private aPaymentService: APaymentService,
    private modalService: NgbModal,
    private aCustomerService: ACustomerService,
    public authService: AuthService,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    this.buildForm();

    this.addOnProjectFunc = this.findProjectAddOn.bind(this);

  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.formAdd.patchValue({
      parttime_member_seq: this.memberSeq
    });

    if ( this.seq == undefined ) {
      this.title = '추가';

    } else {
      this.title = '수정';
      this.formAdd.patchValue(this.selectedItem);

      let dateModelWork: NgbDateStruct = this.utilService.getDate(this.selectedItem.work_date);
      let dateModelPay: NgbDateStruct = this.utilService.getDate(this.selectedItem.pay_date);

      this.formAdd.patchValue({
        work_date: dateModelWork,
        pay_date: dateModelPay
      })

    }
  }


  /*******************************************************************************
    설  명 : 프로젝트 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  findProjectAddOn() {
    const modalRef = this.modalService.open(ACProjectFindComponent, options);

    modalRef.result.then((result) => {
      this.formAdd.patchValue({
        project_seq: result.seq,
        project_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);
    
    this.formAdd.patchValue({
      work_date: this.ngbDateParserFormatter.format( this.formAdd.value.work_date ),
      pay_date: this.ngbDateParserFormatter.format( this.formAdd.value.pay_date ),
      work_pay: this.formAdd.value.work_pay ? this.formAdd.value.work_pay.replaceAll(',','') : 0,
      overtime_pay: this.formAdd.value.overtime_pay ? this.formAdd.value.overtime_pay.replaceAll(',','') : 0,
      total_pay: this.formAdd.value.total_pay ? this.formAdd.value.total_pay.replaceAll(',','') : 0,
    })

    if (this.formAdd.valid) {
      this.aPaymentService.setPartTimeHistory(this.formAdd).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '근무내역 저장');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '근무내역 저장');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }


  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if ( confirm('선택하신 근무내역을 삭제하시겠습니까?') ) {
      this.aPaymentService.delPartTimeHistory( this.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '근무내역 삭제');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '근무내역 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 항목 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedAccount( $event ) {
    this.formAdd.patchValue({
      account_seq: $event.id
    });
  }

  /*******************************************************************************
    설  명 : 합계 입력 시 근무수당+초과근무수당 자동 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcAmt($event) {
    let work_pay: any = this.formAdd.controls.work_pay.value == '' ? 0 : this.formAdd.controls.work_pay.value.replace(/,/gi, '');
    let overtime_pay: any = this.formAdd.controls.overtime_pay.value == '' ? 0 : this.formAdd.controls.overtime_pay.value.replace(/,/gi, '');
    let total: any = 0;

    total = parseInt(work_pay) + parseInt(overtime_pay);

    var str = String(total);

    this.formAdd.patchValue({
      total_pay: total ? str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0'
    });
  }
 
}