/*******************************************************************************
  설  명 : 거래처 프로젝트 정보 컴포넌트
  작성일 : 2019-09-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UtilService } from '@app/service/util.service';
import { AProjectService } from '@admin/service/project.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-customer-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ACProjectComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  selectedRows: number = 0;
  projectList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;

  rowSelection: any;
  noRowsTemplate: string;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aProjectService: AProjectService
  ) {
    this.columnDefs = [
      {headerName: '프로젝트명', field: 'name', cellClass: 'cp center' },
      {headerName: '기간', field: 'date_term', cellClass: 'cp center' },
      {headerName: '시간', field: 'time', cellClass: 'cp center' },
      {headerName: '장소', field: 'place', cellClass: 'cp center' },
      {headerName: '주최사', field: 'supervisor', cellClass: 'cp center' },
      {headerName: '주관사', field: 'organiser', cellClass: 'cp center' },
      {headerName: '링크', field: 'link', cellClass: 'cp center' },
      {headerName: '개요', field: 'outline', cellClass: 'cp' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 프로젝트 가져오기
    this.getProjectList( this.aProjectService.selectSeq );
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : seq = 거래처 코드
    리턴값 : 없음
  *******************************************************************************/
  getProjectList( seq ) {
    this.aProjectService.getACProjectList( seq ).then( response => {
      this.projectList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
}
