/*******************************************************************************
  설  명 : 비회원 추가
  작성일 : 2020-09-28
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';

import { AMemberListService } from '@admin/service/member.service';

@Component({
  selector: 'app-non-member-add',
  templateUrl: './non-member-add.component.html',
  styleUrls: ['./non-member-add.component.scss']
})
export class NonMemberAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      name:["", [Validators.required, Validators.maxLength(50)] ],
      mem_no:["", [] ],
      password:["", [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      password_confirm:["", [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      hp:["", [Validators.maxLength(20), CustomValidator.phone] ],
      email:["", [Validators.email, Validators.maxLength(100)] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private aMemberListService: AMemberListService,
    public activeModal: NgbActiveModal,
    public authService: AuthService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : 비회원 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if( this.formAdd.controls.password.value !== this.formAdd.controls.password_confirm.value ) {
      this.toastrService.error( "비밀번호와 비밀번호 확인의 값이 일치하지 않습니다.", "비회원 저장");

      return false;
    }

    if(this.formAdd.valid) {
      this.aMemberListService.setNonMember( this.formAdd ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, "비회원 저장");

          this.activeModal.close( response.data );
        } else {
          this.toastrService.error( response.ResultMessage, "비회원 저장");
        }
      });
    }
  }

}
