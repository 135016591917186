import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { CustomValidator } from '@app/service/custom.validators';
import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AAccountAddComponent } from './account-add/account-add.component';
import { ACompanyService } from '@admin/service/company.service';
import { ACommonService } from '@admin/service/common.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class ACompanyComponent implements OnInit {

  public form: FormGroup;
  public formErrors = {};

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  selectedRows: number = 0;
  asGubunList: any = [];
  accountList: any = [];
  loading : boolean = true;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  picker: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  buildForm(): void {
    this.form = this.formBuilder.group({
      comcd: ['', []],
      company_name:["", [Validators.required, Validators.maxLength(50)] ],
      company_name_eng:["", [Validators.maxLength(50)] ],
      business_no:["", [Validators.required, Validators.maxLength(20)] ],
      business_no2:["", [Validators.required, Validators.maxLength(50)] ],
      business_type:["", [Validators.maxLength(50)] ],
      business_item:["", [Validators.maxLength(50)] ],
      ceo:["", [Validators.required, Validators.maxLength(50)] ],
      tel:["", [Validators.required, Validators.maxLength(20), CustomValidator.phone] ],
      fax:["", [Validators.required, Validators.maxLength(20), CustomValidator.phone] ],
      email:["", [Validators.required, Validators.email, Validators.maxLength(50)] ],
      homepage:["", [Validators.maxLength(50)] ],
      cs_tel:["", [Validators.required, Validators.maxLength(50), CustomValidator.phone] ],
      manager_name:["", [Validators.maxLength(50)] ],
      manager_hp:["", [Validators.maxLength(20), CustomValidator.phone] ],
      zipcode:["", [Validators.required, Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric] ],
      address:["", [Validators.required, Validators.maxLength(100)] ],
      address_detail:["", [Validators.required, Validators.maxLength(100)] ],
      order_yn:["", [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCompanyService: ACompanyService,
    private agGridSaveComponent: AgGridSaveComponent,
    private aCommonService: ACommonService,
    public authService: AuthService
  ) {
    // 폼 빌드
    this.buildForm();

    // 그리드 컬럼 선언
    this.columnDefs = [
      {headerName: '계좌코드', field: 'seq', hide: true },
      {headerName: '대표계좌', field: 'default', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.default == true ) return '<span class="badge badge-primary f12 fn">대표계좌</span>';
          else return '<span class="badge badge-secondary f12 fn">일반계좌</span>';
        }
      },
      {headerName: '은행명', field: 'account_name', cellClass: 'cp center' },
      {headerName: '계좌번호', field: 'account', cellClass: 'cp center' },
      {headerName: '계좌주', field: 'name', cellClass: 'cp center' },
      {headerName: '은행로고', field: 'logo_url', cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      {headerName: '파일명', field: 'logo_origin', cellClass: 'cp' }
    ];

    // 컬럼 디폴트 값 선언
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };
  }

  // 초기화 처리
  ngOnInit() {
    // 회사 기본정보 가져오기
    this.getCompanyInfo();

    // 계좌 정보 가져오기
    this.getAccountList();
  }

  // 그리드 준비 시 처리
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // 필터 변경시 처리
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  // 그리드 row 클릭 시 처리
  onRowClicked($event) {
    this.accountAdd( $event.data );
  }

  // 계좌 추가&수정
  accountAdd( item ) {
    const modalRef = this.modalService.open(AAccountAddComponent, options);

    modalRef.componentInstance.item = item;

    modalRef.result.then((result) => {
      // 계좌 정보 가져오기
      this.getAccountList();
    }, (reason) => {
    });
  }

  onCellClicked($event) {
  /*
    if( $event.colDef.field !== 'button' ) {
      this.accountAdd( false );
    }
  */
  }

  // 처음 데이터 렌더링 시 처리
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // 다음주소창에서 클릭시
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  // 회사 기본정보 데이터를 가져온다.
  getCompanyInfo() {
    this.aCompanyService.getCompanyInfo().then( response => {
      this.form.patchValue( response.data );
    });
  }

  // 회사 기본정보 저장하기
  setCompanyInfo() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aCompanyService.setCompanyInfo(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  // 계좌정보 가져오기
  getAccountList() {
    this.aCompanyService.getAccountList().then( response => {
      this.accountList = response.data;
    });
  }
}
