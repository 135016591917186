import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ADepositService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 입금대사 관리 위젯 통계 가져오기
  getDepositStatistic(search:any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'deposit',
        version: '1.0',
        action: 'getDepositStatistic'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 계좌입금 현황 가져오기
  getDepositList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'deposit',
        version: '1.0',
        action: 'getDepositList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 매칭 미완료된 주문내역 가져오기
  getIncompleteOrderList( bankAccountSeq: any, autoDepositSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'deposit',
        version: '1.0',
        action: 'getIncompleteOrderList'
      }, {
				seq: bankAccountSeq,
        auto_deposit_seq: autoDepositSeq
			}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
	}
	
  // 입금/주문 매칭
  setDepositComplete( bankAccountRow: any, orderRow: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'deposit',
        version: '1.0',
        action: 'setDepositComplete'
      }, {
				bankAccountInfo: bankAccountRow,
				orderInfo: orderRow
			}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
	}

  // 입금/주문 매칭취소
  setDepositCancel( bankAccountRow: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'deposit',
        version: '1.0',
        action: 'setDepositCancel'
      }, {
				bankAccountInfo: bankAccountRow
			}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
	}
  
  // 강제 처리완료
  setDepositForceComplete( bankAccountRow: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'deposit',
        version: '1.0',
        action: 'setDepositForceComplete'
      }, {
				bankAccountInfo: bankAccountRow
			}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
	}

}
