/*******************************************************************************
  설  명 : 거래처 관리
  작성일 : 2019-09-11
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ASMSService } from '@admin/service/sms.service';
import { Globals } from '@app/service/globals.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/component/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { ACSendSmsComponent } from '@admin/component/send-sms/send-sms.component';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class ASmsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public SMSList = [];

  // 검색 필드
  public search = {
    searchTerm: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    searchText: ''
  }

  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  // 그리드 관련 선언
  selectedRows: number = 0;
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aSMSService: ASMSService,
    private calendar: NgbCalendar,
    public authService: AuthService,
    private globals: Globals
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '발송일', field: 'send_date', cellClass: 'cp center' },
      {headerName: '제목', field: 'title', cellClass: 'cp' },
      {headerName: '내용', field: 'msg', cellClass: 'cp' },
      {headerName: '발송번호', field: 'send_tel', cellClass: 'cp' },
      {headerName: '수신번호', field: 'receipt_tel', cellClass: 'cp' },
      {headerName: '구분', field: 'message_gbn', cellClass: 'cp' },
      {headerName: '상태', field: 'resultMessage', cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    this.searchInit();

    // SMS 발송 리스트 가져오기
    this.getSMSSendList();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchTerm: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      searchText: ''
    }

    // this.setSearchTerm('0');

    this.getSMSSendList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
    }
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSMSSendList();
    }
  }

  /*******************************************************************************
    설  명 : SMS 발송내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSMSSendList() {
    this.SMSList = [];
    this.aSMSService.getSMSSendList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.SMSList = response.data;
      }
    }, error => {
      this.toastrService.error( error, 'SMS발송내역');
    });
  }

  /*******************************************************************************
    설  명 : 검색 기간 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // setSearchTerm( index: any ) {
  //   this.search.searchTerm = index;

  //   // 전체
  //   if( index === '' ) {
  //     this.search.sdate = '';
  //     this.search.edate = '';

  //   // 이번달
  //   } else if( index === '0' ) {
  //     this.search.sdate = this.utilService.getDate( moment().format('YYYY-MM-01') );

  //   // 기간별
  //   } else {
  //     this.search.sdate = this.utilService.getDate( moment().add( parseInt(index) * -1, 'month').format('YYYY-MM-DD') );
  //   }

  //   if( index !== '' ) {
  //     this.search.edate = this.utilService.getDate( moment().format('YYYY-MM-DD') );
  //   }

  //   this.getSMSSendList();
  // }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // getToday( obj: NgbInputDatepicker, check: boolean ) {
  //   if( check ) {
  //     this.search.sdate = this.utilService.getDateStr( this.calendar.getToday() );
  //     obj.close();
  //   } else {
  //     this.search.edate = this.utilService.getDateStr( this.calendar.getToday() );
  //     obj.close();
  //   }
  // }
}
