/*******************************************************************************
  설  명 : 현장관리 - 배송/회수 전체완료
  작성일 : 2020-12-28
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ASiteManagementService } from '@admin/service/site.management.service';
import { AEmployeeService } from '@admin/service/employee.service';

import * as moment from 'moment';

@Component({
  selector: 'app-place-complete',
  templateUrl: './place-complete.component.html',
  styleUrls: ['./place-complete.component.scss']
})
export class PlaceCompleteComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/  
  public projectSeq: any = '';
  public place: any = '';

  employeeList: any = [];

  formAdd: FormGroup;
  formErrors = {};

  /*******************************************************************************
    설  명 : 빌드 폼
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let todayModel: NgbDateStruct = this.utilService.getDate('');

    this.formAdd = this.formBuilder.group({
      group:["0", [] ],
      projectSeq: ["", [Validators.required] ],
      process_date:[todayModel, [Validators.required] ],
      charge_memno:["", [Validators.required] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private aSiteManagementService: ASiteManagementService,
    public authService: AuthService,
    private aEmployeeService: AEmployeeService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getEmployeeSelect();

    this.formAdd.patchValue({projectSeq: this.projectSeq});
  }

  /*******************************************************************************
    설  명 : 사원 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeSelect() {
    this.aEmployeeService.getEmployeeSelect().then( response => {
      console.log( response.data );
      if( response.ResultCode ) {
        let tmp: any = [];
        for( let item of response.data ) {
          tmp.push({
            title: item.name,
            value: item.mem_no
          });
        }
        this.employeeList = tmp;
      } else {
        this.employeeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 배송/회수 전체완료 처리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors( this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.aSiteManagementService.setSiteManagementAll( this.formAdd ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, "배송/회수 전체완료" );
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, "배송/회수 전체완료" );
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '배송/회수 전체완료');
    }
  }

}
