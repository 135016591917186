/*******************************************************************************
  설  명 : 관리자 상품 주문
  작성일 : 2019-12-17
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject, BehaviorSubject } from 'rxjs';

import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';
import { CustomValidator } from '@app/service/custom.validators';

import { AProductService } from '@admin/service/product.service';
import { AProjectService } from '@admin/service/project.service';
import { AApplicationService } from '@admin/service/application.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class AApplicationDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public productList: any = [];
  public params: any = {};

  public title: string = '관리자 주문';

  public selectedCategory: number = 0;     // 선택된 카테고리 index
  public selectedSubCategory: number = 0;  // 선택된 카테고리 서브 카테고리 index
  public baseURL: any = config.baseUrl;    // 이미지 경로

  public form: FormGroup;
  public formErrors = {};

  public projectGroupList: any = [];    // 프로젝트 구분

  public addMemberFunc: any;            // 회원검색
  public addCustomerFunc: any;          // 거래처검색
  public changedFunc: any;              // 전시/행사 변경시 처리 함수

  public projectList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public projectList: any = [];
  private projectListData: any = [];

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  private set: any = {
    install_date: '',
    install_time: '08:00',
    person_name: '',
    person_hp: '',
    person_email: '',
    place: '',
    total_amt: 0,
    open: true,
    product: []
  }

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let today: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.form = this.formBuilder.group({
      seq:["", [] ],
      project_group:["", [Validators.required] ],
      rental_sdate:[today, [Validators.required] ],
      rental_edate:[today, [Validators.required] ],
      order_date:["", [] ],
      order_time: ["", [] ],
      confirmation_date:["", [] ],
      confirmation_time: ["", []],
      mem_no:["", [] ],
      mem_name: ["", [Validators.required] ],
      customer_seq:["", [] ],
      customer_name:["", [] ],
      status: ["1000", [] ],
      project_seq:["", [] ],
      project_name:["", [Validators.required, Validators.maxLength(255)] ],
      project_name2:["", [] ],
      memo:["", [Validators.maxLength(255)] ],
      delivery_amt:[0, [CustomValidator.amt] ],
      zipcode: ["", [Validators.required] ],
      address: ["", [Validators.required] ],
      address_detail: ["", [Validators.required] ],
      amt: [0, [Validators.required] ],
      set: [[], []],
      active: [0, []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private aProductService: AProductService,
    private aCommonService: ACommonService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private aApplicationService: AApplicationService,
    private activatedRoute: ActivatedRoute,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private router: Router,
    private aProjectService: AProjectService
  ) {
    this.buildForm();

    this.addMemberFunc = this.searchMember.bind(this);
    this.addCustomerFunc = this.searchCustomer.bind(this);
    this.changedFunc = this.changed.bind(this);

    // 프로젝트 선택 시 select2 처리
    let projectList = this.projectList$.asObservable();
    projectList.subscribe( data => {
      this.projectList = data;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params = params;

      if( this.params.seq == '0' ) {
        this.title = '관리자 주문';
      } else {
        this.title = '관리자 주문 수정';

        // 주문 내역 가져오기
        this.getApplicationDetail();
      }
    });

    // 프로젝트 구분 리스트 가져오기
    this.getCommonList();

    // 상품 리스트 가져오기
    this.getProductList();

    // 전시 행사명 가져오기
    this.getProjectList();

    // 기본 세트 상품 추가
    this.insertSet();
  }

  /*******************************************************************************
    설  명 : 주문 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApplicationDetail() {
    this.aApplicationService.getApplicationDetail( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        // form data
      } else {

      }
    });
  }

  /*******************************************************************************
    설  명 : 세트 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  insertSet() {
    let set: any = [];
    set = this.form.controls.set.value;

    let tmp: any = {
      install_date: '',
      install_time: '08:00',
      person_name: this.set.person_name,
      person_hp: this.set.person_hp,
      person_email: this.set.person_email,
      place: '',
      total_amt: 0,
      open: true,
      product: []
    };

    set.push( tmp );

    this.form.patchValue({
      set: set
    });
  }

  /*******************************************************************************
    설  명 : 세트 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteSet(index) {
    if( confirm( "선택하신 세트를 삭제하시겠습니까?" ) ) {
      let set: any = this.form.controls.set.value;

      set = set.splice( index );

      this.form.patchValue({set: set});
    }
  }

  /*******************************************************************************
    설  명 : 카테고리별 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductList() {
    this.aProductService.getCategoryProductDiscountList(
      this.form.controls.mem_no.value,
      this.form.controls.project_seq.value
    ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;

      } else {
        this.productList = [];

        this.toastrService.error( response.ResultMessage, '상품 가져오기');
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '상품 가져오기');
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 리스트 정보 가져오기
  *******************************************************************************/
  getProjectList() {
    this.aProjectService.getCurrProjectList().then( response => {
       if( response.ResultCode ) {
        this.projectListData = response.data;

        this.setProjectList();
      }
    }, error => {
      this.toastrService.error( error, '전시/행사명');
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 리스트 생성 - 구분 = 전시/기타에 따라
  *******************************************************************************/
  setProjectList() {
    let tmp: any = [];
    tmp.push({
      id: 0,
      text: '전시/행사명 선택'
    });

    for( let item of this.projectListData ) {
      if( item.type_seq == this.form.controls.project_group.value ) {
        tmp.push({
          id: item.seq,
          text: item.name
        });
      }
    }

    this.projectList$.next( tmp );
  }

  /*******************************************************************************
    설  명 : 프로젝트 구분 클릭 시 처리
    입력값 : item = project
    리턴값 : 없음
  *******************************************************************************/
  projectGroupClick( item: any ) {
    this.form.patchValue({project_group: item.seq, inout_code: item.common_code});

    // select2 리스트 생성
    this.setProjectList();
  }

  /*******************************************************************************
    설  명 : 공통코드 - 프로젝트구분, 주문상태 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.projectGroupList = [];

    // 프로젝트구분
    this.aCommonService.getCommonListCode('PJT').then( response => {
      if( response.ResultCode ) {
        this.projectGroupList = response.data;

        this.form.patchValue({project_group: this.projectGroupList[0].seq});
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 선택 시 값 초기화
    입력값 : 선택된 카테고리 index
    리턴값 : 없음
  *******************************************************************************/
  selectCategory( index ) {
    this.selectedCategory = index;     // 선택된 카테고리 index
    this.selectedSubCategory = 0;  // 선택된 카테고리 서브 카테고리 index
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 현재 선택 된 세트 상품에 추가
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  selectProduct( product ) {
    let tmp: any = Object.create(product);

    tmp.qty = 1;
    tmp.dc_amt = 0;

    this.form.controls.set.value[this.form.controls.active.value].product.push( tmp );

    this.calcAmt();
  }

  /*******************************************************************************
    설  명 : 전체 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcAmt() {
    let item: any = this.form.controls.set.value;
    let total_amt: number = 0;

    for( let subItem of item ) {
      let sub_total_amt: number = 0;

      for( let product of subItem.product ) {
        //sub_total_amt += ( product.sale_amt * product.qty );
        sub_total_amt += ( ( parseInt(product.sale_amt) - parseInt(product.discount_amt) ) * parseInt(product.qty) );
      }

      subItem.total_amt = sub_total_amt;

      total_amt += sub_total_amt;
    }

    item.total_amt = total_amt;

    this.form.patchValue({ set: item });
    this.form.patchValue({ amt: total_amt });
  }

  /*******************************************************************************
    설  명 : 상품 수량 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  qtyChange( product, $event ) {
    product.qty = parseInt($event.target.value);

    this.calcAmt();
  }


  /*******************************************************************************
    설  명 : 세트 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSetList() {
    var set: any = [];
    for(let item of this.form.controls.set.value ) {
      set.push( item );
    }
    return set;
  }

  /*******************************************************************************
    설  명 : 세트 담긴 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSetProductList( item ) {
    if( item.product.length < 1 ) return [];

    var product: any = [];

    for(let subitem of item.product ) {
      product.push( subitem );
    }
    return product;
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(ACMemberFindComponent, optionsLG);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.set.person_name = result.data.name;
      this.set.person_hp = result.data.person_hp;
      this.set.person_email = result.data.person_email;

      this.form.patchValue({
        mem_no: result.data.mem_no,
        mem_name: result.data.name,
        customer_seq: result.data.customer_seq,
        customer_name: result.data.customer_name
      });

      let set: any = this.form.controls.set.value;

      for( let item of set ) {
        item.person_name = result.data.name;
        item.person_hp = result.data.hp;
        item.person_email = result.data.email;
      }

      this.form.patchValue({set : set});

      // 할인 적용된 값으로 리스트 다시 불러오기
      this.getProductList();

      $(window).scrollTop(0);

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.set.person_name = result.person_name;
      this.set.person_hp = result.charge_hp;
      this.set.person_email = result.email;

      this.form.patchValue({
        mem_no: result.person_memno,
        mem_name: result.person_name,
        customer_seq: result.seq,
        customer_name: result.name
      });

      let set: any = this.form.controls.set.value;

      for( let item of set ) {
        item.person_name = result.person_name;
        item.person_hp = result.charge_hp;
        item.person_email = result.email;
      }

      this.form.patchValue({set : set});

      $(window).scrollTop(0);
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 주문정보(배송정보) 열기 / 닫기 처리
    입력값 : index
    리턴값 : 없음
  *******************************************************************************/
  openCheck( index ) {
    let set = this.form.controls.set.value;

    set[index].open = ! set[index].open;

    this.form.patchValue({set: set});
  }

  /*******************************************************************************
    설  명 : 주문하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {

      for( let item of this.form.controls.set.value ) {
        if( item.install_date.length < 1 ) {
          this.toastrService.error( '설치 종료일자를 선택하세요.', '관리자 주문');
          return false;

        } else if( item.place.length < 1 ) {
          this.toastrService.error( '설치 장소(부스번호)를 입력해주세요.', '관리자 주문');
          return false;

        } else if( item.person_name.length < 1 ) {
          this.toastrService.error( '인수 담당자를 입력해주세요.', '관리자 주문');
          return false;

        } else if( item.person_hp.length < 1 ) {
          this.toastrService.error( '인수 담당자를 연락처를 입력해주세요.', '관리자 주문');
          return false;

        } else if( item.product.length < 1 ) {
          this.toastrService.error( '주문할 상품을 추가하세요.', '관리자 주문');
          return false;

        } else {
          // 상품 별 수량 체크
          for( let subItem of item.product ) {
            if( subItem.qty < 1 ) {
              this.toastrService.error( '주문할 상품의 수량을 체크해 주세요.', '관리자 주문');
              return false;
            }
          }
        }
      }

      this.aApplicationService.setApplication(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.goList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '관리자 주문');
    }
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/order/application'], {
      //relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 전시/행사 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changed( e: any): void {
    for( let item of this.projectListData ) {
      if( item.seq == e.value ) {
        let start_date: NgbDateStruct = this.utilService.getDate( item.start_date );
        let end_date: NgbDateStruct = this.utilService.getDate( item.end_date );

        this.form.patchValue({
          project_seq: item.seq,
          project_name: item.name,
          rental_sdate: start_date,
          rental_edate: end_date,
          zipcode: item.zipcode,
          address: item.address,
          address_detail: item.address_detail
        });

        // 할인 적용 상품 가져오기
        this.getProductList();

        break;
      }
    }
  }

  /*******************************************************************************
    설  명 : 이미지 URL 리턴
    입력값 : url
    리턴값 : url
  *******************************************************************************/
  getImageURL( url: any ) {
    return ( url.indexOf('http') > -1 ) ? url : this.baseURL + url;
  }

  /*******************************************************************************
    설  명 : 주문정보 상품리스트 선택 삭제
  *******************************************************************************/
  deleteProduct( index ) {
    this.form.controls.set.value[this.form.controls.active.value].product.splice(index, 1);

    this.calcAmt();
  }

  /*******************************************************************************
    설  명 : 상태값 변경 시 처리
    입력값 : status
    리턴값 : 없음
  *******************************************************************************/
  clickStatus( status: any ) {
    if( status == '2000' ) {
      this.form.patchValue({
        confirmation_date: moment().format('YYYY-MM-DD'),
        confirmation_time: moment().format('HH:mm:ss'),
        status: status
      });
    } else {
      this.form.patchValue({
        confirmation_date: '',
        confirmation_time: '',
        status: status
      });
    }
  }
}