/*******************************************************************************
  설  명 : 출고관리
  작성일 : 2020-08-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModalOptions, NgbModal, NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject, BehaviorSubject } from 'rxjs';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { ACommonService } from '@admin/service/common.service';
import { AProjectService } from '@admin/service/project.service';
import { AWarehouseService } from '@admin/service/warehouse.service';
import { AStockService } from '@admin/service/stock.service';
import { AProductService } from '@admin/service/product.service';
import { AApplicationService } from '@admin/service/application.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';
import { AgGridExComponent } from '@app/component/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridButtonComponent } from '@app/component/ag-grid-button/ag-grid-button.component';

import { ACProductFindGridComponent } from '@admin/component/product-find-grid/product-find-grid.component';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-delivery-add',
  templateUrl: './delivery-add.component.html',
  styleUrls: ['./delivery-add.component.scss']
})
export class ADeliveryAddComponent implements OnInit {
  [x: string]: any;

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public projectSelectList$: BehaviorSubject<[]> = new BehaviorSubject([]);

  public projectList: any = [];
  public scaleList = [];
  public stockHistoryList: any = [];
  public applicationList: any = [];
  public yearList: any = [];
  public monthList: any = ['01','02','03','04','05','06','07','08','09','10','11','12'];

  public inWarehouseList: any = [];
  public outWarehouseList: any = [];

  public tabIndex: number = 0;

  public defaultWarehouse: any = {
    seq: ''
  };

  public isChangeWarehouse: boolean = false;

  changeStock: any = 0;

  projectSeq: any = '';
  selectedProjectInfo: any = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDetailDefs: any;

  gridApiOrder: any;
  gridColumnApiOrder: any;
  columnOrderDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionDetail: any;
  rowSelectionOrder: any;
  noRowsTemplate: string;
  isRowSelectable: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  search: any = {
    projectSeq: '',
    status: '1',
    scale: '',
    searchYear: moment().format('YYYY'),
    searchMonth: '',
    date: '',
    outWarehouseSeq: '',
    inWarehouseSeq: '',
    out_date: this.calendar.getToday(),
    searchText: ''
  };

  public params: any = {
    mng_out_seq: '',
    project_seq: '',
    instructions: '',
    memo: '',
    data: []
  }

  public options = {
    multiple: false
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aProjectService: AProjectService,
    private agGridExComponent: AgGridExComponent,
    private aWarehouseService: AWarehouseService,
    private aStockService: AStockService,
    private modalService: NgbModal,
    private aApplicationService: AApplicationService,
    private aProductService: AProductService,
    public authService: AuthService,
  ) {
    this.columnDefs = [
      {headerName: '', field: 'seq', cellClass: 'cp center', width:50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '프로젝트명', field: 'name', width: 150, cellClass: 'cp' },
      {headerName: '장소', field: 'place', width: 90, cellClass: 'cp' },
      {headerName: '시작일', field: 'start_date', width: 90, cellClass: 'cp center' },
      {headerName: '종료일', field: 'end_date', width: 90, cellClass: 'cp left' },
    ];

    this.columnOrderDefs = [
      {
        headerName: '주문번호',
        field: 'order_seq',
        width: 100,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {headerName: '주문일자', width: 130, field: 'order_datetime', cellClass: 'cp center' },
      {headerName: '거래처', width: 120, field: 'customer_name', cellClass: 'cp' },
      {headerName: '회원명', width: 200, field: 'name', cellClass: 'cp' },
      {headerName: '상품명', width: 200, field: 'product_name', cellClass: 'cp' },
      {headerName: '수량', width: 80, field: 'qty', cellClass: 'cp right' },
      {headerName: '부스', width: 150, field: 'place', cellClass: 'cp' },
      {headerName: '설치요청일', width: 120, field: 'install_date', cellClass: 'cp' },
      {headerName: '시간', width: 80, field: 'install_time', cellClass: 'cp' },

    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";
    this.rowSelectionDetail = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.isRowSelectable = function(rowNode) {
      return rowNode.data ? ( rowNode.data.detail_del_yn == '0' ) : false;
    };

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };
  }

  /*******************************************************************************
    설  명 : 출고 상세 ag-grid 컬럼 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initColumnDetail() {
    this.columnDetailDefs = [
      { headerName: '', field: '', cellClass: 'cp center', width: 50,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '상태', field: 'status', width: 80, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.detail_del_yn == '0' ) {
            if( params.data.status == '출고대기' ) return '<span class="badge badge-secondary f11">출고대기</span>';
            else return '<span class="badge badge-success f11">출고완료</span>';
          } else {
            return '<span class="badge badge-danger f11">주문삭제</span>';
          }
        }
      },
      { headerName: '출고구분', field: 'out_type', width: 90, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.out_type == '1' ) return '<span class="badge badge-primary f11">주문서출고</span>';
          else return '<span class="badge badge-secondary f11">관리자출고</span>';
        }
      },
      { headerName: '구분', field: 'type_name', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type_name == '단품' ) return '<span class="badge badge-info f11">단품</span>';
          else return '<span class="badge badge-primary f11">세트상품</span>';
        }
      },
      { headerName: '분류', field: 'grd', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.grd == '부속상품' ) return '<span class="badge badge-secondary f11">부속상품</span>';
          else return '<span class="badge badge-light f11">자체상품</span>';
        }
      },
      { headerName: '바코드', field: 'barcode_yn', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if ( params.data.barcode_yn == '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
      { headerName: '상품코드', field: 'product_seq', hide: true },
      { headerName: '상품명', field: 'name', width: 150, cellClass: 'cp left'},
      { headerName: '출고일자', field: 'out_date', width: 90, cellClass: 'cp center ag-cell-edit', /*editable: true, cellEditor: "datePicker"*/ },
      { headerName: '주문수량', field: 'qty', width: 90, cellClass: 'cp right' },
      { headerName: '출고수량', field: 'out_qty', width: 90, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if( params.data.barcode_yn == '1' ) {
            return 'cp right';
          } else {
            return 'cp right ag-cell-edit';
          }
        },
        editable: function(params) {
          if( params.data.barcode_yn == '1' ) {
            return false;
          } else {
            return true;
          }
        },
        valueGetter: function(params) {
          var str = String(params.data.out_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          if (parseInt(params.data.qty) > parseInt( params.data.out_qty ) ) {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return {color:'#000', backgroundColor:'#f2f2f2'};
          }
        }
      },
      { headerName: '바코드 입력', field: 'barcode', width: 120, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'barcode',  // 바코드용 그리드버튼
          applyField: 'out_qty',  // 적용할 필드
          btnName: '바코드 입력'  // 버튼명
        }
      },
      { headerName: '추가출고', field: 'out_add_qty', hide: true, width: 90, cellClass: 'cp right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.out_add_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
      },
      { headerName: '재고', field: 'stock_qty', width: 90, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.stock_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellStyle: function(params) {
          if (parseInt(params.data.out_qty) > parseInt( params.data.stock_qty )) {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return null;
          }
        }
      },
      { headerName: '과부족', field: 'not_qty', width: 90, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( (parseInt(params.data.out_qty) + parseInt(params.data.out_add_qty)) - parseInt( params.data.stock_qty ) > 0 ) {
            var str = String((parseInt(params.data.out_qty) + parseInt(params.data.out_add_qty)) - parseInt( params.data.stock_qty ));
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          } else return '';
        },
        cellStyle: function(params) {
          if( ( parseInt(params.data.out_qty) + parseInt(params.data.out_add_qty)) > parseInt( params.data.stock_qty )) {
            //mark police cells as red
            return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
            return null;
          }
        }
      },
      { headerName: '출고창고', field: 'out_stock_seq', hide: true },
      { headerName: '출고창고', field: 'out_warehouse', width: 100, cellClass: 'cp', editable: false,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.outWarehouseList, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {
          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.out_warehouse ) selected = item;
          }

          return (selected == null ) ? '' : selected.name;
        }
      },
      { headerName: '입고창고', field: 'in_stock_seq', hide: true },
      { headerName: '입고창고', field: 'in_warehouse', width: 100, cellClass: 'cp ag-cell-edit', editable: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.inWarehouseList, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {
          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.in_warehouse ) selected = item;
          }

          return (selected == null ) ? '' : selected.name;
        }
      },
    ];
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.setYearList();

    // 공통코드 가져오기
    this.getCommonList();

    // 기본 입고/출고 창고 정보 가져오기
    this.getDefaultWarehouse();

    // 창고 리스트 가져오기
    this.getWarehouseList();

    // 검색 초기화
    this.initSearch();
  }

  /*******************************************************************************
    설  명 : 연도 설정
  *******************************************************************************/
  setYearList() {
    for (let i = 2019; i <= parseInt(moment().add(1, "y").format('YYYY')); i++){
      this.yearList.push(i.toString());
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 프로젝트 규모
    this.aCommonService.getCommonListCode('PJS').then( response => {
      if( response.ResultCode ) {
        this.scaleList = response.data;
      } else {
        this.scaleList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 기본 입고/출고 창고 정보를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDefaultWarehouse() {
    this.aWarehouseService.getDefaultWarehouse().then( response => {
      if( response.ResultCode ) {
        this.defaultWarehouse = response.data[0];
      } else {
        this.defaultWarehouse = {};
      }
    });

  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.projectSeq = $event.data.seq;
    this.selectedProjectInfo = $event.data;

    this.getProjectOutStock( this.projectSeq );
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    //if( $event.colDef.field == 'seq' )
    return false;
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyOrder(params) {
    this.gridApiOrder = params.api;
    this.gridColumnApiOrder = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedOrder($event) {
  }


  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {

  }

  /*******************************************************************************
    설  명 : 오늘 날짜 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj, data ) {
    if( data == 'outdate' )
      this.search.out_date = this.calendar.getToday();
    else if( data == 'sdate' )
      this.search.sdate = this.calendar.getToday();
    else
      this.search.edate = this.calendar.getToday();

    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    this.search = {
      projectSeq: '',
      status: '1',
      scale: '',
      searchYear: moment().format('YYYY'),
      searchMonth: '',
      date: '',
      outWarehouseSeq: '',
      inWarehouseSeq: '',
      out_date: this.calendar.getToday()
    };

    this.getProjectList();

    this.getProjectOutList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.search[key] = value;

    this.getProjectList();

    this.getProjectOutList();
  }

  /*******************************************************************************
    설  명 : 검색에 사용할 프로젝트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectList() {
    let params: any = {
      type: '',
      scale: this.search.scale,
      status: this.search.status,
      searchYear: this.search.searchYear,
      searchMonth: this.search.searchMonth,
      searchGroup: '',
      searchText: ''
    };

    this.aProjectService.getProjectList(params).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        tmp.push({
          id: '',
          text: '프로젝트 검색'
        });

        for( let item of response.data ) {
          tmp.push({
            id: item.seq,
            text: item.name
          });
        }

        this.projectSelectList$.next(tmp);
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectOutList() {
    this.aProjectService.getProjectOutList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.projectList = response.data;

        // 첫번째 데이터 선택
        setTimeout(() => {
          let index = 0;
          let nodes = this.gridApi.rowRenderer.getRenderedNodes();
          for(let node of nodes ) {
            if( this.selectedProjectInfo.seq !== undefined ) {
              if( this.selectedProjectInfo.seq == node.data.seq ) {
                node.setSelected(true);

                this.selectedProjectInfo = node.data;
                this.projectSeq = this.selectedProjectInfo.seq;

                // 상세옵션 리스트 가져오기
                this.getProjectOutStock( this.projectSeq );
              }
            } else {
              if( index++ == 0 ) {
                node.setSelected(true);

                this.selectedProjectInfo = node.data;
                this.projectSeq = this.selectedProjectInfo.seq;

                // 상세옵션 리스트 가져오기
                this.getProjectOutStock( this.projectSeq );
              }
            }
          }
        }, 0);

      } else {
        this.projectList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 선택 시 출고 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectOutDetailList() {
    this.aProjectService.getProjectOutDetailList( this.projectSeq ).then( response => {
      if( response.ResultCode ) {
        this.stockHistoryList = response.data;
      } else {
        this.stockHistoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 창고 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseList() {
    this.aWarehouseService.getWarehouseList({typeSelect: '', searchText: ''}).then( response => {
      if( response.ResultCode ) {
        this.inWarehouseList = response.data;
        this.outWarehouseList = response.data;

        this.search.inWarehouseSeq = '';
        this.search.outWarehouseSeq = '';

      } else {
        this.inWarehouseList = [];
        this.outWarehouseList = [];
      }

      // ag-grid 컬럼 설정
      this.initColumnDetail();
    });
  }

  /*******************************************************************************
    설  명 : 주문서 자동 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderAdd() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "주문서를 추가할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    // 상세정보 가져오기
    this.getProjectOutDetailList();
  }

  /*******************************************************************************
    설  명 : 검색일자 처리
    입력값 : day 날짜
    리턴값 : 없음
  *******************************************************************************/
  setDate( day: any ) {
    this.search.date = day;

    switch( day ) {
      case '':
        this.search.sdate = '';
        this.search.edate = '';
        break;

      case 1:
        this.search.sdate = this.utilService.getDate( moment().format('YYYY-MM-DD') );
        break;

      case 7:
      case 15:
        this.search.sdate = this.utilService.getDate( moment().add(-1 * parseInt(day), 'day').format('YYYY-MM-DD') );
        break;

      case 30:
        this.search.sdate = this.utilService.getDate( moment().add(-1, 'month').format('YYYY-MM-DD') );
        break;

      case 60:
        this.search.sdate = this.utilService.getDate( moment().add(-2, 'month').format('YYYY-MM-DD') );
        break;
    }

    if( this.search.sdate != '' )
      this.search.edate = this.utilService.getDate( moment().format('YYYY-MM-DD') );

    this.getProjectList();

    this.getProjectOutList();
  }

  /*******************************************************************************
    설  명 : 출고일자 일괄적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOutDate() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "주문서를 추가할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    if( this.search.out_date == '' ) {
      this.toastrService.error( "일괄로 적용할 출고일자를 선택하세요" );
      return false;
    }

    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm("전체 데이터에 출고일자를 일괄로 적용하시겠습니까?") ) {
        this.gridApiDetail.selectAll()

      } else {
        this.toastrService.error( "출고일자를 일괄로 적용할 데이터를 선택하세요" );
        return false;
      }
    } else {
      if( ! confirm("선택하신 일자로 출고일자를 일괄 적용 하시겠습니까?" ) ) return false;
    }

    this.gridApiDetail.forEachNode(function(node, index) {
      if( node.selected ) {
        this.stockHistoryList[index].out_date = this.utilService.getDateStr( this.search.out_date );
        node.setData( this.stockHistoryList[index] );
      }
    }.bind(this));
  }

  /*******************************************************************************
    설  명 : 창고 일괄 적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async setWarehouse() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "주문서를 추가할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm("전체 데이터에 출고창고/입고창고를 일괄로 적용하시겠습니까?") ) {
        this.gridApiDetail.selectAll()

      } else {
        this.toastrService.error( "출고창고/입고창고를 일괄로 적용할 데이터를 선택하세요" );
        return false;
      }
    } else {
      if( ! confirm("선택하신 창고로 출고창고 / 입고창고를 일괄 적용 하시겠습니까?" ) ) return false;
    }

    var data: any = [];
    this.gridApiDetail.forEachNode( (node, index) => {
      data.push({
        index: index,
        node: node
      });
    });

    for(let node of data ) {
      if( node.node.selected ) {
        let check: boolean = false;

        if( this.search.outWarehouseSeq !== '' ) {
          await this.getProductWarehouseStock( this.stockHistoryList[node.index].product_seq, this.search.outWarehouseSeq );

          this.stockHistoryList[node.index].out_warehouse = this.search.outWarehouseSeq;
          this.stockHistoryList[node.index].stock_qty = ( this.changeStock == null ) ? 0 : this.changeStock;

          check = true;
        }

        if( this.search.inWarehouseSeq !== '' ) {
          this.stockHistoryList[node.index].in_warehouse = this.search.inWarehouseSeq;
          check = true;
        }

        if( check ) node.node.setData( this.stockHistoryList[node.index] );
      }
    }

  }

  /*******************************************************************************
    설  명 : 출고관리 - 출고저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOutStock() {
    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( ! confirm("전체 내역을 출고 저장하시겠습니까?") ) {
        this.toastrService.error("저장하실 출고내역을 선택하시기 바랍니다.", "출고저장");
        return false;
      }

      this.gridApiDetail.selectAll();

    } else {
      if( ! confirm("선택 내역을 출고 저장하시겠습니까?") ) {
        return false;
      }
    }

    this.params.projectSeq = this.projectSeq;

    let data: any = [];
    let inWarehouseCheck: boolean;

    this.gridApiDetail.forEachNode( (node) => {
      if( node.selected ) {
        if( node.data.in_warehouse == '0' || node.data.in_warehouse == '' ) {
          inWarehouseCheck = false;
        }

        data.push( node.data );
      }
    });

    if( inWarehouseCheck === false ) {
      this.toastrService.error("입고창고가 없는 출고내역이 있습니다. 다시 확인하시기 바랍니다.", "출고저장");
      return false;
    } else {
      this.params.data = data;

      this.aStockService.setOutOrderStock( this.params ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage );

          // 상세정보 가져오기
          this.getProjectOutStock( this.projectSeq );

        } else {
          this.toastrService.error( response.ResultMessage );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 프로젝트 별 출고 리스트 가져오기
    입력값 : project_seq
    리턴값 : 없음
  *******************************************************************************/
  getProjectOutStock( project_seq: any ) {
    this.aStockService.getProjectOutStock( project_seq, this.search.searchText ).then( response => {
      if( response.ResultCode ) {
        this.stockHistoryList = response.data;

        // 바코드 상품은 출고수량 0으로 설정
        this.stockHistoryList.forEach(item => {
          if( item.status == '출고대기' && item.barcode_yn == '1' ) item.out_qty = 0;
        });

        this.params.mng_out_seq = response.info.seq == undefined? '' : response.info.seq;
        this.params.instructions = response.info.instructions;
        this.params.memo = response.info.memo;
        this.params.data = response.data;

      } else {
        this.stockHistoryList = [];
        this.params.instructions = '';
        this.params.memo = '';
        this.params.data = [];
      }

      this.getOrderCompleteList();
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 별 출고취소 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delOutStock() {
    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm( "현재 출고된 내역을 전부 취소하시겠습니까?" )) {
        this.gridApiDetail.selectAll();

      } else {
        this.toastrService.error("출고취소할 출고내역을 선택하시기 바랍니다.");
        return false;
      }
    } else {
      if( ! confirm("선택하신 출고내역을 취소하시겠습니까?" ) ) return false;
    }

    nodes = this.gridApiDetail.getSelectedRows();

    let data: any = [];
    for( let node of nodes ) {
      data.push( node.seq );
    }

    this.aStockService.delOutStock( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage );

        this.getProjectOutStock( this.projectSeq );

      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });

  }

  /*******************************************************************************
    설  명 : 주문리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderCompleteList() {
    this.aApplicationService.getOrderCompleteList( this.projectSeq ).then( response => {
      if( response.ResultCode ) {
        this.applicationList = response.data;
      } else {
        this.applicationList = [];
      }

    }, error => {
      this.toastrService.error( error, '주문내역');
    });
  }

  /*******************************************************************************
    설  명 : 출고지시서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openStockOrderPrint() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "출고지시서를 출력할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    const url = '/process/delivery/add/print?seq=' + this.projectSeq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1000, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 부스별 신청내역 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOrderPrint() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "부스별 신청내역을 출력할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    const url = '/process/delivery/add/print1?seq=' + this.projectSeq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1000, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 주문요청사항 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOrderMemoPrint() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "주문요청사항을 출력할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    const url = '/process/delivery/add/print2?seq=' + this.projectSeq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1000, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 관리자가 추가한 출고 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delProduct() {
    let nodes: any = this.gridApiDetail.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error("삭제할 출고상품을 선택하시기 바랍니다.");
      return false;
    }

    var checkOutType: boolean = true;
    for(let node of nodes ) {
      if( node.out_type !== '0') checkOutType = false;
    }

    if( ! checkOutType ) {
      this.toastrService.error("관리자출고가 아닌 상품은 삭제하실 수 없습니다.");
      return false;
    }

    if( ! confirm("선택하신 출고내역을 삭제하시겠습니까?") ) {
      return false;
    }


    this.aStockService.delOutProduct( nodes ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, "상품삭제" );

        this.getProjectOutStock( this.projectSeq );
      } else {
        this.toastrService.error( response.ResultMessage, "상품삭제" );
      }
    });
  }

  /*******************************************************************************
    설  명 : 출고 상품 관리자 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addProduct() {
    if( this.projectSeq == '' ) {
      this.toastrService.error( "출고 상품을 추가할 프로젝트를 선택하시기 바랍니다." );
      return false;
    }

    // 상품 선택 모달 중복된 상품은 검색 안되도록 처리
    const modalRef = this.modalService.open(ACProductFindGridComponent, optionsXXL);

    modalRef.componentInstance.customer_seq = '';
    modalRef.componentInstance.project_seq = this.projectSeq;
    modalRef.componentInstance.mem_no = '';
    modalRef.componentInstance.part_include = true;

    modalRef.result.then(( result ) => {
      if( ! result ) {
        return false;
      }

      var index = this.stockHistoryList.length - 1;

      // 출고창고
      var out_warehouse = ( index < 0 ) ? this.defaultWarehouse.seq : this.stockHistoryList[index].out_warehouse;

      // 입고창고
      var in_warehouse = ( index < 0 ) ? this.selectedProjectInfo.warehouse_seq : this.stockHistoryList[index].in_warehouse;

      this.aProductService.getProductAttachList( out_warehouse, result ).then( response => {
        if( response.ResultCode ) {
          var data = response.data;

          // 중복 상품 체크
          let addProductInfo: any = [];
          let stockAdd: any = {};

          for( let item of data ) {

            let duplicate: any = this.stockHistoryList.filter(obj => obj.product_seq === item.product_seq);

            if( duplicate.length < 1 ) {
              stockAdd = {
                seq: null,
                out_type: '0',
                status: '출고대기',
                assembled_yn: 0,
                grd: item.grd,
                in_stock_seq: null,
                in_warehouse: in_warehouse,
                name: item.name,
                out_add_qty: '0',
                out_date: moment().format('YYYY-MM-DD'),
                out_qty: '1',
                out_stock_seq: null,
                out_warehouse: out_warehouse,
                product_org_seq: item.product_org_seq,
                product_qty: 1,
                product_seq: item.product_seq,
                qty: '0',
                stock_qty: item.stock_qty,
                type: item.type,
                type_name: item.type_name
              }

              // this.stockHistoryList.push(stockAdd);
              addProductInfo.push(stockAdd);
            }
          }

          // 출고 DB에 저장하기
          this.setOutProductInsert(addProductInfo);

          // this.gridApiDetail.setRowData( this.stockHistoryList );

        } else {
          this.toastrService.error("상품 정보를 불러오는데 실패하였습니다. 잠시 후에 다시 시도해주시기 바랍니다.");
        }
      });

      return false;
    });
  }

  /*******************************************************************************
    설  명 : 출고관리 - 상품 추가 시 출고내역 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOutProductInsert( addProductInfo: any ) {
    this.params.projectSeq = this.projectSeq;
    let data: any = [];

    this.params.data = addProductInfo;

    this.aStockService.setOutProductInsert( this.params ).then( response => {
      if( response.ResultCode ) {
        //this.toastrService.success( response.ResultMessage );
        this.getProjectOutStock( this.projectSeq );
      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });
  }

  /*******************************************************************************
    설  명 : 출고 리스트 셀 변경 시 호출 - 출고창고가 변경될 경우 재고 수량 변경을 위함
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  async onCellValueChanged( $event ) {
    if( this.isChangeWarehouse ) return false;

    const field: any = $event.colDef.field;

    //출고창고가 변경될 경우 재고 수량 변경을 위함
    if( field == 'out_warehouse' ) {
      this.isChangeWarehouse = true;

      // 변경된 창고의 재고 수량 확인
      await this.getProductWarehouseStock( $event.data.product_seq, $event.data.out_warehouse );

      this.stockHistoryList[ $event.node.rowIndex ].stock_qty = this.changeStock;

      $event.node.setData( this.stockHistoryList[ $event.node.rowIndex ] );
    }

    // 변경셀이 수량 또는 단가일 때
    if( field == 'barcode' ) {
      const barcode = $event.data.barcode;

      //let barcodeLines: any = barcode.replace(/\s/gi, "").split('\n');
      const barcodeLines: any = barcode.split('\n');

      let lineCount: number = 0;
      barcodeLines.forEach(line => {
        if( line != '' ) lineCount++;
      });

      this.stockHistoryList[ $event.node.rowIndex ].out_qty = lineCount;

      $event.node.setData( this.stockHistoryList[ $event.node.rowIndex ] );
    }
  }

  /*******************************************************************************
    설  명 : 출고창고 재고 가져오기
    입력값 : product_seq = 상품코드, warehouse_seq = 창고코드
    리턴값 : 없음
  *******************************************************************************/
  async getProductWarehouseStock( product_seq, warehouse_seq ) {
    await this.aStockService.getProductWarehouseStock( product_seq, warehouse_seq ).then( response => {
      if( response.ResultCode ) {
        this.changeStock = response.data['total_stock'];
      } else {
        this.changeStock = 0;
      }

      this.isChangeWarehouse = false;
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProjectOutStock( this.projectSeq );
    }
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setMemo() {
    this.params.projectSeq = this.projectSeq;

    this.aStockService.setMemo( this.params ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage );

        // 상세정보 가져오기
        this.getProjectOutStock( this.projectSeq );

      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedProject( event: any ) {
    this.selectedProjectInfo = {};

    this.getProjectOutList();
  }

}
