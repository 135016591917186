import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ATaxService } from '@admin/service/tax.service';

@Component({
  selector: 'app-invoice-resend',
  templateUrl: './invoice-resend.component.html',
  styleUrls: ['./invoice-resend.component.scss']
})
export class InvoiceResendComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() taxSeq: any;

  public taxInfo: any = {};
  
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      taxSeq:["", [Validators.required] ],
      reEmail:["", [Validators.required] ],
      reEmail2:["", [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public authService: AuthService,
    private aTaxService: ATaxService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      taxSeq: this.taxSeq
    });

    this.getTaxDetail();
  }

  /*******************************************************************************
    설  명 : 세금계산서 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTaxDetail() {
    this.aTaxService.getTaxDetail( this.taxSeq ).then( response => {
      if( response.ResultCode ) {
        this.taxInfo = response.data;

        this.form.patchValue({
          reEmail: this.taxInfo.reEmail,
          reEmail2: this.taxInfo.reEmail2
        });
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    }, error => {
      this.toastrService.error( error, '세금계산서 상세정보' );
    });
  }
  
  /*******************************************************************************
     설  명 : 계산서 재발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInvoiceReSend() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.aTaxService.setInvoiceReSend( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
  
}
