import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AStockService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 재고 마스터 리스트 가져오기
  getStockList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getStockList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

   // 재고 마스터 리스트 가져오기
  getStockDetailList( seq, inout, date ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getStockDetailList'
      }, {
        seq: seq,
        inout: inout,
        inout_date: date
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 재고 상세 정보 가져오기
  getStockDetailInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getStockDetailInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기초자료/기타입고 처리
  setStock( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'setStock'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고별 상품 재고 현황 가져오기
  getWarehouseStockList( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getWarehouseStockList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 바코드 발행 리스트 가져오기
  getBarcodeStockList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getBarcodeStockList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고이동 처리
  moveStock( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'moveStock'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 폐기/분실 처리
  lossStock( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'lossStock'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // 바코드 발행 처리(기존 재고)
  setBarcodeStock( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'setBarcodeStock'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기초/기타 입고 내역 삭제
  deleteStock( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'deleteStock'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기초/기타 입고 내역 삭제
  deleteStockList( list: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'deleteStockList'
      }, {
        list: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 폐기/분실 상세 내역을 가져온다.
  getLossStockDetail( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getLossStockDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고이동 - 이동출고 상세 내역을 가져온다.
  getMoveStockDetail( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getMoveStockDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 구매입고 처리
  setStockPurchase( form, list ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'setStockPurchase'
      }, {
        data: form.value,
        list: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // stock_history.seq 기준 구매입고 내역 가져오기
  getStockPurchase( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getStockPurchase'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // purchase_order.seq 기준 구매입고 내역 가져오기
  getPurchaseStock( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getPurchaseStock'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고관리 - 출고저장
  setOutOrderStock( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'setOutOrderStock'
      }, {
        project_seq: params.projectSeq,
        instructions: params.instructions,
        memo: params.memo,
        data: params.data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고관리 - 출고 리스트 가져오기
  getProjectOutStock( project_seq: any, searchText: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getProjectOutStock'
      }, {
        project_seq: project_seq,
        searchText: searchText
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고관리 - 출고취소
  delOutStock( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'delOutStock'
      }, {
        data: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고지시서 가져오기
  getStockOrderPrint( projectSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getStockOrderPrint'
      }, {
        project_seq: projectSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 프로젝트별 신청내역 가져오기
  getOrderPrint( project_seq: any, location: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getOrderPrint'
      }, {
        project_seq: project_seq,
        location: location
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 프로젝트별 주문요청내역 가져오기
  getOrderMemoPrint( project_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getOrderMemoPrint'
      }, {
        project_seq: project_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 이동 처리
  stockWarehouseMove( warehouseSeq: any, data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'stockWarehouseMove'
      }, {
        warehouseSeq: warehouseSeq,
        data: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품의 창고 재고수량
  getProductWarehouseStock( productSeq: any, warehouseSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getProductWarehouseStock'
      }, {
        productSeq: productSeq,
        warehouseSeq: warehouseSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 출고관리 - 상품추가 처리
  setOutProductInsert( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'setOutProductInsert'
      }, {
        project_seq: params.projectSeq,
        instructions: params.instructions,
        memo: params.memo,
        data: params.data
      }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  delOutProduct( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'delOutProduct'
      }, {
        data: data
      }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 출고관리 - 출고지시사항 메모 저장
  setMemo( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'setMemo'
      }, {
        mng_out_seq: params.mng_out_seq,
        project_seq: params.projectSeq,
        instructions: params.instructions,
        memo: params.memo
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

}
