/*******************************************************************************
  설  명 : 게시판
  작성일 : 2019-10-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';

import { ACommonService } from '@admin/service/common.service';
import { ABoardService } from '@admin/service/board.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class ABoardComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public memberInfo: any = {};

  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: '',
    status: ''
  };

  public linecnt: any = [];
  public groupTab: any = [];

  public boardList = [];

  public totalCount: number;

  public totalInfo: any = {
    total: 0,
    complete: 0,
    process: 0
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aCommonService: ACommonService,
    private aBoardService: ABoardService,
    private memberService: MemberService,
    private authService: AuthService,
  ) {

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.aBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/dashboard'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ) {
        switch( this.boardInfo.lvt_default ) {
          case 'A': this.params.type = 'album'; break;
          case 'G': this.params.type = 'gallery'; break;
          case 'C': this.params.type = 'calendar'; break;
          default: case 'L': this.params.type = 'list';
        }
      }

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
      else if( this.params.type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
      else if( this.params.type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');

      if( this.boardInfo.lvt_group == '1' ) {
        this.boardInfo.group = '전체|' + this.boardInfo.group;
        this.groupTab = this.boardInfo.group.split('|');
      }

      this.getBoardList();

      // 1:1 문의 경우 통계 가져오기
      if( this.boardInfo.board_id == 'qna' ) {
        this.getBoardQnaInfo();
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.aBoardService.getBoardList( this.params ).then( response => {
      //console.log( response );
      if( response.ResultCode ) {
        this.boardList = response.data;
        this.boardInfo.total_count = response.total;

        this.totalCount = parseInt( response.total );

      } else {
        this.boardList = [];
        this.boardInfo.total_count = 0;
        this.totalCount = 0;
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 타입 변경
    입력값 : type = L, A, C, G
    리턴값 : 없음
  *******************************************************************************/
  viewChange( type ) {
    this.params.type = type;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 글 쓰기 권한
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  authWriteCheck(): boolean {
    if( this.boardInfo.auth_write <= this.memberInfo.level ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 글 저장/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.router.navigate(
    ['/board/add'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.params.no = page;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( group_id ) {
    this.params.group_id = group_id;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 1:1문의 경우 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardQnaInfo() {
    this.aBoardService.getBoardQnaInfo( this.boardInfo.board_id ).then( response => {
      if( response.ResultCode ) {
        this.totalInfo = response.data;
      }
    }, error => {
      this.toastrService.error( error, "게시판" );
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 주소 검색 input enter 키 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchService( $event ) {
    if( $event.key == 'Enter' ) {
      this.getBoardList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.params[key] = value;

    this.getBoardList();
  }
}
