/*******************************************************************************
  설  명 : 거래처/회원의 발주정보 컴포넌트
  작성일 : 2019-08-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { AOrderService } from '@admin/service/order.service';

import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-customer-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class ACOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public type: boolean = true; // 거래처/회원 구분, true = 거래처, false = 회원

  selectedRows: number = 0;
  orderList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalAmt: number = 0;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aOrderService: AOrderService,
    private agGridHtmlComponent: AgGridHtmlComponent
  ) {
    this.columnDefs = [
      {headerName: '발주번호', field: 'seq', cellClass: 'cp center'  },
      {headerName: '일시', field: 'date', cellClass: 'cp center'  },
      {headerName: '발주구분', field: 'gbn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let color: string;

          if( params.data.gbn == null ) return '';
          else if( params.data.gbn == '구매발주' ) color = 'primary';
          else color = 'success';

          return '<span class="badge badge-' + color + ' f12 fn">' + params.data.gbn + '</span>';
        }
      },
      //{headerName: '프로젝트', field: 'project_name', cellClass: 'cp'  },
      {headerName: '상품', field: 'product_name', cellClass: 'cp' },
      {headerName: '수량', field: 'qty', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '단가', field: 'unit_amt', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
      {headerName: '금액', field: 'amt', cellClass: 'cp right', valueFormatter: 'Math.floor(value).toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")', valueParser: "Number(newValue)" },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 주문정보 가져오기
    this.getOrderList( this.aOrderService.selectSeq );
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 필터 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    /*
    if( $event.colDef.field == 'subject' ) {
      this.router.navigate(
        ['/admin/customer/as/view'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: $event.data.seq },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    */
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderList( seq ) {
    this.aOrderService.getOrderList( seq ).then( response => {
      this.orderList = response.data;

      if( this.orderList.length > 0 ) {
        this.orderList.forEach( ( row, index ) => {
          this.totalAmt += parseInt( ( row.amt != null ) ? row.amt : 0 );
        });
      } else {
        this.totalAmt = 0;
      }
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

}
