import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ApiResponse } from '@app/service/api-response';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',]
})
export class ALoginComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  errorResponse: ApiResponse;
  public form: FormGroup;
  public formErrors = {
    id:'',
    pw:''
  };

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      id:["", Validators.required],
      pw:["", Validators.required],
      saveId: [false, []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public memberService: MemberService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    public authService: AuthService,
    private toastrService: ToastrService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.buildForm();

    let check = this.cookieService.check('thefirstadmin');

    if( check ) {
      this.form.patchValue({
        id: this.cookieService.get('thefirstadmin'),
        saveId: true
      });
    }
  }

  /*******************************************************************************
    설  명 : 로그인 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid){
      this.memberService.login('admin', this.form.value.id, this.form.value.pw).then(response => {
        if( response['ResultCode'] == true ) {
          this.authService.login(response);

          // 아이디 저장 시
          if( this.form.controls.saveId.value ) {
            this.cookieService.set( 'thefirstadmin', this.form.controls.id.value );
          } else {
            this.cookieService.delete( 'thefirstadmin' );
          }

          this.toastrService.success('로그인에 성공하였습니다.', '로그인 성공');

          this.router.navigate(
            ['/dashboard'],
            {
              queryParams: {},
              queryParamsHandling: '', // remove to replace all query params by provided
            }
          );

          //window.location.href = '/admin/dashboard';

        } else {
          this.toastrService.error('로그인에 실패하였습니다.', '로그인 실패');
          //this.router.navigate(['/admin/login']);
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

}
