/*******************************************************************************
  설  명 : 발주 등록/수정
  작성일 : 2019-12-08
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ACommonService } from '@admin/service/common.service';
import { AStockService } from '@admin/service/stock.service';
import { APurchaseOrderService } from '@admin/service/purchase.order.service';
import { Globals } from '@app/service/globals.service';
import { AProductService } from '@admin/service/product.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@app/component/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/component/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/component/ag-grid-save/ag-grid-save.component';

import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACProductFindGridComponent } from '@admin/component/product-find-grid/product-find-grid.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class AOrderDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: string;
  public title: string = '추가';

  public form: FormGroup;
  public formErrors = {};

  orderList: any = [];
  gbnList: any = [];
  productList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  columnDetailDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  public addCustomerFunc: any;         // 거래처검색
  private today: NgbDateStruct = { year: moment().year(), month: moment().month()+1, day: moment().date() };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ["", [] ],
      date:[ this.today, [Validators.required] ],
      gbn:["", [Validators.required] ],
      gbn_seq:["", [Validators.required] ],
      customer_seq:["", [Validators.required] ],
      customer_name:["", [] ],
      memo:["", [] ],
      product: [[], [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aPurchaseOrderService: APurchaseOrderService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private aProductService: AProductService,
    public authService: AuthService
  ) {
    this.buildForm();

    this.columnDefs = [
      {headerName: '선택', field: 'product_seq', width: 80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '이미지', field: 'thumbnail_filename', cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridImageComponent' },
      {headerName: '대분류', field: 'parent_category_name', cellClass: 'cp left ag-cell50h' },
      {headerName: '중분류', field: 'category_name', cellClass: 'cp left ag-cell50h' },
      {headerName: '상품명', field: 'name', cellClass: 'cp left ag-cell50h' },
      {headerName: '바코드', field: 'barcode_yn', width: 120, cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if ( params.data.barcode_yn == '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
      {headerName: '수량(개)', field: 'qty', cellClass: 'cp right ag-cell-edit ag-cell50h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor"
      },
      {headerName: '발주단가(원)', field: 'purchase_amt', cellClass: 'cp right ag-cell-edit ag-cell50h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.purchase_amt);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor"
      },
      {headerName: '구매금액(원)', field: 'amt', cellClass: 'cp right ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let purchaseAmt = params.data.purchase_amt;
          let qty = params.data.qty;

          purchaseAmt = String(purchaseAmt).replace(/,/g, '');
          qty = String(qty).replace(/,/g, '');

          params.data.amt = qty * purchaseAmt || 0;
          var str = String(qty * purchaseAmt || 0);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.components = { numericCellEditor: this.getNumericCellEditor() };

    this.addCustomerFunc = this.searchCustomer.bind(this);
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 50;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();

    this.activatedRoute.queryParams.subscribe(params => {
      this.seq = params.seq;
      if( this.seq == '0' ) {
        this.title = '추가';
      } else {
        this.title = '수정';

        this.getPurchaseInfo( this.seq );
      }
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.gbnList = [];

    // 발주 구분
    this.aCommonService.getCommonListCode('POG').then( response => {
      if( response.ResultCode ) {
        this.gbnList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 리스트로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(['/stock/order']);
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 발주 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderAdd( seq ) {
    this.router.navigate(
    ['/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { seq: seq },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상품 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
  }

  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped( event: any ) {
    const field: any = event.colDef.field;

    // 수정모드
    if( this.seq != '0' && this.seq != '' ) {
      // 수량 변경시
      if( field == 'qty' ) {
        // 바코드상품만
        if( event.data.barcode_yn == '1' ) {
          this.toastrService.warning( '수량 변경 시 바코드 발행번호가 변경될 수 있습니다.', '바코드 상품 수량 변경 시 주의', {
            timeOut: 10000
          });
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async productAdd( seq ) {
    const modalRef = this.modalService.open(ACProductFindGridComponent, optionsXL);

    modalRef.componentInstance.customer_seq = this.form.controls.customer_seq.value;
    modalRef.componentInstance.project_seq = '';
    modalRef.componentInstance.mem_no = '';
    modalRef.componentInstance.part_include = true;

    modalRef.result.then((response) => {

      response.forEach(result => {

        // 세트상품인 경우 포함된 상품 리스트 가져오기
        if( result.type_code === '0002' ) {
          this.aProductService.getSetProductList( result.product_seq ).then( response => {
            if( response.ResultCode ) {
              const setItem: any = response.data;

              for( const item of setItem ) {
                item.qty = 1;
                item.amt = item.qty * item.purchase_amt || 0;

                this.productList.push( item );
              }

              this.gridApi.setRowData( this.productList );
              this.form.patchValue( { product: this.productList} );
            }
          }, error => {
            this.toastrService.error( error, '세트상품');
          });

        // 일반 상품 인 경우 처리
        } else {
          // 조립상품인 경우
          if( result.assembled_yn == '1' ) {
            this.aProductService.getProductAssembleList( result.product_seq ).then( response => {
              if( response.ResultCode ) {

                if( response.data[0].seq ) {
                  for( let item of response.data ) {
                    item.qty = item.product_qty;
                    item.amt = item.qty * item.purchase_amt || 0;
                    this.productList.push( item );
                  }

                  this.gridApi.setRowData( this.productList );
                  this.form.patchValue( { product: this.productList} );
                } else {
                  this.toastrService.error( '부속상품이 없습니다. 등록 후 다시 시도하시기 바랍니다.', '' );
                }

              } else {
                this.toastrService.error( response.ResultMessage, '조립상품');
              }
            }, error => {
              this.toastrService.error( error, '조립상품');
            });

          // 자체상품인 경우
          } else {
            // 수량 설정
            result.qty = 1;

            // 수량 * 발주금액 계산
            result.amt = result.qty * result.purchase_amt || 0;

            let item:any = result;

            this.productList.push( item );
            this.gridApi.setRowData( this.productList );
            this.form.patchValue( { product: this.productList} );

          }
        }

      });

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productDel() {
    let nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 상품을 선택하세요.', '상품삭제');
    } else if( confirm("선택한 상품을 삭제하시겠습니까?") ) {

      let checkIndex: any = [];

      // 선택한 상품 리스트 가져오기
      for( let item of nodes ) {
        for( let index in this.productList ) {
          if( this.productList[index].product_seq == item.data.product_seq ) checkIndex.push( index );
        }
      }

      // 선택한 상품 삭제
      for( let i = checkIndex.length - 1; i >= 0; i-- ) {
        this.productList.splice( checkIndex[i], 1 );
      }

      // 그리드에서 삭제
      this.gridApi.setRowData( this.productList );

      // 폼 데이터 수정
      this.form.patchValue( { product: this.productList} );
    }
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.form.patchValue({
        customer_seq: result.seq,
        customer_name: result.name
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 셀 숫자 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function(event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function() {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function() {};
    NumericCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };
    NumericCellEditor.prototype.focusOut = function() {
    };
    return NumericCellEditor;
  }

  /*******************************************************************************
    설  명 : 발주서 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.form.patchValue({
        date: this.ngbDateParserFormatter.format( this.form.controls.date.value )
      });

      this.aPurchaseOrderService.setPurchaseOrder(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '발주서 저장');

          this.goList();
        } else {
          this.toastrService.error( response.ResultMessage, '발주서 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '발주서 저장');
    }
  }

  /*******************************************************************************
    설  명 : 발주구분 클릭 처리
    입력값 : item = 공통코드 정보
    리턴값 : 없음
  *******************************************************************************/
  clickGbn( item ) {
    this.form.patchValue({
      gbn: item.common_code,
      gbn_seq: item.seq
    });
  }

  /*******************************************************************************
    설  명 : 발주 수정 시 데이터 불러오기
    입력값 : item = 공통코드 정보
    리턴값 : 없음
  *******************************************************************************/
  getPurchaseInfo( seq: any ) {
    this.aPurchaseOrderService.getPurchaseInfo( seq ).then( response => {

      if( response.ResultCode ) {
        let date: NgbDateStruct = {
          year: moment( response.data.date ).year(),
          month: moment( response.data.date ).month() + 1,
          day: moment( response.data.date ).date(),
        };

        response.data.date = date;

        this.form.patchValue( response.data );
        this.productList = response.data.product;

      } else {
        this.toastrService.error(response.ResultMessage, '발주서 정보 가져오기');
        this.goList();
      }
    }, error => {
      this.toastrService.error(error, '발주서 정보 가져오기');
      this.goList();
    });
  }
}
