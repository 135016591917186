/*******************************************************************************
  설  명 : 주문 상세 관리
  작성일 : 2020-08-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbInputDatepicker, NgbCalendar, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';

import { AApplicationService } from '@admin/service/application.service';
import { ACommonService } from '@admin/service/common.service';
import { AProjectService } from '@admin/service/project.service';
import { AOrderService } from '@admin/service/order.service';
import { ABoardService } from '@admin/service/board.service';
import { APaymentService } from '@admin/service/payment.service';
import { AProductService } from '@admin/service/product.service';
import { ACustomerService } from '@admin/service/customer.service';

import { ACProductFindComponent } from '@admin/component/product-find/product-find.component';
import { ACProductFindGridComponent } from '@admin/component/product-find-grid/product-find-grid.component';
import { ACMemberFindComponent } from '@admin/component/member-find/member-find.component';
import { ACCustomerFindComponent } from '@admin/component/customer-find/customer-find.component';
import { NonMemberAddComponent } from '@admin/page/order/application/non-member-add/non-member-add.component';

import * as $ from 'jquery';
import * as moment from 'moment';

import { PlaceOrderComponent } from '@admin/page/process/place/place-order/place-order.component';

const modalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const modalOptionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown modal-xxl'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-application-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class AApplicationInfoComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public orderInfo: any = {};
  public projectInfo: any = {};
  public payInfo: any = {};
  public productInfo: any = {};
  public payMethodCardList: any = [];
  public payMethodBankList: any = [];

  public isCheckedAll: boolean = false;

  public params: any = {
    seq: '',
    date_group: '',
    sdate: '',
    edate: '',
    date_index: '',
    project_seq: '',
    project_name: '',
    customer_seq: '',
    customer_name: '',
    status: '',
    searchText: '',
    taxbill_yn: '0'
  };

  public baseURL: string = config.baseUrl;

  public projectTypeList: any = [];
  public orderStatusList: any = [];
  public originProjectList: any = [];
  public projectList: any = [];

  public addMemberFunc: any;            // 회원검색
  public addCustomerFunc: any;          // 거래처검색
  public changedFunc: any;              // 전시/행사 변경시 처리 함수
  public dateChangedFunc: any;              // 주문일자 변경시 처리 함수

  private dateModel: NgbDateStruct;

  public select2Options: any = {
    multiple: false
  };

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  // 주문시 원래 주소 정보
  private addressInfo: any = {
    zipcode: '',
    address: '',
    address_detail: ''
  };


  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      old_status_code: ['', []],
      status_code: ['1000', [Validators.required]],
      taxbill_yn: ['0', []],
      hometax_date: [null, []],
      order_date: [null, []],
      estimate_date: [null, []],
      confirmation_date: [this.calendar.getToday(), []],
      customer_seq: ['', []],
      customer_name: ['', []],
      mem_no: ['', [Validators.required]],
      mem_name: ['', [Validators.required]],
      mem_hp: ['', [Validators.maxLength(20), CustomValidator.phone]],
      mem_email: ['', []],
      project_group: ['', [Validators.required]],
      project_group_code: ['', []],
      project_seq: ['', []],
      project_name: ['', []],
      rental_sdate: [null, [Validators.required]],
      rental_edate: [null, [Validators.required]],
      zipcode:['', [Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric] ],
      address:['', [Validators.maxLength(100)] ],
      address_detail:['', [Validators.maxLength(100)] ],
      project_place: ['', []],
      //delivery_amt: ['', []],
      memo: ['', [Validators.maxLength(255)]],
      admin_memo: ['', []],
      tax_yn: ['', []],
      detail: [[], []],
      install_date: [[], []],
      install_time: [[], []],
      place: [[], []],
      person_name: [[], []],
      person_hp: [[], []],
      person_email: [[], []],
      qty: [[], []],
      unit_amt: [[], []],
      dc_amt: [[], []],
      vat: [[], []],
      amt: [[], []],
      charge_status: ['', []],
      pay_method: ['', []],
      pay_amt: ['', []],
      app_date: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private aCommonService: ACommonService,
    private aApplicationService: AApplicationService,
    private aProjectService: AProjectService,
    private aOrderService: AOrderService,
    private aBoardService: ABoardService,
    public authService: AuthService,
    private aPaymentService: APaymentService,
    private aProductService: AProductService,
    private aCustomerService: ACustomerService
  ) {
    // 폼 빌드
    this.buildForm();

    this.addMemberFunc = this.searchMember.bind(this);
    this.addCustomerFunc = this.searchCustomer.bind(this);
    this.changedFunc = this.select2Changed.bind(this);
    this.dateChangedFunc = this.orderDateChanged.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 셀렉트박스 리스트 가져오기
    await this.getSelectBoxList();
  

    // 결제내역 리스트 가져오기
    this.getPayMethodList();

    await this.activatedRoute.queryParams.subscribe( async params => {
      this.params = {
        seq: params.seq,
        date_group: params.date_group,
        sdate: params.sdate,
        edate: params.edate,
        date_index: params.date_index,
        project_seq: params.project_seq,
        project_name: params.project_name,
        customer_seq: params.customer_seq,
        customer_name: params.customer_name,
        status: params.status,
        searchText: params.searchText,
        taxbill_yn: params.taxbill_yn,
        boardSeq: params.boardSeq
      };

      //this.getApplicationDetail();
      if( this.params.seq != '' ) {
        await this.getApplicationDetail();
        await this.getOrderPayList();
      } else {
        await this.insertSet();
      }
    });

    if( this.params.boardSeq ) {
      this.getEstimateView();
    }
  }

  getPayMethodList() {
    this.aPaymentService.getPayMethodList().then( response => {
      if( response.ResultCode ) {
        let card: any = [];
        for(let item of response.card ) {
          card.push({
            title: item.name,
            value: item.code
          })
        }

        let bank: any = [];
        for(let item of response.bank ) {
          bank.push({
            title: item.name,
            value: item.code
          })
        }

        this.payMethodCardList = card;
        this.payMethodBankList = response.bank;
      } else {
        this.payMethodCardList = [];
        this.payMethodBankList = [];
      }
    })
  }

  /*******************************************************************************
    설  명 : 셀렉트박스 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getSelectBoxList() {
    // 프로젝트 구분
    await this.aCommonService.getCommonListCode('PJT').then( response => {
      if ( response.ResultCode ) {
        this.projectTypeList = response.data;
      } else {
        this.projectTypeList = [];
      }
    });

    // 주문상태
    await this.aCommonService.getCommonListCode('ORS').then( response => {
      if ( response.ResultCode ) {
        let data: any = [];
        for( let item of response.data ) {
          data.push({
            title: item.common_name,
            value: item.common_code
          })
        }

        this.orderStatusList = data;
      } else {
        this.orderStatusList = [];
      }
    });

    // 전시/행사
    await this.aProjectService.getProjectList({type: '', searchGroup: '', searchText: '', status: '', sdate: '', edate: ''}).then( response => {
      if( response.ResultCode ) {
        this.originProjectList = response.data;
        let data: any = [];

        data.push({
          text: '전시/행사를 선택하세요',
          id: 0
        });

        for( let item of this.originProjectList ) {
          data.push({
            text: '[' + (item.type_code=='0001' ? '전시' : '행사') + '] ' + item.name + ' (' + item.start_date + '~' + item.end_date + ')',
            id: item.seq
          })
        }

        this.projectList = data;
        console.log(`데이터확인${JSON.stringify(data)}`);
      } else {
        this.projectList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 관리자주문 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getApplicationDetail() {
    await this.aApplicationService.getApplicationDetail( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;

        this.addressInfo = {
          zipcode: this.orderInfo.zipcode,
          address: this.orderInfo.address,
          address_detail: this.orderInfo.address_detail
        };

        // 주문일자
        if( this.orderInfo.order_date != '' && this.orderInfo.order_date != null ) {
          if( typeof this.orderInfo.order_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.order_date.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.order_date.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.order_date.substr( 8, 2 ) )
            };

            this.orderInfo.order_date = this.dateModel;
          }
        } else {
          this.orderInfo.order_date = null;
        }

        // 확정일자
        if( this.orderInfo.confirmation_date != '' && this.orderInfo.confirmation_date != null ) {
          if( typeof this.orderInfo.confirmation_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.confirmation_date.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.confirmation_date.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.confirmation_date.substr( 8, 2 ) )
            };

            this.orderInfo.confirmation_date = this.dateModel;
          }
        } else {
          this.orderInfo.confirmation_date = null;
        }

        // 견적일자
        if( this.orderInfo.estimate_date != '' && this.orderInfo.estimate_date != null ) {
          if( typeof this.orderInfo.estimate_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.estimate_date.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.estimate_date.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.estimate_date.substr( 8, 2 ) )
            };

            this.orderInfo.estimate_date = this.dateModel;
          }
        } else {
          this.orderInfo.estimate_date = null;
        }

        // 홈택스 발행일자
        if( this.orderInfo.hometax_date != '' && this.orderInfo.hometax_date != null ) {
          if( typeof this.orderInfo.hometax_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.hometax_date.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.hometax_date.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.hometax_date.substr( 8, 2 ) )
            };

            this.orderInfo.hometax_date = this.dateModel;
          }
        } else {
          this.orderInfo.hometax_date = null;
        }

        if( typeof this.orderInfo.detail != 'undefined' ) {
          if( this.orderInfo.detail.length > 0 ) {
            this.orderInfo.detail.forEach(item => {

              // 설치요청일시
              if( item.install_date != '' && item.install_date != null ) {
                if( typeof item.install_date != 'object' ) {
                  this.dateModel = {
                    year: parseInt( item.install_date.substr( 0, 4 ) ),
                    month: parseInt( item.install_date.substr( 5, 2 ) ),
                    day: parseInt( item.install_date.substr( 8, 2 ) )
                  };

                  item.install_date = this.dateModel;
                }
              } else {
                item.install_date = null;
              }

            });
          }
        }

        // 대여기간 시작날짜
        if( this.orderInfo.rental_sdate != '' && this.orderInfo.rental_sdate != null ) {
          if( typeof this.orderInfo.rental_sdate != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.rental_sdate.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.rental_sdate.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.rental_sdate.substr( 8, 2 ) )
            };

            this.orderInfo.rental_sdate = this.dateModel;
          }
        } else {
          this.orderInfo.rental_sdate = null;
        }

        // 대여기간 종료날짜
        if( this.orderInfo.rental_edate != '' && this.orderInfo.rental_edate != null ) {
          if( typeof this.orderInfo.rental_edate != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.rental_edate.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.rental_edate.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.rental_edate.substr( 8, 2 ) )
            };

            this.orderInfo.rental_edate = this.dateModel;
          }
        } else {
          this.orderInfo.rental_edate = null;
        }

        this.orderInfo.old_status_code = this.orderInfo.status_code;

        this.form.patchValue( this.orderInfo );

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

    }, error => {
      this.toastrService.error( error, '' );
    });
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 가져오기
  *******************************************************************************/
  async getOrderPayList() {
    await this.aOrderService.getOrderPayList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.projectInfo = response.data;
        this.payInfo = response.pay;

        this.form.patchValue({ app_date: this.payInfo.pay_date });

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 견적문의 상세내역 가져오기
  *******************************************************************************/
  getEstimateView() {
    this.aBoardService.getEstimateView( 'estimate', this.params.boardSeq, false ).then( response => {
      if( response.ResultCode ) {

        const boardInfo: any = response.data;
        const excelList: any = response.excel;

        // 시작날짜
        if( boardInfo.notice_sdate != '' && boardInfo.notice_sdate != null ) {
          if( typeof boardInfo.notice_sdate != 'object' ) {
            this.dateModel = {
              year: parseInt( boardInfo.notice_sdate.substr( 0, 4 ) ),
              month: parseInt( boardInfo.notice_sdate.substr( 5, 2 ) ),
              day: parseInt( boardInfo.notice_sdate.substr( 8, 2 ) )
            };

            boardInfo.rental_sdate = this.dateModel;
          }
        } else {
          boardInfo.rental_sdate = null;
        }

        // 종료날짜
        if( boardInfo.notice_edate != '' && boardInfo.notice_edate != null ) {
          if( typeof boardInfo.notice_edate != 'object' ) {
            this.dateModel = {
              year: parseInt( boardInfo.notice_edate.substr( 0, 4 ) ),
              month: parseInt( boardInfo.notice_edate.substr( 5, 2 ) ),
              day: parseInt( boardInfo.notice_edate.substr( 8, 2 ) )
            };

            boardInfo.rental_edate = this.dateModel;
          }
        } else {
          boardInfo.rental_edate = null;
        }

        boardInfo.memo = boardInfo.contents;

        this.form.patchValue( boardInfo );

        if( excelList.length > 0 ) {
          let set: any = [];

          set = this.form.controls.detail.value;

          excelList.forEach(item => {
            if( item.product_seq ) {

              // 대여기간
              set[0].rental_sdate = boardInfo.rental_sdate;
              set[0].rental_edate = boardInfo.rental_edate;

              // 설치요청일시
              if( item.install_date ) {
                const installDateTime = item.install_date.split(' ');

                this.dateModel = {
                  year: parseInt( installDateTime[0].substr( 0, 4 ) ),
                  month: parseInt( installDateTime[0].substr( 5, 2 ) ),
                  day: parseInt( installDateTime[0].substr( 8, 2 ) )
                };

                set[0].install_date = this.dateModel;
                set[0].install_time = installDateTime[1].substr(0,2) + ':00';
              }

              // 장소(부스번호)
              set[0].place = item.booth + ' ' + item.booth_name;

              // 인수담당자 정보
              set[0].person_name = item.manager_name;
              set[0].person_hp = item.manager_hp;
              set[0].person_email = item.manager_email;

              const calc_amt: number = item.discount_product_amt * item.qty;
              const dc_amt: number = item.sale_amt - item.discount_product_amt;
              const calc_vat: number = ( item.tax_yn == '1' ) ? calc_amt / 10 : 0;
              const total_amt: number = calc_amt + calc_vat;

              // 상품 정보
              set[0].product.push({
                amt: this.setComma(calc_amt),
                category_name: item.category_name,
                category_seq: item.category_seq,
                dc_amt: this.setComma(dc_amt),
                dc_rate: '',
                dc_seq: '',
                file_path: item.file_path,
                parent_seq: null,
                product_name: item.product_name,
                product_seq: item.product_seq,
                product_spec: item.product_spec,
                product_unit: item.product_unit,
                qty: item.qty,
                rental_daycount: null,
                rental_diff: boardInfo.rental_diff,
                rental_edate: boardInfo.rental_edate,
                rental_sdate: boardInfo.rental_sdate,
                seq: null,
                set_index: '0',
                status: null,
                status_code: null,
                status_name: null,
                thumbnail_filename: item.thumbnail_filename,
                total_amt: this.setComma(total_amt),
                unit_amt: this.setComma(item.sale_amt),
                vat: this.setComma(calc_vat),
                tax_yn: item.tax_yn
              });
            }
          });

          this.calcSetTotalAmt();
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 전시구분 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProjectGroup( item: any ) {
    this.form.get('project_group').setValue(item.seq);
    this.form.get('project_group_code').setValue(item.common_code);

    this.orderDateChanged();
  }

  /*******************************************************************************
    설  명 : 주문일자 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderDateChanged() {
    let data: any = [];

    data.push({
      text: '전시/행사를 선택하세요',
      id: 0
    });

    const orderDate = this.utilService.getDateStr( this.form.controls.order_date.value );
    const orderDateMonth2 = moment(orderDate).add(2, 'months').format('YYYY-MM-DD');

    const project_group_code = this.form.controls.project_group_code.value;

  // select2에 주문일자에 따라 리스트가 변하는 증상 수정 
/*
    for( let item of this.originProjectList ) {
      if( project_group_code ) {
        if( project_group_code == item.type_code ) {
        } else {
          continue;
        }
      }

      if( orderDate ) {
        if( orderDate <= item.start_date && item.start_date <= orderDateMonth2 ) {
        } else {
          continue;
        }
      }

      data.push({
        text: '[' + (item.type_code=='0001' ? '전시' : '행사') + '] ' + item.name + ' (' + item.start_date + '~' + item.end_date + ')',
        id: item.seq
      });
    }

    this.projectList = data;
 */   
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( item: any, obj: NgbInputDatepicker ) {
    item.install_date = this.calendar.getToday();
    obj.close();
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(ACMemberFindComponent, optionsXL);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        mem_name: result.data.name,
        mem_hp: result.data.hp,
        mem_email: result.data.email,
        customer_seq: result.data.customer_seq,
        customer_name: result.data.customer_name
      });

      $(window).scrollTop(0);

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        customer_seq: result.seq,
        customer_name: result.name
      });

      let set: any = [];

      set = this.form.controls.detail.value;

      this.aCustomerService.getProductDiscountList( result.seq ).then( response => {
        if( response.ResultCode ) {

          set.forEach(row => {
            row.product.forEach(item2 => {
              for( let item of response.data ) {
                if( item.seq == item2.product_seq ) {
                  item2.unit_amt = this.deleteComma(item2.unit_amt);

                  item2.discount_product_amt = parseInt(item.discount_amt);
                  item2.dc_amt = parseInt(item2.unit_amt) - item2.discount_product_amt;

                  if( item2.qty === 0 ) item2.qty = 1;

                  item2.amt = ( ( item2.unit_amt - item2.dc_amt ) * item2.qty );
                  item2.vat = ( item2.tax_yn == '1' ) ? item2.amt / 10 : 0;
                  item2.total_amt = parseInt(item2.amt) + parseInt(item2.vat);

                  item2.unit_amt = this.setComma(item2.unit_amt);
                  item2.dc_amt = this.setComma(item2.dc_amt);
                  item2.amt = this.setComma(item2.amt);
                  item2.vat = this.setComma(item2.vat);
                  item2.total_amt = this.setComma(item2.total_amt);
                }
              }
            });
          });

          this.calcSetTotalAmt();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        console.log( error );
      });

      $(window).scrollTop(0);

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 전시/행사 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  select2Changed( e: any): void {
    if( e.id == 0 ) {
      this.form.patchValue({
        project_group: '',
        project_group_code: '',
        project_seq: 0,
        rental_sdate: null,
        rental_edate: null,
        zipcode: this.addressInfo.zipcode,
        address: this.addressInfo.address,
        address_detail: this.addressInfo.address_detail
      });

    } else {
      for( let item of this.originProjectList ) {
        if( item.seq == e.id ) {
          let start_date: NgbDateStruct = this.utilService.getDate( item.start_date );
          let end_date: NgbDateStruct = this.utilService.getDate( item.end_date );

          if( item.type_code == '0002' ) {
            this.form.patchValue({
              project_group: item.type_seq,
              project_group_code: item.type_code,
              project_seq: item.seq,
              project_place: item.place,
              rental_sdate: start_date,
              rental_edate: end_date,
            });
          } else {
            this.form.patchValue({
              project_group: item.type_seq,
              project_group_code: item.type_code,
              project_seq: item.seq,
              project_place: item.place,
              rental_sdate: start_date,
              rental_edate: end_date,
              zipcode: item.zipcode,
              address: item.address,
              address_detail: item.address_detail
            });
          }

          break;
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 부스 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  insertSet() {
    let set: any = [];
    set = this.form.controls.detail.value;

    const tmp: any = {
      seq: ( typeof set[0] != 'undefined' ) ? set[0].seq : '',
      order_seq: ( typeof set[0] != 'undefined' ) ? set[0].order_seq : '',
      set_index: ( typeof set[0] != 'undefined' ) ? set.length : '',
      rental_sdate: this.orderInfo.rental_sdate,
      rental_edate: this.orderInfo.rental_edate,
      install_date: ( typeof set[0] != 'undefined' ) ? set[0].install_date : '',
      install_time: ( typeof set[0] != 'undefined' ) ? set[0].install_time : '',
      place: ( typeof set[0] != 'undefined' ) ? set[0].place : '',
      person_name: ( typeof set[0] != 'undefined' ) ? set[0].person_name : '',
      person_hp: ( typeof set[0] != 'undefined' ) ? set[0].person_hp : '',
      person_email: ( typeof set[0] != 'undefined' ) ? set[0].person_email : '',
      mng_person: ( typeof set[0] != 'undefined' ) ? set[0].mng_person : '',
      set_total_amt: 0,
      product: []
    };

    set.push( tmp );

    this.form.patchValue({
      detail: set
    });

    setTimeout(() => {
      $("#place" + (set.length - 1)).focus();
    }, 0);
  }

  /*******************************************************************************
    설  명 : 부스 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteSet() {
    let set: any = [];
    const tmp: any = [];

    set = this.form.controls.detail.value;

    let index = 0;
    set.forEach(item => {
      if( typeof item.isChecked != 'undefined' && item.isChecked === true ) {
        index++;
      } else {
        tmp.push(item);
      }
    });

    if( index < 1 ) {
      this.toastrService.error( '삭제할 부스를 선택하시기 바랍니다.', '' );
    } else {
      this.form.patchValue({
        detail: tmp
      });
    }
  }

  /*******************************************************************************
    설  명 : 부스 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addSetProduct() {
    let set: any = [];

    set = this.form.controls.detail.value;

    let index = 0;
    set.forEach(item => {
      if( typeof item.isChecked != 'undefined' && item.isChecked === true ) {
        index++;
      }
    });

    if( index < 1 ) {
      this.toastrService.error( '추가할 부스를 선택하시기 바랍니다.', '' );
    } else {
      const modalRef = this.modalService.open(ACProductFindGridComponent, optionsXXL);

      modalRef.componentInstance.customer_seq = this.form.controls.customer_seq.value;
      modalRef.componentInstance.project_seq = this.form.controls.project_seq.value;
      modalRef.componentInstance.mem_no = this.form.controls.mem_no.value;

      modalRef.result.then(( result ) => {
        result.forEach(obj => {

          set.forEach(item => {
            if( typeof item.isChecked != 'undefined' && item.isChecked === true ) {

              const duplicate = item.product.filter(function (product) {
                return product.product_seq === obj.product_seq;
              });

              if( duplicate.length > 0 ) {
                if( duplicate[0].status > 10 ) {
                  this.toastrService.error( '취소/환불 상태의 같은 상품이 존재합니다. 기존 상품을 삭제 후 등록하시기 바랍니다.', '' );
                  return false;
                } else {
                  item.product.forEach(row => {
                    if( duplicate[0].product_seq == row.product_seq ) {
                      row.qty = parseInt(row.qty) + 1;

                      this.setCalc( row );
                    }
                  });
                }
              } else {

                const calc_amt: number = obj.discount_product_amt * 1;
                const dc_amt: number = obj.sale_amt - obj.discount_product_amt;
                const calc_vat: number = ( obj.tax_yn == '1' ) ? calc_amt / 10 : 0;
                const total_amt: number = calc_amt + calc_vat;

                item.product.push({
                  amt: this.setComma(calc_amt),
                  category_name: obj.category_name,
                  category_seq: obj.category_seq,
                  dc_amt: this.setComma(dc_amt),
                  dc_rate: '',
                  dc_seq: '',
                  file_path: obj.file_path,
                  parent_seq: null,
                  product_name: obj.name,
                  product_seq: obj.product_seq,
                  product_spec: obj.spec,
                  product_unit: obj.unit,
                  qty: '1',
                  rental_daycount: null,
                  rental_diff: item.rental_diff,
                  rental_edate: item.rental_edate,
                  rental_sdate: item.rental_sdate,
                  seq: null,
                  set_index: item.set_index,
                  status: null,
                  status_code: null,
                  status_name: null,
                  thumbnail_filename: obj.thumbnail_filename,
                  total_amt: this.setComma(total_amt),
                  unit_amt: this.setComma(obj.sale_amt),
                  vat: this.setComma(calc_vat),
                  tax_yn: obj.tax_yn
                });
              }
            }
          });

        });

        this.calcSetTotalAmt();

      }, (reason) => {
        // console.log( reason );
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문정보 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderInfoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      // 상태값이 환불신청, 환불완료, 부분환불일 경우 안내 메시지 창 활성화
      let status: any = ['9000','9010','9020'];

      if( status.indexOf( this.form.controls.status_code.value ) > -1 ) {
        if( ! confirm("해당 주문내역을 환불 처리하시겠습니까?") ) {
          return false;
        }
      }

      // 결제완료 상태로 주문 시 결제정보 입력 체크
      if( this.form.controls.status_code.value == '3000' &&
          (
            !this.form.controls.pay_method.value ||
            !this.form.controls.app_date.value ||
            ( !this.form.controls.pay_amt.value && this.form.controls.pay_amt.value == '' )
          )
        ) {
        this.toastrService.error('결제정보 필수 입력항목을 확인하시기 바랍니다.', '' );
        return false;
      }

      // 상품이 있는지 체크
      let tmp: any = [];
      let check: boolean;

      tmp = this.form.controls.detail.value;

      tmp.forEach(item => {
        if( item.product.length < 1 ) {
          check = true;
        }
      });

      if( check === true ) {
        this.toastrService.error('부스마다 하나 이상의 상품을 추가하시기 바랍니다.', '' );
      } else {
        const jsonValue = JSON.stringify(this.form.value);

        this.aApplicationService.setOrderInfoSave( jsonValue ).then( async response => {
          if( response.ResultCode ) {

            // 견적문의 상태를 진행중에서 -> 완료로 변경
            if( this.params.boardSeq != '') {
              this.aBoardService.updateOrderApplication(this.params.boardSeq).then( response => {
                if( response.ResultCode ) {

                } else {
                  this.toastrService.error( response.ResultMessage );
                }
              });
            }

            this.toastrService.success( response.ResultMessage, '');

            // 신규 저장 시 seq 값이 없을 때
            if( !this.params.seq || this.params.seq == '' ) {
              this.params.seq = response.orderSeq;
              this.form.patchValue({ seq: response.orderSeq });
            }

            await this.getApplicationDetail();
            await this.getOrderPayList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 주문 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderCancel() {
    if( confirm( '주문을 취소하시겠습니까?' ) ) {
      const params: any = {
        seq: this.form.controls.seq.value,
        status_code: this.form.controls.status_code.value
      };

      this.aApplicationService.setOrderCancel( params ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );

          this.goList();
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      }, error => {
        this.toastrService.error( error, '' );
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문 공통 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailCommonInfoSave( item: any ) {
    if( confirm( '저장하시겠습니까?' ) ) {
      const params: any = {
        order_seq: item.order_seq,
        set_index: item.set_index,
        install_date: item.install_date,
        install_time: item.install_time,
        place: item.place,
        person_name: item.person_name,
        person_hp: item.person_hp,
        person_email: item.person_email
      };

      this.aApplicationService.setOrderDetailCommonInfoSave( params ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      }, error => {
        this.toastrService.error( error, '' );
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문 상품 정보 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailProductInfoSave( item: any ) {
    if( confirm( '변경 시 화면이 새로고침 됩니다. 진행하시겠습니까?' ) ) {
      const params: any = {
        seq: item.seq,
        qty: item.qty,
        unit_amt: item.unit_amt,
        dc_amt: item.dc_amt,
        vat: item.vat,
        amt: item.amt,
        tax_yn: item.tax_yn
      };

      this.aApplicationService.setOrderDetailProductInfoSave( params ).then( async response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );

          await this.getApplicationDetail();
          await this.getOrderPayList();
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      }, error => {
        this.toastrService.error( error, '' );
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문 상품 정보 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailProductInfoDelete( item: any, detailIndex: number, productIndex: number ) {
    if( item.seq !== null ) {
      if( confirm( '삭제 시 화면이 새로고침 됩니다. 진행하시겠습니까?' ) ) {
        const params: any = {
          seq: item.seq
        };

        this.aApplicationService.setOrderDetailProductInfoDelete( params ).then( async response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '' );

            await this.getApplicationDetail();
            await this.getOrderPayList();
          } else {
            this.toastrService.error( response.ResultMessage, '' );
          }
        }, error => {
          this.toastrService.error( error, '' );
        });
      }
    } else {
      if( confirm( '삭제하시겠습니까?' ) ) {
        const set = this.form.controls.detail.value;

        set[ detailIndex ].product.splice( productIndex, 1 );

        this.form.patchValue({detail: set});

        this.calcSetTotalAmt();
      }
    }
  }

  /*******************************************************************************
    설  명 : 주문 상품 수량/금액 변경 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalc( item: any ) {
    if( item.qty === 0 ) item.qty = 1;

    item.unit_amt = this.deleteComma(item.unit_amt);
    item.dc_amt = this.deleteComma(item.dc_amt);
    item.amt = this.deleteComma(item.amt);
    item.vat = this.deleteComma(item.vat);
    item.total_amt = this.deleteComma(item.total_amt);

    item.amt = ( ( item.unit_amt - item.dc_amt ) * item.qty );
    item.vat = ( item.tax_yn == '1' ) ? item.amt / 10 : 0;
    item.total_amt = parseInt(item.amt) + parseInt(item.vat);

    item.unit_amt = this.setComma(item.unit_amt);
    item.dc_amt = this.setComma(item.dc_amt);
    item.amt = this.setComma(item.amt);
    item.vat = this.setComma(item.vat);
    item.total_amt = this.setComma(item.total_amt);

    this.calcSetTotalAmt();
  }

  /*******************************************************************************
    설  명 : 주문 상품 부가세 변경 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalcVat( item: any ) {
    if( item.qty === 0 ) item.qty = 1;

    item.unit_amt = this.deleteComma(item.unit_amt);
    item.dc_amt = this.deleteComma(item.dc_amt);
    item.amt = this.deleteComma(item.amt);
    item.vat = this.deleteComma(item.vat);
    item.total_amt = this.deleteComma(item.total_amt);

    item.amt = ( ( item.unit_amt - item.dc_amt ) * item.qty );
    item.total_amt = parseInt(item.amt) + parseInt(item.vat);

    item.unit_amt = this.setComma(item.unit_amt);
    item.dc_amt = this.setComma(item.dc_amt);
    item.amt = this.setComma(item.amt);
    item.vat = this.setComma(item.vat);
    item.total_amt = this.setComma(item.total_amt);

    this.calcSetTotalAmt();
  }

  /*******************************************************************************
     설  명 : 소계 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  calcSetTotalAmt() {
    let set: any = [];

    set = this.form.controls.detail.value;

    set.forEach(item => {
      let setTotalAmt = 0;
      item.product.forEach(item2 => {
        setTotalAmt += parseInt(this.deleteComma(item2.total_amt));
      });

      item.set_total_amt = setTotalAmt;
    });
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  removeCommas( row: any, key: any ) {
    row[ key ] = this.deleteComma(row[ key ]);
  }

  /*******************************************************************************
    설  명 : 콤마 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCommas( row: any, key: any ) {
    row[ key ] = this.setComma(row[ key ]);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  setComma( value: any ) {
    let str = String(value);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  deleteComma( value: any ) {
    let str = String(value);
    return str.replace(/,/g, '');
  }

  /*******************************************************************************
    설  명 : 전체 체크박스 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCheckedAll( event: any ) {
    const checked: boolean = event.target.checked;

    let set: any = [];
    set = this.form.controls.detail.value;

    set.forEach(item => {
      item.isChecked = checked;
    });
  }

  /*******************************************************************************
    설  명 : 계산서 발행여부 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTaxbillYn( value: any ) {
    this.form.get('taxbill_yn').setValue( value );

    if( value == '0' ) this.form.get('hometax_date').setValue(null);
  }

  /*******************************************************************************
    설  명 : 배송료 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeliveryAmtAdd() {
    if( this.form.controls.status_code.value == '1000' || this.form.controls.status_code.value == '2000' ) {
      this.aProductService.getCategoryProductDiscountList( this.form.controls.mem_no.value, this.form.controls.project_seq.value ).then( response => {
        if( response.ResultCode ) {

          response.data.forEach(item => {
            if( item.category_code == '009' ) {   // 관리상품(category_code:009)
              item.sub.forEach(subItem => {
                if( subItem.product_seq == '1' ) {    // 배송료(product.seq:1)
                  this.productInfo = subItem;
                }
              });
            }
          });

          let set: any = [];

          set = this.form.controls.detail.value;

          const duplicate = set[0].product.filter(function (product) {
            return product.product_seq === '1';
          });

          if( duplicate.length > 0 ) {
            this.toastrService.error( '이미 배송료가 추가되어 있습니다.', '' );
            return false;
          } else {
            // 상품 정보
            set[0].product.push({
              amt: this.setComma(this.productInfo.sale_amt),
              category_name: this.productInfo.category_name,
              category_seq: this.productInfo.category_seq,
              dc_amt: '0',
              dc_rate: '0',
              dc_seq: '0',
              file_path: this.productInfo.file_path,
              parent_seq: null,
              product_name: this.productInfo.name,
              product_seq: this.productInfo.product_seq,
              product_spec: this.productInfo.spec,
              product_unit: this.productInfo.unit,
              qty: 1,
              rental_daycount: null,
              rental_diff: ( this.orderInfo.detail[0] ) ? this.orderInfo.detail[0].rental_diff : null,
              rental_edate: ( this.orderInfo.detail[0] ) ? this.orderInfo.detail[0].rental_edate : null,
              rental_sdate: ( this.orderInfo.detail[0] ) ? this.orderInfo.detail[0].rental_sdate : null,
              seq: null,
              set_index: '0',
              status: null,
              status_code: null,
              status_name: null,
              thumbnail_filename: this.productInfo.thumbnail_filename,
              total_amt: this.setComma(this.productInfo.total_amt),
              unit_amt: this.setComma(this.productInfo.sale_amt),
              vat: ( this.productInfo.tax_yn == '1' ) ? this.setComma(this.productInfo.sale_amt / 10) : 0,
              tax_yn: this.productInfo.tax_yn
            });
          }

        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      }, error => {
        this.toastrService.error( error, '' );
      });

    } else {
      if( this.form.controls.project_seq.value == '' ) {
        this.toastrService.error("프로젝트를 선택하시기 바랍니다.");
        return false;
      }

      // 상품 선택 모달 중복된 상품은 검색 안되도록 처리
      const modalRef = this.modalService.open(PlaceOrderComponent, modalOptionsXL);

      modalRef.componentInstance.projectSeq = this.form.controls.project_seq.value;
      modalRef.componentInstance.orderSeq = this.form.controls.seq.value;
      modalRef.componentInstance.setIndex = '';
      modalRef.componentInstance.statusCode = '2000';
      modalRef.componentInstance.deliveryAdd = true;

      modalRef.result.then(( result ) => {
        if( result ) {
          this.router.navigate(
            ['/order/application'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {},
              queryParamsHandling: '', // remove to replace all query params by provided
            });
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 견적서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openEstimate(num) {
    const url = '/order/print/estimate?seq=' + this.form.controls.seq.value  + '&memNo=' + this.form.controls.mem_no.value + '&email=' + this.form.controls.mem_email.value + '&type=' + num;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=790, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 세금계산서 발행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  taxInvoice() {
    if( this.orderInfo.taxbill_yn == '1' ) {
      this.toastrService.error( '이미 발행된 주문입니다.', '계산서발행');
      return false;
    }

    this.router.navigate(
    ['/order/tax/invoice'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.params.seq = '';
    this.params.boardSeq = '';

    this.router.navigate(
    ['/order/application'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 비회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nonMemberAdd() {
    const modalRef = this.modalService.open(NonMemberAddComponent, options);

    modalRef.result.then((result) => {
      this.form.patchValue({
        mem_no: result.mem_no,
        mem_name: result.name,
        mem_hp: result.hp,
        mem_email: result.email
      });

    }, (reason) => {
      // console.log( reason );
    });
  }

  setStatusCode( value: any ) {
    if( value == '3000' ) {
      this.form.patchValue({
        status_code: value,
        pay_amt: this.orderInfo.sum_total_amt
      });
    } else {
      this.form.patchValue({status_code: value});
    }
  }

}
